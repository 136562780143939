import { PayloadAction } from "@reduxjs/toolkit";

export interface IPostThanksGivingState {
  pending: boolean;
  error: any;
  error_message: string | null;
  hasPosted: boolean;
  text: string;
  users: number[];
  anonymous: boolean;
}

export interface IPostThanksGivingStatePending {
  text: string;
  anonymous: boolean;
  users: number[];
}

export interface IPostThanksGivingStateSuccess {
  error_message?: string | null;
}

export interface IPostThanksGivingStateError {
  error: any;
}

export const initialState: IPostThanksGivingState = {
  pending: false,
  error: null,
  error_message: null,
  hasPosted: false,
  text: "",
  users: [],
  anonymous: false,
};

export const reducers = {
  postThanksGiving: (
    state: IPostThanksGivingState,
    action: PayloadAction<IPostThanksGivingStatePending>
  ) => {
    return {
      ...state,
      hasPosted: false,
      pending: true,
      error: null,
      ...(action.payload || {}),
    };
  },
  postThanksGivingSuccess: (
    state: IPostThanksGivingState,
    action: PayloadAction<IPostThanksGivingStateSuccess>
  ) => {
    const { error_message } = action.payload || {};

    return {
      ...state,
      pending: false,
      hasPosted: !error_message?.length,
      error_message: error_message || null,
    };
  },
  postThanksGivingError: (
    state: IPostThanksGivingState,
    action: PayloadAction<IPostThanksGivingStateError>
  ) => ({
    ...state,
    pending: false,
    hasPosted: false,
    error: action.payload.error,
  }),

  //RESET
  resetPostThanksGiving: (state: IPostThanksGivingState) => ({
    ...state,
  }),
  resetPostThanksGivingSuccess: (state: IPostThanksGivingState) => ({
    ...state,
    pending: false,
    error: null,
    error_message: null,
    hasPosted: false,
    text: "",
    users: [],
    anonymous: false,
  }),
  resetPostThanksGivingError: (state: IPostThanksGivingState) => ({
    ...state,
  }),
};
