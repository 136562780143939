import {
  format,
  startOfISOWeek,
  endOfISOWeek,
  eachDayOfInterval,
} from "date-fns";
import { TableRow, TableCell } from "@mui/material";

interface IHeaderProps {
  currentDate: Date;
}

export function Header(props: IHeaderProps) {
  const { currentDate } = props;

  const startWeek = startOfISOWeek(currentDate);
  const endWeek = endOfISOWeek(currentDate);
  const daysOfWeek = eachDayOfInterval({
    start: startWeek,
    end: endWeek,
  });

  return (
    <TableRow>
      <TableCell sx={{ fontWeight: 500 }}>Project</TableCell>
      <TableCell>Sum</TableCell>
      {daysOfWeek.map((day) => (
        <TableCell key={format(day, "EEE d")}>{format(day, "EEE d")}</TableCell>
      ))}
    </TableRow>
  );
}
