import { DayView } from "../DayView";

interface ISelectedDayViewProps {
  timeButton: any;
  currentDate: Date;
  outageHours: any;
  projectsHours: any;
  totalColumnHours: any;
}

export function SelectedDayView(props: ISelectedDayViewProps) {
  const {
    timeButton,
    currentDate,
    outageHours,
    projectsHours,
    totalColumnHours,
  } = props;

  return (
    <DayView
      day={currentDate}
      timeButton={timeButton}
      outageHours={outageHours}
      projectsHours={projectsHours}
      totalColumnHours={totalColumnHours}
    />
  );
}
