import { PayloadAction } from "@reduxjs/toolkit";

export interface ITimeOffAvailableDaysState {
  pending: boolean;
  error: any;
  type: string;
  days: number;
  message: string;
}

export interface ITimeOffAvailableDaysStatePending {
  type: string | number;
  start_date?: string; // format dd/mm/yyyy
}

export interface ITimeOffAvailableDaysStateSuccess {
  paid_time_off: number;
  health_time_off: number;
}

export interface ITimeOffAvailableDaysStateError {
  error: any;
}

export const initialState: ITimeOffAvailableDaysState = {
  pending: false,
  error: null,
  days: 0,
  type: "",
  message: "",
};

export const reducers = {
  getTimeOffAvailableDays: (
    state: ITimeOffAvailableDaysState,
    action: PayloadAction<ITimeOffAvailableDaysStatePending>
  ) => ({
    ...state,
    days: 0,
    type: "",
    message: "",
    error: "",
    pending: true,
  }),
  getTimeOffAvailableDaysSuccess: (
    state: ITimeOffAvailableDaysState,
    action: PayloadAction<ITimeOffAvailableDaysStateSuccess>
  ) => ({
    ...state,
    ...(action.payload || {}),
    pending: false,
  }),
  getTimeOffAvailableDaysError: (
    state: ITimeOffAvailableDaysState,
    action: PayloadAction<ITimeOffAvailableDaysStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),

  //RESET
  resetTimeOffAvailableDays: (state: ITimeOffAvailableDaysState) => ({
    ...state,
    days: 0,
    message: "",
    type: "",
    error: "",
  }),
  resetTimeOffAvailableDaysSuccess: (state: ITimeOffAvailableDaysState) => ({
    ...state,
  }),
  resetTimeOffAvailableDaysError: (state: ITimeOffAvailableDaysState) => ({
    ...state,
  }),
};
