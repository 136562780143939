import { styled } from "@mui/material/styles";
import { IconButton, DialogActions } from "@mui/material";

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2.25),
  right: theme.spacing(2),
  color: theme.modeColors({
    dark: theme.palette.common.white,
    light: theme.palette.primary.main,
  }),
}));

export const Actions = styled(DialogActions)(({ theme }) => ({
  gap: theme.spacing(2),

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",

    "& button, & a": {
      width: "100%",
    },

    "& > :not(:first-of-type)": {
      marginLeft: 0,
    },
  },
}));

export const MessageWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

export const MessageTitle = styled("div")(({ theme }) => ({
  fontWeight: 900,
  marginTop: theme.spacing(1),

  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const MessageDesc = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.darkGrey.main,
  fontWeight: 500,
  marginBottom: theme.spacing(2),
  maxWidth: 205,
}));

export const MessagetButtonWrapp = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(2),
}));
