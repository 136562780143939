import { PayloadAction } from "@reduxjs/toolkit";

export interface IPopularAuthor {
  avatar: string;
  name: string;
  likes: number;
  posts: number;
}

export interface IPopularAuthorsState {
  pending: boolean;
  error: any;
  authors: IPopularAuthor[];
}

export interface IPopularAuthorsStateSuccess {
  authors: IPopularAuthor[];
}

export interface IPopularAuthorsStateError {
  error: any;
}

export const initialState: IPopularAuthorsState = {
  pending: false,
  error: "",
  authors: [],
};

export const reducers = {
  getPopularAuthors: (state: IPopularAuthorsState) => {
    return {
      ...state,
      pending: true,
      error: "",
    };
  },
  getPopularAuthorsSuccess: (
    state: IPopularAuthorsState,
    action: PayloadAction<IPopularAuthorsStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      authors: action.payload.authors,
    };
  },
  getPopularAuthorsError: (
    state: IPopularAuthorsState,
    action: PayloadAction<IPopularAuthorsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
