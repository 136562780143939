import { PayloadAction } from "@reduxjs/toolkit";

export interface IMoodLiterature {
  image: string;
  title: string;
  link: string;
  author: string;
}

export interface IMoodLiterature {
  literature: IMoodLiterature[];
}

export interface IMoodLiteratureState {
  error: any;
  pending: boolean;
  literature: IMoodLiterature[];
}

export interface IMoodLiteratureStateError {
  error: any;
}

export const initialState: IMoodLiteratureState = {
  error: "",
  pending: false,
  literature: [],
};

export const reducers = {
  getMoodLiterature: (state: IMoodLiteratureState) => ({
    ...state,
    pending: true,
    error: "",
  }),
  getMoodLiteratureSuccess: (
    state: IMoodLiteratureState,
    action: PayloadAction<IMoodLiterature>
  ) => {
    return {
      ...state,
      pending: false,
      literature: action.payload?.literature,
    };
  },
  getMoodLiteratureError: (
    state: IMoodLiteratureState,
    action: PayloadAction<IMoodLiteratureStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload,
  }),
};
