import { PayloadAction } from "@reduxjs/toolkit";
import { transformSurveys } from "./helpers";

export interface ISurveysListItem {
  url: string;
  name: string;
  expire_date: string;
  status: string; // passed, missed
  state: string; // actual, finished
}

export interface ISurveysListState {
  pending: boolean;
  error: any;
  // surveys: Array<ISurveysListItem>;
  surveys: {
    actual: Array<ISurveysListItem>;
    finished: Array<ISurveysListItem>;
  };
}

export interface ISurveysListStateSuccess {
  surveys: Array<ISurveysListItem>;
}

export interface ISurveysListStateError {
  error: any;
}

export const initialState: ISurveysListState = {
  pending: false,
  error: "",
  surveys: {
    actual: [],
    finished: [],
  },
};

export const reducers = {
  getSurveysList: (state: ISurveysListState) => {
    return {
      ...state,
      pending: true,
      error: "",
      surveys: {
        actual: [],
        finished: [],
      },
    };
  },
  getSurveysListSuccess: (
    state: ISurveysListState,
    action: PayloadAction<ISurveysListStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      surveys: transformSurveys(action?.payload?.surveys || []),
    };
  },
  getSurveysListError: (
    state: ISurveysListState,
    action: PayloadAction<ISurveysListStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
