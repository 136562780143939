import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IContactsState } from "./reducers";

export const contactsSelector = (state: RootState): IContactsState =>
  state.contacts;

export const selectContacts = createSelector(
  contactsSelector,
  (contactsState: IContactsState) => ({ ...contactsState })
);
