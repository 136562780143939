import {
  Container,
  TimeOffHead,
  TimeOffDays,
  TimeOffHistory,
  Page,
} from "@/components";

export function TimeOff() {
  return (
    <Page>
      <Container>
        <TimeOffHead />

        <TimeOffDays />

        <TimeOffHistory />
      </Container>
    </Page>
  );
}
