import { styled } from "@mui/material/styles";
import { Description } from "@/shared/components";

export const FaqArticleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const FaqArticleContent = styled("div")(({ theme }) => ({
  flex: 1,
}));

export const FaqArticleSidebar = styled("div")(({ theme }) => ({
  width: "270px",
  marginLeft: "120px",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: "0",
  },
}));

export const FaqArticleDate = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontWeight: 500,
  marginBottom: theme.spacing(0.5),
}));

export const FaqArticleDescription = styled(Description)(({ theme }) => ({
  fontWeight: 400,

  "& img": {
    display: "block",
    width: "auto",
    maxWidth: "100%",
  },
}));

export const FaqArticleActions = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(4),
}));
