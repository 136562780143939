import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { IMarketplaceState } from "./reducers";
import {
  getMarketplace,
  getMarketplaceError,
  getMarketplaceSuccess,
} from "./slice";
import { getMarketplaceRequest } from "./api";

function* getMarketplaceSaga(action: PayloadAction<IMarketplaceState>) {
  try {
    const Marketplace: IMarketplaceState = yield call(
      getMarketplaceRequest,
      action.payload
    );

    yield put(getMarketplaceSuccess(Marketplace));
  } catch (error) {
    yield put(getMarketplaceError({ error }));
  }
}

function* MarketplaceWatcher() {
  yield takeLatest(getMarketplace.type, getMarketplaceSaga);
}

export default MarketplaceWatcher;
