import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TimesheetActivityActions } from "@/store/redux";
import {
  IPostTimesheetActivityStateSuccess,
  IPostTimesheetActivityStatePending,
} from "./reducers";
import {
  postTimesheetActivity,
  postTimesheetActivityError,
  postTimesheetActivitySuccess,
  resetPostTimesheetActivity,
  resetPostTimesheetActivityError,
  resetPostTimesheetActivitySuccess,
} from "./slice";
import {
  postTimesheetActivityRequest,
  putTimesheetActivityRequest,
} from "./api";

function* postTimesheetActivitySaga(
  action: PayloadAction<IPostTimesheetActivityStatePending>
) {
  try {
    const { id, start_date, period, ...restPayload } = action.payload;
    const apiMethod = id
      ? putTimesheetActivityRequest
      : postTimesheetActivityRequest;
    const postTimesheetActivity: IPostTimesheetActivityStateSuccess =
      yield call(apiMethod, { id, start_date, ...restPayload });

    yield put(postTimesheetActivitySuccess(postTimesheetActivity));

    yield put(TimesheetActivityActions.getTimesheetActivity(period));
  } catch (error) {
    yield put(postTimesheetActivityError({ error }));
  }
}

function* resetPostTimesheetActivitySaga() {
  try {
    yield put(resetPostTimesheetActivitySuccess());
  } catch (error) {
    yield put(resetPostTimesheetActivityError());
  }
}

function* PostTimesheetActivityWatcher() {
  yield takeLatest(postTimesheetActivity.type, postTimesheetActivitySaga);
  yield takeLatest(
    resetPostTimesheetActivity.type,
    resetPostTimesheetActivitySaga
  );
}

export default PostTimesheetActivityWatcher;
