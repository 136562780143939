import { useSelector } from "react-redux";
import { selectUserProjects } from "@/store/redux";
import { Select } from "@/shared/components";
import { IFieldProps } from "../interfaces";
import { GenerateField } from "./GenerateField";

export function ProjectField(props: IFieldProps) {
  const { pending: postPending, selectedEvent } = props;

  const { projects, pending } = useSelector(selectUserProjects);
  const hasDisabled =
    pending || !projects?.length || Boolean(postPending || selectedEvent?.id);
  const transformedProjects = (projects || []).map(({ id, name }) => ({
    value: id,
    title: name,
  }));

  return (
    <GenerateField
      name="project_id"
      render={({ field }) => (
        <Select
          {...field}
          fullWidth
          disabled={hasDisabled}
          variant="filled"
          label="Project"
          options={transformedProjects}
        />
      )}
    />
  );
}
