import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TimeOffListActions, TimeOffActions } from "@/store/redux";
import { ICancelTimeOffStatePending } from "./reducers";
import {
  cancelTimeOff,
  cancelTimeOffError,
  cancelTimeOffSuccess,
} from "./slice";
import { cancelTimeOffRequest } from "./api";

function* cancelTimeOffSaga(action: PayloadAction<ICancelTimeOffStatePending>) {
  try {
    const { id, filters } = action.payload;

    yield call(cancelTimeOffRequest, id);

    yield put(cancelTimeOffSuccess());

    yield put(TimeOffActions.getTimeOff());
    yield put(TimeOffListActions.getTimeOffList({ ...filters, poffset: 0 }));
  } catch (error) {
    yield put(cancelTimeOffError({ error }));
  }
}

function* CancelTimeOffWatcher() {
  yield takeLatest(cancelTimeOff.type, cancelTimeOffSaga);
}

export default CancelTimeOffWatcher;
