import { Link, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Title } from "@/shared/components";

export const BrandHead = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),

  [theme.breakpoints.down("smd")]: {
    gap: theme.spacing(3),
    alignItems: "center",
  },
}));

export const BrandHeadContent = styled("div")(({ theme }) => ({
  flex: 1,
}));

export const BrandAvatarWrapper = styled("div")(({ theme }) => ({
  width: 174,
  height: 174,

  [theme.breakpoints.down("smd")]: {
    width: 90,
    height: 90,
  },
}));

export const BrandAvatar = styled(Avatar)(({ theme }) => ({
  border: "2px solid #E6E6E6",
  width: 174,
  height: 174,
  borderRadius: "50%",
  overflow: "hidden",

  [theme.breakpoints.down("smd")]: {
    border: "1px solid #E6E6E6",
    width: 90,
    height: 90,
  },
}));

export const BrandTitle: any = styled(Title)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(60),
  lineHeight: 1,
  marginBottom: theme.spacing(1),

  [theme.breakpoints.down("smd")]: {
    fontSize: theme.typography.pxToRem(30),
  },
}));

export const BrandLink = styled(Link)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: 700,
}));

export const BrandDesc = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: "67%",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const BrandQuote = styled("div")(({ theme }) => ({
  background: theme.palette.yellowBlue.main,
  padding: theme.spacing(0.5),
  margin: theme.spacing(4, 0),
}));

export const BrandQuoteBackground = styled("div")(({ theme }) => ({
  background: theme.modeColors({
    dark: theme.palette.primary.main,
    light: theme.palette.common.white,
  }),
  padding: theme.spacing(4, 5),

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2, 1.5),
  },

  "& em": {
    fontStyle: "normal",
  },
}));

export const BrandImage = styled("img")(({ theme }) => ({
  display: "block",
  width: "100%",
  marginTop: theme.spacing(3),
}));
