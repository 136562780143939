import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TableHeadCellTitle } from "../MyDocuments.styled";

interface IHeaderTitleProps {
  title: string;
  name: "name" | "modifiedDate";
  orderByField: "name" | "modifiedDate";
  sortType: "asc" | "desc";
  onSort: (k: "name" | "modifiedDate") => void;
}

export function HeaderTitle(props: IHeaderTitleProps) {
  const { title, name, orderByField, sortType, onSort } = props;

  const hasAscSort = sortType === "asc";
  const hasOrder = name === orderByField;
  const sortIcon = hasAscSort ? (
    <KeyboardArrowDownIcon />
  ) : (
    <KeyboardArrowUpIcon />
  );

  const handleOnSort = () => {
    onSort(name);
  };

  return (
    <TableHeadCellTitle onClick={handleOnSort}>
      <span>{title}</span>

      {hasOrder ? sortIcon : null}
    </TableHeadCellTitle>
  );
}
