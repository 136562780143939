import { PayloadAction } from "@reduxjs/toolkit";
import { FAQ } from "@/routes/consts";

export interface IFaqArticle {
  id: number;
  title: string;
  created_at: string;
  description: string;
  video_url: string;
  likes: number;
  has_like: boolean;
}

export interface IFaqArticleState {
  pending: boolean;
  error: any;
  article: IFaqArticle;
}

export interface IFaqArticleStateError {
  error: any;
}

export const initialState: IFaqArticleState = {
  pending: false,
  error: null,
  article: {} as IFaqArticle,
};

export const reducers = {
  getFaqArticle: (
    state: IFaqArticleState,
    action: PayloadAction<string | number>
  ) => {
    return {
      ...state,
      article: {} as IFaqArticle,
      pending: true,
      error: "",
    };
  },
  getFaqArticleSuccess: (
    state: IFaqArticleState,
    action: PayloadAction<IFaqArticle>
  ) => {
    const transformedArticle = {
      ...(action?.payload || {}),
      href: `${FAQ}/${action?.payload?.id}`,
    };

    return {
      ...state,
      pending: false,
      article: transformedArticle,
    };
  },
  getFaqArticleError: (
    state: IFaqArticleState,
    action: PayloadAction<IFaqArticleStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
