import { styled } from "@mui/material/styles";

export const DropzoneWrapper = styled("div")<{ $hasDragged: boolean }>(
  ({ theme, $hasDragged }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    border: `1px solid ${theme.palette.darkGrey.main}`,
    background: $hasDragged
      ? theme.palette.yellowBlue.main
      : theme.modeColors({ dark: "#232323", light: "#e6e6e6" }),

    padding: theme.spacing(3),
  })
);

export const Title = styled("div")(({ theme }) => ({
  color: theme.modeColors({ dark: "#eee", light: "#000" }),
  fontSize: "0.875rem",
  textAlign: "center",
  textDecoration: "underline",
  marginBottom: theme.spacing(0.5),
}));

export const Subtitle = styled("div")(({ theme }) => ({
  fontSize: "0.75rem",
  textAlign: "center",
  color: theme.modeColors({ dark: "#eee", light: "#000" }),
}));
