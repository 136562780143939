import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IMarketplaceState } from "./reducers";

export const marketplaceSelector = (state: RootState): IMarketplaceState =>
  state.marketplace;

export const selectMarketplace = createSelector(
  marketplaceSelector,
  (marketplaceState: IMarketplaceState) => ({ ...marketplaceState })
);
