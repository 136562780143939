import { useState } from "react";
import { useTheme, useMediaQuery, InputLabel } from "@mui/material";
import {
  Title,
  Carousel,
  GradientButton,
  MarketplaceLabel,
} from "@/shared/components";
import { IMarketplaceBrandProduct, CartActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { ProductOptions, IOptions } from "./MarketPlaceBrandProductOptions";
import {
  ListItem,
  ListItemContent,
  ListItemImage,
  ListItemPrice,
} from "./MarketPlaceBrandProducts.styled";

export function ProductItem(props: IMarketplaceBrandProduct) {
  const { id, images, description, sizes, price, title, quantity } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { postCart } = useActions<typeof CartActions>(CartActions);

  const [hasError, setHasError] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<IOptions>({
    quantity: 1,
  });

  const hasCartDisabled = !(selectedOptions?.quantity && selectedOptions?.size);
  const hasInfinity = images?.length > 1;
  const hasDots = !isMobile && hasInfinity;

  const slides = (images || []).map((image) => (
    <img key={image} src={image} alt="" />
  ));

  const handleOnOptionsChange = (options: IOptions) => {
    setHasError(false);

    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      ...options,
    }));
  };

  const handleOnCart = () => {
    const { quantity, size } = selectedOptions;

    if (hasCartDisabled) {
      setHasError(true);
      return;
    }

    postCart({ id, quantity, size });
  };

  return (
    <ListItem>
      <ListItemImage>
        <MarketplaceLabel quantity={quantity} />

        <Carousel infinite={hasInfinity} dots={hasDots} slides={slides} />
      </ListItemImage>
      <ListItemContent>
        <div>
          <Title noTransform fontWeight="500">
            {title}
          </Title>

          <ListItemPrice>$ {price}</ListItemPrice>
          <ProductOptions
            sizes={sizes}
            quantity={quantity}
            hasError={hasError}
            onOptionsChange={handleOnOptionsChange}
          />

          {hasError ? (
            <InputLabel error shrink sx={{ mt: 1.25 }}>
              Select required fields
            </InputLabel>
          ) : null}
        </div>

        <GradientButton
          sx={{ width: isMobile ? "100%" : 175 }}
          onClick={handleOnCart}
        >
          ADD TO CART
        </GradientButton>

        <div dangerouslySetInnerHTML={{ __html: description }} />
      </ListItemContent>
    </ListItem>
  );
}
