import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { HotTopicsActions } from "@/store/redux";
import { INewsArticleLike } from "./reducers";
import {
  postNewsArticleLike,
  postNewsArticleLikeError,
  postNewsArticleLikeSuccess,
} from "./slice";
import { postNewsArticleLike as postNewsArticleLikeApi } from "./api";

function* postNewsArticleLikeSaga(action: PayloadAction<number>) {
  try {
    const like: INewsArticleLike = yield call(
      postNewsArticleLikeApi,
      action.payload
    );

    yield put(postNewsArticleLikeSuccess(like));

    yield put(HotTopicsActions.getHotTopics());
  } catch (error) {
    yield put(postNewsArticleLikeError({ error }));
  }
}

function* NewsArticleLikeWatcher() {
  yield takeLatest(postNewsArticleLike.type, postNewsArticleLikeSaga);
}

export default NewsArticleLikeWatcher;
