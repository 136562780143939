import { styled } from "@mui/material/styles";
import { Button, TextField } from "@mui/material";
import { GradientButton } from "@/shared/components";

export const CommentsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  margin: theme.spacing(9, 0, 3),
}));

export const CommentItemStyled = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

export const CommentHead = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const CommentBody = styled("div")(({ theme }) => ({
  margin: theme.spacing(3, 0, 0),
  fontWeight: 400,
  wordBreak: "break-word",
}));

export const CommentFooter = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, -1, 0),
}));

export const CommentAuthor = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginRight: theme.spacing(1.5),
}));

export const CommentAuthorName = styled("div")(({ theme }) => ({
  fontWeight: 700,
}));

export const CommentDate = styled("div")(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.darkGrey.main,
}));

export const CommentLikes = styled(Button)(({ theme }) => ({
  fontFamily: "Druk",
  fontStyle: "italic",
  fontSize: "30px",
  fontWeight: 400,
  lineHeight: 1,
  borderRadius: 32,
}));

export const CommentReplyButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  textTransform: "none",
  fontSize: "1rem",
  borderRadius: 32,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
}));

export const CommentSubItemsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  marginLeft: theme.spacing(4),
}));

export const AddCommentForm = styled("form")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const AddCommentField = styled(TextField)(({ theme }) => ({
  padding: theme.spacing(3, 0),

  "& fieldset": {
    border: "none",
  },

  "& .MuiInputBase-root": {
    padding: 0,
  },
}));

export const AddCommentButton = styled(GradientButton)(({ theme }) => ({
  minWidth: 170,

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const ReplyFormWrapper = styled("div")(({ theme }) => ({
  maxWidth: "calc(100% - 48px)",

  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

export const ReplyFormStyled = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  margin: theme.spacing(10, 0, 8),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const ReplyFormField = styled("div")(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? "rgba(255,255,255,0.1)"
      : theme.palette.common.white,
  flex: 1,
  padding: theme.spacing(0, 2),
}));

export const ReplyFormButton = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",

  [theme.breakpoints.down("sm")]: {
    "& button": {
      width: "100%",
    },
  },
}));

export const LoadMoreWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: theme.spacing(4, 6),

  [theme.breakpoints.down("sm")]: {
    "& button": {
      width: "100%",
    },
  },
}));

export const ParentReplyComment = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const ParentReplyCommentLink = styled("span")(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.secondary.main}`,
  cursor: "pointer",
  lineHeight: 1,
}));

export const ParentReplyCommentName = styled("span")(({ theme }) => ({
  color:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.primary.main,
  margin: theme.spacing(0, 1),
}));

export const AddCommentFieldWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  paddingTop: theme.spacing(0.5),
}));

export const AddCommentCounter = styled("span")(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: theme.spacing(1),
  fontSize: "0.875rem",
  color: theme.palette.darkGrey.main,
}));
