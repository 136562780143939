import { useFormContext, Controller, ControllerProps } from "react-hook-form";

export function GenerateField(props: ControllerProps) {
  const { rules, ...restProps } = props;
  const { control } = useFormContext();

  return (
    <Controller
      {...restProps}
      control={control}
      rules={{ required: true, ...(rules || {}) }}
    />
  );
}
