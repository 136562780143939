import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IPostTimesheetActivityState } from "./reducers";

export const postTimesheetActivitySelector = (
  state: RootState
): IPostTimesheetActivityState => state.postTimesheetActivity;

export const selectPostTimesheetActivity = createSelector(
  postTimesheetActivitySelector,
  (postTimesheetActivityState: IPostTimesheetActivityState) => ({
    ...postTimesheetActivityState,
  })
);
