import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { selectCart } from "@/store/redux";
import { Donate } from "./Donate";
import { TotalWrapper, TotalDesc, TotalValue } from "./Total.styled";

export function Total() {
  const { watch } = useFormContext();
  const { ptotal } = useSelector(selectCart);

  const donate = watch("donate");

  return (
    <>
      <Donate />

      <TotalWrapper sx={{ display: "flex" }}>
        Total:
        <TotalValue>$ {ptotal + donate}</TotalValue>
      </TotalWrapper>
      <TotalWrapper sx={{ display: "flex", fontSize: "1.5rem" }}>
        Donation:
        <TotalValue>$ {donate || 0}</TotalValue>
      </TotalWrapper>
      <TotalDesc>
        This sum is going to be deducted from your monthly compensation and
        donated to purchase a pickup truck for the Armed Forces of Ukraine.
      </TotalDesc>
    </>
  );
}
