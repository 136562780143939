import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { INewsCommentState } from "./reducers";

export const deleteNewsCommentSelector = (
  state: RootState
): INewsCommentState => state.newsDeleteComment;

export const selectDeleteNewsComment = createSelector(
  deleteNewsCommentSelector,
  (deleteNewsCommentState: INewsCommentState) => ({ ...deleteNewsCommentState })
);
