import { Skeleton, TableRow, TableCell } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { EmptyFolderWrapper } from "../MyDocuments.styled";

interface IEmptyFolderProps {
  loading: boolean;
}

export function EmptyFolder(props: IEmptyFolderProps) {
  const { loading } = props;

  return loading ? (
    <TableRow>
      <TableCell>
        <Skeleton sx={{ width: "100%", height: 12 }} />
      </TableCell>
      <TableCell>
        <Skeleton sx={{ width: 75, height: 12 }} />
      </TableCell>
    </TableRow>
  ) : (
    <TableRow>
      <TableCell colSpan={2}>
        <EmptyFolderWrapper>
          <FolderIcon sx={{ fontSize: "4rem" }} />
          This folder is empty
        </EmptyFolderWrapper>
      </TableCell>
    </TableRow>
  );
}
