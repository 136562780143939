import { styled } from "@mui/material/styles";

export const RequestHead = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

export const RequestHeadTitle = styled("div")(({ theme }) => ({
  fontWeight: 900,
  marginTop: theme.spacing(1),

  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const RequestHeadSubTitle = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 500,
  color: theme.palette.darkGrey.main,
  textAlign: "center",
}));

export const RequestButtonWrapp = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const RequestCaption = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: "0.875rem",
  fontWeight: 400,
}));

export const DaysMessage = styled("div")<{ $error?: boolean }>(
  ({ theme, $error }) => ({
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
    fontSize: "0.875rem",
    background: theme.palette[$error ? "pink" : "secondary"].main,
  })
);

export const MessageDesc = styled("div")(({ theme }) => ({
  fontSize: "0.75rem",
  fontWeight: 700,

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));
