import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "PostTimesheetActivity",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  postTimesheetActivity,
  postTimesheetActivityError,
  postTimesheetActivitySuccess,
  resetPostTimesheetActivity,
  resetPostTimesheetActivityError,
  resetPostTimesheetActivitySuccess,
} = slice.actions;

reducerRegistry.register("postTimesheetActivity", reducer);
