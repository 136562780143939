import { styled } from "@mui/material/styles";

export const ShareMessageWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  minWidth: 165,
  background: theme.palette.secondary.main,
  padding: theme.spacing(1, 2),
  fontSize: "0.875rem",
  textAlign: "center",
  color: theme.palette.common.black,
}));
