import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Title, MoreButton } from "@/shared/components";
import {
  IMarketplaceItem,
  selectMarketplace,
  MarketplaceActions,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { ProductListItem } from "./ProductListItem";
import { ProductsList, LoadMoreWrapp } from "./ProductsList.styled";

export function MarketPlaceProductsList() {
  const { products, pending, plimit, poffset, ptotal } =
    useSelector(selectMarketplace);
  const { getMarketplace } =
    useActions<typeof MarketplaceActions>(MarketplaceActions);

  const getMarketplaceProducts = (params = {}) => {
    getMarketplace({ plimit: 21, poffset: 0, ...params });
  };

  const handleOnMore = () => {
    getMarketplaceProducts({ poffset: poffset + plimit });
  };

  useEffect(() => {
    getMarketplaceProducts({ poffset: 0 });
  }, []); //eslint-disable-line

  return (
    <ProductsList>
      <Title fontWeight="500">GOODIES FOR GOOD</Title>

      <Grid container columnSpacing={2.25} rowGap={10} sx={{ mt: 4 }}>
        {(products || []).map((item: IMarketplaceItem, idx: number) => (
          <Grid
            key={`product-${item.brand_id}-${item.title}`}
            item
            xs={6}
            sm={6}
            md={4}
          >
            <ProductListItem {...item} />
          </Grid>
        ))}
      </Grid>

      <LoadMoreWrapp>
        <MoreButton
          limit={plimit}
          offset={poffset}
          total={ptotal}
          loading={pending}
          onClick={handleOnMore}
        >
          Show more
        </MoreButton>
      </LoadMoreWrapp>
    </ProductsList>
  );
}
