import { styled } from "@mui/material/styles";
import { IconButton, IconButtonProps } from "@mui/material";
import { turquoise } from "@/theme";

interface IHoursButtonProps extends IconButtonProps {
  $hasEmpty?: boolean;
  $hasTimeOff: boolean;
}

export const TimesheetDaysWrapp = styled("div")(({ theme }) => ({
  textAlign: "center",
  fontWeight: 900,
  margin: theme.spacing(1.5, 0, 3),
}));

export const TableContainer = styled("div")(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontSize: "1rem",
    verticalAlign: "middle",
  },

  "& .MuiTableHead-root": {
    backgroundColor: theme.palette.mode === "dark" ? "transparent" : "#F9F9F9",
    fontWeight: 500,

    "& .MuiTableCell-root": {
      padding: theme.spacing(1),
      color: theme.palette.darkGrey.main,

      "&:not(:first-of-type)": {
        textAlign: "center",
      },
    },
  },

  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      "& .MuiTableCell-root": {
        padding: theme.spacing(1, 0),

        "&:not(:first-of-type)": {
          textAlign: "center",
        },

        "&:first-of-type": {
          padding: theme.spacing(1),
          fontWeight: 500,
        },

        "&:nth-of-type(2)": {
          padding: theme.spacing(1, 1.5),
          fontWeight: 500,
        },
      },
    },
  },

  "& .MuiTableFooter-root": {
    backgroundColor: theme.palette.mode === "dark" ? "transparent" : "#F9F9F9",

    "& .MuiTableCell-root": {
      padding: theme.spacing(2, 1),
      color:
        theme.palette.mode === "dark"
          ? theme.palette.common.white
          : theme.palette.common.black,
      fontWeight: 500,

      "&:not(:first-of-type)": {
        textAlign: "center",
      },
    },
  },
}));

export const HoursButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "$hasEmpty" && prop !== "$hasTimeOff",
})<IHoursButtonProps>(({ theme, $hasEmpty, $hasTimeOff }) => ({
  padding: theme.spacing(1, 1.5),
  fontSize: "1rem",
  fontWeight: 400,
  color: $hasTimeOff
    ? theme.modeColors({
        light: theme.palette.common.black,
        dark: theme.palette.common.black,
      })
    : $hasEmpty
    ? theme.palette.darkGrey.main
    : theme.modeColors({
        light: theme.palette.common.black,
        dark: theme.palette.common.white,
      }),
  minHeight: 35,
}));

export const Wrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const Header = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
}));

export const Title = styled("div")(({ theme }) => ({
  fontSize: "1.5rem",
}));

export const Date = styled("span")(({ theme }) => ({
  fontWeight: 700,
}));

export const Year = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const Total = styled("div")(({ theme }) => ({
  fontWeight: 500,
}));

export const ColTitle = styled("span")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: "0.875rem",
}));

export const ProjectName = styled("span")(({ theme }) => ({
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

export const ProjectHours = styled("span")(({ theme }) => ({
  fontWeight: 500,
}));

export const ProjectsList = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

export const ProjectItem = styled("div")<{ $dayOff?: { type: string } }>(
  ({ theme }) => ({
    borderBottom: "1px solid #E6E6E6",
    padding: theme.spacing(1, 0),
    display: "flex",
    flexWrap: "wrap",
  })
);

export const Col = styled("div")(({ theme }) => ({
  flex: "66.666667%",
  display: "flex",
  alignItems: "center",

  "& + &": {
    flex: "33.3333%",
  },
}));

export const ColFullWidth = styled("div")(({ theme }) => ({
  flex: "100%",
}));

export const Dot = styled("span")<{ $hasOutage: boolean }>(
  ({ theme, $hasOutage }) => ({
    display: "flex",
    borderRadius: "50%",
    width: 6,
    height: 6,
    backgroundColor: $hasOutage ? turquoise : theme.palette.secondary.main,
  })
);

export const ProjectTitle = styled("span")(({ theme }) => ({
  flex: 1,
}));

export const FakeInput = styled("div")(({ theme }) => ({
  height: 58,
  border: "1px solid #8E949A",
  padding: "6px 12px",
  background: theme.modeColors({
    light: "rgba(0, 0, 0, 0.12)",
    dark: "rgba(255, 255, 255, 0.12)",
  }),
  fontWeight: 400,
}));

export const FakeInputLabel = styled("div")(({ theme }) => ({
  fontSize: 12,
  color: theme.modeColors({
    light: "rgba(0, 0, 0, 0.6)",
    dark: "rgba(255, 255, 255, 0.7)",
  }),
}));
