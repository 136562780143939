import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { ITimeOffAvailableDaysStatePending } from "./reducers";

export const getTimeOffAvailableDaysRequest = async (
  params: ITimeOffAvailableDaysStatePending
) => {
  const { type, start_date } = params;

  const response: AxiosResponse = await apiInstance.get(
    "/time-off/available-days",
    {
      params: {
        type,
        start_date,
      },
    }
  );

  return response.data;
};
