import { useState } from "react";
import { useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { pick } from "lodash";
import { format, parseISO } from "date-fns";
import { Grid, Alert } from "@mui/material";
import {
  PostTimesheetActivityActions,
  selectPostTimesheetActivity,
  ITimesheetActivityItem,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { GradientButton, ErrorMessage } from "@/shared/components";
import { getPeriod } from "./helpers";
import {
  DateField,
  HoursField,
  ProjectField,
  RecordingTypeField,
  TaskField,
} from "./Fields";

interface ITimesheetAddFormProps {
  weekView?: boolean;
  selectedDate: Date | null;
  selectedEvent: ITimesheetActivityItem | null;
  totalColumnHours: { [k: string | number]: number };
}

const fieldsMap: any = {
  project: ["hours", "date", "type", "project_id", "task"],
  outage: ["hours", "date", "type"],
};

const revertedTypesMap: any = {
  project: 1,
  outage: 2,
};

function getFieldsByType(type: number) {
  return fieldsMap[type];
}

function getSubmitFields(state: any, fields: string[]) {
  return pick(state, fields);
}

function transformDate(date: Date | string) {
  const parsedDate = date instanceof Date ? date : parseISO(date);

  return format(parsedDate, "dd/MM/yyyy");
}

export function TimesheetAddForm(props: ITimesheetAddFormProps) {
  const { weekView, selectedDate, selectedEvent, totalColumnHours } = props;
  const { pending, error_message } = useSelector(selectPostTimesheetActivity);
  const { postTimesheetActivity } = useActions<
    typeof PostTimesheetActivityActions
  >(PostTimesheetActivityActions);

  const { id, type, start_date, project, hours, task } = selectedEvent || {};

  const [hasValidHours, setHasValidHours] = useState(true);

  const methods = useForm({
    defaultValues: {
      hours: hours || "",
      type: type || "",
      task: task || "",
      project_id: project?.id || "",
      start_date: start_date || selectedDate || null,
    },
  });

  const {
    watch,
    handleSubmit,
    getValues,
    formState: { isValid },
  } = methods;

  const hasSubmitDisabled = !isValid || pending;
  const totalHoursPerDay =
    totalColumnHours[format(new Date(selectedDate as Date) || 0, "dd/MM/yyyy")];

  const getValidHours = (value: any) => {
    const maxHoursPerDay = 24;
    const intValue = parseFloat(value || 0);
    const intTotal = totalHoursPerDay || 0;
    const intEventHours = selectedEvent?.hours || 0;
    const hasValid = id
      ? maxHoursPerDay - intTotal - (intValue - intEventHours) >= 0
      : intTotal + intValue <= maxHoursPerDay;

    return hasValid;
  };

  const onPostForm = (data: any, hasRemove?: boolean) => {
    const { startDate, endDate } = getPeriod(
      data.start_date,
      weekView ? "week" : undefined
    );
    const pickedFields = getSubmitFields(data, getFieldsByType(data.type));
    const transformData = {
      ...pickedFields,
      id,
      type: revertedTypesMap[data.type],
      hours: hasRemove ? 0 : parseFloat(data.hours),
      start_date: transformDate(data.start_date),
      period: {
        start_date: startDate,
        end_date: endDate,
      },
    };

    postTimesheetActivity(transformData);
  };

  const onSubmit = (data: any) => {
    onPostForm(data);
  };

  const handleOnRemove = () => {
    const form = getValues();

    onPostForm(form, true);
  };

  const hasRecordingTypeSelected = watch("type");
  const hasProjectSelected = watch("project_id");
  const hasOutageSelected = watch("type") === "outage";
  const reportedHours = watch("hours");

  const hasDayWarn =
    (reportedHours as number) > 8 && (reportedHours as number) <= 24;

  return (
    <>
      <ErrorMessage message={error_message} />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {hasDayWarn ? (
              <Grid item xs={12}>
                <Alert severity="warning">
                  Please report no more than <b>8 hours</b> per day unless you
                  have negotiated overtime with the manager or client
                </Alert>
              </Grid>
            ) : !hasValidHours ? (
              <Grid item xs={12}>
                <Alert severity="error">
                  The number of reported working hours must be fewer than{" "}
                  <b>24</b>. It's unlikely, but who knows :)
                </Alert>
              </Grid>
            ) : null}

            <Grid item xs={6}>
              <HoursField
                pending={pending}
                onGetValidHours={getValidHours}
                onSetHasValidHours={setHasValidHours}
              />
            </Grid>
            <Grid item xs={6}>
              <DateField pending={pending} selectedEvent={selectedEvent} />
            </Grid>
            <Grid item xs={12}>
              <RecordingTypeField
                pending={pending}
                selectedEvent={selectedEvent}
              />
            </Grid>

            {!hasOutageSelected ? (
              <>
                {!!hasRecordingTypeSelected ? (
                  <Grid item xs={12}>
                    <ProjectField
                      pending={pending}
                      selectedEvent={selectedEvent}
                    />
                  </Grid>
                ) : null}

                {hasProjectSelected ? (
                  <Grid item xs={12}>
                    <TaskField
                      pending={pending}
                      selectedEvent={selectedEvent}
                    />
                  </Grid>
                ) : null}
              </>
            ) : null}

            {!!hasRecordingTypeSelected ? (
              <Grid item xs={12}>
                <GradientButton
                  fullWidth
                  type="submit"
                  disabled={hasSubmitDisabled}
                >
                  {id ? "Save changes" : "Add time"}
                </GradientButton>
              </Grid>
            ) : null}

            {id ? (
              <Grid item xs={12}>
                <GradientButton
                  fullWidth
                  color="error"
                  disabled={pending}
                  onClick={handleOnRemove}
                >
                  Delete record
                </GradientButton>
              </Grid>
            ) : null}
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
