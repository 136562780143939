import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";
import { PostalServiceField, CityField, PostalPointField } from "./Fields";

export function ParcelLockerTypeFields() {
  const { watch } = useFormContext();
  const deliveryType = watch("delivery_type");
  const hasParcelLockerType = deliveryType === 3;

  if (!hasParcelLockerType) {
    return null;
  }

  return (
    <>
      <Grid item xs={12}>
        <PostalServiceField />
      </Grid>
      <Grid item xs={12}>
        <CityField />
      </Grid>
      <Grid item xs={12}>
        <PostalPointField label="Number of Parcel Locker" />
      </Grid>
    </>
  );
}
