import { useNavigate } from "react-router-dom";
import { Box, Skeleton } from "@mui/material";
import { IconWithCount } from "@/shared/components";
import { LikeIcon, ViewsIcon } from "@/shared/icons";
import {
  TopicsStyled,
  TopicStyled,
  TopicTitle,
  TopicActions,
} from "./ShortTopics.styled";

interface ITopicProps {
  href: string;
  title: string;
  likes: number;
  views: number;
}
interface ITopicsProps {
  data: ITopicProps[];
  loading?: boolean;
}

export function ShortTopics(props: ITopicsProps) {
  const { data, loading } = props;

  if (loading) {
    return <LoadingTopics />;
  }

  return (
    <TopicsStyled>
      {data.map((item: any) => (
        <Topic key={`short-topic${item.title}`} {...item} loading={loading} />
      ))}
    </TopicsStyled>
  );
}

export function Topic(props: ITopicProps) {
  const { title, href, likes, views } = props;
  const navigate = useNavigate();

  const handleOnNavigate = () => {
    navigate(href);
  };

  return (
    <TopicStyled onClick={handleOnNavigate}>
      <TopicTitle>{title}</TopicTitle>

      <TopicActions>
        <IconWithCount icon={<LikeIcon />} value={likes} />
        <IconWithCount icon={<ViewsIcon />} value={views} />
      </TopicActions>
    </TopicStyled>
  );
}

function LoadingTopics() {
  return (
    <Box sx={{ mt: 1 }}>
      <Skeleton />
      <Skeleton />
    </Box>
  );
}
