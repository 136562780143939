import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const getTimesheetActivityRequest = async (params = {}) => {
  const response: AxiosResponse = await apiInstance.get("/timesheet/activity", {
    params: {
      ...params,
    },
  });

  return response.data;
};

export const getTimeOffsRequest = async (params: {
  ptype: number;
  pstatus: number;
  start_date: string;
  end_date: string;
}) => {
  const response: AxiosResponse = await apiInstance.get("/time-off/calendar", {
    params: {
      ...params,
    },
  });

  return response.data;
};

export const getHolidaysRequest = async (params: {
  start_date: string;
  end_date: string;
}) => {
  const response: AxiosResponse = await apiInstance.get("/holidays", {
    params: {
      ...params,
    },
  });

  return response.data;
};
