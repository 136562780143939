import { styled } from "@mui/material/styles";
import LinkIcon from "@mui/icons-material/Link";

export const ListWrapper = styled("div")(({ theme }) => ({}));

export const ListHead = styled("div")(({ theme }) => ({
  position: "sticky",
  zIndex: 1,
  top: 100,
  background: theme.modeColors({
    dark: theme.palette.primary.dark,
    light: "#F9F9F9",
  }),
  color: theme.palette.darkGrey.main,
  fontSize: "1rem",
  padding: theme.spacing(1),
}));

export const ListHeadItem = styled("span")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  cursor: "pointer",
}));

export const ListBody = styled("div")(({ theme }) => ({}));

export const ListItemLinkIcon = styled(LinkIcon)(({ theme }) => ({
  color: "#d6d6d6",
}));

export const ListItem = styled("div")(({ theme }) => ({
  display: "flex",
  borderBottom: `1px solid ${theme.modeColors({
    dark: theme.palette.common.black,
    light: "#E6E6E6",
  })}`,
  color: theme.modeColors({ dark: theme.palette.darkGrey.main, light: "#000" }),
  fontWeight: "500",
  fontSize: "1rem",
  padding: theme.spacing(2, 1),

  "&:hover": {
    [ListItemLinkIcon as any]: {
      color: theme.palette.primary.main,
    },
  },
}));

export const ListItemTitle = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: "0.75rem",
}));

export const TypeValue = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const TypeDot = styled("span")<{ $color: string }>(
  ({ theme, $color }) => ({
    display: "inline-flex",
    background: theme.palette[$color].main,
    width: 12,
    height: 12,
    borderRadius: "50%",
    marginRight: theme.spacing(1),
  })
);

export const ItemDesc = styled("div")(({ theme }) => ({
  wordBreak: "break-word",
}));

export const ListItemLink = styled("a")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const ListItemStatus = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  textTransform: "capitalize",
}));

export const ListItemStatusBadge = styled("span")<{ $hasPassed: boolean }>(
  ({ theme, $hasPassed }) => ({
    display: "inline-flex",
    borderRadius: "50%",
    width: "12px",
    height: "12px",
    background: $hasPassed
      ? theme.palette.secondary.main
      : theme.palette.pink.main,
  })
);
