import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import { Dialog, GradientButton, Bubble } from "@/shared/components";
import { selectCheckout, CheckoutActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { MessageDesc, MessageTitle, MessageWrapp } from "./SuccessModal.styled";

export function CheckoutSuccessModal() {
  const { hasSubmitted } = useSelector(selectCheckout);
  const { resetCheckout } = useActions<typeof CheckoutActions>(CheckoutActions);

  const [openModal, setOpenModal] = useState(false);

  const handleOnClose = () => {
    setOpenModal(false);
    resetCheckout();
  };

  const content = (
    <MessageWrapp>
      <Bubble color="yellowPink" width="100px" height="100px">
        <DoneIcon sx={{ fontSize: 56 }} />
      </Bubble>
      <MessageTitle>We&apos;ve got your order!</MessageTitle>
      <MessageDesc>
        Delivery time depends on your location. But we&apos;ll try to deliver it
        ASAP.
      </MessageDesc>

      <GradientButton fullWidth onClick={handleOnClose}>
        Got it
      </GradientButton>
    </MessageWrapp>
  );

  useEffect(() => {
    if (!hasSubmitted) return;

    setOpenModal(true);
  }, [hasSubmitted]);

  return (
    <Dialog
      top
      fullWidth
      open={openModal}
      onClose={handleOnClose}
      maxWidth="xsm"
      title="THANK YOU!"
      dialogContent={content}
    />
  );
}
