import { Label } from "./MarketplaceLabel.styled";

function OutOfStock() {
  return <Label color="pinkViolet">Out of stock</Label>;
}

function LeftFewItems() {
  return <Label color="yellowPink">Few items left</Label>;
}

export function MarketplaceLabel(props: { quantity: number }) {
  const { quantity } = props;

  if (!quantity) {
    return <OutOfStock />;
  }

  if (quantity < 4) {
    return <LeftFewItems />;
  }

  return null;
}
