import {
  useState,
  useEffect,
  useMemo,
  createContext,
  PropsWithChildren,
} from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { selectProfile, ProfileActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { theme } from "@/theme";

export const AppThemeContext = createContext<{
  mode: string | "dark" | "light";
  setMode: any;
}>({
  mode: "light",
  setMode: () => {},
});

export function AppThemeProvider(props: PropsWithChildren) {
  const { profile } = useSelector(selectProfile);
  const { updateProfile } = useActions<typeof ProfileActions>(ProfileActions);

  const [mode, setThemeMode] = useState<string | "dark" | "light">("light");
  const appTheme = useMemo(() => theme(mode === "dark"), [mode]);

  const handleOnSetMode = () => {
    const newTheme = mode === "light" ? "dark" : "light";

    updateProfile({ theme: newTheme });
  };

  useEffect(() => {
    setThemeMode(profile?.theme || "light");
  }, [profile?.theme]);

  return (
    <AppThemeContext.Provider
      value={{
        mode,
        setMode: handleOnSetMode,
      }}
    >
      <ThemeProvider theme={appTheme}>{props.children}</ThemeProvider>
    </AppThemeContext.Provider>
  );
}
