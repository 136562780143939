import { SxProps } from "@mui/material";
import { TitleStyled } from "./Title.styled";

interface ITitle {
  fontStyle?: "italic" | "normal";
  fontSize?: string;
  fontWeight?: string;
  noTransform?: boolean;
  className?: any;
  sx?: SxProps;
  children: React.ReactNode;
}

export function Title(props: ITitle) {
  const {
    children,
    fontStyle,
    fontSize,
    fontWeight,
    noTransform,
    ...restProps
  } = props;

  return (
    <TitleStyled
      {...restProps}
      $fontStyle={fontStyle}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $noTransform={noTransform}
    >
      {children}
    </TitleStyled>
  );
}
