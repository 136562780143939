import { useSelector } from "react-redux";
import { Filters, FilterControl, Select } from "@/shared/components";
import { selectTimeOffCalendar } from "@/store/redux";
import { typesMap, statusesMap } from "../consts";

export interface IFilters {
  ptype: string;
  pstatus: string;
  pmonth: Date;
}

export interface ITimeOffFiltersProps {
  filters: IFilters;
  onChange: (k: any) => void;
}

const types = [
  { value: "-1", title: "All Bookings" },
  ...Object.entries(typesMap).map(([k, v]) => ({ value: k, title: v })),
];
const statuses = [
  { value: "-1", title: "All Statuses" },
  ...Object.entries(statusesMap)
    .map(([k, v]) => ({
      value: k,
      title: v,
    }))
    .filter((item) => parseInt(item.value) < 3),
];

export function TimeOffCalendarFilters(props: ITimeOffFiltersProps) {
  const { filters, onChange } = props;
  const { pending } = useSelector(selectTimeOffCalendar);

  return (
    <Filters>
      <FilterControl
        label="Type:"
        control={
          <Select
            disabled={pending}
            name="ptype"
            options={types}
            value={filters.ptype}
            onChange={onChange}
          />
        }
      />
      <FilterControl
        label="Status:"
        control={
          <Select
            disabled={pending}
            name="pstatus"
            options={statuses}
            value={filters.pstatus}
            onChange={onChange}
          />
        }
      />
    </Filters>
  );
}
