import { useEffect } from "react";
import { FaqContent, FaqSidebar, NewsSidebarSection } from "@/components";
import { Title, Description, FaqRecents } from "@/shared/components";
import { FaqRecentActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { FaqFeed } from "./FaqFeed";
import { desc, title } from "./Faq";

export function FaqDesktopView() {
  const { getFaqRecents } =
    useActions<typeof FaqRecentActions>(FaqRecentActions);

  useEffect(() => {
    getFaqRecents();
  }, []); //eslint-disable-line

  return (
    <>
      <FaqContent>
        <Title fontSize="60px">{title}</Title>

        <Description>{desc}</Description>

        <FaqFeed />
      </FaqContent>

      <FaqSidebar>
        <NewsSidebarSection title="RECENT VIDEOS" content={<FaqRecents />} />
      </FaqSidebar>
    </>
  );
}
