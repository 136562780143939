import { CircularProgress } from "@mui/material";
import { LoaderWrapper } from "./SuspenseLoader.styled";

export function SuspenseLoader() {
  return (
    <LoaderWrapper>
      <CircularProgress color="primary" />
    </LoaderWrapper>
  );
}
