import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useActions } from "@/utils/redux";
import { HotTopicsActions, selectHotTopics } from "@/store/redux";
import { ShortTopics } from "@/shared/components";

export function HotTopics() {
  const { topics, pending } = useSelector(selectHotTopics);
  const { getHotTopics } =
    useActions<typeof HotTopicsActions>(HotTopicsActions);

  useEffect(() => {
    getHotTopics();
  }, []); //eslint-disable-line

  return <ShortTopics data={topics} loading={pending} />;
}
