import { Container, Page, EmployeeCalendar } from "@/components";
import { Title } from "@/shared/components";

export function Calendar() {
  return (
    <Page title="Calendar">
      <Container>
        <Title fontSize="60px" sx={{ mb: 2 }}>
          CALENDAR
        </Title>
        <EmployeeCalendar />
      </Container>
    </Page>
  );
}
