import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IReferralsVacancyState } from "./reducers";

export const referralsVacancySelector = (
  state: RootState
): IReferralsVacancyState => state.referralsVacancy;

export const selectReferralsVacancy = createSelector(
  referralsVacancySelector,
  (referralsVacancyState: IReferralsVacancyState) => ({
    ...referralsVacancyState,
  })
);
