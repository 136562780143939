import type { orderDirection, orderField } from "./SurveysList";
import { orderBy } from "lodash";
import { ISurveysListItem } from "@/store/redux";

export function orderSurveys({
  data = [],
  type = "asc",
  orderByField,
}: {
  data: Array<ISurveysListItem>;
  type: orderDirection | undefined;
  orderByField: orderField;
}) {
  const orderFunc = (survey: ISurveysListItem) =>
    orderByField === "status"
      ? survey["status"]
      : +new Date(survey["expire_date"]);

  const orderedSurveys = orderBy(data, orderFunc, type);

  return orderedSurveys;
}
