import { useRef, useState, useEffect } from "react";
import { INewsCommentsItem } from "@/store/redux";
import { CommentItem } from "./CommentItem";

interface ICommentListProps {
  comments: INewsCommentsItem[];
  loading: boolean;
  onReply: (k: INewsCommentsItem) => void;
  onDelete: (k: INewsCommentsItem) => void;
}

export function CommentsList(props: ICommentListProps) {
  const { comments, loading, onReply, onDelete } = props;
  const selectedCommentRef = useRef<HTMLInputElement | null>(null);
  const [selectedReplyId, setSelectedReplyId] = useState<number | null>(null);

  const handleOnParentSelect = (id: any) => {
    setSelectedReplyId(id);
  };

  const handleOnScrollToComment = () => {
    if (selectedCommentRef && selectedCommentRef?.current) {
      selectedCommentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    setSelectedReplyId(null);
  };

  useEffect(() => {
    handleOnScrollToComment();
  }, [selectedReplyId]);

  return (
    <>
      {(comments || []).map((comment: INewsCommentsItem) => (
        <CommentItem
          key={comment.id}
          comment={comment}
          loading={loading}
          onReply={onReply}
          onDelete={onDelete}
          onParentSelect={handleOnParentSelect}
          selectedReplyId={selectedReplyId}
          selectedCommentRef={selectedCommentRef}
        />
      ))}
    </>
  );
}
