import { Box } from "@mui/material";
import { Title } from "@/shared/components";
import { TimeOffRequest } from "./Request";

export function TimeOffHead() {
  return (
    <Box mb={3}>
      <Title fontSize="60px">TIME OFF BOOKING</Title>

      <TimeOffRequest />
    </Box>
  );
}
