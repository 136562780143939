import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { IEmployeeCalendarStatePending } from "./reducers";

export const getEmployeeCalendarRequest = async (
  params: IEmployeeCalendarStatePending
) => {
  const response: AxiosResponse = await apiInstance.get("/calendar", {
    params: {
      ...params,
    },
  });

  return response.data;
};
