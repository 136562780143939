import { useSelector } from "react-redux";
import { Title, Literature } from "@/shared/components";
import { MoodLiteratureActions, selectMoodLiterature } from "@/store/redux";
import { useActions } from "@/utils/redux";

export function MoodMonitoringLiterature() {
  const { pending, literature } = useSelector(selectMoodLiterature);
  const { getMoodLiterature } = useActions<typeof MoodLiteratureActions>(
    MoodLiteratureActions
  );

  return (
    <>
      <Title>Literature</Title>

      <Literature
        pending={pending}
        literature={literature}
        getLiterature={getMoodLiterature}
      />
    </>
  );
}
