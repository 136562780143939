import { TextField } from "@mui/material";
import { GenerateField } from "./GenerateField";

export function ApartmentField() {
  return (
    <GenerateField
      name="apartment"
      rules={{
        required: false,
        validate: (value) => (value ? !!value?.trim() : true),
      }}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          label="Apartment"
          variant="filled"
          inputProps={{
            maxLength: 200,
          }}
        />
      )}
    />
  );
}
