import { PayloadAction } from "@reduxjs/toolkit";

export interface IUserProjectsItem {
  id: number;
  name: string;
}

export interface IUserProjectsState {
  pending: boolean;
  error: any;
  projects: IUserProjectsItem[];
}

export interface IUserProjectsStateSuccess {
  project: IUserProjectsItem[];
}

export interface IUserProjectsStateError {
  error: any;
}

export const initialState: IUserProjectsState = {
  pending: false,
  error: "",
  projects: [],
};

export const reducers = {
  getUserProjects: (state: IUserProjectsState) => {
    return {
      ...state,
      pending: true,
      error: "",
    };
  },
  getUserProjectsSuccess: (
    state: IUserProjectsState,
    action: PayloadAction<IUserProjectsStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      projects: action.payload.project || [],
    };
  },
  getUserProjectsError: (
    state: IUserProjectsState,
    action: PayloadAction<IUserProjectsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
