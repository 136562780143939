import { Avatar } from "@mui/material";
import {
  CommentAuthor,
  CommentAuthorName,
  CommentDate,
  ParentReplyComment,
  ParentReplyCommentLink,
  ParentReplyCommentName,
} from "./Comments.styled";

interface IAuthorCommentProps {
  name: string;
  image?: string;
  date?: string;
  replyParentName?: string | null | undefined;
  hasParent?: boolean;
  onScrollToReply?: () => void;
}

export function AuthorComment(props: IAuthorCommentProps) {
  const { image, name, date, replyParentName, hasParent, onScrollToReply } =
    props;

  return (
    <CommentAuthor>
      <Avatar src={image} sx={{ width: 48, height: 48 }} />
      <div>
        <CommentAuthorName>{name}</CommentAuthorName>
        <CommentDate>
          {date}

          {hasParent ? (
            <ParentReplyComment>
              on
              <ParentReplyCommentName>{replyParentName}</ParentReplyCommentName>
              <ParentReplyCommentLink onClick={onScrollToReply}>
                comment
              </ParentReplyCommentLink>
            </ParentReplyComment>
          ) : null}
        </CommentDate>
      </div>
    </CommentAuthor>
  );
}
