import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const postQuestionsRequest = async (params: any) => {
  const response: AxiosResponse = await apiInstance.post("/ask-question", {
    ...params,
  });

  return response.data;
};
