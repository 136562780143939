import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TimeOffListActions, TimeOffActions } from "@/store/redux";
import { IPostTimeOffStateSuccess, IPostTimeOffStatePending } from "./reducers";
import {
  postTimeOff,
  postTimeOffError,
  postTimeOffSuccess,
  resetPostTimeOff,
  resetPostTimeOffError,
  resetPostTimeOffSuccess,
} from "./slice";
import { postTimeOffRequest, putTimeOffRequest } from "./api";

function* postTimeOffSaga(action: PayloadAction<IPostTimeOffStatePending>) {
  try {
    const { id, filters } = action.payload;
    const apiMethod = id ? putTimeOffRequest : postTimeOffRequest;
    const postTimeOff: IPostTimeOffStateSuccess = yield call(
      apiMethod,
      action.payload
    );

    yield put(postTimeOffSuccess(postTimeOff));

    yield put(TimeOffActions.getTimeOff());
    yield put(TimeOffListActions.getTimeOffList({ ...filters, poffset: 0 }));
  } catch (error) {
    yield put(postTimeOffError({ error }));
  }
}

function* resetPostTimeOffSaga() {
  try {
    yield put(resetPostTimeOffSuccess());
  } catch (error) {
    yield put(resetPostTimeOffError());
  }
}

function* PostTimeOffWatcher() {
  yield takeLatest(postTimeOff.type, postTimeOffSaga);
  yield takeLatest(resetPostTimeOff.type, resetPostTimeOffSaga);
}

export default PostTimeOffWatcher;
