import { Box } from "@mui/material";
import { Title, Tabs } from "@/shared/components";
import { TimesheetMonthView } from "./MonthView/TimesheetMonthView";
import { TimesheetWeekView } from "./WeekView/TimesheetWeekView";

export function TimesheetHistory() {
  return (
    <Box sx={{ mt: 4, mb: 20 }}>
      <Title fontStyle="normal">ACTIVITY HISTORY</Title>
      <Tabs
        titles={["Week", "Month"]}
        contents={[<TimesheetWeekView />, <TimesheetMonthView />]}
      />
    </Box>
  );
}
