import { PayloadAction } from "@reduxjs/toolkit";

export interface IMarketplaceBrandProductSize {
  value: string;
  title: string;
  quantity: number;
}

export interface IMarketplaceBrandProduct {
  id: number;
  brand_id: number;
  images: string[];
  title: string;
  sizes: IMarketplaceBrandProductSize[];
  price: number;
  quantity: number;
  description: string;
}

export interface IMarketplaceBrandProductsState {
  pending: boolean;
  error: any;
  plimit: number;
  poffset: number;
  ptotal: number;
  products: IMarketplaceBrandProduct[];
}

export interface IMarketplaceBrandProductsStatePending {
  id?: number | string | undefined;
  poffset?: number;
  plimit?: number;
}

export interface IMarketplaceBrandProductsStateSuccess {
  plimit: number;
  poffset: number;
  products: IMarketplaceBrandProduct[];
}

export interface IMarketplaceBrandProductsStateError {
  error: any;
}

export const initialState: IMarketplaceBrandProductsState = {
  pending: false,
  error: "",
  plimit: 10,
  poffset: 0,
  ptotal: 0,
  products: [] as IMarketplaceBrandProduct[],
};

export const reducers = {
  getMarketplaceBrandProducts: (
    state: IMarketplaceBrandProductsState,
    action: PayloadAction<IMarketplaceBrandProductsStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: "",
      ...(action.payload || {}),
    };
  },
  getMarketplaceBrandProductsSuccess: (
    state: IMarketplaceBrandProductsState,
    action: PayloadAction<IMarketplaceBrandProductsStateSuccess>
  ) => {
    const { poffset, products, ...restOptions } = action?.payload;

    return {
      ...state,
      ...restOptions,
      pending: false,
      products: poffset ? [...(state.products || []), ...products] : products,
    };
  },
  getMarketplaceBrandProductsError: (
    state: IMarketplaceBrandProductsState,
    action: PayloadAction<IMarketplaceBrandProductsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
