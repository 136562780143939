import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { MoodCalendarActions, ProfileActions } from "@/store/redux";
import { IPostMoodStateSuccess, IPostMoodStatePending } from "./reducers";
import {
  postMood,
  postMoodError,
  postMoodSuccess,
  resetPostMood,
  resetPostMoodError,
  resetPostMoodSuccess,
} from "./slice";
import { postMoodRequest } from "./api";

function* postMoodSaga(action: PayloadAction<IPostMoodStatePending>) {
  try {
    const postMood: IPostMoodStateSuccess = yield call(
      postMoodRequest,
      action.payload
    );

    yield put(postMoodSuccess(postMood));

    yield put(
      MoodCalendarActions.getMoodCalendar({
        pmonth: format(new Date(), "dd/MM/yyyy"),
      })
    );

    yield put(ProfileActions.getProfile());
  } catch (error) {
    yield put(postMoodError({ error }));
  }
}

function* resetPostMoodSaga() {
  try {
    yield put(resetPostMoodSuccess());
  } catch (error) {
    yield put(resetPostMoodError());
  }
}

function* PostMoodWatcher() {
  yield takeLatest(postMood.type, postMoodSaga);
  yield takeLatest(resetPostMood.type, resetPostMoodSaga);
}

export default PostMoodWatcher;
