import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { IReferralsVacancy, IReferralsVacancyStatePending } from "./reducers";
import {
  getReferralsVacancy,
  getReferralsVacancyError,
  getReferralsVacancySuccess,
  resetReferralsVacancy,
  resetReferralsVacancyError,
  resetReferralsVacancySuccess,
} from "./slice";
import { getReferralsVacancyRequest } from "./api";

function* getReferralsVacancySaga(
  action: PayloadAction<IReferralsVacancyStatePending>
) {
  try {
    const vacancy: IReferralsVacancy = yield call(
      getReferralsVacancyRequest,
      action.payload
    );

    yield put(getReferralsVacancySuccess(vacancy));
  } catch (error) {
    yield put(getReferralsVacancyError({ error }));
  }
}

function* resetReferralsVacancySaga() {
  try {
    yield put(resetReferralsVacancySuccess());
  } catch (error) {
    yield put(resetReferralsVacancyError());
  }
}

function* ReferralsVacancyWatcher() {
  yield takeLatest(getReferralsVacancy.type, getReferralsVacancySaga);
  yield takeLatest(resetReferralsVacancy.type, resetReferralsVacancySaga);
}

export default ReferralsVacancyWatcher;
