import { ChangeEvent } from "react";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import { Box, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  AddMoreButton,
  RemoveButton,
  QuestionsWrapp,
  QuestionFieldWrapp,
  QuestionFieldHead,
} from "../AskQuestion.styled";

export function Questions() {
  const { control, watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  const maxValue = 2000;
  const questionsArr = watch("questions");
  const hasDisableAddButton = !!(questionsArr || []).filter(
    (item: any) => !item?.text?.trim()
  )?.length;

  const getFieldTitle = (idx: number) =>
    idx > 0 || fields.length > 1 ? idx + 1 : "Your";
  const getCounter = (length: number) => (
    <Box component="span" sx={{ display: "block", textAlign: "right" }}>
      {length} / {maxValue}
    </Box>
  );

  const handleOnAddQuestion = () => {
    append({ text: "" });
  };

  const handleOnRemoveQuestion = (index: number) => () => {
    remove(index);
  };

  const handleOnChange =
    (idx: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (value?.length > maxValue) {
        return;
      }

      setValue(`questions.${idx}.text`, value);
    };

  return (
    <QuestionsWrapp>
      {fields.map((item, index) => (
        <QuestionFieldWrapp key={item.id}>
          <QuestionFieldHead>
            {getFieldTitle(index)} question
            {fields?.length > 1 ? (
              <RemoveButton
                type="button"
                variant="text"
                onClick={handleOnRemoveQuestion(index)}
              >
                Delete
              </RemoveButton>
            ) : null}
          </QuestionFieldHead>

          <Controller
            name={`questions.${index}.text`}
            control={control}
            rules={{
              required: true,
              validate: (value) => !!value?.trim(),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                multiline
                minRows={4}
                maxRows={8}
                onChange={handleOnChange(index)}
                helperText={getCounter(field.value?.length)}
                variant="filled"
                placeholder="Write your question here"
              />
            )}
          />
        </QuestionFieldWrapp>
      ))}

      <AddMoreButton
        startIcon={<AddIcon />}
        disabled={hasDisableAddButton}
        type="button"
        variant="text"
        onClick={handleOnAddQuestion}
      >
        Add one more question
      </AddMoreButton>
    </QuestionsWrapp>
  );
}
