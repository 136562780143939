import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IProfileState } from "./reducers";

export const profileSelector = (state: RootState): IProfileState =>
  state.profile;

export const selectProfile = createSelector(
  profileSelector,
  (profileState: IProfileState) => ({ ...profileState })
);

export const selectProfileLang = createSelector(
  profileSelector,
  (profileState: IProfileState) => profileState.profile?.lang
);

export const selectProfileLocation = createSelector(
  profileSelector,
  (profileState: IProfileState) => profileState.profile?.location
);

export const selectProfileNewsSubs = createSelector(
  profileSelector,
  (profileState: IProfileState) => profileState.profile?.news_subscriptions
);
