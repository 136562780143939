import { format, startOfISOWeek, endOfISOWeek } from "date-fns";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  CalendarToolbarMonth,
  CalendarToolbarDate,
  CalendarToolbarWrapp,
  CalendarToolbarButton,
  CalendarButtonsWrapp,
  CalendarToolbarButtonGroup,
} from "./TimeOffCalendar.styled";

interface ICalendarToolbarProps {
  date: Date;
  pending: boolean;
  weekView?: boolean;
  onNavigate: (k: string) => void;
}

export function CalendarToolbar(props: ICalendarToolbarProps) {
  const { weekView, date, pending, onNavigate } = props;

  const startWeekDay = format(startOfISOWeek(date), "d");
  const endWeekDay = format(endOfISOWeek(date), "d");

  const startWeekMonth = format(startOfISOWeek(date), "MMM");
  const endWeekMonth = format(endOfISOWeek(date), "MMM");
  const hasMonthsDifference = startWeekMonth !== endWeekMonth;

  const month = format(date, weekView ? "MMM" : "MMMM");
  const year = format(date, "yyyy");

  const weekViewRange = hasMonthsDifference ? (
    <>
      {startWeekDay} {startWeekMonth} - {endWeekDay} {endWeekMonth}
    </>
  ) : (
    <>
      {startWeekDay}-{endWeekDay} {month}
    </>
  );

  const hasCurrentDate =
    format(date, "dd-MM-yyyy") === format(new Date(), "dd-MM-yyyy");

  const goToBack = () => {
    onNavigate("PREV");
  };
  const goToNext = () => {
    onNavigate("NEXT");
  };

  const goToToday = () => {
    onNavigate("TODAY");
  };

  return (
    <CalendarToolbarWrapp>
      <CalendarButtonsWrapp>
        <CalendarToolbarButton
          disabled={pending || hasCurrentDate}
          size="large"
          variant="outlined"
          color="primary"
          onClick={goToToday}
        >
          Today
        </CalendarToolbarButton>

        <CalendarToolbarButtonGroup>
          <CalendarToolbarButton
            disabled={pending}
            size="large"
            variant="outlined"
            color="primary"
            onClick={goToBack}
          >
            <ArrowBackIosNewIcon />
          </CalendarToolbarButton>
          <CalendarToolbarButton
            disabled={pending}
            size="large"
            variant="outlined"
            color="primary"
            onClick={goToNext}
          >
            <ArrowForwardIosIcon />
          </CalendarToolbarButton>
        </CalendarToolbarButtonGroup>
      </CalendarButtonsWrapp>
      <CalendarToolbarDate>
        <CalendarToolbarMonth>
          {weekView ? weekViewRange : month}
        </CalendarToolbarMonth>
        {year}
      </CalendarToolbarDate>
    </CalendarToolbarWrapp>
  );
}
