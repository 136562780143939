import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IHotTopicsState } from "./reducers";

export const hotTopicsSelector = (state: RootState): IHotTopicsState =>
  state.hotTopics;

export const selectHotTopics = createSelector(
  hotTopicsSelector,
  (hotTopicsState: IHotTopicsState) => ({ ...hotTopicsState })
);
