import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const postTimeOffRequest = async (params: any) => {
  const response: AxiosResponse = await apiInstance.post("/time-off/booking", {
    ...params,
  });

  return response.data;
};

export const putTimeOffRequest = async (params: any) => {
  const { id, ...restParams } = params;

  const response: AxiosResponse = await apiInstance.put(
    `/time-off/booking/${id}`,
    {
      ...restParams,
    }
  );

  return response.data;
};
