import { useState, useEffect } from "react";
import { PopupMessage } from "@/shared/components";

interface IErrorMessageProps {
  message: string;
  onReset?: any;
}

export function ErrorMessage(props: IErrorMessageProps) {
  const { message, onReset } = props;

  const [showMessage, setShowMessage] = useState(false);

  const handleOnClose = () => {
    setShowMessage(false);
  };

  const handleOnExit = () => {
    if (onReset && onReset instanceof Function) onReset();
  };

  useEffect(() => {
    setShowMessage(!!message?.length);
  }, [message]); //eslint-disable-line

  return (
    <PopupMessage
      variant="error"
      open={showMessage}
      message={message}
      onClose={handleOnClose}
      onExit={handleOnExit}
    />
  );
}
