import { useSelector } from "react-redux";
import { Box, Grid, Link, useTheme, useMediaQuery } from "@mui/material";
import { Title, Bubble } from "@/shared/components";
import { selectTeam } from "@/store/redux";
import { Loader } from "./Loader";
import { TeamLogo } from "./TeamLogo";
import { TeamTitleStyled, TeamInfo, TeamDescription } from "./Team.styled";

export function TeamHeader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { team, pending } = useSelector(selectTeam);

  const { description, logo, members_count, title, domain, website_url } = team;

  const bubleParams = isMobile ? "132px" : "148px";

  if (pending) {
    return <Loader />;
  }

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Title noTransform fontSize="60px">
          {title}
        </Title>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={6} sm={6} md={3}>
          <TeamTitleStyled>TEAM MEMBERS</TeamTitleStyled>

          <Bubble color="yellowPink">
            <Title fontSize="60px">{members_count}</Title>
          </Bubble>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <TeamTitleStyled>LOGO</TeamTitleStyled>

          <TeamLogo logo={logo} title={title} bubleParams={bubleParams} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TeamTitleStyled>INFO</TeamTitleStyled>

          {domain ? (
            <TeamInfo>
              Domain: <b>{domain}</b>
            </TeamInfo>
          ) : null}

          {website_url ? (
            <TeamInfo>
              <Link href={website_url} target="_blank" rel="noopener">
                <b>Company Website</b>
              </Link>
            </TeamInfo>
          ) : null}

          <TeamDescription dangerouslySetInnerHTML={{ __html: description }} />
        </Grid>
      </Grid>
    </>
  );
}
