import { useState } from "react";
import { Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PhoneIcon from "@mui/icons-material/Phone";
import PeopleIcon from "@mui/icons-material/People";
import { GradientButton } from "@/shared/components";
import {
  ContactsListItemTitle,
  LinkButton,
  MoreInfoButton,
} from "./Contacts.styled";

interface IMoreInfoProps {
  desc: string;
  phone_number: string;
  email: string;
  has_viber: boolean;
}

export function MoreInfo(props: IMoreInfoProps) {
  const { desc, phone_number, email, has_viber } = props;
  const [hasOpen, setHasOpen] = useState<boolean>(false);

  const handleOnTogglerClick = () => {
    setHasOpen(!hasOpen);
  };

  return (
    <Box sx={{ mt: 0.5 }}>
      <MoreInfoButton
        fullWidth
        disableElevation
        size="small"
        color="secondary"
        variant="contained"
        onClick={handleOnTogglerClick}
        endIcon={hasOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        MORE INFO
      </MoreInfoButton>

      {hasOpen ? (
        <Box sx={{ p: 1 }}>
          <ContactsListItemTitle>Help with</ContactsListItemTitle>

          <Box sx={{ fontWeight: 500 }}>
            <div dangerouslySetInnerHTML={{ __html: desc }}></div>
          </Box>

          {phone_number ? (
            <LinkButton
              target="_blank"
              rel="noopener"
              href={`tel:${phone_number}`}
            >
              <GradientButton
                fullWidth
                sx={{ mt: 1.5 }}
                startIcon={<PhoneIcon />}
              >
                {phone_number}
                {has_viber ? " (Viber)" : null}
              </GradientButton>
            </LinkButton>
          ) : null}

          {email ? (
            <LinkButton
              target="_blank"
              rel="noopener"
              href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}
            >
              <GradientButton
                fullWidth
                sx={{ mt: 1 }}
                startIcon={<PeopleIcon />}
              >
                Teams Chat
              </GradientButton>
            </LinkButton>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}
