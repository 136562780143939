import { call, put, takeLatest } from "redux-saga/effects";
import { ISurveysListStateSuccess } from "./reducers";
import {
  getSurveysList,
  getSurveysListError,
  getSurveysListSuccess,
} from "./slice";
import { getSurveysListRequest } from "./api";

function* getSurveysListSaga() {
  try {
    const list: ISurveysListStateSuccess = yield call(getSurveysListRequest);

    yield put(getSurveysListSuccess(list));
  } catch (error) {
    yield put(getSurveysListError({ error }));
  }
}

function* SurveysListWatcher() {
  yield takeLatest(getSurveysList.type, getSurveysListSaga);
}

export default SurveysListWatcher;
