import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ITeamsState } from "./reducers";

export const teamsSelector = (state: RootState): ITeamsState => state.teams;

export const selectTeams = createSelector(
  teamsSelector,
  (teamsState: ITeamsState) => ({ ...teamsState })
);
