import { styled } from "@mui/material/styles";

interface ITitle {
  $fontStyle?: string;
  $fontSize?: string;
  $fontWeight?: string;
  $noTransform?: boolean;
}

export const TitleStyled = styled("div")<ITitle>(
  ({ theme, $noTransform, $fontSize, $fontStyle, $fontWeight }) => ({
    fontFamily: "Druk, Arial, sans-serif",
    textTransform: $noTransform ? "none" : "uppercase",
    fontSize: $fontSize || "1.875rem",
    fontStyle: $fontStyle || "italic",
    fontWeight: $fontWeight || "700",
    letterSpacing: "0.04em",

    [theme.breakpoints.down("md")]: {
      lineHeight: "1.25",
    },
  })
);
