import { PayloadAction } from "@reduxjs/toolkit";

export interface ICancelTimeOffState {
  pending: boolean;
  error: null;
  id: number | string | null;
}

export interface ICancelTimeOffStatePending {
  id: number;
  filters: { ptype: number; pstatus: number; psort: string };
}

export interface ICancelTimeOffStateError {
  error: any;
}

export const initialState: ICancelTimeOffState = {
  pending: false,
  error: null,
  id: null,
};

export const reducers = {
  cancelTimeOff: (
    state: ICancelTimeOffState,
    action: PayloadAction<ICancelTimeOffStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: null,
    };
  },
  cancelTimeOffSuccess: (state: ICancelTimeOffState) => {
    return {
      ...state,
      pending: false,
    };
  },
  cancelTimeOffError: (
    state: ICancelTimeOffState,
    action: PayloadAction<ICancelTimeOffStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
