import { useMsal } from "@azure/msal-react";
import { SigninButton } from "./SigninButton";
import {
  SigninContainer,
  SigninLogo,
  SigninHeader,
  SigninContent,
  SigninFooter,
  SigninForm,
} from "./Signin.styled";

export function Signin() {
  const { inProgress } = useMsal();
  const hasProgress = inProgress !== "none";

  return (
    <SigninContainer>
      <SigninHeader>
        <SigninLogo variant="black" />
      </SigninHeader>

      <SigninContent>
        <SigninForm>
          <div>
            Welcome to the Bright Portal! Please, sign in to book some time off,
            read our NewsFeed, refer a friend, check our WIKI and many more.
          </div>
          <SigninButton disabled={hasProgress} />
        </SigninForm>
      </SigninContent>
      <SigninFooter>&copy; {new Date().getFullYear()} Brightgrove</SigninFooter>
    </SigninContainer>
  );
}
