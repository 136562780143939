import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const getReferralsCandidatesRequest = async () => {
  const response: AxiosResponse = await apiInstance.get(
    "/referrals/candidates"
  );

  return response.data;
};
