import { useEffect, useState } from "react";
import { MyDocumentsActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { MyDocumentsList, MyDocumentsBreadcrumbs } from "./components";
import { Wrapper } from "./MyDocuments.styled";

export interface IBreadcrumb {
  title: string;
  url: string;
}

export function MyDocuments() {
  const { getMyDocuments } =
    useActions<typeof MyDocumentsActions>(MyDocumentsActions);

  const [breadcrumbs, setBreadcrumbs] = useState<Array<IBreadcrumb>>([]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleOnAddBreadcrumb = (breadcrumb: IBreadcrumb) => {
    const hasUrl = !!breadcrumb.url;

    setBreadcrumbs(hasUrl ? [...breadcrumbs, breadcrumb] : []);
  };

  const handleOnSelectBreadcrumb = (breadcrumb: IBreadcrumb) => {
    const indxSelected = (breadcrumbs || []).findIndex(
      (item) => item.url === breadcrumb.url
    );
    const slicedBreadcrumbs = breadcrumbs.slice(0, indxSelected + 1);

    setBreadcrumbs(slicedBreadcrumbs);

    getMyDocuments({ path: breadcrumb.url });
  };

  useEffect(() => {
    getMyDocuments({});
  }, []); //eslint-disable-line

  useEffect(() => {
    scrollToTop();
  }, [breadcrumbs]);

  return (
    <Wrapper>
      <MyDocumentsBreadcrumbs
        data={breadcrumbs}
        onSelect={handleOnSelectBreadcrumb}
      />

      <MyDocumentsList onSelectItem={handleOnAddBreadcrumb} />
    </Wrapper>
  );
}
