import { TextField, Box } from "@mui/material";

interface ITimeOffRequestNoteProps {
  value: string;
  onChange: (k: string) => void;
}

export function TimeOffRequestNote(props: ITimeOffRequestNoteProps) {
  const { value, onChange } = props;

  const maxValue = 2000;
  const getCounter = (length: number) => (
    <Box component="span" sx={{ display: "block", textAlign: "right" }}>
      {length} / {maxValue}
    </Box>
  );

  const handleOnChange = (e: any) => {
    const value = e.target.value;

    if (value?.length > maxValue) {
      return;
    }

    onChange(value);
  };

  return (
    <TextField
      fullWidth
      multiline
      minRows={4}
      maxRows={6}
      variant="filled"
      label="Add a note for your manager (optional)"
      helperText={getCounter(value?.length)}
      value={value}
      onChange={handleOnChange}
    />
  );
}
