import { SxProps } from "@mui/material";
import { BubbleStyled } from "./Bubble.styled";
import { IBadgeProps } from "@/shared/components";

interface IBubbleProps extends IBadgeProps {
  width?: string;
  height?: string;
  sx?: SxProps;
}

export function Bubble(props: IBubbleProps) {
  const { children, width, height, ...restProps } = props;

  return (
    <BubbleStyled $width={width} $height={height} {...restProps}>
      {children}
    </BubbleStyled>
  );
}
