import { Grid } from "@mui/material";
import { ITeamMember } from "@/store/redux";
import { Card } from "./Card";
import { GridStyled } from "./Card.styled";

interface ICardsListProps {
  data: ITeamMember[];
  loading?: boolean;
}

export function CardsList(props: ICardsListProps) {
  const { data, loading } = props;

  return (
    <Grid container spacing={2}>
      {(data || []).map((item: ITeamMember, idx: number) => (
        <GridStyled key={`card-${idx}`} item xs={12} sm={6} md={4} lg={3}>
          <Card {...item} loading={loading} />
        </GridStyled>
      ))}
    </Grid>
  );
}
