import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  PostTaskReportingActions,
  postTaskReportingSelector,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { GradientButton } from "@/shared/components";
import {
  SummaryWrapper,
  SummaryTitle,
  SummarySubTitle,
  SummaryBorder,
} from "../TaskReporting.styled";

interface ISummaryProps {
  reporting: {
    [key: number]: {
      project_name: string;
      tasks: Array<{ id: string; value: string; checked: boolean }>;
    };
  } | null;
}

export function Summary(props: ISummaryProps) {
  const { reporting = {} } = props;

  const { id = "" } = useParams();

  const { postTaskReporting } = useActions<typeof PostTaskReportingActions>(
    PostTaskReportingActions
  );
  const { pending } = useSelector(postTaskReportingSelector);

  const reportingEntries = Object.entries(reporting || {});
  const selectedTasks = reportingEntries
    .map(([key, value]) => {
      const checkedTasks = value?.tasks.filter((item) => item.checked);
      const hasChecked = !!checkedTasks.length;

      return {
        hasChecked,
        project_id: key,
        project_name: value.project_name,
        tasks: checkedTasks,
      };
    })
    .filter((item) => item.hasChecked);

  const hasSelectedTasks = !!selectedTasks?.length;

  const handleOnSubmit = () => {
    const reporting = selectedTasks.map(({ project_id, tasks }) => ({
      project_id: Number(project_id),
      tasks: tasks.map((item) => item.value),
    }));

    postTaskReporting({ id, reporting });
  };

  return hasSelectedTasks ? (
    <SummaryWrapper>
      <SummaryTitle>Tasks summary</SummaryTitle>

      <SummaryBorder>
        {selectedTasks.map(
          ({ hasChecked, project_id, project_name, tasks }) => {
            return hasChecked ? (
              <div key={project_id}>
                <SummarySubTitle>{project_name}</SummarySubTitle>
                <ul>
                  {tasks.map((item, idx) => (
                    <li key={`${item.id}`}>{item.value}</li>
                  ))}
                </ul>
              </div>
            ) : null;
          }
        )}
      </SummaryBorder>

      <GradientButton
        sx={{ minWidth: "200px", mt: 4 }}
        disabled={pending}
        onClick={handleOnSubmit}
      >
        Submit
      </GradientButton>
    </SummaryWrapper>
  ) : null;
}
