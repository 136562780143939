import { PayloadAction } from "@reduxjs/toolkit";

export interface IMoodCalendarValues {
  date: string;
  score: number;
}

export interface IMoodCalendarState {
  pending: boolean;
  error: any;
  scores: IMoodCalendarValues[] | [];
}

export interface IMoodCalendarStatePending {
  pmonth: string;
}

export interface IMoodCalendarStateSuccess {
  scores: IMoodCalendarValues[] | [];
}

export interface IMoodCalendarStateError {
  error: any;
}

export const initialState: IMoodCalendarState = {
  pending: false,
  error: null,
  scores: [],
};

export const reducers = {
  getMoodCalendar: (
    state: IMoodCalendarState,
    action: PayloadAction<IMoodCalendarStatePending>
  ) => {
    return {
      ...state,
      scores: [],
      pending: true,
      error: "",
    };
  },
  getMoodCalendarSuccess: (
    state: IMoodCalendarState,
    action: PayloadAction<IMoodCalendarStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      ...(action.payload || {}),
    };
  },
  getMoodCalendarError: (
    state: IMoodCalendarState,
    action: PayloadAction<IMoodCalendarStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
