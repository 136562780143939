import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const getMarketplaceRequest = async (params = {}) => {
  const response: AxiosResponse = await apiInstance.get("/marketplace", {
    params: {
      ...params,
    },
  });

  return response.data;
};
