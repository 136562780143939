import { ReactNode } from "react";
import { Title } from "@/shared/components";
import {
  NewsSidebarSectionStyled,
  NewsSidebarSectionContent,
} from "./News.styled";

interface INewsSidebarSectionProps {
  title: string;
  content: ReactNode;
}

export const title = "Bright News";
export const desc = (
  <>
    Here you can find what&apos;s bright happening at Brightgrove: company news,
    events, updates. Feel free to comment, like or hate :) We are always open to
    cover anything you feel important to talk about.
  </>
);

export function NewsSidebarSection(props: INewsSidebarSectionProps) {
  const { title, content } = props;

  return (
    <NewsSidebarSectionStyled>
      <Title>{title}</Title>
      <NewsSidebarSectionContent>{content}</NewsSidebarSectionContent>
    </NewsSidebarSectionStyled>
  );
}
