import { Link, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Title } from "@/shared/components";

export const ContactsTitle = styled(Title)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "1.875rem",
  },
}));

export const ContactsList = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(5),
  marginTop: theme.spacing(2),
  paddingBottom: theme.spacing(1.5),

  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(2),
  },
}));

export const ContactsListItem = styled("div")(({ theme }) => ({
  display: "flex !important",
  alignItems: "start",
  gap: theme.spacing(2.5),
  paddingRight: theme.spacing(5),
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    maxWidth: 340,
    paddingRight: theme.spacing(2),
  },
}));

export const ContactsListItemContent = styled("div")(({ theme }) => ({
  flex: 1,
}));

export const ContactsListItemAvatar = styled("div")(({ theme }) => ({
  width: 80,
  height: 80,
  overflow: "hidden",
  borderRadius: "50%",
}));

export const ContactsListItemTitle = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontWeight: 500,
  fontSize: "0.875rem",
}));

export const ContactsListItemName = styled("div")(({ theme }) => ({
  fontWeight: 900,
  fontSize: "0.875rem",
}));

export const ContactsListItemMore = styled("div")(({ theme }) => ({}));

export const LinkButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));

export const MoreInfoButton = styled(Button)(({ theme }) => ({
  fontSize: "0.75rem",
}));
