import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const getReferralsVacanciesRequest = async (params = {}) => {
  const response: AxiosResponse = await apiInstance.get(
    "/referrals/vacancies",
    {
      params: {
        ...params,
      },
    }
  );

  return response.data;
};
