import { useState, ChangeEvent } from "react";
import { FormControlLabel, Checkbox, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { TaskField } from "./TaskField";

interface ITaskItemProps {
  taskIndx: number;
  task: { value: string; checked: boolean };
  disabled: boolean;
  onSave: (idx: number, value: string) => void;
  onSelect: (taskIdx: number, value: boolean) => void;
}

export function TaskItem(props: ITaskItemProps) {
  const { task, taskIndx, disabled, onSave, onSelect } = props;

  const [hasEdit, setHasEdit] = useState(false);

  const handleOnToggle = () => {
    setHasEdit((prev) => !prev);
  };

  const handleOnSave = (value: string) => {
    onSave(taskIndx, value);
    setHasEdit(false);
  };

  const handleOnCancel = () => {
    setHasEdit(false);
  };

  const handleOnSelect = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onSelect(taskIndx, checked);
  };

  return (
    <>
      {hasEdit ? (
        <TaskField
          disabled={disabled}
          value={task.value}
          onSave={handleOnSave}
          onCancel={handleOnCancel}
        />
      ) : (
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={task?.checked}
              onChange={handleOnSelect}
            />
          }
          label={
            <>
              {task?.value}
              <IconButton
                size="small"
                disabled={disabled}
                onClick={handleOnToggle}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </>
          }
          sx={{
            alignItems: "start",
            "& .MuiFormControlLabel-label": { paddingTop: "8px" },
          }}
        />
      )}
    </>
  );
}
