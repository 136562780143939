import { useNavigate } from "react-router-dom";
import { REFERRALS } from "@/routes/consts";
import { WhiteButton } from "@/shared/components";
import { ReferrButtonWrapper } from "./UserMenu.styled";

interface IReferrButtonProps {
  onClose: () => void;
}

export function ReferrButton(props: IReferrButtonProps) {
  const { onClose } = props;

  const navigate = useNavigate();

  const handleOnClick = () => {
    onClose();

    navigate(REFERRALS, {
      state: { hasReferralsCandidate: true },
    });
  };

  return (
    <ReferrButtonWrapper>
      <WhiteButton
        onClick={handleOnClick}
        sx={{
          width: (theme) => ({
            width: "50%",

            [theme.breakpoints.down("sm")]: {
              width: "65%",
            },
          }),
        }}
      >
        Referr a friend
      </WhiteButton>
    </ReferrButtonWrapper>
  );
}
