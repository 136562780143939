import { PayloadAction } from "@reduxjs/toolkit";

export interface INewsComment {
  id: number;
  text: string;
  created_at?: string;
  parent_id?: number | null;
  parent_reply_id?: number | null;
}

export interface INewsCommentState {
  pending: boolean;
  error: any;
  comment: INewsComment;
}

export interface INewsCommentStatePending {
  id: string | number | undefined;
  parent_reply_id: number | null | undefined;
  text: string;
}

export interface INewsCommentStateError {
  error: any;
}

export const initialState: INewsCommentState = {
  pending: false,
  error: "",
  comment: {} as INewsComment,
};

export const reducers = {
  postNewsComment: (
    state: INewsCommentState,
    action: PayloadAction<INewsCommentStatePending>
  ) => {
    return {
      ...state,
      comment: {} as INewsComment,
      pending: true,
      error: "",
    };
  },
  postNewsCommentSuccess: (
    state: INewsCommentState,
    action: PayloadAction<INewsComment>
  ) => {
    return {
      ...state,
      pending: false,
      comment: action?.payload,
    };
  },
  postNewsCommentError: (
    state: INewsCommentState,
    action: PayloadAction<INewsCommentStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
