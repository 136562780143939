import { styled } from "@mui/material/styles";
import { Fab } from "@mui/material";

export const OpenButton = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: 24,
  right: 24,
  color: "transparent",
  width: theme.spacing(8),
  height: theme.spacing(8),

  [theme.breakpoints.down("sm")]: {
    bottom: 16,
    right: 16,
  },
}));

export const ErrorMessageDesc = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 700,
}));
