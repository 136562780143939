import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const postMoodRequest = async (params: any) => {
  const response: AxiosResponse = await apiInstance.post("/mood-monitoring", {
    ...params,
  });

  return response.data;
};
