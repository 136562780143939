import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IReferralsState } from "./reducers";

export const referralsSelector = (state: RootState): IReferralsState =>
  state.referrals;

export const selectReferrals = createSelector(
  referralsSelector,
  (referralsState: IReferralsState) => ({ ...referralsState })
);
