import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ITimeOffCalendarStateSuccess,
  ITimeOffCalendarStatePending,
} from "./reducers";
import {
  getTimeOffCalendar,
  getTimeOffCalendarError,
  getTimeOffCalendarSuccess,
} from "./slice";
import { getTimeOffCalendarRequest, getHolidaysRequest } from "./api";

interface IHoliday {
  start_date: string;
  end_date: string;
  title: string;
}

function* getTimeOffCalendarSaga(
  action: PayloadAction<ITimeOffCalendarStatePending>
) {
  try {
    const timeOffCalendar: ITimeOffCalendarStateSuccess = yield call(
      getTimeOffCalendarRequest,
      action.payload
    );
    const holidaysEvents: { holidays: IHoliday[] } = yield call(
      getHolidaysRequest,
      {
        start_date: action?.payload?.start_date,
        end_date: action?.payload?.end_date,
      }
    );

    const computedEvents = {
      ...(timeOffCalendar || {}),
      dates: [
        ...(timeOffCalendar?.dates || []),
        ...(holidaysEvents?.holidays || []).map((item) => ({
          ...item,
          type: "holiday",
        })),
      ],
    };

    yield put(getTimeOffCalendarSuccess(computedEvents));
  } catch (error) {
    yield put(getTimeOffCalendarError({ error }));
  }
}

function* TimeOffCalendarWatcher() {
  yield takeLatest(getTimeOffCalendar.type, getTimeOffCalendarSaga);
}

export default TimeOffCalendarWatcher;
