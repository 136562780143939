import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function ViewsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" htmlColor="transparent" {...props}>
      <path
        d="M2 12.2727C2 12.2727 5.63636 5 12 5C18.3636 5 22 12.2727 22 12.2727C22 12.2727 18.3636 19.5455 12 19.5455C5.63636 19.5455 2 12.2727 2 12.2727Z"
        stroke="#8E949A"
        // fill="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C13.5062 15 14.7273 13.779 14.7273 12.2727C14.7273 10.7665 13.5062 9.54545 12 9.54545C10.4938 9.54545 9.27273 10.7665 9.27273 12.2727C9.27273 13.779 10.4938 15 12 15Z"
        stroke="#8E949A"
        // fill="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
