import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IMoodContactsState } from "./reducers";

export const moodContactsSelector = (state: RootState): IMoodContactsState =>
  state.moodMonitoringContacts;

export const selectMoodContacts = createSelector(
  moodContactsSelector,
  (moodContactsState: IMoodContactsState) => ({ ...moodContactsState })
);
