import { Box, Grid } from "@mui/material";
import { Title, GradientButton } from "@/shared/components";
import { FormItems } from "./FormItems";

export function DeliveryForm() {
  return (
    <Box sx={{ mt: 4 }}>
      <Title sx={{ mb: 2 }}>DELIVERY INFORMATION</Title>

      <Grid container spacing={2} sx={{ maxWidth: 660 }}>
        <FormItems />

        <Grid item xs={12}>
          <GradientButton fullWidth type="submit" sx={{ mt: 3 }}>
            Place order
          </GradientButton>
        </Grid>
      </Grid>
    </Box>
  );
}
