import { useState, useEffect } from "react";
import { groupBy } from "lodash";
import { ITimesheetActivityItem, IUserProjectsItem } from "@/store/redux";
import { splitedByType, getOutageHours, getProjectsHours } from "./helpers";
import { IProjectsHoursState, IOutageHoursState } from "./interfaces";

export function useWeekViewToolbar() {
  const [, setDiff] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());

  const getSelectedDate = (newDiff: number) => {
    var date = new Date();

    date.setDate(date.getDate() + newDiff);

    setCurrentDate(date);
  };

  const getNavigationType = (type: string) => {
    const types: any = {
      PREV: -7,
      NEXT: 7,
      TODAY: 0,
    };

    return types[type];
  };

  const onDateChange = (type: string) => {
    const getNewDiff = (prevDiff: number) => {
      const selectedDiff = getNavigationType(type);
      const newDiff = selectedDiff ? prevDiff + selectedDiff : 0;

      getSelectedDate(newDiff);

      return newDiff;
    };

    setDiff(getNewDiff);
  };

  return { currentDate, onDateChange };
}

export function useReporting({
  type,
  currentDate,
  userProjects,
  timesheet,
}: {
  type: "week" | "month";
  currentDate: Date;
  userProjects: IUserProjectsItem[];
  timesheet: ITimesheetActivityItem[];
}) {
  const [totalColumnHours, setTotalColumnHours] = useState<
    { [k: string | number]: number }[]
  >([]);
  const [projectsHours, setProjectsHours] = useState<IProjectsHoursState[]>([]);
  const [outageHours, setOutageHours] = useState<IOutageHoursState>({
    sum: 0,
    rawHours: [],
    reporting: [],
  } as IOutageHoursState);

  const workingTypeList = splitedByType(timesheet).working || [];
  const outageTypeList = splitedByType(timesheet).outage || [];

  const groupedByProject = groupBy(
    workingTypeList,
    (item) => item?.project?.id
  );
  const projectsReporting: any = userProjects.map((project) => ({
    ...project,
    reporting: groupedByProject[project.id] || [],
  }));

  const getHours = () => {
    const outage = getOutageHours({ type, currentDate, list: outageTypeList });
    const projects = getProjectsHours({
      type,
      currentDate,
      list: projectsReporting,
    });

    const outageHours = outage.rawHours;
    const projectsHours = projects.map((item) => item.rawHours) as any;

    // TODO: need to clarify which hours should be in a total row
    const total = [outageHours, ...projectsHours].reduce((a, b) => {
      return a.map((item: any, i: any) => {
        const key = Object.keys(item)[0];
        const value = Object.values(item)[0];
        const computedValue = isNaN(value + b[i][key]) ? 0 : value + b[i][key];

        return { [key]: computedValue };
      });
    });

    return { outage, projects, total };
  };

  const generateTimesheet = () => {
    const { outage, projects, total } = getHours();

    setOutageHours(outage);
    setProjectsHours(projects);
    setTotalColumnHours(total);
  };

  useEffect(() => {
    if (currentDate && userProjects?.length) {
      generateTimesheet();
    }
  }, [currentDate, timesheet, userProjects]); //eslint-disable-line

  return { projectsHours, outageHours, totalColumnHours };
}
