import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ITaskReportingState } from "./reducers";

export const taskReportingSelector = (state: RootState): ITaskReportingState =>
  state.taskReporting;

export const selectTaskReporting = createSelector(
  taskReportingSelector,
  (taskReportingState: ITaskReportingState) => ({ ...taskReportingState })
);
