import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IPostThanksGivingState } from "./reducers";

export const postThanksGivingSelector = (
  state: RootState
): IPostThanksGivingState => state.postThanksGiving;

export const selectPostThanksGiving = createSelector(
  postThanksGivingSelector,
  (postThanksGivingState: IPostThanksGivingState) => ({
    ...postThanksGivingState,
  })
);
