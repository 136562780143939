import { Button as MUIButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledButton = styled(MUIButton)(({ theme, color }) => ({
  position: "relative",
  padding: theme.spacing(1.5, 2),
  borderRadius: "0",
  boxShadow: "none",
  background: theme.palette.common.white,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.common.black,
  fontWeight: "900",
  fontSize: "0.875rem",
  overflow: "hidden",
  zIndex: 1,

  "&:before": {
    content: "''",
    position: "absolute",
    top: "4px",
    left: "4px",
    bottom: "4px",
    right: "4px",
    backgroundImage:
      color === "error"
        ? "linear-gradient(#F9C6B9,#F9C6B9)"
        : theme.palette.yellowBlue.main,
    zIndex: "-1",
    transition: "all ease-in-out 0.3s",
    opacity: 0,
  },

  "&:after": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundImage:
      color === "error"
        ? "linear-gradient(#F9C6B9,#F9C6B9)"
        : theme.palette.yellowBlue.main,
    zIndex: -3,
  },

  "&:hover": {
    boxShadow: "none",

    "&:before": {
      opacity: "0.5",
    },
  },
}));

export const ButtonBg = styled("span")(({ theme }) => ({
  position: "absolute",
  zIndex: "-2",
  top: "4px",
  left: "4px",
  bottom: "4px",
  right: "4px",
  display: "block",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.common.white,
}));
