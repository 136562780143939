import { styled } from "@mui/material/styles";

export const IconWithCountWrapper = styled("span")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  fontWeight: 500,
  gap: theme.spacing(1),

  "& svg": {
    fontSize: "1.5rem",
  },
}));

export const IconWithCountValue = styled("span")<{
  $small?: boolean | undefined;
}>(({ theme, $small }) => ({
  color:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.common.black,
  fontWeight: 400,
  fontSize: $small ? "0.875rem" : "1rem",
}));
