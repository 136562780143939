import { PayloadAction } from "@reduxjs/toolkit";

export interface ISwitchUserState {
  error: any;
  pending: boolean;
  user: string | null | undefined;
  original_user: string | null | undefined;
}

export interface ISwitchUserStatePending {
  user: string | null | undefined;
  original_user: string | null | undefined;
}

export interface ISwitchUserStateError {
  error: any;
}

export const initialState: ISwitchUserState = {
  pending: false,
  error: "",
  user: null,
  original_user: null,
};

export const reducers = {
  switchUser: (
    state: ISwitchUserState,
    action: PayloadAction<ISwitchUserStatePending>
  ) => ({
    ...state,
    pending: true,
    error: "",
  }),
  switchUserSuccess: (
    state: ISwitchUserState,
    action: PayloadAction<ISwitchUserStatePending>
  ) => {
    return {
      ...state,
      pending: false,
      ...(action.payload || {}),
    };
  },
  switchUserError: (
    state: ISwitchUserState,
    action: PayloadAction<ISwitchUserStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload,
  }),
};
