import { PayloadAction } from "@reduxjs/toolkit";

export interface IPostMoodState {
  score: number | null;
  hasPosted: boolean;
  pending: boolean;
  message: string;
  error_message: string;
  error: any;
}

export interface IPostMoodStatePending {
  score: number | null;
}

export interface IPostMoodStateSuccess {
  error_message: string;
  message: string;
}

export interface IPostMoodStateError {
  error: any;
}

export const initialState: IPostMoodState = {
  pending: false,
  error: null,
  error_message: "",
  message: "",
  hasPosted: false,
  score: null,
};

export const reducers = {
  postMood: (
    state: IPostMoodState,
    action: PayloadAction<IPostMoodStatePending>
  ) => {
    return {
      ...state,
      hasPosted: false,
      pending: true,
      message: "",
      error_message: "",
      error: "",
      ...(action.payload || {}),
    };
  },
  postMoodSuccess: (
    state: IPostMoodState,
    action: PayloadAction<IPostMoodStateSuccess>
  ) => {
    const { error_message, message } = action.payload;

    return {
      ...state,
      message,
      pending: false,
      hasPosted: !error_message?.length,
      error_message: error_message || "",
    };
  },
  postMoodError: (
    state: IPostMoodState,
    action: PayloadAction<IPostMoodStateError>
  ) => ({
    ...state,
    pending: false,
    hasPosted: false,
    error: action.payload.error,
  }),

  //RESET
  resetPostMood: (state: IPostMoodState) => ({
    ...state,
    pending: false,
    error: null,
    message: "",
    error_message: "",
    hasPosted: false,
    score: null,
  }),
  resetPostMoodSuccess: (state: IPostMoodState) => ({
    ...state,
  }),
  resetPostMoodError: (state: IPostMoodState) => ({
    ...state,
  }),
};
