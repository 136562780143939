import { PropsWithChildren, ReactNode } from "react";
import { SxProps } from "@mui/material";
import {
  FilterControLabel,
  FilterControlWrapper,
  FiltersWrapper,
} from "./Filters.styled";

interface IFiltersProps extends PropsWithChildren {
  vertical?: boolean;
  sx?: SxProps;
}

interface IFilterControlProps {
  control: ReactNode;
  label?: string | ReactNode;
  vertical?: boolean;
  sx?: SxProps;
}

export function Filters(props: IFiltersProps) {
  const { children, vertical, ...restProps } = props;

  return (
    <FiltersWrapper {...restProps} $vertical={vertical}>
      {children}
    </FiltersWrapper>
  );
}

export function FilterControl(props: IFilterControlProps) {
  const { control, label, vertical, ...restProps } = props;

  return (
    <FilterControlWrapper {...restProps} $vertical={vertical}>
      {label ? <FilterControLabel>{label}</FilterControLabel> : null}

      {control}
    </FilterControlWrapper>
  );
}
