import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "NewsArticleComments",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getNewsArticleComments,
  getNewsArticleCommentsError,
  getNewsArticleCommentsSuccess,
} = slice.actions;

reducerRegistry.register("newsArticleComments", reducer);
