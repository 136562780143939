import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import {
  selectCountries,
  selectProfile,
  CountriesActions,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { countriesAlphabeticallySort, getDefaultCountry } from "../helpers";
import { GenerateField } from "./GenerateField";

export function CountryField() {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const { profile } = useSelector(selectProfile);
  const { countries } = useSelector(selectCountries);
  const { getCountries } =
    useActions<typeof CountriesActions>(CountriesActions);

  const fieldName = "country";
  const hasError = !!errors[fieldName]?.type;

  useEffect(() => {
    if (!profile?.id || countries.length) {
      return;
    }

    getCountries();
  }, [profile]); //eslint-disable-line

  useEffect(() => {
    if (!countries.length) return;

    setValue("country", getDefaultCountry(countries, profile));
  }, [countries]); //eslint-disable-line

  return (
    <GenerateField
      errorText="Choose one of the options"
      name={fieldName}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, ...restField } }) => (
        <Autocomplete
          {...restField}
          onChange={(e, value) => {
            onChange(value);
          }}
          options={countriesAlphabeticallySort(countries)}
          getOptionLabel={(item: any) => item.name || ""}
          isOptionEqualToValue={(option: any, value) => {
            return (
              value === undefined ||
              value === "" ||
              option?.name === value?.name
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              error={hasError}
              label="Country"
              variant="filled"
            />
          )}
        />
      )}
    />
  );
}
