import { PropsWithChildren } from "react";
import { format, differenceInCalendarYears } from "date-fns";
import { Grid } from "@mui/material";
import { ISurveysListItem } from "@/store/redux";
import { useDevice } from "@/utils/hooks";
import {
  ListItem,
  ListItemTitle,
  ListItemLink,
  ListItemLinkIcon,
  ListItemStatus,
  ListItemStatusBadge,
} from "./SurveysList.styled";

interface ISurveysListItemProps extends ISurveysListItem {
  hasFinished?: boolean;
}

export function SurveysListItem(props: ISurveysListItemProps) {
  const { hasFinished, expire_date, name, url, status, state } = props;

  const hasPassed = state === "finished" || status === "passed";
  const hasShowYear =
    differenceInCalendarYears(new Date(), new Date(expire_date)) !== 0;
  const expireDate = format(
    new Date(expire_date),
    `dd MMM ${hasShowYear ? "yyyy" : ""}`
  );

  return (
    <ListItem>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <ItemHead>Name</ItemHead>

          {hasPassed ? (
            name
          ) : (
            <ListItemLink href={url} target="_blank" rel="noopener noreferrer">
              {name}
              <ListItemLinkIcon />
            </ListItemLink>
          )}
        </Grid>
        <Grid item xs={12} md={hasFinished ? 3 : 7}>
          <ItemHead>Expiry Date</ItemHead>
          {expireDate}
        </Grid>

        {hasFinished ? (
          <Grid item xs={12} md={4}>
            <ListItemStatus>
              <ListItemStatusBadge $hasPassed={status === "passed"} />
              {status}
            </ListItemStatus>
          </Grid>
        ) : null}
      </Grid>
    </ListItem>
  );
}

function ItemHead(props: PropsWithChildren) {
  const isMobile = useDevice("down", "md");

  return isMobile ? <ListItemTitle>{props.children}</ListItemTitle> : null;
}
