import { useMediaQuery, useTheme } from "@mui/material";
import {
  Container,
  FaqWrapper,
  FaqMobileView,
  FaqDesktopView,
  Page,
} from "@/components";

export function Faq() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const PageContent = () => (isMobile ? <FaqMobileView /> : <FaqDesktopView />);

  return (
    <Page>
      <Container>
        <FaqWrapper>
          <PageContent />
        </FaqWrapper>
      </Container>
    </Page>
  );
}
