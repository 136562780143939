import { PayloadAction } from "@reduxjs/toolkit";

export interface IReferralsVacancy {
  id: number;
  title: string;
  description: string;
  company: string;
  location: string;
  area: string;
  level: string;
  tech_stack: string;
  about_client: string;
  project_details: string;
  your_team: string;
  benefits: string;
  responsibilities: string;
  skills: string;
  recruiter: {
    photo: string;
    name: string;
    email: string;
  };
  legacy_url?: string; //TODO: remove after moving to new Careers
}

export interface IReferralsVacancyState {
  pending: boolean;
  error: any;
  vacancy: IReferralsVacancy;
}

export interface IReferralsVacancyStatePending {
  id: number | string | null;
}

export interface IReferralsVacancyStateError {
  error: any;
}

export const initialState: IReferralsVacancyState = {
  pending: false,
  error: null,
  vacancy: {} as IReferralsVacancy,
};

export const reducers = {
  getReferralsVacancy: (
    state: IReferralsVacancyState,
    action: PayloadAction<IReferralsVacancyStatePending>
  ) => {
    return {
      ...state,
      vacancy: {} as IReferralsVacancy,
      pending: true,
      error: "",
    };
  },
  getReferralsVacancySuccess: (
    state: IReferralsVacancyState,
    action: PayloadAction<IReferralsVacancy>
  ) => {
    return {
      ...state,
      pending: false,
      vacancy: action.payload,
    };
  },
  getReferralsVacancyError: (
    state: IReferralsVacancyState,
    action: PayloadAction<IReferralsVacancyStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),

  //RESET
  resetReferralsVacancy: (state: IReferralsVacancyState) => {
    return {
      ...state,
      vacancy: {} as IReferralsVacancy,
    };
  },
  resetReferralsVacancySuccess: (state: IReferralsVacancyState) => {
    return {
      ...state,
    };
  },
  resetReferralsVacancyError: (state: IReferralsVacancyState) => ({
    ...state,
  }),
};
