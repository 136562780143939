import { call, put, takeLatest } from "redux-saga/effects";
import { IContacts } from "./reducers";
import { getContacts, getContactsError, getContactsSuccess } from "./slice";
import { getContactsRequest } from "./api";

function* getContactsSaga() {
  try {
    const contacts: IContacts = yield call(getContactsRequest);

    yield put(getContactsSuccess(contacts));
  } catch (error) {
    yield put(getContactsError({ error }));
  }
}

function* contactsWatcher() {
  yield takeLatest(getContacts.type, getContactsSaga);
}

export default contactsWatcher;
