import { styled } from "@mui/material/styles";

export const BreadcrumbsContainer = styled("div")<{ $isStatic: boolean }>(
  ({ theme, $isStatic }) => ({
    zIndex: 10,
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: theme.spacing(3, 3, 1),

    ...($isStatic
      ? {
          "& .MuiBreadcrumbs-li, & .MuiBreadcrumbs-li .MuiLink-root, & .MuiBreadcrumbs-separator":
            {
              color: `${theme.palette.common.white} !important`,
            },
        }
      : {
          position: "sticky",
          top: "48px",
          background: theme.modeColors({
            dark: theme.palette.primary.main,
            light: theme.palette.common.white,
          }),
        }),
  })
);
