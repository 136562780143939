import { useSelector } from "react-redux";
import { selectTaskReporting, selectPostTaskReporting } from "@/store/redux";
import { ReportingRules, ProjectsList } from "../components";

interface IContentProps {
  date: string;
}

export function Content(props: IContentProps) {
  const { date } = props;

  const {
    reporting,
    alreadyReported,
    error: getReportingError,
  } = useSelector(selectTaskReporting);
  const { hasReported } = useSelector(selectPostTaskReporting);

  const hasGetReportingError =
    getReportingError?.statusCode && getReportingError?.statusCode >= 500;

  if (alreadyReported || hasReported || hasGetReportingError) {
    return null;
  }

  return (
    <>
      <div>
        Insert your tasks for <b>{date}</b> in this form. Please, take a look at
        the rules and the example first. If you all that stuff, just skip it :)
      </div>

      <ReportingRules />

      <ProjectsList taskReporting={reporting} />
    </>
  );
}
