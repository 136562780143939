function ReducerRegistry() {
  let _emitChange: any = null;
  let _reducers = {};

  const getReducers = () => {
    return { ..._reducers };
  };

  const register = (name: any, reducer: any) => {
    _reducers = { ..._reducers, [name]: reducer };

    if (_emitChange) {
      _emitChange(getReducers());
    }
  };

  const setChangeListener = (listener: any) => {
    _emitChange = listener;
  };

  return {
    register,
    getReducers,
    setChangeListener,
  };
}

export default ReducerRegistry();
