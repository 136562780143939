import { useDevice } from "@/utils/hooks";
import { Menu } from "../Menu";
import { IFilters } from "../Positions";
import { SidebarWrapper } from "./Positions.styled";

interface ISidebarProps {
  menu: { id: number; title: string; value: number }[];
  values: IFilters;
  onChange: (v: { [k: string]: number }) => void;
}

export function Sidebar(props: ISidebarProps) {
  const { menu, values, onChange } = props;

  const hasMobile = useDevice("down", "smd");

  return hasMobile ? null : (
    <SidebarWrapper>
      <Menu
        menu={menu}
        onChange={onChange}
        selected={values["parea"]}
        type="parea"
      />
    </SidebarWrapper>
  );
}
