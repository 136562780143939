import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Title } from "@/shared/components";

export const ErrorWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(10, 2),
  color: theme.palette.secondary.main,
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  background: theme.palette.primary.main,
  fontSize: "3.5rem",
  fontWeight: 500,
  overflow: "hidden",

  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

export const ErrorContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "55vh",
}));

export const ErrorContent = styled("div")(() => ({
  position: "relative",
  flex: "1",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const ErrorLight = styled("div")(({ theme }) => ({
  position: "absolute",
  backgroundColor: "#deff2b",
  height: "200px",
  width: "150vw",
  top: "35%",
  left: "-25px",
  zIndex: "1",
  transformOrigin: "0 0",
  animationName: "light",
  animationTimingFunction: "linear",
  animationDuration: "5s",
  animationIterationCount: "infinite",

  [theme.breakpoints.up("lg")]: {
    height: "300px",
    width: "calc(100vw + 160px)",
    left: "-45px",
  },

  "&:after": {
    content: '""',
    position: "absolute",
    bottom: "-2px",
    left: "-2px",
    border: "100px solid transparent",
    borderBottom: "0 solid #000F1B",
    borderLeft: "150vw solid #000F1B",

    [theme.breakpoints.up("lg")]: {
      border: "200px solid transparent",
      borderBottom: "0 solid #000F1B",
      borderLeft: "calc(100vw + 160px) solid #000F1B",
    },
  },

  "@keyframes light": {
    "0%": {
      transform: "rotate(0)",
    },
    "30%": {
      transform: "rotate(-5deg)",
    },

    "70%": {
      transform: "rotate(5deg)",
    },

    "100%": {
      transform: "rotate(0)",
    },

    [theme.breakpoints.down("smd")]: {
      "0%": {
        transform: "rotate(0)",
      },
      "30%": {
        transform: "rotate(-25deg)",
      },

      "70%": {
        transform: "rotate(25deg)",
      },

      "100%": {
        transform: "rotate(0)",
      },
    },
  },
}));

export const ErrorText = styled(Title)(({ theme }) => ({
  flex: 1,
  zIndex: "2",
  textAlign: "center",
  fontSize: "48px",
  fontStyle: "italic",
  textTransform: "uppercase",
  letterSpacing: "7px",
  color: theme.palette.primary.main,

  [theme.breakpoints.up("md")]: {
    fontSize: "64px",
    padding: "0 16px",
  },

  [theme.breakpoints.up("lg")]: {
    fontSize: "76px",
  },
}));
