import { call, put, takeLatest } from "redux-saga/effects";
import { IReferralsCandidatesStateSuccess } from "./reducers";
import {
  getReferralsCandidates,
  getReferralsCandidatesError,
  getReferralsCandidatesSuccess,
} from "./slice";
import { getReferralsCandidatesRequest } from "./api";

function* getReferralsCandidatesSaga() {
  try {
    const candidates: IReferralsCandidatesStateSuccess = yield call(
      getReferralsCandidatesRequest
    );

    yield put(getReferralsCandidatesSuccess(candidates));
  } catch (error) {
    yield put(getReferralsCandidatesError({ error }));
  }
}

function* ReferralsCandidatesWatcher() {
  yield takeLatest(getReferralsCandidates.type, getReferralsCandidatesSaga);
}

export default ReferralsCandidatesWatcher;
