import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import {
  ReferralsVacanciesActions,
  selectReferralsVacancies,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { MoreButton } from "@/shared/components";
import { Vacancy } from "./Vacancy";
import { NoVacancies } from "./NoVacancies";
import { IFilters } from "../Positions";
import { VacanciesWrapper } from "./Vacancies.styled";

interface IVacanciesProps {
  filters: IFilters;
  onDialogOpen: () => void;
}

export function Vacancies(props: IVacanciesProps) {
  const { filters, onDialogOpen } = props;

  const { list, ptotal, poffset, plimit, pending } = useSelector(
    selectReferralsVacancies
  );
  const { getReferralsVacancies } = useActions<
    typeof ReferralsVacanciesActions
  >(ReferralsVacanciesActions);

  const cleanFilters = (filters: { [k: string]: number | string | null }) => {
    const obj = { ...(filters || {}) };

    Object.keys(obj).forEach((key) => {
      if (obj[key] === -1 || obj[key] === "") {
        obj[key] = null;
      }
    });

    return obj;
  };

  const getVacancies = (params: any) => {
    getReferralsVacancies({
      ...cleanFilters(filters),
      plimit: 10,
      poffset: 0,
      ...(params || {}),
    });
  };

  const handleOnMore = () => {
    getVacancies({ poffset: poffset + plimit });
  };

  useEffect(() => {
    getVacancies(cleanFilters(filters));
  }, [filters]); //eslint-disable-line

  return (
    <Box sx={{ flex: 1 }}>
      {!pending && !list?.length ? (
        <NoVacancies onDialogOpen={onDialogOpen} />
      ) : null}

      <VacanciesWrapper>
        {list.map((vacancy) => (
          <Vacancy key={vacancy?.id} vacancy={vacancy} />
        ))}
      </VacanciesWrapper>

      <MoreButton
        fullWidth
        limit={plimit}
        offset={poffset}
        total={ptotal}
        loading={pending}
        onClick={handleOnMore}
      >
        SHOW MORE VACANCIES
      </MoreButton>
    </Box>
  );
}
