import { useSelector } from "react-redux";
import { format, getDay } from "date-fns";
import { Grid } from "@mui/material";
import { selectTimesheetActivity } from "@/store/redux";
import { getDecimal, getHoliday } from "../helpers";
import { IProjectsHoursState, IOutageHoursState } from "../interfaces";
import { Outage } from "./Outage";
import { Projects } from "./Projects";
import {
  Wrapper,
  Header,
  Title,
  Date,
  Year,
  Total,
  ColTitle,
  ProjectsList,
} from "../Timesheet.styled";

interface IDayViewProps {
  day: Date;
  totalColumnHours: { [k: string | number]: number };
  projectsHours: IProjectsHoursState[];
  outageHours: IOutageHoursState;
  timeButton: any;
}

export function DayView(props: IDayViewProps) {
  const { day, outageHours, projectsHours, totalColumnHours, timeButton } =
    props;

  const { holidays } = useSelector(selectTimesheetActivity);

  const date = format(day, "dd/MM/yyyy");
  const dateMonth = format(day, "eee, d MMMM");
  const year = format(day, "yyyy");

  const total = getDecimal(totalColumnHours[date] || 0);

  const hasHoliday = !!getHoliday({ list: holidays, day })?.type;
  const hasDisableDay = [0, 6].includes(getDay(day)) || hasHoliday;
  const disableStyles = {
    background: "rgba(230, 230, 230, 0.30)",
    padding: "8px 8px 0 8px",
  };

  return (
    <Wrapper
      sx={{
        ...(hasDisableDay ? disableStyles : {}),
      }}
    >
      <Header>
        <Title>
          <Date>{dateMonth}</Date>
          <Year>{year}</Year>
        </Title>
        <Total>Total Hours — {total}</Total>
      </Header>

      <Grid container>
        <Grid item xs={8}>
          <ColTitle>Project</ColTitle>
        </Grid>
        <Grid item xs={4}>
          <ColTitle>Hours</ColTitle>
        </Grid>
      </Grid>

      <ProjectsList>
        <Projects
          date={date}
          reporting={projectsHours}
          timeButton={timeButton}
        />

        <Outage
          date={date}
          reporting={outageHours?.reporting}
          timeButton={timeButton}
        />
      </ProjectsList>
    </Wrapper>
  );
}
