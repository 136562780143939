import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Title } from "@/shared/components";

export const LiteratureTitle = styled(Title)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "1.875rem",
  },
}));

export const LiteratureList = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(5),
  marginTop: theme.spacing(2),
  paddingBottom: theme.spacing(1.5),

  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(2),
  },
}));

export const LiteratureListItem = styled("div")(({ theme }) => ({
  display: "flex !important",
  alignItems: "start",
  gap: theme.spacing(2.5),
  paddingRight: theme.spacing(5),
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    maxWidth: 340,
    paddingRight: theme.spacing(2),
  },
}));

export const LiteratureListItemContent = styled("div")(({ theme }) => ({
  flex: 1,
}));

export const LiteratureListItemImage = styled("div")(({ theme }) => ({
  width: 80,
  height: 120,
  overflow: "hidden",
}));

export const LiteratureListItemAuthors = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontWeight: 500,
  fontSize: "0.875rem",
}));

export const LiteratureListItemName = styled("div")(({ theme }) => ({
  fontWeight: 900,
  fontSize: "0.875rem",
}));

export const LiteratureListItemLink = styled(Link)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 500,
  marginTop: theme.spacing(0.5),
  cursor: "pointer",
}));
