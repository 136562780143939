import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IMyDocumentsAccesStateSuccess,
  IMyDocumentsState,
  IMyDocumentStatePending,
} from "./reducers";
import {
  getMyDocuments,
  getMyDocumentsError,
  getMyDocumentsSuccess,
  getMyDocumentAccess,
  getMyDocumentAccessError,
  getMyDocumentAccessSuccess,
} from "./slice";
import { getMyDocumentsRequest, getMyDocumentsAccessRequest } from "./api";

function* getMyDocumentsSaga(action: PayloadAction<IMyDocumentStatePending>) {
  try {
    const documents: IMyDocumentsState = yield call(
      getMyDocumentsRequest,
      action?.payload
    );

    yield put(getMyDocumentsSuccess(documents));
  } catch (error) {
    yield put(getMyDocumentsError({ error }));
  }
}

function* getMyDocumentsAccessSaga() {
  try {
    const access: IMyDocumentsAccesStateSuccess = yield call(
      getMyDocumentsAccessRequest
    );

    yield put(getMyDocumentAccessSuccess(access));
  } catch (error) {
    yield put(getMyDocumentAccessError({ error }));
  }
}

function* MyDocumentsWatcher() {
  yield takeLatest(getMyDocuments.type, getMyDocumentsSaga);
  yield takeLatest(getMyDocumentAccess.type, getMyDocumentsAccessSaga);
}

export default MyDocumentsWatcher;
