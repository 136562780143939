import { Calendar } from "react-big-calendar";
import { styled, Theme } from "@mui/material/styles";
import { Button, ButtonGroup, darken, lighten } from "@mui/material";
import { pink, violet, turquoise, secondaryMain } from "@/theme";

const timeOffColors: any = (theme: Theme) => ({
  1: {
    background: lighten(theme.palette.secondary.main, 0.85),
    color: theme.palette.secondary.main,
  },
  2: {
    background: lighten(theme.palette.pink.main, 0.85),
    color: theme.palette.pink.main,
  },
  3: {
    background: lighten(theme.palette.violet.main, 0.85),
    color: darken(theme.palette.violet.main, 0.15),
  },
  4: {
    background: lighten(turquoise, 0.85),
    color: turquoise,
  },
  health_time_off: {
    background: lighten(pink, 0.85),
    color: pink,
  },
  paid_time_off: {
    background: lighten(violet, 0.85),
    color: darken(violet, 0.15),
  },
  project: {
    background: lighten(secondaryMain, 0.85),
    color: secondaryMain,
  },
  holiday: {
    background: lighten(pink, 0.85),
    color: pink,
  },
  outage: {
    background: lighten(turquoise, 0.85),
    color: turquoise,
  },
  event: {
    background: "#f3f3f3",
    color: turquoise,
  },
});

export const calendarStyles = (theme: Theme) => ({
  "& .rbc-month-row": {
    flexBasis: "auto",
    minHeight: "125px",

    [theme.breakpoints.down("md")]: {
      minHeight: "50px",
    },
  },

  "& .rbc-month-view": {
    border: "none",

    "& .rbc-header + .rbc-header": {
      borderLeft: "none",
    },
  },

  "& .rbc-off-range-bg": {
    background: "transparent",
  },

  "& .rbc-date-cell": {
    "&.rbc-off-range": {
      fontWeight: 400,
    },
  },

  "& .rbc-day-bg.rbc-today": {
    background:
      theme.palette.mode === "dark" ? "rgba(255,255,255, 0.12)" : "#eaf6ff",
  },

  "& .rbc-date": {
    display: "flex",
    flex: 1,
    cursor: "pointer",

    "& .rbc-day-bg": {
      borderLeft: "1px solid #ddd",
    },

    "&:last-of-type": {
      "& .rbc-day-bg": {
        borderRight: "1px solid #ddd",
      },
    },

    "&-disabled": {
      cursor: "default",
      background:
        theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.25)" : "#f6f6f6",
    },
  },

  "& .rbc-month-row:last-of-type": {
    borderBottom: "1px solid #ddd",
  },

  [theme.breakpoints.down("md")]: {
    "& .rbc-date-cell": {
      textAlign: "center",
    },

    "& .rbc-month-row + .rbc-month-row": {
      border: "none",
    },

    "& .rbc-header": {
      border: "none",
      paddingBottom: theme.spacing(3),
    },

    "& .rbc-date": {
      borderLeft: "none",

      "&:last-of-type": {
        borderRight: "none",
      },
    },
  },
});

export const CalendarWrapper = styled(Calendar)(({ theme }) => ({
  ...calendarStyles(theme),
}));

export const CalendarToolbarWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(5),
  margin: theme.spacing(5, 0),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "start",
    gap: theme.spacing(2),
  },
}));

export const CalendarToolbarDate = styled("div")(({ theme }) => ({
  fontSize: "1.5rem",

  [theme.breakpoints.down("sm")]: {
    order: 1,
    width: "100%",
  },
}));

export const CalendarToolbarMonth = styled("span")(({ theme }) => ({
  fontWeight: 700,
}));

export const CalendarButtonsWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),

  [theme.breakpoints.down("sm")]: {
    order: 2,
    width: "100%",
  },
}));

export const CalendarToolbarButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  textTransform: "none",
  fontWeight: 500,
  fontSize: "1rem",
  lineHeight: 1,
  height: "42px",
  color:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.primary.main,
  borderColor:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.primary.main,

  "&:hover": {
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.common.white
        : theme.palette.primary.main,
    background:
      theme.palette.mode === "dark"
        ? theme.palette.primary.light
        : theme.palette.common.white,
  },

  "& svg": {
    fontSize: "0.875rem",
  },

  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
}));

export const CalendarToolbarButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
}));

export const DateTitle = styled("span")(({ theme }) => ({
  fontSize: "1rem",
}));

export const CalendarEvent = styled("span")<{ $type: number | string }>(
  ({ theme, $type }) => {
    const color = timeOffColors(theme)[$type]?.color;
    const backgroundColor = timeOffColors(theme)[$type]?.background;

    return {
      position: "relative",
      display: "block",
      padding: "1px 1px 1px 4px",
      background: theme.palette.mode === "dark" ? color : backgroundColor,
      borderLeft: `8px solid ${color}`,
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(0.5),
      fontSize: "0.875rem",
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",

      [theme.breakpoints.down("md")]: {
        background: color,
        height: "2px",
        marginTop: 1,
      },

      "& .rbc-event, & .rbc-event-content": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        cursor: "pointer",
        zIndex: 1,
      },
    };
  }
);
