import { PayloadAction } from "@reduxjs/toolkit";

export interface ITimeOffListItem {
  id: number;
  date_string: string; // e.g. Tue, Dec 27 - Fri, Dec 30, 2022
  start_date: string;
  end_date: string;
  booked_days: number;
  status: number;
  type: number | string;
  description: string;
  can_edit: boolean;
  can_cancel: boolean;
}

export interface ITimeOffListState {
  pending: boolean;
  error: any;
  list: ITimeOffListItem[];
  plimit: number;
  poffset: number;
  ptotal: number;
  ptype: number;
  pstatus: number;
  psort: string;
}

export interface ITimeOffListStatePending {
  plimit?: number;
  poffset?: number;
  ptotal?: number;
  ptype?: number;
  pstatus?: number;
  psort?: string;
}

export interface ITimeOffListStateSuccess {
  plimit?: number;
  poffset?: number;
  ptotal?: number;
  ptype?: number;
  pstatus?: number;
  psort?: string;
  list: ITimeOffListItem[];
}

export interface ITimeOffListStateError {
  error: any;
}

export const initialState: ITimeOffListState = {
  pending: false,
  error: "",
  plimit: 5,
  poffset: 0,
  ptotal: 0,
  ptype: 0,
  pstatus: 0,
  psort: "",
  list: [],
};

export const reducers = {
  getTimeOffList: (
    state: ITimeOffListState,
    action: PayloadAction<ITimeOffListStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: "",
      ...(action.payload || {}),
    };
  },
  getTimeOffListSuccess: (
    state: ITimeOffListState,
    action: PayloadAction<ITimeOffListStateSuccess>
  ) => {
    const { poffset, list, ...restOptions } = action?.payload;

    return {
      ...state,
      ...restOptions,
      pending: false,
      list: poffset ? [...(state.list || []), ...list] : list || [],
    };
  },
  getTimeOffListError: (
    state: ITimeOffListState,
    action: PayloadAction<ITimeOffListStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
