import { useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { GenerateField } from "./GenerateField";

export function StreetField() {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldName = "street";
  const hasError = !!errors[fieldName]?.type;

  return (
    <GenerateField
      errorText="Required field"
      name="street"
      rules={{
        validate: (value) => !!value?.trim(),
      }}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          error={hasError}
          label="Street"
          variant="filled"
          inputProps={{
            maxLength: 2000,
          }}
        />
      )}
    />
  );
}
