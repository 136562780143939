import { styled } from "@mui/material/styles";

export const TimeOffDaysWrapp = styled("div")(({ theme }) => ({
  // gap: theme.spacing(3),
  textAlign: "center",
  fontWeight: 900,
  margin: theme.spacing(1.5, 0, 3),

  // [theme.breakpoints.down("sm")]: {
  //   gap: theme.spacing(2),
  // },
}));
