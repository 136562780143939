import { styled } from "@mui/material/styles";

export const DayOffTypesWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

export const DayOffTypesItem = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const DayOffTypesColor = styled("div")<{ color: string }>(
  ({ theme, color }) => ({
    width: 32,
    height: 32,
    borderRadius: "50%",
    background: color,
  })
);

export const DayOffTypesDesc = styled("div")(({ theme }) => ({
  fontStyle: "italic",
}));
