import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "TeamMembers",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const { getTeamMembers, getTeamMembersError, getTeamMembersSuccess } =
  slice.actions;

reducerRegistry.register("teamMembers", reducer);
