import { forwardRef } from "react";
import {
  Select as MuiSelect,
  MenuItem,
  SelectProps,
  FormControl,
  InputLabel,
  Typography,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { DropdownMenuStylesProps } from "@/shared/components";
import { SelectedBadge } from "./Select.styled";

type IFilterSelectProps = SelectProps & {
  options:
    | Array<string>
    | Array<number>
    | Array<{
        title: string | number;
        value: string | number | null;
      }>;
  hasCheckmarks?: boolean;
};

function SelectInput(props: IFilterSelectProps) {
  const {
    options,
    label,
    variant = "standard",
    placeholder,
    disabled,
    hasCheckmarks,
    ...restProps
  } = props;

  const getChecked = ({
    selectedValue,
    value,
  }: {
    selectedValue: Array<number | string>;
    value: number | string;
  }) => {
    return (selectedValue || []).indexOf(value) > -1;
  };

  return (
    <MuiSelect
      {...restProps}
      {...(variant === "standard" && { disableUnderline: true })}
      {...(hasCheckmarks
        ? {
            renderValue: (selected: any) => (
              <>
                <SelectedBadge>{selected.length}</SelectedBadge> Selected
              </>
            ),
          }
        : {})}
      disabled={disabled}
      variant={variant}
      MenuProps={DropdownMenuStylesProps}
      sx={{
        "& .MuiSelect-select .notranslate::after": placeholder
          ? {
              content: `"${placeholder}"`,
              opacity: 0.42,
            }
          : {},

        "& .MuiTypography-root": {
          paddingRight: "8px",
        },
      }}
    >
      {options.map((option: any) => {
        return (
          <MenuItem
            key={option?.value || option}
            value={option?.value || option}
            disabled={disabled}
          >
            {hasCheckmarks ? (
              <>
                <Checkbox
                  checked={getChecked({
                    selectedValue: props?.value as any,
                    value: option?.value,
                  })}
                  sx={{
                    color: "inherit !important",
                  }}
                />
                <ListItemText primary={option?.title} />
              </>
            ) : (
              <Typography variant="inherit" noWrap sx={{ paddingRight: "8px" }}>
                {option?.title || option}
              </Typography>
            )}
          </MenuItem>
        );
      })}
    </MuiSelect>
  );
}

export const Select = forwardRef((props: IFilterSelectProps, ref: any) => {
  const {
    options,
    label,
    variant,
    placeholder,
    fullWidth,
    hasCheckmarks,
    ...restProps
  } = props;

  return label ? (
    <FormControl ref={ref} fullWidth={fullWidth} variant="filled">
      <InputLabel>{label}</InputLabel>
      <SelectInput
        {...restProps}
        fullWidth={fullWidth}
        variant="filled"
        options={options}
        placeholder={placeholder}
        hasCheckmarks={hasCheckmarks}
      />
    </FormControl>
  ) : (
    <SelectInput
      {...restProps}
      ref={ref}
      fullWidth={fullWidth}
      options={options}
      variant={variant}
      placeholder={placeholder}
      hasCheckmarks={hasCheckmarks}
    />
  );
});
