import { PropsWithChildren } from "react";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import { ListHead } from "./TimeOffList.styled";

export function TimeOffListHead() {
  return (
    <Head>
      <Grid container spacing={2}>
        <Grid item xs={3.5}>
          Date
        </Grid>
        <Grid item xs={1.5}>
          Days Booked
        </Grid>
        <Grid item xs={1.5}>
          Status
        </Grid>
        <Grid item xs={2}>
          Type
        </Grid>
        <Grid item xs={3}>
          Description
        </Grid>
        <Grid item xs={0.5}></Grid>
      </Grid>
    </Head>
  );
}

function Head(props: PropsWithChildren) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("md"));

  return isMobile ? null : <ListHead>{props.children}</ListHead>;
}
