import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IReferralsFilters } from "@/store/redux";
import { Dialog } from "@/shared/components";
import { Menu } from "../Menu";
import { IFilters } from "../Positions";
import {
  FilterWrapp,
  FilterName,
  FilterValue,
  FiltersMobileWrapp,
} from "./Filters.styled";

interface IMobileProps extends IReferralsFilters {
  pending: boolean;
  values: IFilters;
  onChange: (v: { [k: string]: number }) => void;
}

export function Mobile(props: IMobileProps) {
  const { values, areas, levels, locations, onChange } = props;

  const [hasOpen, setHasOpen] = useState(false);
  const [active, setActive] = useState<string>("");

  const filters: { [k: string]: any } = {
    parea: { title: "Area", options: areas },
    plocation: { title: "Location", options: locations },
    plevel: { title: "Seniority Level", options: levels },
  };

  const getValue = (key: string) => {
    return filters[key].options.filter(
      (item: any) => item.value === values[key]
    )?.[0]?.title;
  };

  const handleOnToggle = () => {
    setHasOpen((prev) => !prev);
  };

  const handleOnSelectFilter = (value: string) => () => {
    setActive(value);
    setHasOpen((prev) => !prev);
  };

  const handleOnChange = (value: { [k: string]: number }) => {
    onChange(value);
    setHasOpen(false);
  };

  return (
    <FiltersMobileWrapp>
      {Object.entries(filters).map(([key, { title }]: any) => (
        <FilterWrapp key={key}>
          <FilterName>{title}:</FilterName>
          <FilterValue onClick={handleOnSelectFilter(key)}>
            {getValue(key)}
            <KeyboardArrowDownIcon />
          </FilterValue>
        </FilterWrapp>
      ))}

      <Dialog
        open={hasOpen}
        onClose={handleOnToggle}
        title={filters[active]?.title}
        dialogContent={
          <Menu
            menu={filters[active]?.options}
            type={active}
            selected={values[active]}
            onChange={handleOnChange}
          />
        }
      />
    </FiltersMobileWrapp>
  );
}
