import { call, put, takeLatest } from "redux-saga/effects";
import { INewsSubscriptionsStateSuccess } from "./reducers";
import {
  getNewsSubscriptions,
  getNewsSubscriptionsError,
  getNewsSubscriptionsSuccess,
} from "./slice";
import { getNewsSubscriptionsRequest } from "./api";

function* getNewsSubscriptionsSaga() {
  try {
    const filters: INewsSubscriptionsStateSuccess = yield call(
      getNewsSubscriptionsRequest
    );

    yield put(getNewsSubscriptionsSuccess(filters));
  } catch (error) {
    yield put(getNewsSubscriptionsError({ error }));
  }
}

function* NewsSubscriptionsWatcher() {
  yield takeLatest(getNewsSubscriptions.type, getNewsSubscriptionsSaga);
}

export default NewsSubscriptionsWatcher;
