import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IReferralsVacanciesState } from "./reducers";

export const referralsVacanciesSelector = (
  state: RootState
): IReferralsVacanciesState => state.referralsVacancies;

export const selectReferralsVacancies = createSelector(
  referralsVacanciesSelector,
  (referralsVacanciesState: IReferralsVacanciesState) => ({
    ...referralsVacanciesState,
  })
);
