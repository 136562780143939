import { styled } from "@mui/material/styles";

export const TopicsStyled = styled("div")(({ theme }) => ({}));

export const TopicStyled = styled("div")(({ theme }) => ({
  cursor: "pointer",

  "& + &": {
    marginTop: theme.spacing(3),
  },
}));

export const TopicTitle = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  textTransform: "uppercase",
  fontWeight: 900,
}));

export const TopicActions = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));
