import { useState, ChangeEvent } from "react";
import { TextField, Button } from "@mui/material";
import { TaskEditWrapper, TaskEditButtons } from "./Tasks.styled";

interface ITaskFieldProps {
  value?: string | undefined | null;
  disabled?: boolean;
  onSave: (value: string) => void;
  onCancel: () => void;
}

export function TaskField(props: ITaskFieldProps) {
  const { value, disabled, onSave, onCancel } = props;

  const [fieldValue, setFieldValue] = useState(value || "");

  const handleOnSave = () => {
    onSave((fieldValue || "").trim());
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.value);
  };

  const nonEmptyValue = !(fieldValue || "").trim();

  return (
    <TaskEditWrapper>
      <TextField
        fullWidth
        multiline
        minRows={2}
        variant="filled"
        label="Task"
        disabled={!!disabled}
        value={fieldValue}
        onChange={handleOnChange}
      />

      <TaskEditButtons>
        <Button
          color="primary"
          variant="outlined"
          disabled={nonEmptyValue || !!disabled}
          onClick={handleOnSave}
        >
          Save
        </Button>
        <Button
          color="primary"
          variant="text"
          disabled={!!disabled}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </TaskEditButtons>
    </TaskEditWrapper>
  );
}
