import { useTheme, useMediaQuery } from "@mui/material";
import { Title, Carousel } from "@/shared/components";
import {
  PointsWrapper,
  PointWrapper,
  PointDesc,
  RulesWrapper,
} from "../TaskReporting.styled";

interface IPoint {
  title: string;
  desc: string;
}

const points: IPoint[] = [
  {
    title: "Pick a project",
    desc: "Open your project or projects from the menu below",
  },
  {
    title: "Choose tasks",
    desc: "Pick the tasks you want to submit. Choose from 4 to 7 tasks. Don't include meetings, planning or education",
  },
  {
    title: "Check & submit",
    desc: "Revise the chosen tasks in the “Tasks summary” field and submit the form",
  },
];

function Point(props: IPoint) {
  const { title, desc } = props;

  return (
    <PointWrapper>
      <Title fontSize="26px" fontWeight="500">
        {title}
      </Title>
      <PointDesc>{desc}</PointDesc>
    </PointWrapper>
  );
}

function Points() {
  const theme = useTheme();
  const hasTablet = useMediaQuery(theme.breakpoints.down("md"));
  const hasMobile = useMediaQuery(theme.breakpoints.down("smd"));

  const slidesToShow = hasMobile ? 1 : hasTablet ? 2 : 3;

  const slides = points.map(({ title, desc }: IPoint, idx) => (
    <Point key={`point-${idx}`} title={title} desc={desc} />
  ));

  return (
    <PointsWrapper>
      <Carousel noArrowsMargin slides={slides} slidesToShow={slidesToShow} />
    </PointsWrapper>
  );
}

export function ReportingRules() {
  return (
    <RulesWrapper>
      <Points />
    </RulesWrapper>
  );
}
