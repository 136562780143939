import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { RootSaga } from "@/store/redux";
import { reducerRegistry } from "@/utils/redux";
import instance from "@/utils/apiInstance";

const devMode = ["development", "staging"].includes(process.env.NODE_ENV);
const sagaMiddleware = createSagaMiddleware();
const logger: any = createLogger({ collapsed: true });
const middleware = [sagaMiddleware];

if (devMode) {
  middleware.push(logger);
}

function combine(preloadedState = {}, reducers: any) {
  const reducerNames = Object.keys(reducers);
  const sortAlphabetically = (obj: any) =>
    Object.fromEntries(Object.entries(obj).sort());

  Object.keys(preloadedState).forEach((item) => {
    if (reducerNames.indexOf(item) === -1) {
      reducers[item] = (state = null) => state;
    }
  });

  const sortedAlphabetically: any = sortAlphabetically(reducers);
  const appReducer: any = combineReducers(sortedAlphabetically);

  return appReducer;
}

function Store(preloadedState = {}) {
  const reducers = combine(preloadedState, reducerRegistry.getReducers());

  const store = configureStore({
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
    reducer: reducers,
    devTools: devMode,
  });

  sagaMiddleware.run(RootSaga);

  // Replace the store's reducer whenever a new reducer is registered.
  reducerRegistry.setChangeListener((reducers: any) => {
    store.replaceReducer(combine(preloadedState, reducers));
  });

  return store;
}

export const store = Store();
export const apiInstance = instance(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
