import { ReactNode } from "react";
import {
  FacebookIcon,
  InstaIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from "@/shared/icons";
import {
  FACEBOOK_LINK,
  INSTA_LINK,
  LINKEDIN_LINK,
  TWITTER_LINK,
  YOUTUBE_LINK,
} from "@/config/socialNetworks";
import { SocialWrapper, SocialLink } from "./SocialNetworks.styled";

interface ISocialLink {
  href: string;
  icon: ReactNode;
}

const socialLinks: ISocialLink[] = [
  {
    href: FACEBOOK_LINK,
    icon: <FacebookIcon />,
  },
  {
    href: INSTA_LINK,
    icon: <InstaIcon />,
  },
  {
    href: TWITTER_LINK,
    icon: <TwitterIcon />,
  },
  {
    href: YOUTUBE_LINK,
    icon: <YoutubeIcon />,
  },
  {
    href: LINKEDIN_LINK,
    icon: <LinkedinIcon />,
  },
];

export function SocialNetworks() {
  return (
    <SocialWrapper>
      {socialLinks.map(({ href, icon }: ISocialLink) => (
        <SocialLink key={href} href={href} target="_blank">
          {icon}
        </SocialLink>
      ))}
    </SocialWrapper>
  );
}
