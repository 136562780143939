import { PayloadAction } from "@reduxjs/toolkit";

export interface IEmployeeCalendarCountry {
  id: number;
  name: string;
  country_code: string;
}

export interface IEmployeeCalendarCountriesState {
  pending: boolean;
  error: any;
  countries: { title: string; value: number; country_code: string }[];
}

export type IEmployeeCalendarCountriesStateSuccess = IEmployeeCalendarCountry[];

export interface IEmployeeCalendarCountriesStateError {
  error: any;
}

export const initialState: IEmployeeCalendarCountriesState = {
  pending: false,
  error: null,
  countries: [],
};

export const reducers = {
  getEmployeeCalendarCountries: (state: IEmployeeCalendarCountriesState) => {
    return {
      ...state,
      countries: [],
      pending: true,
      error: "",
    };
  },
  getEmployeeCalendarCountriesSuccess: (
    state: IEmployeeCalendarCountriesState,
    action: PayloadAction<IEmployeeCalendarCountriesStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      countries: (action?.payload || []).map(({ id, name, country_code }) => ({
        country_code,
        title: name,
        value: id,
      })),
    };
  },
  getEmployeeCalendarCountriesError: (
    state: IEmployeeCalendarCountriesState,
    action: PayloadAction<IEmployeeCalendarCountriesStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
