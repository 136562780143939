import { useState, createContext, PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "@/utils/hooks";
import { Breadcrumbs } from "@/components";

export const BreadcrumbsContext = createContext<{
  title: string | null;
  from: string | undefined;
  setTitle: any;
}>({
  title: null,
  from: undefined,
  setTitle: () => {},
});

export function BreadcrumbsProvider(props: PropsWithChildren) {
  const { pathname } = useLocation();
  const [breadcrumbTitle, setBreadcrumbTitle] = useState("");

  const prevLocation = usePrevious(pathname);

  return (
    <BreadcrumbsContext.Provider
      value={{
        title: breadcrumbTitle,
        setTitle: setBreadcrumbTitle,
        from: prevLocation,
      }}
    >
      <Breadcrumbs />
      {props.children}
    </BreadcrumbsContext.Provider>
  );
}
