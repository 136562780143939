import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AreaChart } from "recharts";

export const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    gap: theme.spacing(1),
  })
);

export const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  border: "none !important",
  borderRadius: "50% !important",
  padding: theme.spacing(1),
  transition: "all 0.3s ease",

  "&:hover": {
    background: "none",
  },

  "&:nth-of-type(1), &:nth-of-type(2)": {
    color: theme.modeColors({
      dark: theme.palette.pink.main,
      light: theme.palette.primary.main,
    }),
    "&:hover": {
      background: theme.palette.pink.main,
      color: theme.palette.primary.main,
    },
  },

  "&:nth-of-type(3)": {
    color:
      theme.palette.mode === "dark" ? "#97E2F7" : theme.palette.primary.main,

    "&:hover": {
      background: "#97E2F7",
      color: theme.palette.primary.main,
    },
  },

  "&:nth-of-type(4), &:nth-of-type(5)": {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.primary.main,

    "&:hover": {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
  },

  "&.Mui-selected": {
    color: theme.palette.primary.main,

    "&:nth-of-type(1), &:nth-of-type(2)": {
      background: theme.palette.pink.main,
    },

    "&:nth-of-type(3)": {
      background: "#97E2F7",
    },

    "&:nth-of-type(4), &:nth-of-type(5)": {
      background: theme.palette.secondary.main,
    },
  },
}));

export const SmileBg = styled("span")<{ $color: string }>(
  ({ theme, $color }) => ({
    display: "inline-flex",
    padding: theme.spacing(1),
    borderRadius: "50%",
    background: $color,
    color: theme.palette.primary.main,
  })
);

export const AreaChartWrapper = styled(AreaChart)(({ theme }) => ({
  "& .recharts-surface": {
    overflow: "visible",
  },
}));
