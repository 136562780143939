import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "PostTaskReporting",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  postTaskReporting,
  postTaskReportingError,
  postTaskReportingSuccess,
} = slice.actions;

reducerRegistry.register("postTaskReporting", reducer);
