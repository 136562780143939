import { useFormContext, Controller } from "react-hook-form";
import { TextField, Box } from "@mui/material";

export function NoteField() {
  const { control, setValue } = useFormContext();
  const name = "note";
  const maxValue = 2000;
  const getCounter = (length: number) => (
    <Box component="span" sx={{ display: "block", textAlign: "right" }}>
      {length} / {maxValue}
    </Box>
  );

  const handleOnChange = (e: any) => {
    const value = e.target.value;

    if (value?.length > maxValue) {
      return;
    }

    setValue(name, value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          multiline
          minRows={2}
          maxRows={6}
          onChange={handleOnChange}
          helperText={getCounter(field.value?.length)}
          variant="filled"
          label="Note (optional)"
        />
      )}
    />
  );
}
