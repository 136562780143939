import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IMarketplaceBrandProductsStatePending,
  IMarketplaceBrandProductsStateSuccess,
} from "./reducers";
import {
  getMarketplaceBrandProducts,
  getMarketplaceBrandProductsError,
  getMarketplaceBrandProductsSuccess,
} from "./slice";
import { getMarketplaceBrandProductsRequest } from "./api";

function* getMarketplaceBrandProductsSaga(
  action: PayloadAction<IMarketplaceBrandProductsStatePending>
) {
  try {
    const MarketplaceBrandProducts: IMarketplaceBrandProductsStateSuccess =
      yield call(getMarketplaceBrandProductsRequest, action.payload);

    yield put(getMarketplaceBrandProductsSuccess(MarketplaceBrandProducts));
  } catch (error) {
    yield put(getMarketplaceBrandProductsError({ error }));
  }
}

function* MarketplaceBrandProductsWatcher() {
  yield takeLatest(
    getMarketplaceBrandProducts.type,
    getMarketplaceBrandProductsSaga
  );
}

export default MarketplaceBrandProductsWatcher;
