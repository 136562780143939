import { styled } from "@mui/material/styles";
import { Badge } from "@/shared/components";

export const SelectedBadge = styled(Badge)(({ theme }) => ({
  height: 20,
  marginRight: theme.spacing(0.5),
  display: "inline-flex",
  color: theme.modeColors({
    dark: "#000",
    light: "#fff",
  }),
  background: theme.modeColors({
    dark: theme.palette.secondary.main,
    light: theme.palette.primary.main,
  }),
}));
