import { useEffect } from "react";
import { SetFieldValue } from "react-hook-form";

export interface FormPersistConfig {
  storage?: Storage;
  watch: (names?: string | string[]) => any;
  setValue: SetFieldValue<any>;
  validate?: boolean;
}

export function useFormPersist(name: string, params: FormPersistConfig) {
  const { storage, watch, setValue, validate } = params;

  const watchedValues = watch();

  const getStorage = () => storage || window.sessionStorage;

  useEffect(() => {
    const str = getStorage().getItem(name);

    if (str) {
      const values = JSON.parse(str);

      Object.keys(values).forEach((key) => {
        setValue(key, values[key], {
          shouldValidate: validate,
        });
      });
    }
  }, [storage, name, setValue]); //eslint-disable-line

  useEffect(() => {
    if (Object.entries(watchedValues).length) {
      getStorage().setItem(name, JSON.stringify(watchedValues));
    }
  }, [watchedValues]); //eslint-disable-line

  return {
    clear: () => getStorage().removeItem(name),
  };
}
