import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "PostQuestions",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  postQuestions,
  postQuestionsError,
  postQuestionsSuccess,
  resetPostQuestions,
  resetPostQuestionsError,
  resetPostQuestionsSuccess,
  resetPostErrorQuestions,
  resetPostErrorQuestionsError,
  resetPostErrorQuestionsSuccess,
} = slice.actions;

reducerRegistry.register("postQuestions", reducer);
