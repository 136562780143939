import { FilterControl, Select, SearchField } from "@/shared/components";
import { INewsFilters } from "../NewsFeed";
import { Locations } from "./Locations";
import { Subscriptions } from "./Subscriptions";
import { NewsFiltersWrapp } from "./Filters.styled";

interface INewsFiltersProps {
  filters: INewsFilters;
  loading?: boolean;
  onChange: (k: any) => void;
}

const languages = [
  { value: "en", title: "English" },
  { value: "ua", title: "Ukrainian" },
];

export function NewsFilters(props: INewsFiltersProps) {
  const { filters, loading, onChange } = props;

  return (
    <NewsFiltersWrapp>
      <FilterControl
        sx={{ width: "100%", minWidth: 250 }}
        control={
          <SearchField
            fullWidth
            placeholder="Search"
            name="psearch"
            disabled={loading}
            loading={loading}
            value={filters.psearch || ""}
            onSubmit={onChange}
          />
        }
      />

      <Locations
        disabled={loading}
        value={filters.plocation}
        onChange={onChange}
      />

      <FilterControl
        label="Language:"
        control={
          <Select
            name="plang"
            disabled={loading}
            options={languages}
            value={filters.plang}
            onChange={onChange}
          />
        }
      />

      <Subscriptions
        disabled={loading}
        value={filters.psubs || []}
        onChange={onChange}
      />
    </NewsFiltersWrapp>
  );
}
