import { PayloadAction } from "@reduxjs/toolkit";

export interface IContact {
  name: string;
  position: string;
  position_desc: string;
  phone_number: string;
  email: string;
  photo_url: string;
  has_viber: boolean;
}

export interface IContacts {
  contacts: IContact[];
}

export interface IContactsState {
  error: any;
  pending: boolean;
  contacts: IContact[];
}

export interface IContactsStateError {
  error: any;
}

export const initialState: IContactsState = {
  error: "",
  pending: false,
  contacts: [],
};

export const reducers = {
  getContacts: (state: IContactsState) => ({
    ...state,
    pending: true,
    error: "",
  }),
  getContactsSuccess: (
    state: IContactsState,
    action: PayloadAction<IContacts>
  ) => {
    return {
      ...state,
      pending: false,
      contacts: action.payload?.contacts,
    };
  },
  getContactsError: (
    state: IContactsState,
    action: PayloadAction<IContactsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload,
  }),
};
