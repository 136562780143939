import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IPostTaskReportingState } from "./reducers";

export const postTaskReportingSelector = (
  state: RootState
): IPostTaskReportingState => state.postTaskReporting;

export const selectPostTaskReporting = createSelector(
  postTaskReportingSelector,
  (postTaskReportingState: IPostTaskReportingState) => ({
    ...postTaskReportingState,
  })
);
