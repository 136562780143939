import { useState } from "react";

export function useFilters<T>(data: T) {
  const [filters, setFilters] = useState<T>(data);

  const handleOnFilters = (values: Partial<T>) => {
    setFilters({ ...filters, ...values });
  };

  return { filters, handleOnFilters };
}
