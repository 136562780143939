import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, Grid, Skeleton, useTheme, useMediaQuery } from "@mui/material";
import { Bubble, Title } from "@/shared/components";
import {
  TeamsActions,
  selectTeams,
  ITeamsItem,
  selectSwitchUser,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { TEAMS } from "@/routes/consts";
import { TeamsWrapper, TeamWrapper, TeamTitle } from "./Teams.styled";

export function TeamsList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { user } = useSelector(selectSwitchUser);
  const { teams, pending } = useSelector(selectTeams);
  const { getTeams } = useActions<typeof TeamsActions>(TeamsActions);

  const bubleParams = isMobile ? "157px" : "174px";

  const TeamLogo = (props: { logo: string; title: string }) => {
    const { logo, title } = props;

    if (logo)
      return (
        <Avatar src={logo} sx={{ width: bubleParams, height: bubleParams }} />
      );

    const splittedTitle = title
      .split(" ")
      .filter(Boolean)
      .map((word) => word[0]);

    const titleLogo =
      splittedTitle.length > 1
        ? `${splittedTitle[0]}${splittedTitle[splittedTitle.length - 1]}`
        : splittedTitle[0];

    return (
      <Bubble color="yellowPink" width={bubleParams} height={bubleParams}>
        <Title fontSize="56px">{titleLogo}</Title>
      </Bubble>
    );
  };

  const navigateToTeam = (id: any) => {
    navigate(`${TEAMS}/${id}`);
  };

  const handleOnTeamClick = (id: number) => () => {
    navigateToTeam(id);
  };

  useEffect(() => {
    getTeams();
  }, [user]); //eslint-disable-line

  // useEffect(() => {
  //   if (teams && teams.length < 2) navigateToTeam(teams[0]?.id);
  // }, [teams]); //eslint-disable-line

  if (pending) {
    return <Loading />;
  }

  return (
    <TeamsWrapper>
      <Grid container columnSpacing={3} rowSpacing={5}>
        {teams?.length ? (
          teams.map(({ id, logo, title }: ITeamsItem) => (
            <Grid key={id} item xs={6} sm={4} md={3} lg={12 / 5}>
              <TeamWrapper onClick={handleOnTeamClick(id)}>
                <TeamLogo logo={logo} title={title} />

                <TeamTitle>{title}</TeamTitle>
              </TeamWrapper>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            No teams found
          </Grid>
        )}
      </Grid>
    </TeamsWrapper>
  );
}

function Loading() {
  return (
    <TeamsWrapper>
      <Grid container columnSpacing={3} rowSpacing={5}>
        {Array.from(new Array(5)).map((item, idx) => (
          <Grid key={`team-${idx}`} item xs={6} sm={4} md={3} lg={12 / 5}>
            <TeamWrapper>
              <Skeleton
                animation="wave"
                variant="circular"
                width={175}
                height={175}
              />

              <Skeleton height={25} width="75%" />
            </TeamWrapper>
          </Grid>
        ))}
      </Grid>
    </TeamsWrapper>
  );
}
