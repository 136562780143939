import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { Grid, Box } from "@mui/material";
import { selectPostReferrals } from "@/store/redux";
import { GradientButton } from "@/shared/components";
import {
  Channel,
  Email,
  FirstName,
  LastName,
  Link,
  Agreement,
  Number,
  Files,
} from "./Fields";

interface IReferFormProps {
  id?: number;
}

export function ReferForm(props: IReferFormProps) {
  const { id } = props;

  const { clearErrors } = useFormContext();
  const { pending } = useSelector(selectPostReferrals);

  useEffect(() => {
    clearErrors();
  }, [clearErrors, id]);

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FirstName pending={pending} />
        </Grid>
        <Grid item xs={12}>
          <LastName pending={pending} />
        </Grid>
        <Grid item xs={12}>
          <Email pending={pending} />
        </Grid>
        <Grid item xs={12}>
          <Link pending={pending} />
        </Grid>
        <Grid item xs={12}>
          <Channel pending={pending} />
        </Grid>
        <Grid item xs={12}>
          <Number pending={pending} />
        </Grid>
        <Grid item xs={12}>
          <Files pending={pending} />
        </Grid>
        <Grid item xs={12}>
          <Agreement pending={pending} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <GradientButton type="submit" disabled={pending} sx={{ width: 200 }}>
            Refer a candidate
          </GradientButton>
        </Box>
      </Grid>
    </Box>
  );
}
