import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "MoodMonitoringLiterature",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getMoodLiterature,
  getMoodLiteratureError,
  getMoodLiteratureSuccess,
} = slice.actions;

reducerRegistry.register("moodMonitoringLiterature", reducer);
