import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IEmployeeCalendarCountriesState } from "./reducers";

export const employeeCalendarCountriesSelector = (
  state: RootState
): IEmployeeCalendarCountriesState => state.employeeCalendarCountries;

export const selectEmployeeCalendarCountries = createSelector(
  employeeCalendarCountriesSelector,
  (employeeCalendarCountriesState: IEmployeeCalendarCountriesState) => ({
    ...employeeCalendarCountriesState,
  })
);
