import { format, subMonths } from "date-fns";
import {
  NewsContent,
  Newcomers,
  NewsSidebarSection,
  HotTopics,
  PopularAuthors,
} from "@/components";
import { Title, Description, Tabs } from "@/shared/components";
import { NewsFeed } from "./NewsFeed";
import { desc, title } from "./News";

export function NewsMobileView() {
  const prevMonth = format(subMonths(new Date(), 1), "MMMM");

  return (
    <>
      <NewsContent>
        <Title fontSize="60px">{title}</Title>

        <Description>{desc}</Description>

        <Tabs
          titles={["Feed", "Actual news", "Newcomers"]}
          contents={[
            <NewsFeed />,
            <>
              <NewsSidebarSection title="Actual news" content={<HotTopics />} />
              <NewsSidebarSection
                title="Popular authors"
                content={<PopularAuthors />}
              />
            </>,
            <NewsSidebarSection
              title={`Newcomers in ${prevMonth}`}
              content={<Newcomers />}
            />,
          ]}
        />
      </NewsContent>
    </>
  );
}
