import { Grid } from "@mui/material";
import { IReferralsCandidate } from "@/store/redux";
import { useDevice } from "@/utils/hooks";
import { Candidate } from "./Candidate";
import { ListWrapper, Header } from "./Candidates.styled";

interface ICandidatesListProps {
  progressTotalSteps: number;
  list: IReferralsCandidate[];
}

export function CandidatesList(props: ICandidatesListProps) {
  const { list, progressTotalSteps } = props;

  const hasLaptop = useDevice("up", "md");

  return (
    <ListWrapper>
      {hasLaptop ? (
        <Header>
          <Grid container spacing={1}>
            <Grid item xs={6} md={2.5}>
              Candidate
            </Grid>
            <Grid item xs={6} md={3}>
              Position
            </Grid>
            <Grid item xs={6} md={3}>
              Recruiter
            </Grid>
            <Grid item xs={6} md={1}>
              Bonus
            </Grid>
            <Grid item xs={6} md={2.5}>
              Status
            </Grid>
          </Grid>
        </Header>
      ) : null}

      {list.map((item, idx) => (
        <Candidate
          key={`candidate-${idx}`}
          data={item}
          progressTotalSteps={progressTotalSteps}
        />
      ))}
    </ListWrapper>
  );
}
