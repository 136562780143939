import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useToggle } from "@/utils/hooks";
import { Wrapper, Title, Content } from "./Collapse.styled";

interface ICollapseProps {
  title: any;
  content: any;
}

export function Collapse(props: ICollapseProps) {
  const { content, title } = props;

  const [open, handleOnToggle] = useToggle();

  return (
    <Wrapper>
      <Title onClick={handleOnToggle}>
        {title}

        <ExpandMoreIcon
          fontSize="large"
          sx={{
            transition: "all 0.25s ease",
            transform: `rotate(${open ? "180deg" : "0deg"})`,
          }}
        />
      </Title>
      <Content in={open}>{content}</Content>
    </Wrapper>
  );
}
