import { IReferralsFilters } from "@/store/redux";
import { useDevice } from "@/utils/hooks";
import { Mobile } from "./Mobile";
import { Desktop } from "./Desktop";
import { IFilters } from "../Positions";

interface IFiltersProps extends IReferralsFilters {
  pending: boolean;
  values: IFilters;
  onChange: (v: { [k: string]: number }) => void;
}

export function Filters(props: IFiltersProps) {
  const { values, areas, levels, locations, pending, onChange } = props;

  const hasMobile = useDevice("down", "smd");

  return hasMobile ? (
    <Mobile
      values={values}
      areas={areas}
      levels={levels}
      locations={locations}
      pending={pending}
      onChange={onChange}
    />
  ) : (
    <Desktop
      values={values}
      areas={areas}
      levels={levels}
      locations={locations}
      pending={pending}
      onChange={onChange}
    />
  );
}
