import { call, put, takeLatest } from "redux-saga/effects";
import { IMoodContacts } from "./reducers";
import {
  getMoodContacts,
  getMoodContactsError,
  getMoodContactsSuccess,
} from "./slice";
import { getMoodContactsRequest } from "./api";

function* getMoodContactsSaga() {
  try {
    const contacts: IMoodContacts = yield call(getMoodContactsRequest);

    yield put(getMoodContactsSuccess(contacts));
  } catch (error) {
    yield put(getMoodContactsError({ error }));
  }
}

function* MoodContactsWatcher() {
  yield takeLatest(getMoodContacts.type, getMoodContactsSaga);
}

export default MoodContactsWatcher;
