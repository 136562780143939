import { styled } from "@mui/material/styles";

export const FiltersTitle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "sticky",
  zIndex: 2,
  top: 147,
  gap: theme.spacing(3),
  padding: theme.spacing(2, 0),
  fontWeight: 500,
  color: theme.palette.darkGrey.main,
  background: theme.modeColors({
    dark: theme.palette.primary.main,
    light: theme.palette.common.white,
  }),
}));

export const FiltersTitleCol = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(4),

  "&:first-of-type": {
    width: 225,
  },
}));

export const FiltersMobileWrapp = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 147,
  zIndex: 2,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: theme.spacing(1.5),
  padding: theme.spacing(2, 0),
  background: theme.modeColors({
    dark: theme.palette.primary.main,
    light: theme.palette.common.white,
  }),
}));

export const FilterWrapp = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const FilterName = styled("span")(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.darkGrey.main,
}));

export const FilterValue = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontWeight: 500,
  color: theme.palette.common.black,
  cursor: "pointer",
}));
