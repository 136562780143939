import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IReferralsCandidatesState } from "./reducers";

export const referralsCandidatesSelector = (
  state: RootState
): IReferralsCandidatesState => state.referralsCandidates;

export const selectReferralsCandidates = createSelector(
  referralsCandidatesSelector,
  (referralsCandidatesState: IReferralsCandidatesState) => ({
    ...referralsCandidatesState,
  })
);
