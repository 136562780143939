import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "Checkout",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  postCheckout,
  postCheckoutError,
  postCheckoutSuccess,
  resetCheckout,
  resetCheckoutError,
  resetCheckoutSuccess,
} = slice.actions;

reducerRegistry.register("checkout", reducer);
