import { useState, ChangeEvent } from "react";
import { Checkbox, useTheme } from "@mui/material";
import { ActionButton } from "@/shared/components";
import { LikeIcon, LikeIconFilled } from "@/shared/icons";
import { LikeButtonText } from "./LikeButton.styled";

interface ILikeProps {
  hasLike: boolean;
  count: number;
  disabled?: boolean;
  comment?: boolean;
  onLike: (likes: number, hasLike: boolean) => void;
}

export function LikeButton(props: ILikeProps) {
  const { disabled, count, comment, hasLike, onLike } = props;
  const { palette, modeColors } = useTheme();
  const iconColor = modeColors({
    dark: palette.secondary.main,
    light: palette.primary.main,
  });

  const [like, setLike] = useState<boolean>(hasLike);
  const [countLikes, setCountLikes] = useState(count);
  const hasLikeCallback = onLike && onLike instanceof Function;
  const buttonText = countLikes || (comment ? null : "like");

  const label = (
    <LikeButtonText $comment={comment}>{buttonText}</LikeButtonText>
  );

  const handleOnLike = (e: ChangeEvent<HTMLInputElement>) => {
    const isLiked = e.target.checked;
    const likesCount = isLiked ? countLikes + 1 : countLikes - 1;

    setLike(isLiked);
    setCountLikes(likesCount);

    if (hasLikeCallback) onLike(likesCount, isLiked);
  };

  return (
    <ActionButton
      hasCount={!!countLikes}
      label={label}
      control={
        <Checkbox
          checked={like}
          onChange={handleOnLike}
          disabled={!hasLikeCallback || disabled}
          icon={<LikeIcon />}
          checkedIcon={<LikeIconFilled htmlColor={iconColor} />}
        />
      }
    />
  );
}
