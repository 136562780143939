import { PayloadAction } from "@reduxjs/toolkit";

export interface IPostReferralsState {
  id: number | null;
  first_name: string;
  last_name: string;
  email: string;
  portfolio_link: string;
  channel_of_communications: string;
  number_of_channel: string;
  cv?: FormData | null;
  fileName: string | null;
  agree: boolean;
  hasRequested: boolean;
  pending: boolean;
  error_message: string;
  error: any;
}

export interface IPostReferralsStatePending {
  id: number | null;
  first_name: string;
  last_name: string;
  email: string;
  portfolio_link: string;
  channel_of_communications: string;
  number_of_channel: string;
  cv?: FormData | null;
  fileName?: string | undefined;
}

export interface IPostReferralsStateSuccess {
  id: number;
  fileName: string;
  error_message?: string;
}

export interface IPostReferralsStateError {
  error: any;
}

export const initialState: IPostReferralsState = {
  pending: false,
  error: null,
  hasRequested: false,
  id: null,
  error_message: "",
  first_name: "",
  last_name: "",
  email: "",
  portfolio_link: "",
  channel_of_communications: "",
  number_of_channel: "",
  cv: null,
  fileName: null,
  agree: false,
};

export const reducers = {
  postReferrals: (
    state: IPostReferralsState,
    action: PayloadAction<IPostReferralsStatePending>
  ) => {
    return {
      ...state,
      hasRequested: false,
      pending: true,
      error_message: "",
      error: "",
      ...(action.payload || {}),
    };
  },
  postReferralsSuccess: (
    state: IPostReferralsState,
    action: PayloadAction<IPostReferralsStateSuccess>
  ) => {
    const { error_message } = action.payload;

    return {
      ...state,
      pending: false,
      hasRequested: !error_message?.length,
      error_message: error_message || "",
    };
  },
  postReferralsError: (
    state: IPostReferralsState,
    action: PayloadAction<IPostReferralsStateError>
  ) => ({
    ...state,
    pending: false,
    hasRequested: false,
    error: action.payload.error,
  }),

  //RESET
  resetPostReferrals: (state: IPostReferralsState) => ({
    ...state,
    hasRequested: false,
    error: null,
    id: null,
    first_name: "",
    last_name: "",
    email: "",
    portfolio_link: "",
    channel_of_communications: "",
    number_of_channel: "",
    cv: null,
    fileName: null,
    agree: false,
  }),
  resetPostReferralsSuccess: (state: IPostReferralsState) => ({
    ...state,
  }),
  resetPostReferralsError: (state: IPostReferralsState) => ({
    ...state,
  }),
};
