import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function CoffeeIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 22 20" fill="none" htmlColor="transparent" {...props}>
      <path
        d="M15.5 7.66602H16.3333C17.2174 7.66602 18.0652 8.0172 18.6904 8.64233C19.3155 9.26745 19.6667 10.1153 19.6667 10.9993C19.6667 11.8834 19.3155 12.7313 18.6904 13.3564C18.0652 13.9815 17.2174 14.3327 16.3333 14.3327H15.5"
        // fill="#fff"
        stroke="#8E949A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16675 7.66602H15.5001V15.166C15.5001 16.0501 15.1489 16.8979 14.5238 17.523C13.8986 18.1482 13.0508 18.4993 12.1667 18.4993H5.50008C4.61603 18.4993 3.76818 18.1482 3.14306 17.523C2.51794 16.8979 2.16675 16.0501 2.16675 15.166V7.66602Z"
        // fill="#fff"
        stroke="#8E949A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 1.83398V4.33398"
        // fill="#fff"
        stroke="#8E949A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.83325 1.83398V4.33398"
        // fill="#fff"
        stroke="#8E949A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1667 1.83398V4.33398"
        // fill="#fff"
        stroke="#8E949A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
