import { useSelector } from "react-redux";
import { TableRow, TableCell, Skeleton } from "@mui/material";
import { selectTimesheetActivity } from "@/store/redux";
import { timeOffColors } from "@/theme";
import { getDayOff, getDecimal, getHoliday } from "../helpers";
import { IProjectsHoursState, IDayReporting } from "../interfaces";

interface IProjectsProps {
  hours: IProjectsHoursState[];
  timeButton: any;
}

interface IProjectItemProps {
  project: {
    id: number;
    name: string;
  };
  reporting: IDayReporting[];
  timeButton: (day: any, reporting: any) => JSX.Element;
}

export function Projects(props: IProjectsProps) {
  const { hours, timeButton } = props;
  const { pending } = useSelector(selectTimesheetActivity);

  return (
    <>
      {hours.map(({ id, name, sum, reporting }) => {
        const decimalSum = getDecimal(sum);

        return (
          <TableRow key={`project-${id}`}>
            <TableCell>{name}</TableCell>

            <TableCell>
              {pending ? <Skeleton height={12} width={24} /> : decimalSum}
            </TableCell>

            <ProjectItem
              project={{ id, name }}
              reporting={reporting}
              timeButton={timeButton}
            />
          </TableRow>
        );
      })}
    </>
  );
}

function ProjectItem(props: IProjectItemProps) {
  const { project, reporting, timeButton: TimeButton } = props;
  const { list, holidays } = useSelector(selectTimesheetActivity);

  const getTimeOff = (day: Date, dayNumber: number) => {
    const dayOff = getDayOff({
      list,
      day,
    });
    const hasHoliday = !!getHoliday({ list: holidays, day })?.type;
    const hasWeekend = [5, 6].includes(dayNumber);
    const bg = !!dayOff?.type
      ? timeOffColors[dayOff?.type]?.color
      : hasWeekend || hasHoliday
      ? "rgba(230, 230, 230, 0.30)"
      : "transparent";

    return { bg, hasTimeOff: !!dayOff?.type };
  };

  return (
    <>
      {reporting.map((item, idx) => {
        const reporting = {
          ...(item?.reporting || {}),
          project: project || {},
          type: "project",
        };

        return (
          <TableCell
            key={`project_item-${item?.reporting?.start_date}-${item?.reporting?.hours}-${idx}`}
            sx={{
              background: getTimeOff(item.day, idx)?.bg,
            }}
          >
            <TimeButton
              day={item?.day}
              reporting={reporting}
              hasTimeOff={getTimeOff(item.day, idx)?.hasTimeOff}
            />
          </TableCell>
        );
      })}
    </>
  );
}
