import Axios, { AxiosResponse } from "axios";
import { appConfig } from "@/config/appConfig";

export const getTaskReportingRequest = async (params: { id: string }) => {
  const response: AxiosResponse = await Axios.get(
    `${appConfig.API_URL}/task-reporting/${params?.id}`
  );

  return response.data;
};
