import { styled } from "@mui/material/styles";

interface IFiltersProps {
  $vertical?: boolean;
}

interface IFilterControlProps {
  $vertical?: boolean;
}

export const FiltersWrapper = styled("div")<IFiltersProps>(
  ({ theme, $vertical }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(4),
    alignItems: "center",

    ...($vertical
      ? {
          flexDirection: "column",
          alignItems: "start",
          gap: theme.spacing(2),
        }
      : {}),
  })
);

export const FilterControlWrapper = styled("div")<IFilterControlProps>(
  ({ theme, $vertical }) => ({
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",

    ...($vertical
      ? {
          flexDirection: "column",
          alignItems: "start",
        }
      : {}),
  })
);

export const FilterControLabel = styled("label")(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.darkGrey.main,
}));
