import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { Skeleton } from "@mui/material";
import { Select } from "@/shared/components";
import {
  selectCheckoutDeliveryInfo,
  selectProfile,
  CheckoutDeliveryInfoActions,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { GenerateField } from "./GenerateField";
import { filterDeliveryTypesByCountry } from "../helpers";

export function DeliveryTypeField() {
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const { profile } = useSelector(selectProfile);
  const { delivery_type, postal_service } = useSelector(
    selectCheckoutDeliveryInfo
  );
  const { getCheckoutDeliveryInfo } = useActions<
    typeof CheckoutDeliveryInfoActions
  >(CheckoutDeliveryInfoActions);

  const country = watch("country");
  const fieldName = "delivery_type";
  const hasError = !!errors[fieldName]?.type;

  useEffect(() => {
    if (!profile?.id || (delivery_type.length && postal_service.length)) {
      return;
    }

    getCheckoutDeliveryInfo();
  }, [profile]); //eslint-disable-line

  if (!delivery_type.length) {
    return <Skeleton height={48} />;
  }

  return (
    <GenerateField
      errorText="Choose one of the options"
      name={fieldName}
      render={({ field }) => (
        <Select
          {...field}
          fullWidth
          error={hasError}
          variant="filled"
          label="Delivery Type"
          options={filterDeliveryTypesByCountry(country, delivery_type)}
        />
      )}
    />
  );
}
