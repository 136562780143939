import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ICartState } from "./reducers";

export const cartSelector = (state: RootState): ICartState => state.cart;

export const selectCart = createSelector(
  cartSelector,
  (cartState: ICartState) => ({ ...cartState })
);
