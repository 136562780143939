import { call, put, takeLatest } from "redux-saga/effects";
import { IMoodLiterature } from "./reducers";
import {
  getMoodLiterature,
  getMoodLiteratureError,
  getMoodLiteratureSuccess,
} from "./slice";
import { getMoodLiteratureRequest } from "./api";

function* getMoodLiteratureSaga() {
  try {
    const literature: IMoodLiterature = yield call(getMoodLiteratureRequest);

    yield put(getMoodLiteratureSuccess(literature));
  } catch (error) {
    yield put(getMoodLiteratureError({ error }));
  }
}

function* MoodLiteratureWatcher() {
  yield takeLatest(getMoodLiterature.type, getMoodLiteratureSaga);
}

export default MoodLiteratureWatcher;
