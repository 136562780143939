import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "MarketplaceDonate",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getMarketplaceDonate,
  getMarketplaceDonateError,
  getMarketplaceDonateSuccess,
} = slice.actions;

reducerRegistry.register("marketplaceDonate", reducer);
