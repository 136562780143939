import { PayloadAction } from "@reduxjs/toolkit";
import { transformReporting } from "./helpers";

export interface ITaskReportingItemSuccess {
  project: {
    id: number;
    name: string;
  };
  tasks: Array<string>;
}

export interface ITaskReporting {
  [key: number]: {
    project_name: string;
    tasks: Array<{ id: string; value: string; checked: boolean }>;
  };
}

export interface ITaskReportingState {
  pending: boolean;
  error: { message: string; statusCode: number } | null | undefined;
  date: string | null;
  alreadyReported: boolean; // 'reported' || null
  reporting: ITaskReporting | null;
}

export interface ITaskReportingStateSuccess {
  reporting: Array<ITaskReportingItemSuccess>;
  date: string;
  reporting_status?: string | null;
}

export interface ITaskReportingStateError {
  error: { message: string; statusCode: number } | null | undefined;
}

export const initialState: ITaskReportingState = {
  pending: false,
  error: null,
  date: null,
  reporting: null,
  alreadyReported: false,
};

export const reducers = {
  getTaskReporting: (
    state: ITaskReportingState,
    action: PayloadAction<{ id: string }>
  ) => {
    return {
      ...state,
      error: null,
      date: null,
      pending: true,
      reporting: null,
      alreadyReported: false,
    };
  },
  getTaskReportingSuccess: (
    state: ITaskReportingState,
    action: PayloadAction<ITaskReportingStateSuccess>
  ) => {
    const { reporting, reporting_status, date } = action?.payload || {};

    return {
      ...state,
      date,
      pending: false,
      alreadyReported: reporting_status === "reported",
      reporting: transformReporting(reporting || []),
    };
  },
  getTaskReportingError: (
    state: ITaskReportingState,
    action: PayloadAction<ITaskReportingStateError>
  ) => ({
    ...state,
    pending: false,
    error: action?.payload?.error,
  }),
};
