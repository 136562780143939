import { PayloadAction } from "@reduxjs/toolkit";

export interface IRecentVacancy {
  id: number;
  title: string;
  referral_bonus: number;
}

export interface IRecentVacanciesState {
  pending: boolean;
  error: any;
  vacancies: IRecentVacancy[];
}

export interface IRecentVacanciesStateSuccess {
  vacancies: IRecentVacancy[];
}

export interface IRecentVacanciesStateError {
  error: any;
}

export const initialState: IRecentVacanciesState = {
  pending: false,
  error: "",
  vacancies: [],
};

export const reducers = {
  getRecentVacancies: (state: IRecentVacanciesState) => ({
    ...state,
    pending: true,
    error: "",
  }),
  getRecentVacanciesSuccess: (
    state: IRecentVacanciesState,
    action: PayloadAction<IRecentVacanciesStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      vacancies: action.payload.vacancies,
    };
  },
  getRecentVacanciesError: (
    state: IRecentVacanciesState,
    action: PayloadAction<IRecentVacanciesStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload,
  }),
};
