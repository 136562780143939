import { styled } from "@mui/material/styles";
import { Container as MuiContainer } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  minWidth: "325px",
}));

export const Content = styled("div")(({ theme }) => ({
  position: "relative",
  flex: 1,
  display: "flex",
  alignItems: "stretch",
  flexDirection: "column",
}));

export const Container = styled(MuiContainer)(({ theme }) => ({
  flex: 1,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));
