import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "TimesheetActivity",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getTimesheetActivity,
  getTimesheetActivityError,
  getTimesheetActivitySuccess,
} = slice.actions;

reducerRegistry.register("timesheetActivity", reducer);
