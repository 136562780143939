import { ITimeOffListItem } from "@/store/redux";
import { TimeOffRequestModal } from "../Request";

interface IEditRequestProps {
  open: boolean;
  currentRequest: ITimeOffListItem;
  onClose: () => void;
}

export function EditRequest(props: IEditRequestProps) {
  const { currentRequest, open, onClose } = props;

  return (
    <TimeOffRequestModal
      editRequest
      title="Edit Time Off Request"
      currentRequest={currentRequest}
      open={open}
      onClose={onClose}
    />
  );
}
