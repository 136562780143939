import { useState, MouseEvent } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { GradientButton } from "@/shared/components";
import {
  VeryBadSmileIcon,
  BadSmileIcon,
  NormalSmileIcon,
  GoodSmileIcon,
  FineSmileIcon,
} from "@/shared/icons";
import { PostMoodActions, selectPostMood } from "@/store/redux";
import { useActions } from "@/utils/redux";
import {
  ToggleButtonGroupStyled,
  ToggleButtonStyled,
} from "./MoodMonitoring.styled";

const scores = [
  {
    value: 1,
    icon: <VeryBadSmileIcon sx={{ fontSize: 40 }} />,
  },
  {
    value: 2,
    icon: <BadSmileIcon sx={{ fontSize: 40 }} />,
  },
  {
    value: 3,
    icon: <NormalSmileIcon sx={{ fontSize: 40 }} />,
  },
  {
    value: 4,
    icon: <GoodSmileIcon sx={{ fontSize: 40 }} />,
  },
  {
    value: 5,
    icon: <FineSmileIcon sx={{ fontSize: 40 }} />,
  },
];

export function MoodMonitoringSmiles() {
  const { pending } = useSelector(selectPostMood);
  const { postMood } = useActions<typeof PostMoodActions>(PostMoodActions);

  const [score, setScore] = useState<number | null>(null);

  const handleOnChange = (event: MouseEvent<HTMLElement>, newScore: number) => {
    setScore(newScore);
  };

  const handleOnSubmit = () => {
    postMood({ score });
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <ToggleButtonGroupStyled
          exclusive
          color="primary"
          value={score}
          onChange={handleOnChange}
        >
          {scores.map(({ value, icon }) => (
            <ToggleButtonStyled key={value} value={value}>
              {icon}
            </ToggleButtonStyled>
          ))}
        </ToggleButtonGroupStyled>
      </Box>

      <GradientButton
        fullWidth
        disabled={!score || pending}
        onClick={handleOnSubmit}
      >
        SUBMIT
      </GradientButton>
    </>
  );
}
