import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { ICheckoutStatePending } from "./reducers";

export const postCheckoutRequest = async (params: ICheckoutStatePending) => {
  const { ...restParams } = params;

  const response: AxiosResponse = await apiInstance.post(
    `/marketplace/cart/checkout`,
    {
      ...restParams,
    }
  );

  return response.data;
};
