import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
  Bubble,
  Dialog,
  DialogMessageContent,
  GradientButton,
} from "@/shared/components";
import { selectPostQuestions, PostQuestionsActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { useDialog } from "@/utils/hooks";
import { AskQuestionForm } from "./AskQuestionForm";
import { AskQuestionErrorMessage } from "./AskQuestionErrorMessage";

export function AskQuestion(props: PropsWithChildren) {
  const { children } = props;
  const { hasPosted } = useSelector(selectPostQuestions);
  const { resetPostQuestions } =
    useActions<typeof PostQuestionsActions>(PostQuestionsActions);

  const [openDialog, onDialogOpen, onDialogClose] = useDialog();

  const handleOnDialogClose = () => {
    onDialogClose();

    setTimeout(resetPostQuestions, 500);
  };

  const content = hasPosted ? (
    <DialogMessageContent
      icon={
        <Bubble color="yellowPink" width="100px" height="100px">
          <CheckIcon sx={{ fontSize: 56 }} />
        </Bubble>
      }
      title="We've got your question!"
      description="Depending on the question, we will either answer it in our regular news updates or respond directly to you."
      action={
        <GradientButton fullWidth onClick={handleOnDialogClose}>
          GOT IT
        </GradientButton>
      }
    />
  ) : (
    <>
      <Box sx={{ fontWeight: 700, textAlign: "center" }}>
        Here you can ask anything about work of a particular department or even
        C-level.
      </Box>

      <AskQuestionForm />
    </>
  );

  return (
    <>
      <div onClick={onDialogOpen}>{children}</div>

      <Dialog
        top
        fullWidth
        maxWidth="xsm"
        open={openDialog}
        onClose={handleOnDialogClose}
        title="Ask a question"
        dialogContent={content}
      />

      <AskQuestionErrorMessage />
    </>
  );
}
