import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCart, CartActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { PopupMessage } from "@/shared/components";
import { MessageTitle, MessageDesc } from "./MarketPlaceBrandMessage.styled";

export function MarketPlaceBrandMessage() {
  const { error_message, postedProduct } = useSelector(selectCart);
  const { resetPostCart } = useActions<typeof CartActions>(CartActions);

  const [showMessage, setShowMessage] = useState(false);

  const hasError = !!error_message;
  const messageVariant = hasError ? "error" : "success";

  const message = hasError ? (
    <>
      <MessageTitle>You can't add more items in your cart</MessageTitle>
      <MessageDesc>{error_message}</MessageDesc>
    </>
  ) : (
    <>
      <MessageDesc>
        <b>{postedProduct?.title}</b> added to cart
      </MessageDesc>
    </>
  );

  const handleOnClose = () => {
    setShowMessage(false);
  };

  const handleOnExit = () => {
    resetPostCart();
  };

  useEffect(() => {
    if (error_message || postedProduct?.id) {
      setShowMessage(true);
    }
  }, [error_message, postedProduct?.id]); //eslint-disable-line

  useEffect(() => {
    return () => {
      resetPostCart();
    };
  }, []); //eslint-disable-line

  if (!error_message && !postedProduct?.id) {
    return null;
  }

  return (
    <PopupMessage
      variant={messageVariant}
      open={showMessage}
      message={message}
      onClose={handleOnClose}
      onExit={handleOnExit}
    />
  );
}
