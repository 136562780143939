import {
  format,
  endOfISOWeek,
  startOfISOWeek,
  startOfMonth,
  endOfMonth,
  differenceInCalendarMonths,
  eachDayOfInterval,
} from "date-fns";
import { ITimesheetActivityItem } from "@/store/redux";

export function splitedByType(list: ITimesheetActivityItem[] = []) {
  const working = [];
  const outage = [];

  for (let i = 0; i < list.length; i++) {
    if (list[i]?.type === "project") {
      working.push(list[i]);
    } else {
      outage.push(list[i]);
    }
  }

  return { working, outage };
}

export function getPeriod(date: Date, type?: "week" | "month") {
  const dateFormat = "dd/MM/yyyy";
  const firstWeekDay = startOfISOWeek(new Date(date));
  const lastWeekDay = endOfISOWeek(new Date(date));

  const firstMonthDay = startOfMonth(new Date(date));
  const lastMonthDay = endOfMonth(new Date(date));

  const firstDay = type === "week" ? firstWeekDay : firstMonthDay;
  const lastDay = type === "week" ? lastWeekDay : lastMonthDay;

  const startDate = format(firstDay, dateFormat);
  const endDate = format(lastDay, dateFormat);

  return { startDate, endDate };
}

export function getDisabledDate(value: Date | null) {
  const currentDate = new Date();
  const selectedDate = new Date(value || 0);

  const startMonth = Array.from({ length: 4 }, (_, i) => i + 1).includes(
    currentDate.getDate()
  );

  const hasCurrentMonth =
    differenceInCalendarMonths(currentDate, selectedDate) === 0;

  const hasPrevMonth =
    differenceInCalendarMonths(currentDate, selectedDate) === 1;

  const hasDisabledDate = startMonth
    ? !hasCurrentMonth && !hasPrevMonth
    : !hasCurrentMonth;

  return hasDisabledDate;
}

export function getSum(reporting: ITimesheetActivityItem[]) {
  const sum = reporting
    .map((item) => item?.hours)
    .filter(Boolean)
    .reduce((prev, curr) => prev + curr, 0);

  return sum;
}

export function getHoursReporting({
  start,
  end,
  list,
}: {
  start: Date;
  end: Date;
  list: ITimesheetActivityItem[];
}) {
  const days = eachDayOfInterval({
    start,
    end,
  });

  const hours = days.map((day) => {
    const index = list.findIndex((x) => {
      const getFormatedDate = (date: Date | number | string) => {
        return format(new Date(date || 0), "dd/MM/yyyy");
      };
      const startDate = getFormatedDate(x.start_date);
      const calendarDay = getFormatedDate(day);

      return calendarDay === startDate;
    });

    return {
      day,
      reporting:
        index > -1 ? list[index] : ({ hours: 0 } as ITimesheetActivityItem),
    };
  });

  return hours;
}

export function getWeekReporting({
  currentDate,
  list,
}: {
  currentDate: Date;
  list: ITimesheetActivityItem[];
}) {
  const start = startOfISOWeek(currentDate);
  const end = endOfISOWeek(currentDate);
  const hours = getHoursReporting({ start, end, list });

  return { hours };
}

export function getMonthReporting({
  currentDate,
  list,
}: {
  currentDate: Date;
  list: ITimesheetActivityItem[];
}) {
  const start = startOfMonth(currentDate);
  const end = endOfMonth(currentDate);
  const hours = getHoursReporting({ start, end, list });

  return { hours };
}

export function getOutageHours({
  type,
  currentDate,
  list,
}: {
  type: "week" | "month";
  currentDate: Date;
  list: ITimesheetActivityItem[];
}) {
  const reportingMethod =
    type === "week" ? getWeekReporting : getMonthReporting;

  const { hours } = reportingMethod({
    currentDate,
    list: list || [],
  });

  const sum = getSum(
    [].concat(...(hours.map((item) => item.reporting) as any))
  );

  const rawHours = [
    { 0: sum },
    ...hours.map(({ day, reporting }) => ({
      [format(day, "dd/MM/yyyy")]: reporting.hours || 0,
    })),
  ];

  return {
    reporting: hours,
    rawHours,
    sum,
  };
}

export function getProjectsHours({
  type,
  currentDate,
  list,
}: {
  type: "week" | "month";
  currentDate: Date;
  list: any[];
}) {
  const reportingMethod =
    type === "week" ? getWeekReporting : getMonthReporting;

  const projectsHours = list.map((project) => {
    const { hours } = reportingMethod({
      currentDate,
      list: project.reporting,
    });
    const sum = getSum(
      [].concat(...(hours.map((item) => item.reporting) as any))
    );
    const rawHours = [
      { 0: sum },
      ...hours.map(({ day, reporting }) => ({
        [format(day, "dd/MM/yyyy")]: reporting.hours || 0,
      })),
    ];

    return {
      ...project,
      reporting: hours,
      rawHours,
      sum,
    };
  });

  return projectsHours;
}

export function getDayOff({ day, list }: { day: Date; list: any }) {
  const timeOffs = list.filter((item: any) =>
    ["paid_time_off", "health_time_off"].includes(item.type as any)
  );
  const dayOff = []
    .concat(
      ...(timeOffs.map(
        ({
          start_date,
          end_date,
          type,
        }: {
          start_date: any;
          end_date: any;
          type: any;
        }) => {
          const timeOffsEvents = eachDayOfInterval({
            start: new Date(start_date),
            end: new Date(end_date),
          }).map((date) => ({
            type,
            date: format(date, "dd/MM/yyyy"),
          }));

          return timeOffsEvents;
        }
      ) as any)
    )
    .filter(
      (event: any) => event.date === format(new Date(day), "dd/MM/yyyy")
    )?.[0];

  return dayOff as {
    type: "paid_time_off" | "health_time_off";
    date: string;
  } | null;
}

export function getHoliday({ day, list }: { day: Date; list: any }) {
  const holidays = list.filter((item: any) =>
    ["holiday"].includes(item.type as any)
  );
  const holiday = []
    .concat(
      ...(holidays.map(
        ({
          start_date,
          end_date,
          type,
        }: {
          start_date: any;
          end_date: any;
          type: any;
        }) => {
          const events = eachDayOfInterval({
            start: new Date(start_date),
            end: new Date(end_date),
          }).map((date) => ({
            type,
            date: format(date, "dd/MM/yyyy"),
          }));

          return events;
        }
      ) as any)
    )
    .filter(
      (event: any) => event.date === format(new Date(day), "dd/MM/yyyy")
    )?.[0];

  return holiday as {
    type: "holiday";
    date: string;
  } | null;
}

export function getDecimal(value: any) {
  const stringValue = typeof value === "string" ? value : String(value || 0);
  const floatTotal = parseFloat(stringValue);

  const floatFixedValue =
    floatTotal % 1 === 0 ? floatTotal : floatTotal.toFixed(2);

  return floatFixedValue;
}
