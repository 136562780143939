import { PayloadAction } from "@reduxjs/toolkit";
import { NEWS } from "@/routes/consts";

export interface INewsArticle {
  id: number;
  author: {
    name: string;
    avatar: string;
  };
  created_at: string;
  title: string;
  description: string;
  likes: number;
  has_like: boolean;
  has_comment: boolean;
  comments: number;
  tags: string[];
  langs: string[];
}

export interface INewsArticleState {
  pending: boolean;
  error: any;
  article: INewsArticle;
}

export interface INewsArticleStatePending {
  plang?: string;
  id: number | string;
}

export interface INewsArticleStateError {
  error: any;
}

export const initialState: INewsArticleState = {
  pending: false,
  error: null,
  article: {} as INewsArticle,
};

export const reducers = {
  getNewsArticle: (
    state: INewsArticleState,
    action: PayloadAction<INewsArticleStatePending>
  ) => {
    return {
      ...state,
      article: {} as INewsArticle,
      pending: true,
      error: "",
    };
  },
  getNewsArticleSuccess: (
    state: INewsArticleState,
    action: PayloadAction<INewsArticle>
  ) => {
    const transformedArticle = {
      ...(action?.payload || {}),
      href: `${NEWS}/${action?.payload?.id}`,
    };

    return {
      ...state,
      pending: false,
      article: transformedArticle,
    };
  },
  getNewsArticleError: (
    state: INewsArticleState,
    action: PayloadAction<INewsArticleStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
