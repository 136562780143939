import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IUserProjectsState } from "./reducers";

export const userProjectsSelector = (state: RootState): IUserProjectsState =>
  state.userProjects;

export const selectUserProjects = createSelector(
  userProjectsSelector,
  (userProjectsState: IUserProjectsState) => ({ ...userProjectsState })
);
