import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import {
  EmployeeCalendarActions,
  selectEmployeeCalendar,
  IEmployeeCalendarDate,
  selectProfile,
  selectEmployeeCalendarCountries,
  selectTeams,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { useDevice, useFilters } from "@/utils/hooks";
import { CalendarEvent, LightTooltip } from "@/shared/components";
import {
  getPeriod,
  getFlagEmoji,
  getEventDate,
  getEventTypeTitle,
} from "./helpers";
import { EmployeeCalendarMobile } from "./EmployeeCalendarMobile";
import { EmployeeCalendarFilters } from "./EmployeeCalendarFilters";
import {
  EmployeeCalendarWrapper,
  EmojiWrapper,
  EventWrapper,
  EventDescWrapper,
  EventDesc,
} from "./EmployeeCalendar.styled";

export interface IFilters {
  date: Date;
  types: Array<string>;
  country: number;
  team: number;
}

export const getEmoji = ({
  countryCode,
  eventType,
}: {
  countryCode: string;
  eventType: string;
}) => {
  const emojisMap: { [k: string]: any } = {
    health_time_off: <>🤒</>,
    paid_time_off: <>🏖️</>,
    birthday: <>🎁</>,
    anniversary: <>🧁</>,
    holiday: getFlagEmoji(countryCode) || <>🎡</>,
  };

  return emojisMap[eventType];
};

export function EmployeeCalendar() {
  const hasMobile = useDevice("down", "md");

  const { profile } = useSelector(selectProfile);
  const { pending: countriesPending } = useSelector(
    selectEmployeeCalendarCountries
  );
  const { events, pending: calendarPending } = useSelector(
    selectEmployeeCalendar
  );
  const { pending: teamsPending } = useSelector(selectTeams);

  const { getEmployeeCalendar } = useActions<typeof EmployeeCalendarActions>(
    EmployeeCalendarActions
  );

  const { filters, handleOnFilters } = useFilters<IFilters>({
    date: new Date(),
    types: ["paid_time_off,health_time_off", "birthday,anniversary", "holiday"],
    country: profile?.holidays_country || -1,
    team: profile?.default_team_id || -1,
  });

  const hasPending = countriesPending || calendarPending || teamsPending;

  const getCalendarEvents = (params?: Partial<IFilters>) => {
    const { date, types, country, team } = params || {};

    const currentDate = new Date();
    const { startDate, endDate } = getPeriod(date || currentDate);

    getEmployeeCalendar({
      start_date: startDate,
      end_date: endDate,
      country: country || filters.country,
      types: (types || filters.types || []).join(",") || undefined,
      team: team || filters.team || -1,
    });
  };

  const handleOnCalendarChange = (date: Date) => {
    getCalendarEvents({ date });

    handleOnFilters({ date });
  };

  const handleOnFilterChange = ({
    name,
    value,
  }: {
    name: string;
    value: Array<string> | number;
  }) => {
    const updatedFilters = {
      ...filters,
      [name]: value,
    };

    handleOnFilters(updatedFilters);

    getCalendarEvents(updatedFilters);
  };

  const eventWrapper = (eventProps: {
    event: IEmployeeCalendarDate;
    children: any;
  }) => {
    const {
      event_type,
      description,
      type,
      start_date,
      end_date,
      country_code,
    } = eventProps?.event;

    const eventDate = getEventDate({ start_date, end_date });
    const emoji = getEmoji({
      countryCode: country_code,
      eventType: event_type,
    });
    const eventTitle = getEventTypeTitle(event_type);

    const title = (
      <EventWrapper>
        <EventDescWrapper>
          <EmojiWrapper>{emoji}</EmojiWrapper>
          <EventDesc>
            <b>{description}</b>
          </EventDesc>
        </EventDescWrapper>

        <div>{eventTitle}</div>

        <div>{eventDate}</div>
      </EventWrapper>
    );

    return (
      <LightTooltip title={title}>
        <CalendarEvent $type={type}>
          {hasMobile ? null : (
            <EventDescWrapper>
              <EmojiWrapper>{emoji}</EmojiWrapper>
              <EventDesc>{description}</EventDesc>
            </EventDescWrapper>
          )}
          {eventProps.children}
        </CalendarEvent>
      </LightTooltip>
    );
  };

  useEffect(() => {
    if (!profile?.id) return;

    const updateFilters = {
      ...filters,
      country: profile?.holidays_country || -1,
      team: profile?.default_team_id || -1,
    };

    handleOnFilters(updateFilters);

    getCalendarEvents(updateFilters);
  }, [profile]); //eslint-disable-line

  return (
    <Box sx={{ mb: 10 }}>
      <EmployeeCalendarFilters
        loading={hasPending}
        filters={filters}
        onChange={handleOnFilterChange}
      />

      <EmployeeCalendarWrapper
        selectable={false}
        pending={hasPending}
        events={events}
        customEventWrapper={eventWrapper}
        onNavigate={handleOnCalendarChange}
      />

      <EmployeeCalendarMobile />
    </Box>
  );
}
