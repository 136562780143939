import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { DropzoneWrapper, Subtitle, Title } from "./Dropzone.styled";

interface IDropzoneProps {
  disabled?: boolean;
  maxSize?: number;
  onDrop?: (k: File[]) => void;
  onChange?: (...event: any[]) => void;
}

export function Dropzone(props: IDropzoneProps) {
  const { maxSize, disabled, onDrop, onChange } = props;

  const handleOnDrop = useCallback(
    (acceptedFiles: File[]) => {
      onDrop && onDrop(acceptedFiles);
    },
    [onDrop]
  ); //eslint-disable-line

  let { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled,
    maxSize,
    onDrop: handleOnDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/rtf": [".rtf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
    },
  });

  return (
    <>
      <DropzoneWrapper {...getRootProps()} $hasDragged={isDragActive}>
        <input {...getInputProps({ onChange })} />

        <Title>Drop or upload your CV (optional)</Title>
        <Subtitle>.pdf , .docx, .rtf, .pptx up to 20mb</Subtitle>
      </DropzoneWrapper>
    </>
  );
}
