import { InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Title } from "@/shared/components";

export const TitleStyled = styled(Title)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.typography.pxToRem(30),
  },
}));

export const List = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const ListItem = styled("div")(({ theme }) => ({
  display: "flex",
  paddingBottom: theme.spacing(6),
  gap: theme.spacing(6),

  "& + &": {
    borderTop: "1px solid #E6E6E6",
    paddingTop: theme.spacing(6),
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(3, 0),
  },
}));

export const ListItemImage = styled("div")(({ theme }) => ({
  width: "32%",
  position: "relative",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const ListItemContent = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}));

export const ListItemPrice = styled("div")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
}));

export const Options = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "start",
    gap: theme.spacing(1),
  },
}));

export const OptionItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

export const OptionTitle = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: "1rem",
}));

export const OptionValues = styled("div")(({ theme }) => ({}));

export const LoadMoreWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(5),

  "& button": {
    minWidth: 275,
  },

  [theme.breakpoints.down("sm")]: {
    "& button": {
      width: "100%",
    },
  },
}));
