import { styled } from "@mui/material/styles";

export const TeamsWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(4, 0, 5),
}));

export const TeamWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  gap: theme.spacing(2),
  cursor: "pointer",
}));

export const TeamTitle = styled("div")(({ theme }) => ({
  fontWeight: 900,
}));
