import { PayloadAction } from "@reduxjs/toolkit";

export interface INewsLocations {
  locations: {
    id: number;
    value: number;
    title: string;
  }[];
}

export interface INewsLocationsState extends INewsLocations {
  pending: boolean;
  error: any;
}

export interface INewsLocationsStateSuccess {
  locations: {
    id: number;
    value: number;
    title: string;
  }[];
}

export interface INewsLocationsStateError {
  error: any;
}

export const initialState: INewsLocationsState = {
  pending: false,
  error: null,
  locations: [],
};

function transfromLocations(locations: Array<any>) {
  return (locations || []).map((item) => ({ ...item, value: item.id }));
}

export const reducers = {
  getNewsLocations: (state: INewsLocationsState) => {
    return {
      ...state,
      pending: true,
      error: "",
    };
  },
  getNewsLocationsSuccess: (
    state: INewsLocationsState,
    action: PayloadAction<INewsLocationsStateSuccess>
  ) => ({
    ...state,
    pending: false,
    locations: transfromLocations(action?.payload?.locations || []),
  }),
  getNewsLocationsError: (
    state: INewsLocationsState,
    action: PayloadAction<INewsLocationsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
