import { useState } from "react";
import { useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { MenuItem, Box } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import {
  SwitchUserActions,
  selectProfile,
  selectSwitchUser,
} from "@/store/redux";
import { apiInstance } from "@/store/configureStore";
import { useActions } from "@/utils/redux";
import { SWITCH_USER_PERMISSION } from "@/config/permissions";
import { AutocompleteAsync, Dialog, GradientButton } from "@/shared/components";

interface ISwitchUserProps {
  onClose: () => void;
}

export function SwitchUser(props: ISwitchUserProps) {
  const { onClose } = props;

  const { profile } = useSelector(selectProfile);
  const { original_user } = useSelector(selectSwitchUser);
  const { switchUser } =
    useActions<typeof SwitchUserActions>(SwitchUserActions);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const hasSwitchUser = (profile?.permissions || []).includes(
    SWITCH_USER_PERMISSION
  );

  const handleOnDialogClose = () => {
    setDialogOpen(false);
  };

  const handleOnGetOptionLabel = (option: any) => {
    if (!option) return;

    return `${option.user_name} (${option.email})`;
  };

  const handleOnMenuClick = () => {
    setDialogOpen(true);
    onClose();
  };

  const handleOnUserChange = ({ value }: any) => {
    setSelectedUser(value?.email);
  };

  const handleOnSubmit = () => {
    switchUser({
      user: selectedUser,
      original_user: profile?.user_name || "",
    });

    setDialogOpen(false);
  };

  return hasSwitchUser && !original_user ? (
    <>
      <MenuItem onClick={handleOnMenuClick}>
        <CachedIcon />
        <Box sx={{ ml: 1 }}>Switch user</Box>
      </MenuItem>

      <Dialog
        top
        fullWidth
        maxWidth="sm"
        open={dialogOpen}
        onClose={handleOnDialogClose}
        title="Select user"
        dialogContent={
          <AutocompleteAsync
            label="User"
            onChange={handleOnUserChange}
            getRequest={usersRequest}
            getOptionLabel={handleOnGetOptionLabel}
          />
        }
        actions={
          <GradientButton sx={{ minWidth: 275 }} onClick={handleOnSubmit}>
            Let's go
          </GradientButton>
        }
      />
    </>
  ) : null;
}

export const usersRequest = async (psearch: string) => {
  const response: AxiosResponse = await apiInstance.get(`/users`, {
    params: { psearch },
  });

  return response.data?.users;
};
