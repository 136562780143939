import {
  Route,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import {
  Profile,
  Signout,
  Page404,
  Page500,
  Faq,
  FaqArticle,
  NewsFeed,
  NewsArticle,
  MarketPlace,
  MarketPlaceBrand,
  Team,
  Teams,
  Checkout,
  TimeOff,
  MoodMonitoring,
  Timesheet,
  Referrals,
  Calendar,
  Surveys,
  MyDocuments,
  TaskReporting,
} from "@/pages";
import { Layout } from "@/components";
import { appConfig } from "@/config/appConfig";
import {
  CART,
  FAQ,
  MARKETPLACE,
  NEWS,
  TEAMS,
  TIMEOFF,
  MOOD,
  TIMESHEET,
  REFERRALS,
  CALENDAR,
  SURVEYS,
  MY_DOCUMENTS,
  TASK_REPORTING,
  ERROR_404,
  ERROR_500,
  LOGOUT,
} from "./consts";

const hasMarketplaceAvailable = appConfig.MARKETPLACE_ENABLE === "true";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={`${TASK_REPORTING}/:id`} element={<TaskReporting />} />

      <Route element={<Layout />}>
        <Route path="/" element={<Profile />} />

        <Route path={TEAMS} element={<Teams />} />
        <Route path={`${TEAMS}/:id`} element={<Team />} />

        <Route path={FAQ} element={<Faq />} />
        <Route path={`${FAQ}/:id`} element={<FaqArticle />} />

        <Route path={NEWS} element={<NewsFeed />} />
        <Route path={`${NEWS}/:id`} element={<NewsArticle />} />

        {hasMarketplaceAvailable ? (
          <>
            <Route path={CART} element={<Checkout />} />
            <Route path={MARKETPLACE} element={<MarketPlace />} />
            <Route path={`${MARKETPLACE}/:id`} element={<MarketPlaceBrand />} />
          </>
        ) : null}

        <Route path={TIMEOFF} element={<TimeOff />} />

        <Route path={MOOD} element={<MoodMonitoring />} />

        <Route path={TIMESHEET} element={<Timesheet />} />

        <Route path={REFERRALS} element={<Referrals />} />
        <Route path={`${REFERRALS}/:id`} element={<Referrals />} />

        <Route path={CALENDAR} element={<Calendar />} />

        <Route path={SURVEYS} element={<Surveys />} />

        <Route path={MY_DOCUMENTS} element={<MyDocuments />} />

        <Route path={LOGOUT} element={<Signout />} />

        <Route path={ERROR_404} element={<Page404 />} />
        <Route path={ERROR_500} element={<Page500 />} />

        <Route path="*" element={<Navigate replace to={ERROR_404} />} />
      </Route>
    </Route>
  )
);

export function AppRoutes() {
  return <RouterProvider router={router} />;
}
