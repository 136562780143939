import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IFaqArticleState } from "./reducers";

export const faqArticleSelector = (state: RootState): IFaqArticleState =>
  state.faqArticle;

export const selectFaqArticle = createSelector(
  faqArticleSelector,
  (faqArticleState: IFaqArticleState) => ({ ...faqArticleState })
);
