import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GradientButton } from "@/shared/components";
import {
  selectProfile,
  INewsCommentsItem,
  NewsCommentsActions,
  selectNewsComments,
  NewsAddCommentsActions,
  selectAddNewsComment,
  selectNewsArticle,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { AddComment } from "./AddComment";
import { CommentsList } from "./CommentsList";
import { CommentsWrapper, LoadMoreWrapp } from "./Comments.styled";

export function Comments() {
  const { id } = useParams();
  const { profile } = useSelector(selectProfile);
  const { article } = useSelector(selectNewsArticle);
  const {
    comments: newsComments,
    pending,
    plimit,
    poffset,
    ptotal,
  } = useSelector(selectNewsComments);
  const { comment, pending: addCommentPending } =
    useSelector(selectAddNewsComment);
  const { getNewsArticleComments } =
    useActions<typeof NewsCommentsActions>(NewsCommentsActions);
  const { postNewsComment } = useActions<typeof NewsAddCommentsActions>(
    NewsAddCommentsActions
  );

  const [tempNewComment, setTempNewComment] = useState({} as INewsCommentsItem);
  const [comments, setComments] = useState<INewsCommentsItem[]>(
    newsComments || []
  );

  const hasMoreButton = plimit + poffset < ptotal;

  const getComments = (params = {}) => {
    if (id) {
      getNewsArticleComments({
        id,
        plimit,
        poffset: 0,
        ...params,
      });
    }
  };

  const postComment = (comment: INewsCommentsItem) => {
    postNewsComment({
      id,
      parent_reply_id: comment.parent_reply_id,
      text: comment.text,
    });
  };

  const updateCommentId = (newId: number, created_at: any) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === tempNewComment.parent_id) {
        const updatedSubComments = (comment.sub_comments || []).map(
          (subComment) => {
            if (subComment.id === tempNewComment.id) {
              return {
                ...subComment,
                id: newId,
                created_at,
              };
            }

            return subComment;
          }
        );

        return {
          ...comment,
          sub_comments: updatedSubComments,
        };
      } else {
        if (comment.id === tempNewComment.id) {
          return {
            ...comment,
            id: newId,
            created_at,
          };
        }

        return comment;
      }
    });

    setTempNewComment({} as INewsCommentsItem);

    setComments(updatedComments);
  };

  const handleOnAddComment = (comment: INewsCommentsItem) => {
    setComments([...comments, comment]);

    setTempNewComment({ ...comment, id: +new Date() });

    postComment(comment);
  };

  const handleOnReplyComment = (newComment: INewsCommentsItem) => {
    const updatedComments = comments.map((comment: INewsCommentsItem) => {
      if (comment.id === newComment.parent_id) {
        return {
          ...comment,
          sub_comments: [...(comment.sub_comments || []), newComment],
        };
      }

      return comment;
    });

    setComments(updatedComments);

    setTempNewComment({ ...newComment, id: +new Date() });
    postComment(newComment);
  };

  const handleOnDeleteComment = (deletedComment: INewsCommentsItem) => {
    const updatedComments = deletedComment.parent_id
      ? comments.map((comment) => {
          const updatedSubComments = (comment.sub_comments || []).filter(
            (subComment) => deletedComment.id !== subComment.id
          );

          return {
            ...comment,
            sub_comments: updatedSubComments,
          };
        })
      : comments.filter((comment) => comment.id !== deletedComment.id);

    setComments(updatedComments);
  };

  const handleOnMore = () => {
    getComments({ poffset: poffset + plimit });
  };

  useEffect(() => {
    if (id) getComments({ poffset: 0 });
  }, [id]); //eslint-disable-line

  useEffect(() => {
    if (comment?.id) updateCommentId(comment?.id, comment?.created_at);
  }, [comment?.id]); //eslint-disable-line

  useEffect(() => {
    setComments(newsComments);
  }, [newsComments]);

  if (!profile?.id || !article?.id || (pending && !comments.length)) {
    return <></>;
  }

  return (
    <CommentsWrapper>
      <AddComment onSubmit={handleOnAddComment} />

      <CommentsList
        loading={addCommentPending}
        comments={comments}
        onReply={handleOnReplyComment}
        onDelete={handleOnDeleteComment}
      />

      {hasMoreButton ? (
        <LoadMoreWrapp>
          <GradientButton
            sx={{ minWidth: 265 }}
            disabled={pending}
            loading={pending}
            onClick={handleOnMore}
          >
            SHOW MORE COMMENTS
          </GradientButton>
        </LoadMoreWrapp>
      ) : null}
    </CommentsWrapper>
  );
}
