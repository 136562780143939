import { styled } from "@mui/material/styles";
import { Badge } from "@/shared/components";

export const BubbleStyled = styled(Badge)<{
  $width?: string;
  $height?: string;
}>(({ theme, $height, $width }) => ({
  borderRadius: "50%",
  width: $width || "148px",
  height: $height || "148px",

  [theme.breakpoints.down("sm")]: {
    width: $width || "132px",
    height: $height || "132px",
  },
}));
