import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { FaqRecentActions } from "@/store/redux";
import { IFaqArticleLike } from "./reducers";
import {
  postFaqArticleLike,
  postFaqArticleLikeError,
  postFaqArticleLikeSuccess,
} from "./slice";
import { postFaqArticleLike as postFaqArticleLikeApi } from "./api";

function* postFaqArticleLikeSaga(action: PayloadAction<number>) {
  try {
    const like: IFaqArticleLike = yield call(
      postFaqArticleLikeApi,
      action.payload
    );

    yield put(postFaqArticleLikeSuccess(like));

    yield put(FaqRecentActions.getFaqRecents());
  } catch (error) {
    yield put(postFaqArticleLikeError({ error }));
  }
}

function* faqArticleLikeWatcher() {
  yield takeLatest(postFaqArticleLike.type, postFaqArticleLikeSaga);
}

export default faqArticleLikeWatcher;
