import { ISurveysListItem } from "./reducers";

export function transformSurveys(surveys: Array<ISurveysListItem>) {
  return surveys.reduce(
    (
      acc: {
        actual: Array<ISurveysListItem>;
        finished: Array<ISurveysListItem>;
      },
      curr: ISurveysListItem
    ) => {
      const hasFinished = curr.state === "finished" || curr.status === "passed";

      return {
        ...acc,
        actual: hasFinished ? acc.actual : acc.actual.concat(curr),
        finished: hasFinished ? acc.finished.concat(curr) : acc.finished,
      };
    },
    {
      actual: [],
      finished: [],
    }
  );
}
