import { styled } from "@mui/material/styles";

export const FaqWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
}));

export const FaqContent = styled("div")(({ theme }) => ({
  flex: 1,
}));

export const FaqSidebar = styled("div")(({ theme }) => ({
  width: "270px",
  marginLeft: "120px",
}));
