import { styled } from "@mui/material/styles";

export const LikeButtonText = styled("span")<{ $comment?: boolean }>(
  ({ theme, $comment }) => {
    return $comment
      ? {
          fontFamily: "Druk",
          fontStyle: "italic",
          fontSize: "30px",
          fontWeight: 400,
          lineHeight: 1,
        }
      : {};
  }
);
