import { FormProvider, useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { GradientButton } from "@/shared/components";
import { PostQuestionsActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { htmlEncode } from "@/utils/helpers";
import { DepartmentField, Questions, Anonymous } from "./Fields";

export function AskQuestionForm() {
  const { postQuestions } =
    useActions<typeof PostQuestionsActions>(PostQuestionsActions);

  const methods = useForm({
    defaultValues: {
      department: "",
      questions: [{ text: "" }],
      anonymous: false,
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSubmit = (data: any) => {
    const transformedData = {
      ...data,
      questions: (data.questions || []).map((item: any) =>
        htmlEncode(item.text)
      ),
    };

    postQuestions(transformedData);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ my: 2 }}>
          <DepartmentField />
        </Box>

        <Questions />

        <Box sx={{ my: 1 }}>
          <Anonymous />
        </Box>

        <Box sx={{ mt: 2 }}>
          <GradientButton fullWidth disabled={!isValid} type="submit">
            SUBMIT QUESTION
          </GradientButton>
        </Box>
      </form>
    </FormProvider>
  );
}
