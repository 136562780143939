import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import {
  MyDocumentsActions,
  selectMyDocuments,
  IMyDocumentsItem,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { orderDocuments } from "../helpers";
import { MyDocumentsListItem } from "./MyDocumentsListItem";
import { IBreadcrumb } from "../MyDocuments";
import { EmptyFolder } from "./EmptyFolder";
import { HeaderTitle } from "./HeaderTitle";
import { TableHeadStyled } from "../MyDocuments.styled";

interface IMyDocumentsListProps {
  onSelectItem: (k: IBreadcrumb) => void;
}

export function MyDocumentsList(props: IMyDocumentsListProps) {
  const { onSelectItem } = props;

  const { documents, pending } = useSelector(selectMyDocuments);
  const { getMyDocuments } =
    useActions<typeof MyDocumentsActions>(MyDocumentsActions);

  const [files, setFiles] = useState(documents);
  const [orderByField, setOrderByField] = useState<"name" | "modifiedDate">(
    "name"
  );
  const [orderType, setOrderType] = useState<"asc" | "desc">("asc");

  const orderedDocs = orderDocuments({
    data: files,
    orderByField,
    type: orderType,
  });

  const handleOnSort = (name: "name" | "modifiedDate") => {
    setOrderByField(name);
    setOrderType((prevType) => (prevType === "asc" ? "desc" : "asc"));
  };

  const handleOnFileClick = (document: IMyDocumentsItem) => {
    const { items, name, go_path, type, properties, sharepoint_url } = document;

    if (type === "file") {
      window.open(
        properties?.LinkingUri || sharepoint_url,
        "_blank",
        "noopener,noreferrer"
      );

      return;
    }

    if (items?.length) {
      setFiles(items);
    } else {
      getMyDocuments({ path: go_path });

      onSelectItem({ title: name, url: go_path });
    }
  };

  useEffect(() => {
    setFiles(documents?.[0]?.items || documents);
  }, [documents]);

  if (!documents?.length && pending) {
    return <>Loading ...</>;
  }

  return (
    <Table>
      <TableHeadStyled>
        <TableRow>
          <TableCell>
            <HeaderTitle
              title="Name"
              name="name"
              orderByField={orderByField}
              sortType={orderType}
              onSort={handleOnSort}
            />
          </TableCell>
          <TableCell>
            <HeaderTitle
              title="Modified"
              name="modifiedDate"
              orderByField={orderByField}
              sortType={orderType}
              onSort={handleOnSort}
            />
          </TableCell>
        </TableRow>
      </TableHeadStyled>
      <TableBody>
        {!orderedDocs?.length ? (
          <EmptyFolder loading={pending} />
        ) : (
          orderedDocs.map((file) => (
            <MyDocumentsListItem
              key={file?.sharepoint_url}
              file={file}
              loading={pending}
              onClick={handleOnFileClick}
            />
          ))
        )}
      </TableBody>
    </Table>
  );
}
