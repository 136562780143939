import { TableRow, TableCell } from "@mui/material";
import { getDecimal } from "../helpers";

export function Total(props: any) {
  const { total } = props;

  return (
    <TableRow>
      <TableCell>Total</TableCell>

      {total.map((item: { [k: string | number]: number }, idx: number) => {
        const key = Object.keys(item)[0];
        const value = Object.values(item)[0] || 0;
        const decimalValue = getDecimal(value);

        return <TableCell key={`${key}-${idx}`}>{decimalValue}</TableCell>;
      })}
    </TableRow>
  );
}
