import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectPostTimeOff, PostTimeOffActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { PopupMessage } from "@/shared/components";
import { MessageDesc } from "./TimeOffRequest.styled";

export function TimeOffRequestMessage() {
  const [showMessage, setShowMessage] = useState(false);
  const { error_message } = useSelector(selectPostTimeOff);
  const { resetPostTimeOff } =
    useActions<typeof PostTimeOffActions>(PostTimeOffActions);

  const hasError = !!error_message;
  const messageVariant = hasError ? "error" : "success";

  const handleOnClose = () => {
    setShowMessage(false);
  };

  const handleOnExit = () => {
    resetPostTimeOff();
  };

  useEffect(() => {
    if (error_message) {
      setShowMessage(true);
    }
  }, [error_message]); //eslint-disable-line

  useEffect(() => {
    return () => {
      resetPostTimeOff();
    };
  }, []); //eslint-disable-line

  return (
    <PopupMessage
      variant={messageVariant}
      open={showMessage}
      message={<MessageDesc>{error_message}</MessageDesc>}
      onClose={handleOnClose}
      onExit={handleOnExit}
    />
  );
}
