import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Dialog } from "@/shared/components";
import { PostMoodActions, selectPostMood, selectProfile } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { useDialog } from "@/utils/hooks";
import { MoodMonitoringSmiles } from "./MoodMonitoringSmiles";
import { MoodMonitoringSuccess } from "./MoodMonitoringSuccess";
import { MoodMonitoringError } from "./MoodMonitoringError";

export function MoodMonitoringDialog() {
  const { profile } = useSelector(selectProfile);
  const { hasPosted } = useSelector(selectPostMood);
  const { postMood, resetPostMood } =
    useActions<typeof PostMoodActions>(PostMoodActions);
  const [openDialog, onDialogOpen, onDialogClose] = useDialog();

  const handleOnSuccess = () => {
    onDialogClose();

    setTimeout(resetPostMood, 500);
  };

  const handleOnClose = () => {
    onDialogClose();

    postMood({ score: 0 });

    setTimeout(resetPostMood, 500);
  };

  const content = hasPosted ? (
    <MoodMonitoringSuccess onSuccess={handleOnSuccess} />
  ) : (
    <>
      <Box sx={{ fontWeight: 700, textAlign: "center", mb: 3 }}>
        We're all ears! Click below to share your mood and let us bring a smile
        to your face. Don't worry, we won't tell anyone if you're feeling a
        little bit goofy today 😉
      </Box>

      <MoodMonitoringSmiles />
    </>
  );

  useEffect(() => {
    if (profile && !profile?.hasMood) onDialogOpen();
  }, [profile]); //eslint-disable-line

  return (
    <>
      <Dialog
        top
        fullWidth
        maxWidth="xsm"
        open={openDialog}
        onClose={handleOnClose}
        title={hasPosted ? "THANKS FOR SHARING!" : "HOW IS YOUR MOOD TODAY?"}
        dialogContent={content}
      />

      <MoodMonitoringError />
    </>
  );
}
