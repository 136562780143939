import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "PostReferrals",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  postReferrals,
  postReferralsError,
  postReferralsSuccess,
  resetPostReferrals,
  resetPostReferralsError,
  resetPostReferralsSuccess,
} = slice.actions;

reducerRegistry.register("postReferrals", reducer);
