import { format, subMonths } from "date-fns";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  NewsSidebar,
  NewsSidebarSection,
  NewsSidebarContent,
  Newcomers,
  HotTopics,
  PopularAuthors,
} from "@/components";

export function NewsArticleSidebar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const prevMonth = format(subMonths(new Date(), 1), "MMMM");

  if (isMobile) {
    return <></>;
  }

  return (
    <NewsSidebar>
      <NewsSidebarContent>
        <NewsSidebarSection title="Hot Topics" content={<HotTopics />} />

        <NewsSidebarSection
          title="Popular Authors"
          content={<PopularAuthors />}
        />

        <NewsSidebarSection
          title={`Newcomers in ${prevMonth}`}
          content={<Newcomers />}
        />
      </NewsSidebarContent>
    </NewsSidebar>
  );
}
