import { SxProps } from "@mui/material";
import { Filters, FilterControl, Select } from "@/shared/components";

interface INewsArticleFiltersProps {
  value: string;
  langs: { title: string; value: string }[];
  loading?: boolean;
  onChange: (k: any) => void;
  sx: SxProps;
}

export function NewsArticleFilter(props: INewsArticleFiltersProps) {
  const { value, langs, loading, onChange, ...restProps } = props;

  const handleOnChange = (e: any) => {
    onChange(e.target.value);
  };

  if (langs.length < 2) return <></>;

  return (
    <Filters {...restProps}>
      <FilterControl
        label="Language:"
        control={
          <Select
            name="plang"
            disabled={loading}
            options={langs}
            value={value}
            onChange={handleOnChange}
          />
        }
      />
    </Filters>
  );
}
