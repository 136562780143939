import { styled } from "@mui/material/styles";
import { Collapse } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  "&+&": {
    marginTop: theme.spacing(2),
  },
}));

export const Title = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  cursor: "pointer",
}));

export const Icon = styled("div")(({ theme }) => ({}));

export const Content = styled(Collapse)(({ theme }) => ({}));
