import { ReactNode } from "react";
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Title, Description } from "@/shared/components";
import { CloseButton, Actions } from "./Dialog.styled";

interface IDialogProps extends DialogProps {
  top?: boolean;
  title?: string | undefined;
  dialogContent?: ReactNode;
  actions?: ReactNode;
  open: boolean;
  onClose: () => void;
}

export function Dialog(props: IDialogProps) {
  const { open, onClose, top, title, dialogContent, actions, ...restProps } =
    props;

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("smd"));

  const dialogVerticalPosition = top
    ? {
        sx: {
          "& .MuiDialog-container": {
            alignItems: "start",
          },
        },
      }
    : {};

  return (
    <MuiDialog
      {...restProps}
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      {...dialogVerticalPosition}
    >
      <DialogTitle>
        <Title fontWeight="700" fontStyle="normal">
          {title}
        </Title>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </DialogTitle>

      {dialogContent ? (
        <DialogContent>
          <Description>{dialogContent}</Description>
        </DialogContent>
      ) : null}

      {actions ? <Actions>{actions}</Actions> : null}
    </MuiDialog>
  );
}
