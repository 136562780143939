import { useSelector } from "react-redux";
import { Skeleton, useTheme, useMediaQuery } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useActions } from "@/utils/redux";
import { CartActions, ICartProduct, selectCart } from "@/store/redux";
import {
  Product,
  ProductContent,
  ProductDesc,
  ProductImage,
  ProductOptionKey,
  ProductOptionValue,
  ProductOptions,
  ProductOption,
  ProductPrice,
  ProductTitle,
  FlexRow,
  CloseButton,
} from "./List.styled";

export function ListItem(props: ICartProduct) {
  const { description, id, images, price, quantity, size, title } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { pending } = useSelector(selectCart);
  const { deleteCart } = useActions<typeof CartActions>(CartActions);

  const handleOnDelete = () => {
    deleteCart(id);
  };

  const ProductContentSection = () =>
    pending ? (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    ) : (
      <>
        <ProductOptions>
          <ProductOption>
            <ProductOptionKey>Size:</ProductOptionKey>
            <ProductOptionValue>{size}</ProductOptionValue>
          </ProductOption>
          <ProductOption>
            <ProductOptionKey>Quantity:</ProductOptionKey>
            <ProductOptionValue>{quantity}</ProductOptionValue>
          </ProductOption>
        </ProductOptions>
        <ProductDesc dangerouslySetInnerHTML={{ __html: description }} />
      </>
    );

  return (
    <Product>
      <FlexRow>
        <ProductImage>
          {pending ? (
            <Skeleton sx={{ aspectRatio: "1/1", transform: "none" }} />
          ) : (
            <img src={images} alt={title} />
          )}
        </ProductImage>
        <ProductContent>
          {pending ? (
            <>
              <Skeleton width="75%" />
              <Skeleton width={75} sx={{ mb: 2 }} />
            </>
          ) : (
            <>
              <ProductTitle>
                <CloseButton onClick={handleOnDelete}>
                  <CancelIcon />
                </CloseButton>
                {title}
              </ProductTitle>
              <ProductPrice>$ {price}</ProductPrice>
            </>
          )}
          {!isMobile ? <ProductContentSection /> : null}
        </ProductContent>
      </FlexRow>

      {isMobile ? (
        <ProductContent>
          <ProductContentSection />
        </ProductContent>
      ) : null}
    </Product>
  );
}
