import { PayloadAction } from "@reduxjs/toolkit";

export interface ITeamMember {
  email: string;
  name: string;
  avatar: string;
  position: string;
  job_type: string; // full-time, part-time, etc.
  birthday: string;
  timeoffs: {
    start_date: string;
    end_date: string;
  };
}
export interface ITeam {
  id: number;
  logo: string;
  title: string;
  domain: string;
  description: string;
  website_url: string;
  members_count: number;
  managers: ITeamMember[];
}

export interface ITeamState {
  pending: boolean;
  error: any;
  team: ITeam;
}

export interface ITeamStateError {
  error: any;
}

export const initialState: ITeamState = {
  pending: false,
  error: null,
  team: {} as ITeam,
};

export const reducers = {
  getTeam: (
    state: ITeamState,
    action: PayloadAction<number | string | undefined>
  ) => {
    return {
      ...state,
      team: {} as ITeam,
      pending: true,
      error: "",
    };
  },
  getTeamSuccess: (state: ITeamState, action: PayloadAction<ITeam>) => {
    return {
      ...state,
      pending: false,
      team: action.payload,
    };
  },
  getTeamError: (
    state: ITeamState,
    action: PayloadAction<ITeamStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
