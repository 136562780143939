import { ReactNode } from "react";
import { Dialog } from "@/shared/components";

interface IEventDialogProps {
  open: boolean;
  title?: string | undefined;
  content?: ReactNode;
  actions?: ReactNode;
  onClose?: () => void;
}

export function EventDialog(props: IEventDialogProps) {
  const { title, content, open, onClose } = props;

  const handleOnClose = () => {
    if (onClose && onClose instanceof Function) {
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xsm"
      title={title}
      dialogContent={content}
      open={open}
      onClose={handleOnClose}
    />
  );
}
