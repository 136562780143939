import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { dateFnsLocalizer } from "react-big-calendar";
import {
  format,
  parse,
  getDay,
  addDays,
  startOfWeek as dateFnsStartOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import CelebrationIcon from "@mui/icons-material/Celebration";
import {
  TimeOffCalendarActions,
  selectTimeOffCalendar,
  selectSwitchUser,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { useDevice } from "@/utils/hooks";
import { typesMap, statusesMap } from "../consts";
import { CalendarToolbar } from "./CalendarToolbar";
import { TimeOffCalendarFilters, IFilters } from "./TimeOffCalendarFilters";
import {
  DateTitle,
  CalendarWrapper,
  CalendarEvent,
  HolidayEvent,
  HolidayEventTitle,
  LightTooltip,
} from "./TimeOffCalendar.styled";
import "react-big-calendar/lib/css/react-big-calendar.css";

const locales = {
  "en-US": require("date-fns"),
};
const startOfWeek = () => dateFnsStartOfWeek(new Date(), { weekStartsOn: 1 });

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export function TimeOffCalendar() {
  const isMobile = useDevice("down", "md");

  const { user } = useSelector(selectSwitchUser);
  const { dates } = useSelector(selectTimeOffCalendar);
  const { getTimeOffCalendar } = useActions<typeof TimeOffCalendarActions>(
    TimeOffCalendarActions
  );

  const [filters, setFilters] = useState<IFilters>({
    ptype: "-1",
    pstatus: "-1",
    pmonth: new Date(),
  });

  const getCalendarRequests = (params: IFilters) => {
    const formatString = "dd/MM/yyyy";
    const startMonthDate = startOfMonth(new Date(params.pmonth));
    const endMonthDate = endOfMonth(new Date(params.pmonth));

    const start_date = format(startMonthDate, formatString);
    const end_date = format(endMonthDate, formatString);

    const requestParams: {
      pstatus: string;
      ptype: string;
      start_date: string;
      end_date: string;
    } = { ...params, start_date, end_date };

    getTimeOffCalendar(requestParams);
  };

  const handleOnCalendarChange = (newDate: Date) => {
    const updatedFilters = {
      ...filters,
      pmonth: newDate,
    };

    setFilters(updatedFilters);
    getCalendarRequests(updatedFilters);
  };

  const handleOnFiltersChange = (e: any) => {
    const updatedFilters = {
      ...filters,
      [e.target.name]: e.target.value,
    };

    setFilters(updatedFilters);
    getCalendarRequests(updatedFilters);
  };

  const customDayPropGetter = () => {
    return isMobile
      ? {
          style: {
            border: "none",
            "text-align": "center",
          },
        }
      : {};
  };

  const monthEvent = ({ event }: any) => {
    return isMobile ? null : event.title;
  };

  const eventWrapperComponent = ({ event }: any) => {
    const { type, status, start_date, end_date, title } = event;
    const statusText =
      type === "holiday" ? (
        <HolidayEvent>
          <CelebrationIcon />
          <HolidayEventTitle>{title}</HolidayEventTitle>
        </HolidayEvent>
      ) : status !== 2 ? (
        <>({statusesMap[status]})</>
      ) : null;
    const dateFormat = "dd/MM/yyyy";
    const startDate = format(new Date(start_date), dateFormat);
    const endDate = format(new Date(end_date), dateFormat);
    const tooltip = (
      <div>
        <div>{typesMap[type] || title}</div>
        <div>
          {startDate} - {endDate}
        </div>
      </div>
    );

    return (
      <LightTooltip title={tooltip}>
        <CalendarEvent $type={type}>
          {isMobile ? null : (
            <>
              {typesMap[type]} {statusText}
            </>
          )}
        </CalendarEvent>
      </LightTooltip>
    );
  };

  const calendarProps = {
    formats: {
      weekdayFormat: (date: Date) => format(date, "eee"),
      dateFormat: (date: Date) => format(date, "d"),
    },
    components: {
      toolbar: CalendarToolbar,
      eventWrapper: eventWrapperComponent,

      month: {
        dateHeader: ({ label }: any) => <DateTitle>{label}</DateTitle>,
        event: monthEvent,
      },
    },
    startAccessor: (event: any) => {
      return new Date(event?.start_date);
    },
    endAccessor: (event: any) => {
      return addDays(new Date(event?.end_date), 1);
    },
    views: {
      month: true,
    },
  };

  useEffect(() => {
    getCalendarRequests(filters);
  }, [user]); //eslint-disable-line

  return (
    <>
      <TimeOffCalendarFilters
        filters={filters}
        onChange={handleOnFiltersChange}
      />

      <CalendarWrapper
        {...calendarProps}
        onNavigate={handleOnCalendarChange}
        defaultView="month"
        events={dates}
        localizer={localizer}
        dayPropGetter={customDayPropGetter}
      />
    </>
  );
}
