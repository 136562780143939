import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ITimeOffCalendarState } from "./reducers";

export const timeOffCalendarSelector = (
  state: RootState
): ITimeOffCalendarState => state.timeOffCalendar;

export const selectTimeOffCalendar = createSelector(
  timeOffCalendarSelector,
  (timeOffCalendarState: ITimeOffCalendarState) => ({ ...timeOffCalendarState })
);
