import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ITimeOffAvailableDaysState } from "./reducers";

export const timeOffAvailableDaysSelector = (
  state: RootState
): ITimeOffAvailableDaysState => state.timeOffAvailableDays;

export const selectTimeOffAvailableDays = createSelector(
  timeOffAvailableDaysSelector,
  (timeOffAvailableDaysState: ITimeOffAvailableDaysState) => ({
    ...timeOffAvailableDaysState,
  })
);
