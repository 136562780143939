import { useTheme, useMediaQuery, Breakpoint } from "@mui/material";

interface IUseDevice {
  fn: "up" | "down" | "only" | "between";
  size: Breakpoint;
}

export function useDevice(fn: IUseDevice["fn"], size: IUseDevice["size"]) {
  const theme = useTheme();
  const hasEqual = useMediaQuery((theme.breakpoints as any)[fn](size));

  return hasEqual;
}
