import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IErrorsState } from "./reducers";

export const errorsSelector = (state: RootState): IErrorsState => state.errors;

export const selectErrors = createSelector(
  errorsSelector,
  (errorsState: IErrorsState) => errorsState.statusCode
);
