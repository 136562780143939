import { PayloadAction } from "@reduxjs/toolkit";

export interface INewsComment {
  author: {
    id?: number | null | undefined;
    avatar: string | null;
    name: string;
  };
  created_at: string;
  text: string;
  id?: number | null;
  likes?: number;
  has_like?: boolean;
  parent_id?: number | null;
}

export interface INewsSubCommentsItem extends INewsComment {
  parent_reply_id?: number | null | undefined;
  parent_reply_name?: string | null | undefined;
}

export interface INewsCommentsItem extends INewsComment {
  parent_reply_id?: number | null | undefined;
  parent_reply_name?: string | null | undefined;
  sub_comments?: INewsSubCommentsItem[];
}

export interface INewsCommentsState {
  pending: boolean;
  error: any;
  plimit: number;
  poffset: number;
  ptotal: number;
  comments: INewsCommentsItem[];
}

export interface INewsCommentsStatePending {
  poffset?: number;
  plimit?: number;
  id: string | number;
}

export interface INewsCommentsStateSuccess {
  ptotal: number;
  plimit: number;
  poffset: number;
  comments: INewsCommentsItem[];
}

export interface INewsCommentsStateError {
  error: any;
}

export const initialState: INewsCommentsState = {
  pending: false,
  error: "",
  plimit: 10,
  poffset: 0,
  ptotal: 0,
  comments: [],
};

export const reducers = {
  getNewsArticleComments: (
    state: INewsCommentsState,
    action: PayloadAction<INewsCommentsStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: "",
      ...(action.payload || {}),
    };
  },
  getNewsArticleCommentsSuccess: (
    state: INewsCommentsState,
    action: PayloadAction<INewsCommentsStateSuccess>
  ) => {
    const { poffset, comments, ...restOptions } = action?.payload;

    return {
      ...state,
      ...restOptions,
      pending: false,
      comments: poffset ? [...(state.comments || []), ...comments] : comments,
    };
  },
  getNewsArticleCommentsError: (
    state: INewsCommentsState,
    action: PayloadAction<INewsCommentsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
