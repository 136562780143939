import { PayloadAction } from "@reduxjs/toolkit";

export interface INewcomersItem {
  avatar: string;
  name: string;
  position: string;
  email: string;
}

export interface INewcomersState {
  pending: boolean;
  error: any;
  plimit: number;
  poffset: number;
  ptotal: number;
  newcomers: INewcomersItem[];
}

export interface INewcomersStatePending {
  poffset?: number;
  plimit?: number;
}

export interface INewcomersStateSuccess {
  plimit: number;
  poffset: number;
  newcomers: INewcomersItem[];
}

export interface INewcomersStateError {
  error: any;
}

export const initialState: INewcomersState = {
  pending: false,
  error: "",
  plimit: 10,
  poffset: 0,
  ptotal: 0,
  newcomers: [],
};

export const reducers = {
  getNewcomers: (
    state: INewcomersState,
    action: PayloadAction<INewcomersStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: "",
      ...(action.payload || {}),
    };
  },
  getNewcomersSuccess: (
    state: INewcomersState,
    action: PayloadAction<INewcomersStateSuccess>
  ) => {
    const { poffset, newcomers, ...restOptions } = action?.payload;

    return {
      ...state,
      ...restOptions,
      pending: false,
      newcomers: poffset ? [...state.newcomers, ...newcomers] : newcomers,
    };
  },
  getNewcomersError: (
    state: INewcomersState,
    action: PayloadAction<INewcomersStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
