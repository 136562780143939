import { PropsWithChildren } from "react";
import { FormProvider, useForm } from "react-hook-form";

interface IFormProps extends PropsWithChildren {
  defaultValues: any;
  onSubmit: (data: any) => void;
}

export function Form(props: IFormProps) {
  const { defaultValues, onSubmit, children } = props;

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
}
