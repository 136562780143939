import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { bluePink, pinkViolet, yellowBlue, violetBlue } from "@/theme";
import { Title, Bubble } from "@/shared/components";

export const DotTitle = styled(Title)(({ theme }) => ({
  fontWeight: 500,

  [theme.breakpoints.down("md")]: {
    fontSize: "1.75rem",
  },
}));

export const ItemTitle = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: "900",
  textAlign: "center",
  marginTop: theme.spacing(1),

  [theme.breakpoints.down("md")]: {
    fontSize: "0.875rem",
  },
}));

export const LinkButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));

export const LinkButtonExternal = styled("a")(({ theme }) => ({
  textDecoration: "none",
}));

export const BubbleStyled = styled(Bubble)(({ theme }) => ({})); // magic for styled-components fro GridStyled

export const GridStyled = styled(Grid)({
  "&:nth-of-type(4n+1)": {
    [BubbleStyled as any]: {
      background: yellowBlue,
    },
  },

  "&:nth-of-type(4n+2)": {
    [BubbleStyled as any]: {
      background: bluePink,
    },
  },

  "&:nth-of-type(4n+3)": {
    [BubbleStyled as any]: {
      background: pinkViolet,
    },
  },

  "&:nth-of-type(4n+4)": {
    [BubbleStyled as any]: {
      background: violetBlue,
    },
  },
});
