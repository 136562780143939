import { styled } from "@mui/material/styles";

export const RulesWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(3, 0, 6),
}));

export const RulesDesc = styled("div")(({ theme }) => ({
  margin: theme.spacing(4, 0),

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

export const PointsWrapper = styled("div")(({ theme }) => ({}));

export const PointWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "0 0 auto",
  width: 315,
  flexDirection: "column",
  padding: theme.spacing(8, 0, 0, 8),
  gap: theme.spacing(1),
  position: "relative",

  "&:before": {
    content: "''",
    display: "block",
    position: "absolute",
    height: "94px",
    width: "94px",
    borderRadius: "50%",
    background: "rgba(222, 255, 43, 0.6)",
    filter: "blur(15px)",
    zIndex: -1,
    top: 30,
    left: 30,
  },

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const PointDesc = styled("div")(({ theme }) => ({
  fontSize: "1.125rem",
  fontWeight: 700,
  maxWidth: 240,
  marginTop: theme.spacing(0.5),

  [theme.breakpoints.down("md")]: {
    maxWidth: "90%",
  },
}));

export const ProjectsWrapper = styled("div")(({ theme }) => ({}));

export const ProjectsHeading = styled("div")(({ theme }) => ({
  background: "#f9f9f9",
  color: theme.palette.darkGrey.main,
  padding: theme.spacing(0.5, 1.5),
  borderBottom: "1px solid #e6e6e6",
}));

export const SummaryWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(6, 0),
}));

export const SummaryTitle = styled("div")(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  marginBottom: theme.spacing(2),
}));

export const SummarySubTitle = styled("div")(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1.5),
}));

export const MessageWrapper = styled("div")(({ theme }) => ({
  background: theme.palette.pinkViolet.main,
  padding: theme.spacing(0.25),
  margin: theme.spacing(2, 0, 3),
}));

export const MessageBg = styled("div")(({ theme }) => ({
  background: theme.modeColors({
    dark: theme.palette.primary.main,
    light: theme.palette.common.white,
  }),
  padding: theme.spacing(2, 3),
}));

export const ErrorMessageWrapper = styled("div")(({ theme }) => ({
  background: theme.palette.yellowPink.main,
  padding: theme.spacing(0.25),
  margin: theme.spacing(2, 0, 3),
}));

export const SummaryBorder = styled("div")(({ theme }) => ({
  border: `1px solid #e6e6e6`,
  padding: theme.spacing(2),
}));
