import { useSelector } from "react-redux";
import { Container, Grid, List } from "@mui/material";
import { selectProfile } from "@/store/redux";
import {
  IMenuItem,
  firstNavigation,
  secondNavigation,
  externalLinks,
} from "@/config/footerNavigation";
import { AskQuestion } from "@/components";
import { SocialNetworks } from "@/shared/components";
import {
  Wrapper,
  ListItemStyled,
  Content,
  CopyWrapper,
  MenusWrapper,
  RouteLink,
  ExternalLink,
} from "./Footer.styled";

function generateMenu(list: IMenuItem[]) {
  return (list || []).map(({ link, title, to }: IMenuItem) => (
    <ListItemStyled key={title} disableGutters>
      {link ? (
        <ExternalLink href={to} target="_blank" rel="noopener">
          {title}
        </ExternalLink>
      ) : (
        <RouteLink to={to}>{title}</RouteLink>
      )}
    </ListItemStyled>
  ));
}

export function Footer() {
  const { profile } = useSelector(selectProfile);

  const copyDate = new Date().getFullYear();
  const hasFirstNavigation = firstNavigation.length;
  const hasSecondNavigation = secondNavigation.length;
  const hasUser = profile?.id;

  return (
    <Wrapper>
      <Container>
        <Content>
          <MenusWrapper>
            {hasUser ? (
              <Grid container spacing={{ xs: 0, sm: 8 }}>
                {hasFirstNavigation ? (
                  <Grid item xs={12} sm="auto">
                    <List dense>{generateMenu(firstNavigation)}</List>
                  </Grid>
                ) : null}

                {hasSecondNavigation ? (
                  <Grid item xs={12} sm="auto">
                    <List dense>
                      {generateMenu(secondNavigation)}

                      <AskQuestion>
                        <ListItemStyled disableGutters>
                          <ExternalLink>Ask a question</ExternalLink>
                        </ListItemStyled>
                      </AskQuestion>
                    </List>
                  </Grid>
                ) : null}
              </Grid>
            ) : (
              <Grid container spacing={{ xs: 0, sm: 8 }}>
                <Grid item xs={12} sm="auto">
                  <List dense>{generateMenu(externalLinks)}</List>
                </Grid>
              </Grid>
            )}
          </MenusWrapper>

          <CopyWrapper>&copy; {copyDate} Brightgrove</CopyWrapper>

          <SocialNetworks />
        </Content>
      </Container>
    </Wrapper>
  );
}
