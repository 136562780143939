import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IReferralsFiltersState } from "./reducers";

export const referralsFiltersSelector = (
  state: RootState
): IReferralsFiltersState => state.referralsFilters;

export const selectReferralsFilters = createSelector(
  referralsFiltersSelector,
  (referralsFiltersState: IReferralsFiltersState) => ({
    ...referralsFiltersState,
  })
);
