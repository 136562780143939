import { styled } from "@mui/material/styles";

export const PopularAuthorsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}));

export const PopularAuthorWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  gap: theme.spacing(2),
}));

export const PopularAuthorAvatar = styled("div")(({ theme }) => ({}));
export const PopularAuthorContent = styled("div")(({ theme }) => ({
  flex: 1,
}));

export const PopularAuthorActions = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));

export const PopularAuthorName = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 900,
  marginBottom: theme.spacing(2),
}));
