import { PayloadAction } from "@reduxjs/toolkit";

export interface IReferralsState {
  pending: boolean;
  error: any;
  candidates_referred: number;
  earning_next_month: number;
  total_bonuses: number;
  my_candidates: number;
}

export interface IReferralsStateSuccess {
  candidates_referred: number;
  earning_next_month: number;
  total_bonuses: number;
  my_candidates: number;
}

export interface IReferralsStateError {
  error: any;
}

export const initialState: IReferralsState = {
  pending: false,
  error: null,
  candidates_referred: 0,
  earning_next_month: 0,
  total_bonuses: 0,
  my_candidates: 0,
};

export const reducers = {
  getReferrals: (state: IReferralsState) => {
    return {
      ...state,
      candidates_referred: 0,
      earning_next_month: 0,
      total_bonuses: 0,
      my_candidates: 0,
      pending: true,
      error: "",
    };
  },
  getReferralsSuccess: (
    state: IReferralsState,
    action: PayloadAction<IReferralsStateSuccess>
  ) => {
    const {
      candidates_referred,
      earning_next_month,
      total_bonuses,
      my_candidates,
    } = action?.payload || {};

    return {
      ...state,
      pending: false,
      candidates_referred: candidates_referred || 0,
      earning_next_month: earning_next_month || 0,
      total_bonuses: total_bonuses || 0,
      my_candidates: my_candidates || 0,
    };
  },
  getReferralsError: (
    state: IReferralsState,
    action: PayloadAction<IReferralsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
