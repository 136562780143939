import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ReferralsActions, ReferralsCandidatesActions } from "@/store/redux";
import {
  IPostReferralsStateSuccess,
  IPostReferralsStatePending,
} from "./reducers";
import {
  postReferrals,
  postReferralsError,
  postReferralsSuccess,
  resetPostReferrals,
  resetPostReferralsError,
  resetPostReferralsSuccess,
} from "./slice";
import { postReferralsRequest, postReferralsCvRequest } from "./api";

function* postReferralsSaga(action: PayloadAction<IPostReferralsStatePending>) {
  try {
    const { cv, fileName, ...restPayload } = action.payload;

    const postReferrals: IPostReferralsStateSuccess = yield call(
      postReferralsRequest,
      restPayload
    );

    if (postReferrals.id && cv?.has("cv") && !!fileName) {
      yield call(postReferralsCvRequest, {
        cv,
        fileName,
        referralId: postReferrals.id,
      });
    }

    yield put(postReferralsSuccess(postReferrals));
    yield put(ReferralsActions.getReferrals());
    yield put(ReferralsCandidatesActions.getReferralsCandidates());
  } catch (error) {
    yield put(postReferralsError({ error }));
  }
}

function* resetPostReferralsSaga() {
  try {
    yield put(resetPostReferralsSuccess());
  } catch (error) {
    yield put(resetPostReferralsError());
  }
}

function* PostReferralsWatcher() {
  yield takeLatest(postReferrals.type, postReferralsSaga);
  yield takeLatest(resetPostReferrals.type, resetPostReferralsSaga);
}

export default PostReferralsWatcher;
