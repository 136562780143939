import { useState } from "react";
import { addMonths } from "date-fns";

export function useChartToolbar() {
  const [, setDiff] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const getSelectedDate = (newDiff: number) => {
    const date = addMonths(new Date(), newDiff);

    setSelectedDate(date);
  };

  const getNavigationType = (type: string) => {
    const types: any = {
      PREV: -1,
      NEXT: 1,
      TODAY: 0,
    };

    return types[type];
  };

  const onDateChange = (type: string) => {
    const getNewDiff = (prevDiff: number) => {
      const selectedDiff = getNavigationType(type);
      const newDiff = selectedDiff ? prevDiff + selectedDiff : 0;

      getSelectedDate(newDiff);

      return newDiff;
    };

    setDiff(getNewDiff);
  };

  return { selectedDate, onDateChange };
}
