import { PayloadAction } from "@reduxjs/toolkit";

export interface IErrorsState {
  statusCode: number;
}

export const initialState: IErrorsState = {
  statusCode: 0,
};

export const reducers = {
  addError: (
    state: IErrorsState,
    action: PayloadAction<{ statusCode: IErrorsState["statusCode"] }>
  ) => {
    return {
      ...state,
      statusCode: action?.payload?.statusCode,
    };
  },
  removeError: (state: IErrorsState) => {
    return {
      ...state,
      statusCode: 0,
    };
  },
};
