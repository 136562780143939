import { styled } from "@mui/material/styles";
import { Logo, GradientButton } from "@/shared/components";

export const SigninContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  color: theme.palette.common.black,
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  background: theme.palette.common.white,
}));

export const SigninHeader = styled("div")(({ theme }) => ({
  padding: theme.spacing(9, 2, 3),
  width: "100%",
}));

export const SigninContent = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  fontSize: "1.375rem",
  width: "100%",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.125rem",
  },
}));

export const SigninForm = styled("div")(({ theme }) => ({
  maxWidth: 700,
}));

export const SigninFooter = styled("div")(({ theme }) => ({
  padding: theme.spacing(3, 2),
  fontSize: "1.25rem",
  width: "100%",
}));

export const SigninButtonStyled = styled(GradientButton)(({ theme }) => ({
  fontSize: "1.125rem",
  padding: theme.spacing(1.5, 6),
  marginTop: theme.spacing(5),
  width: "40%",

  [theme.breakpoints.down("sm")]: {
    fontSize: theme.spacing(2),
    padding: theme.spacing(1.5, 2),
    width: "100%",
  },
}));

export const SigninLogo = styled(Logo)(({ theme }) => ({
  fontSize: theme.spacing(7.25),
}));
