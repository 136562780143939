import Slider from "react-slick";
import { styled } from "@mui/material/styles";
import { Bubble } from "@/shared/components";

interface INavigatonButton {
  $next?: boolean;
  $noArrowsMargin?: boolean;
}

export const NavigatonButtonWrapp = styled("span")<INavigatonButton>(
  ({ theme, $next, $noArrowsMargin }) => ({
    top: "calc(50% - 22px)",
    display: "inline-flex",
    position: "absolute",
    zIndex: 1,
    cursor: "pointer",

    ...($next
      ? {
          right: $noArrowsMargin ? 0 : theme.spacing(2),
        }
      : {
          left: $noArrowsMargin ? 0 : theme.spacing(2),
        }),

    [theme.breakpoints.down("smd")]: {
      top: "calc(50% - 16px)",

      ...($next
        ? {
            right: $noArrowsMargin ? 0 : theme.spacing(1),
          }
        : {
            left: $noArrowsMargin ? 0 : theme.spacing(1),
          }),
    },
  })
);

export const NavigatonButton = styled(Bubble)(({ theme }) => ({
  width: 44,
  height: 44,

  [theme.breakpoints.down("smd")]: {
    width: 32,
    height: 32,
  },
}));

export const DotsButtons = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  left: 0,
  zIndex: 1,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(0.5),
}));

export const DotButton = styled("span")<{ $isActive: boolean }>(
  ({ theme, $isActive }) => ({
    background: $isActive
      ? theme.palette.common.black
      : theme.palette.common.white,
    border: "1px solid #000",
    width: theme.spacing(1),
    height: theme.spacing(1),
    cursor: "pointer",
  })
);

export const SliderWrapper = styled(Slider)(({ theme }) => ({
  "& .slick-list> div": {
    marginLeft: 0,
  },
}));
