import {
  MessageDesc,
  MessageTitle,
  MessageWrapp,
  MessagetButtonWrapp,
} from "./Dialog.styled";

import { ReactNode } from "react";

interface IDialogMessageContentProps {
  icon: ReactNode;
  title: string | ReactNode;
  description?: string | ReactNode;
  action: ReactNode;
}

export function DialogMessageContent(props: IDialogMessageContentProps) {
  const { icon, title, description, action } = props;

  return (
    <>
      <MessageWrapp>
        {icon}

        <MessageTitle>{title}</MessageTitle>

        {description ? <MessageDesc>{description}</MessageDesc> : null}

        <MessagetButtonWrapp>{action}</MessagetButtonWrapp>
      </MessageWrapp>
    </>
  );
}
