import { useSelector } from "react-redux";
import { PostReferralsActions, selectPostReferrals } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { Title, Form, ErrorMessage } from "@/shared/components";
import { ReferForm } from "./ReferForm";

interface IFormValues {
  id: number | null;
  first_name: string;
  last_name: string;
  email: string;
  portfolio_link: string;
  channel_of_communications: string;
  number_of_channel: string;
  agree: boolean;
  cv?: File[] | null;
}

export function ReferCandidate(props: { id: number | undefined }) {
  const { id } = props;

  const { error_message } = useSelector(selectPostReferrals);
  const { postReferrals } =
    useActions<typeof PostReferralsActions>(PostReferralsActions);

  const defaultValues: IFormValues = {
    id: id || null,
    first_name: "",
    last_name: "",
    email: "",
    portfolio_link: "",
    channel_of_communications: "",
    number_of_channel: "",
    agree: false,
    cv: null,
  };

  const handleOnSubmit = (data: IFormValues) => {
    const file = data?.cv?.[0];
    const formData = new FormData();

    formData.append("cv", file as File);

    const cleanedData = {
      ...data,
      first_name: data.first_name.trim(),
      last_name: data.last_name.trim(),
      email: data.email.trim(),
      portfolio_link: data.portfolio_link.trim(),
      channel_of_communications: data.channel_of_communications.trim(),
      number_of_channel: data.number_of_channel.trim(),
      cv: file ? formData : null,
      fileName: file?.name,
    };

    postReferrals(cleanedData);
  };

  return (
    <>
      <ErrorMessage message={error_message} />

      <Title>REFER A CANDIDATE</Title>

      <Form defaultValues={defaultValues} onSubmit={handleOnSubmit}>
        <ReferForm id={id} />
      </Form>
    </>
  );
}
