import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { IFaqArticle } from "./reducers";
import {
  getFaqArticle,
  getFaqArticleError,
  getFaqArticleSuccess,
} from "./slice";
import { getFaqArticleRequest } from "./api";

function* getFaqArticleSaga(action: PayloadAction<number>) {
  try {
    const article: IFaqArticle = yield call(
      getFaqArticleRequest,
      action.payload
    );

    yield put(getFaqArticleSuccess(article));
  } catch (error) {
    yield put(getFaqArticleError({ error }));
  }
}

function* faqArticleWatcher() {
  yield takeLatest(getFaqArticle.type, getFaqArticleSaga);
}

export default faqArticleWatcher;
