import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "PostThanksGiving",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  postThanksGiving,
  postThanksGivingError,
  postThanksGivingSuccess,
  resetPostThanksGiving,
  resetPostThanksGivingError,
  resetPostThanksGivingSuccess,
} = slice.actions;

reducerRegistry.register("postThanksGiving", reducer);
