import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const getNewsCommentsRequest = async (params = {}) => {
  const { id, ...restParams }: any = params;

  const response: AxiosResponse = await apiInstance.get(
    `/news/${id}/comments`,
    {
      params: { ...restParams },
    }
  );

  return response.data;
};
