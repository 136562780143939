import { styled } from "@mui/material/styles";

const sidebarWudth = 250;

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
  alignItems: "start",
  background: theme.modeColors({
    dark: theme.palette.primary.main,
    light: theme.palette.common.white,
  }),
}));

export const SidebarWrapper = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 200,
  flex: `0 0 ${sidebarWudth}px`,
}));

export const VacanciesWrapper = styled("div")(({ theme }) => ({
  flex: 1,
  marginBottom: theme.spacing(3),
}));

export const VacancyItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.75),

  "& + &": {
    marginTop: theme.spacing(1),
    borderTop: "1px solid #E6E6E6",
    paddingTop: theme.spacing(2),
  },
}));

export const VacancyLocation = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: "0.875rem",
  fontWeight: 500,
}));

export const VacancyTitle = styled("div")(({ theme }) => ({
  fontSize: "1.125rem",
  fontWeight: 700,
}));

export const VacancySubtitle = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: "0.875rem",
  fontWeight: 500,
}));

export const VacancyDesc = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
}));

export const SearchWrapp = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 101,
  zIndex: 2,
}));
