import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const postNewsCommentLikeRequest = async (id: number) => {
  const response: AxiosResponse = await apiInstance.post(
    `/news/comments/${id}/like`
  );

  return response.data;
};
