import { useState, ChangeEvent } from "react";
import {
  InputBase,
  Paper,
  IconButton,
  InputProps,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

interface ISearchFieldProps extends InputProps {
  loading?: boolean;
}

export function SearchField(props: ISearchFieldProps) {
  const {
    fullWidth,
    loading,
    value,
    size,
    name,
    onChange,
    onSubmit,
    ...restProps
  } = props;

  const [search, setSearch] = useState(value || "");
  const hasOnSubmit = onSubmit && onSubmit instanceof Function;

  const searchSubmit = (value?: string) => {
    if (!hasOnSubmit) return;

    onSubmit({
      target: { name, value: value !== undefined ? value : search },
    } as any);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();

    const searchValue = e.target.value;

    setSearch(searchValue);

    if (hasOnSubmit && !searchValue.length) onSubmit(e);
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    searchSubmit();
  };

  const handleOnSearchClear = () => {
    setSearch("");
    searchSubmit("");
  };

  return (
    <Paper
      square
      onSubmit={handleOnSubmit}
      variant="outlined"
      component="form"
      sx={{
        p: "0 4px",
        display: "flex",
        alignItems: "center",
        width: fullWidth ? "100%" : "auto",
      }}
    >
      <IconButton type="submit" sx={{ p: "10px" }}>
        <SearchIcon />
      </IconButton>

      <InputBase
        {...restProps}
        name={name}
        sx={{
          ml: 1,
          flex: 1,
          "& input[placeholder]": {
            textOverflow: "ellipsis",
          },
        }}
        value={search}
        onChange={handleOnChange}
      />

      {loading ? (
        <CircularProgress color="inherit" size={16} sx={{ m: "0 10px" }} />
      ) : null}

      {search ? (
        <IconButton size={size} onClick={handleOnSearchClear}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </Paper>
  );
}
