import { PayloadAction } from "@reduxjs/toolkit";

export interface IFaqArticleLike {
  articleId: number;
  likes: number;
  has_like: boolean;
}

export interface IFaqArticleLikeState {
  pending: boolean;
  error: any;
  like: IFaqArticleLike;
}

export interface IFaqArticleLikeStateError {
  error: any;
}

export const initialState: IFaqArticleLikeState = {
  pending: false,
  error: null,
  like: {} as IFaqArticleLike,
};

export const reducers = {
  postFaqArticleLike: (
    state: IFaqArticleLikeState,
    action: PayloadAction<number | undefined>
  ) => {
    return {
      ...state,
      like: {} as IFaqArticleLike,
      pending: true,
      error: "",
    };
  },
  postFaqArticleLikeSuccess: (
    state: IFaqArticleLikeState,
    action: PayloadAction<IFaqArticleLike>
  ) => {
    return {
      ...state,
      pending: false,
      like: action.payload,
    };
  },
  postFaqArticleLikeError: (
    state: IFaqArticleLikeState,
    action: PayloadAction<IFaqArticleLikeStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
