import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Skeleton, useTheme, useMediaQuery } from "@mui/material";
import { useActions } from "@/utils/redux";
import { MarketplaceBrandActions, selectMarketplaceBrand } from "@/store/redux";
import {
  BrandDesc,
  BrandHead,
  BrandHeadContent,
  BrandImage,
  BrandAvatar,
  BrandAvatarWrapper,
  BrandLink,
  BrandQuote,
  BrandQuoteBackground,
  BrandTitle,
} from "./MarketPlaceBrandHead.styled";

export function MarketPlaceBrandHead() {
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("smd"));

  const { brand, pending } = useSelector(selectMarketplaceBrand);
  const { getMarketplaceBrand } = useActions<typeof MarketplaceBrandActions>(
    MarketplaceBrandActions
  );

  const { description, images, logo, quote, title, website } = brand;

  const Desc = () => (
    <BrandDesc dangerouslySetInnerHTML={{ __html: description }} />
  );

  const Image = () =>
    isMobile ? (
      <BrandImage src={images?.mobile || images?.desktop || ""} alt={title} />
    ) : (
      <BrandImage src={images?.desktop || ""} alt={title} />
    );

  useEffect(() => {
    getMarketplaceBrand(id);
  }, [id]); //eslint-disable-line

  if (pending) {
    return <Loader />;
  }

  return (
    <>
      <BrandHead>
        <BrandAvatar src={logo} />
        <BrandHeadContent>
          <BrandTitle>{title}</BrandTitle>
          <BrandLink href={website} target="_blank" rel="noopener">
            Company Website
          </BrandLink>

          {!isMobile ? <Desc /> : null}
        </BrandHeadContent>
      </BrandHead>

      {isMobile ? <Desc /> : null}

      <Image />

      <BrandQuote>
        <BrandQuoteBackground dangerouslySetInnerHTML={{ __html: quote }} />
      </BrandQuote>
    </>
  );
}

function Loader() {
  return (
    <>
      <BrandHead>
        <BrandAvatarWrapper>
          <Skeleton
            animation="wave"
            variant="circular"
            width="100%"
            height="100%"
          />
        </BrandAvatarWrapper>
        <BrandHeadContent>
          <BrandTitle>
            <Skeleton animation="wave" height={45} width="80%" />
          </BrandTitle>

          <Skeleton animation="wave" height={15} width="25%" sx={{ mb: 2 }} />
          <BrandDesc>
            <Skeleton animation="wave" height={15} width="65%" />
            <Skeleton animation="wave" height={15} width="65%" />
            <Skeleton animation="wave" height={15} width="65%" />
            <Skeleton animation="wave" height={15} width="65%" />
            <Skeleton animation="wave" height={15} width="65%" />
          </BrandDesc>
        </BrandHeadContent>
      </BrandHead>

      <Skeleton animation="wave" height={250} width="100%" />
    </>
  );
}
