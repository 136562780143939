import { PayloadAction } from "@reduxjs/toolkit";

export interface IReferralsCandidate {
  name: string;
  position: string;
  recruiter: {
    name: string;
    avatar: string;
  };
  bonus: number;
  status: {
    id: number;
    title: string;
    progress_step: number;
  };
  candidate_state: "in_progress" | "history";
}

export interface IReferralsCandidatesState {
  pending: boolean;
  error: any;
  progress_total_steps: number;
  candidates: IReferralsCandidate[];
}

export interface IReferralsCandidatesStateSuccess {
  progress_total_steps: number;
  candidates: IReferralsCandidate[];
}

export interface IReferralsCandidatesStateError {
  error: any;
}

export const initialState: IReferralsCandidatesState = {
  pending: false,
  error: null,
  candidates: [],
  progress_total_steps: 0,
};

export const reducers = {
  getReferralsCandidates: (state: IReferralsCandidatesState) => {
    return {
      ...state,
      candidates: [],
      pending: true,
      error: "",
    };
  },
  getReferralsCandidatesSuccess: (
    state: IReferralsCandidatesState,
    action: PayloadAction<IReferralsCandidatesStateSuccess>
  ) => {
    const { candidates, progress_total_steps } = action?.payload || {};

    return {
      ...state,
      progress_total_steps: progress_total_steps || 0,
      candidates: candidates || [],
      pending: false,
    };
  },
  getReferralsCandidatesError: (
    state: IReferralsCandidatesState,
    action: PayloadAction<IReferralsCandidatesStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
