import { FormHelperText } from "@mui/material";
import { useFormContext, Controller, ControllerProps } from "react-hook-form";

interface IGenerateFieldProps extends ControllerProps {
  errorText?: string;
}

export function GenerateField(props: IGenerateFieldProps) {
  const { errorText, rules, name, ...restProps } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const hasError = !!errors[name]?.type;

  return (
    <>
      <Controller
        {...restProps}
        name={name}
        control={control}
        rules={{ required: true, ...(rules || {}) }}
      />

      {hasError ? <FormHelperText error>{errorText}</FormHelperText> : null}
    </>
  );
}
