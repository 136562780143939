import { useSelector } from "react-redux";
import { selectMarketplaceBrand } from "@/store/redux";
import {
  Container,
  Page,
  MarketPlaceBrandHead,
  MarketPlaceBrandProducts,
  MarketPlaceBrandMessage,
} from "@/components";

export function MarketPlaceBrand() {
  const { brand } = useSelector(selectMarketplaceBrand);

  return (
    <Page title={brand?.title}>
      <Container sx={{ paddingTop: 3 }}>
        <MarketPlaceBrandHead />

        <MarketPlaceBrandProducts />

        <MarketPlaceBrandMessage />
      </Container>
    </Page>
  );
}
