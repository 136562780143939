import { useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, GradientButton, Bubble } from "@/shared/components";
import {
  selectProfile,
  INewsCommentsItem,
  NewsDeleteCommentsActions,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { DELETE_COMMENT_PERMISSION } from "@/config/permissions";
import {
  CommentDeleteButton,
  MessageDesc,
  MessageWrapp,
} from "./DeleteComment.styled";

interface IDeleteCommentProps {
  comment: INewsCommentsItem;
  loading: boolean;
  onDelete: (k: INewsCommentsItem) => void;
}

export function DeleteComment(props: IDeleteCommentProps) {
  const { comment, loading, onDelete } = props;

  const { profile } = useSelector(selectProfile);
  const { deleteNewsComment } = useActions<typeof NewsDeleteCommentsActions>(
    NewsDeleteCommentsActions
  );

  const [openConfirm, setOpenConfirm] = useState(false);

  const { id, author } = comment;

  const hasDeletePermission = (profile?.permissions || []).includes(
    DELETE_COMMENT_PERMISSION
  );

  const handleOnConfirmOpen = () => {
    setOpenConfirm(true);
  };

  const handleOnConfirmClose = () => {
    setOpenConfirm(false);
  };

  const handleOnDelete = () => {
    deleteNewsComment(id);

    onDelete(comment);
  };

  const content = (
    <MessageWrapp>
      <Bubble color="pink" width="100px" height="100px">
        <CloseIcon sx={{ fontSize: 56 }} />
      </Bubble>
      <MessageDesc>Are you sure you want to delete your comment?</MessageDesc>

      <GradientButton fullWidth onClick={handleOnDelete}>
        Delete
      </GradientButton>
    </MessageWrapp>
  );

  return profile?.id === author.id || hasDeletePermission ? (
    <>
      <Dialog
        top
        fullWidth
        maxWidth="xsm"
        title="Delete comment"
        dialogContent={content}
        open={openConfirm}
        onClose={handleOnConfirmClose}
      />

      <CommentDeleteButton
        size="small"
        disabled={loading}
        onClick={handleOnConfirmOpen}
      >
        Delete
      </CommentDeleteButton>
    </>
  ) : null;
}
