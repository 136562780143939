interface IUsersData {
  user: string | null | undefined;
  original_user: string | null | undefined;
}

interface ISwitchUser {
  getUserData: any;
  setUserData: any;
}

function switchUserUtils(): ISwitchUser {
  let usersData: IUsersData = {
    user: null,
    original_user: null,
  };
  const setUserData = (data: IUsersData) => {
    usersData = data;
  };
  const getUserData = () => {
    return usersData;
  };

  return {
    getUserData,
    setUserData,
  };
}

export default switchUserUtils();
