import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { IReferralsVacancyStatePending } from "./reducers";

export const getReferralsVacancyRequest = async (
  params: IReferralsVacancyStatePending
) => {
  const { id } = params;
  const response: AxiosResponse = await apiInstance.get(
    `/referrals/vacancies/${id}`
  );

  return response.data;
};
