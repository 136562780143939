import { PayloadAction } from "@reduxjs/toolkit";

export interface IMarketplaceItem {
  id: number;
  brand_id: number;
  images: string[];
  title: string;
  price: number;
  quantity: number;
}

export interface IMarketplaceState {
  pending: boolean;
  error: any;
  plimit: number;
  poffset: number;
  ptotal: number;
  products: IMarketplaceItem[];
}

export interface IMarketplaceStatePending {
  poffset?: number;
  plimit?: number;
}

export interface IMarketplaceStateSuccess {
  plimit: number;
  poffset: number;
  products: IMarketplaceItem[];
}

export interface IMarketplaceStateError {
  error: any;
}

export const initialState: IMarketplaceState = {
  pending: false,
  error: "",
  plimit: 10,
  poffset: 0,
  ptotal: 0,
  products: [],
};

export const reducers = {
  getMarketplace: (
    state: IMarketplaceState,
    action: PayloadAction<IMarketplaceStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: "",
      ...(action.payload || {}),
    };
  },
  getMarketplaceSuccess: (
    state: IMarketplaceState,
    action: PayloadAction<IMarketplaceStateSuccess>
  ) => {
    const { poffset, products, ...restOptions } = action?.payload;

    return {
      ...state,
      ...restOptions,
      pending: false,
      products: poffset ? [...(state.products || []), ...products] : products,
    };
  },
  getMarketplaceError: (
    state: IMarketplaceState,
    action: PayloadAction<IMarketplaceStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
