import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IEmployeeCalendarState } from "./reducers";

export const employeeCalendarSelector = (
  state: RootState
): IEmployeeCalendarState => state.employeeCalendar;

export const selectEmployeeCalendar = createSelector(
  employeeCalendarSelector,
  (employeeCalendarState: IEmployeeCalendarState) => ({
    ...employeeCalendarState,
  })
);
