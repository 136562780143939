import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IMyDocumentsState } from "./reducers";

export const myDocumentsSelector = (state: RootState): IMyDocumentsState =>
  state.myDocuments;

export const selectMyDocuments = createSelector(
  myDocumentsSelector,
  (myDocumentsState: IMyDocumentsState) => ({ ...myDocumentsState })
);
