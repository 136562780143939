import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const getMarketplaceBrandRequest = async (id: number) => {
  const response: AxiosResponse = await apiInstance.get(
    `/marketplace/brand/${id}`
  );

  return response.data;
};
