import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { SearchField, CardsList, MoreButton } from "@/shared/components";
import {
  TeamMembersActions,
  selectTeamMembers,
  selectTeam,
  selectSwitchUser,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { TeamTitleStyled, LoadMoreWrapp } from "./Team.styled";

export function TeamMembers() {
  const { id } = useParams();
  const { team, pending, plimit, poffset, ptotal, psearch } =
    useSelector(selectTeamMembers);
  const {
    team: { managers },
  } = useSelector(selectTeam);
  const { user } = useSelector(selectSwitchUser);
  const { getTeamMembers } =
    useActions<typeof TeamMembersActions>(TeamMembersActions);

  const getManagers = () => {
    return psearch
      ? (managers || []).filter((item) =>
          item.name.toLowerCase().includes((psearch || "").toLowerCase())
        )
      : managers || [];
  };
  const getMemebers = (params = {}) => {
    getTeamMembers({ id, plimit: 8, poffset: 0, ...params });
  };

  const handleOnSearch = (e: any) => {
    getMemebers({ psearch: e.target.value });
  };

  const handleOnMore = () => {
    getMemebers({ poffset: poffset + plimit });
  };

  const managersForRender = getManagers();

  useEffect(() => {
    getMemebers({ poffset: 0 });
  }, [user]); //eslint-disable-line

  return (
    <>
      <Box sx={{ my: 4 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={3} lg={4} />
          <Grid item xs={12} sm={12} md={3} lg={4} />
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <SearchField
              fullWidth
              placeholder="Type name or a job title"
              loading={pending}
              onSubmit={handleOnSearch}
            />
          </Grid>
        </Grid>
      </Box>

      {!managersForRender?.length && !team?.length ? (
        <Box sx={{ my: 6 }}>Not found</Box>
      ) : null}

      {managersForRender?.length ? (
        <Box mb={6}>
          <TeamTitleStyled>Management</TeamTitleStyled>
          <CardsList data={managersForRender} loading={pending} />
        </Box>
      ) : null}

      {team?.length ? (
        <Box mb={4}>
          <TeamTitleStyled>Core</TeamTitleStyled>
          <CardsList data={team || []} loading={pending} />
        </Box>
      ) : null}

      <LoadMoreWrapp>
        <MoreButton
          sx={{ minWidth: 265 }}
          limit={plimit}
          offset={poffset}
          total={ptotal}
          loading={pending}
          onClick={handleOnMore}
        >
          Show more
        </MoreButton>
      </LoadMoreWrapp>
    </>
  );
}
