import { ChangeEvent } from "react";
import {
  AddCommentField,
  AddCommentFieldWrapper,
  AddCommentCounter,
} from "./Comments.styled";

interface IFieldWithCounterProps {
  value: string;
  maxValue: number;
  onChange: any;
}

export function FieldWithCounter(props: IFieldWithCounterProps) {
  const { value, maxValue, onChange } = props;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const slicedValue =
      value.length >= maxValue ? value.slice(0, maxValue) : value;

    onChange(slicedValue);
  };

  return (
    <AddCommentFieldWrapper>
      <AddCommentCounter>
        {value.length}/{maxValue}
      </AddCommentCounter>

      <AddCommentField
        multiline
        fullWidth
        minRows={2}
        maxRows={10}
        variant="standard"
        placeholder="Start typing to add a comment"
        value={value}
        onChange={handleOnChange}
      />
    </AddCommentFieldWrapper>
  );
}
