import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IFaqArticleLikeState } from "./reducers";

export const faqArticleLikeSelector = (
  state: RootState
): IFaqArticleLikeState => state.faqArticleLike;

export const selectFaqArticleLike = createSelector(
  faqArticleLikeSelector,
  (faqArticleLikeState: IFaqArticleLikeState) => faqArticleLikeState.like
);
