import { styled } from "@mui/material/styles";
import { Drawer, ListItemButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IMenuItemProps {
  component: any;
  to: string;
}

export const DrawerStyled = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    background: theme.palette.primary.main,
    width: "430px",
    color: theme.palette.common.white,

    [theme.breakpoints.down("smd")]: {
      width: "100%",
    },
  },
}));

export const MenuItem = styled(ListItemButton)<IMenuItemProps>(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: "uppercase",
  borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  padding: "12px 32px",

  "&:hover": {
    color: theme.palette.secondary.main,
    background: theme.palette.primary.light,
  },

  "&.Mui-selected": {
    color: theme.palette.secondary.main,
  },
}));

export const MenuItemText = styled("div")(({ theme }) => ({
  fontWeight: "700",
  fontFamily: "Druk, sans-serif",
  letterSpacing: "0.04em",
  fontSize: theme.spacing(4),
}));

export const MenuItemExternal = styled(Link)(({ theme }) => ({
  display: "block",
  color: theme.palette.common.white,
  textTransform: "uppercase",
  textDecoration: "none",
  borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  padding: "12px 32px",

  "&:hover": {
    color: theme.palette.secondary.main,
    background: theme.palette.primary.light,
  },

  "&.Mui-selected": {
    color: theme.palette.secondary.main,
  },
}));

export const CloseWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const Close = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: "2rem",
}));

export const ReferrButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: theme.spacing(4, 0),
}));
