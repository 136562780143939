import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { INewcomersState } from "./reducers";

export const newcomersSelector = (state: RootState): INewcomersState =>
  state.newcomers;

export const selectNewcomers = createSelector(
  newcomersSelector,
  (newcomersState: INewcomersState) => ({ ...newcomersState })
);
