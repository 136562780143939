import { CommentItemTemplate, ICommentItemProps } from "./CommentItemTemplate";
import { SubComments } from "./SubComments";

export function CommentItem(props: ICommentItemProps) {
  const {
    loading,
    comment,
    selectedCommentRef,
    selectedReplyId,
    onReply,
    onDelete,
    onParentSelect,
  } = props;

  return (
    <>
      <CommentItemTemplate
        loading={loading}
        comment={comment}
        onReply={onReply}
        onDelete={onDelete}
        onParentSelect={onParentSelect}
        selectedCommentRef={selectedCommentRef}
        selectedReplyId={selectedReplyId}
      />
      <SubComments
        loading={loading}
        comments={comment?.sub_comments}
        onReply={onReply}
        onDelete={onDelete}
        onParentSelect={onParentSelect}
        selectedCommentRef={selectedCommentRef}
        selectedReplyId={selectedReplyId}
      />
    </>
  );
}
