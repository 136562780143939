import { useState, useEffect } from "react";
import { ITaskReporting } from "@/store/redux";
import { Accordion } from "@/shared/components";
import { TasksList } from "./Tasks";
import { Summary } from "./Summary";
import { ProjectsWrapper, ProjectsHeading } from "../TaskReporting.styled";

interface IProjectsListProps {
  taskReporting: ITaskReporting | null;
}

export function ProjectsList(props: IProjectsListProps) {
  const { taskReporting } = props;

  const [reporting, setReporting] = useState(taskReporting);

  const handleOnAdd = (
    projectId: number,
    value: { checked: boolean; value: string }
  ) => {
    const updatedReporting = {
      ...reporting,
      [projectId]: {
        ...reporting?.[projectId],
        tasks: [...(reporting?.[projectId]?.tasks || []), value],
      },
    };

    setReporting(updatedReporting as any);
  };

  const handleOnSave = (projectId: number, taskIdx: number, value: string) => {
    const tasks = reporting?.[projectId]?.tasks || [];
    const currTask = { ...tasks[taskIdx], value };
    const prevTasks = tasks.slice(0, taskIdx);
    const afterTasks = tasks.slice(taskIdx + 1, tasks?.length);
    const updatedTasks = [...prevTasks, currTask, ...afterTasks];

    const updatedReporting = {
      ...reporting,
      [projectId]: {
        ...reporting?.[projectId],
        tasks: updatedTasks,
      },
    };

    setReporting(updatedReporting as any);
  };

  const handleOnSelect = (
    projectId: number,
    taskIdx: number,
    value: boolean
  ) => {
    const tasks = reporting?.[projectId]?.tasks || [];
    const currTask = { ...tasks[taskIdx], checked: value };
    const prevTasks = tasks.slice(0, taskIdx);
    const afterTasks = tasks.slice(taskIdx + 1, tasks?.length);
    const updatedTasks = [...prevTasks, currTask, ...afterTasks];

    const updatedReporting = {
      ...reporting,
      [projectId]: {
        ...reporting?.[projectId],
        tasks: updatedTasks,
      },
    };

    setReporting(updatedReporting as any);
  };

  const data = Object.entries(reporting || {}).map(([projectId, value]) => {
    return {
      title: value.project_name,
      desc: (
        <TasksList
          projectId={Number(projectId)}
          tasks={value.tasks}
          onAdd={handleOnAdd}
          onSave={handleOnSave}
          onSelect={handleOnSelect}
        />
      ),
    };
  });

  useEffect(() => {
    setReporting(taskReporting);
  }, [taskReporting]);

  return (
    <ProjectsWrapper>
      <ProjectsHeading>Your projects</ProjectsHeading>

      <Accordion data={data} />

      <Summary reporting={reporting} />
    </ProjectsWrapper>
  );
}
