import { Box } from "@mui/material";
import { Title } from "@/shared/components";

export function TimesheetHead() {
  return (
    <Box mb={3}>
      <Title fontSize="60px">OUTAGE & TIME REPORTS</Title>
    </Box>
  );
}
