import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function TwitterIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 46 47" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0477 46.55C35.7226 46.55 45.9977 36.275 45.9977 23.6C45.9977 10.9251 35.7226 0.650024 23.0477 0.650024C10.3727 0.650024 0.0976562 10.9251 0.0976562 23.6C0.0976562 36.275 10.3727 46.55 23.0477 46.55ZM33.73 13.16H30.0154L23.8943 20.0407L18.6019 13.16H10.9367L20.0954 24.9371L11.4151 34.6933H15.132L21.8314 27.1655L27.6864 34.6933H35.1617L25.6145 22.2812L33.73 13.16ZM30.77 32.5069H28.7116L15.2746 15.2316H17.4834L30.77 32.5069Z"
      />
    </SvgIcon>
  );
}
