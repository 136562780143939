import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const MessageWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

export const MessageTitle = styled("div")(({ theme }) => ({
  fontWeight: 900,
  marginTop: theme.spacing(1),
}));

export const MessageDesc = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.darkGrey.main,
  fontWeight: 500,
  margin: theme.spacing(1, 0, 2),
  maxWidth: 250,
}));

export const CommentDeleteButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  textTransform: "none",
  fontSize: "1rem",
  borderRadius: 32,
  color:
    theme.palette.mode === "dark"
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
}));
