import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "PopularAuthors",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getPopularAuthors,
  getPopularAuthorsError,
  getPopularAuthorsSuccess,
} = slice.actions;

reducerRegistry.register("popularAuthors", reducer);
