import { useEffect, useState, PropsWithChildren } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { loginRequest } from "@/config/msalConfig";
import { useActions } from "@/utils/redux";
import { ProfileActions } from "@/store/redux";
import { SplashScreen } from "@/shared/components";

export function AppData(props: PropsWithChildren) {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const { getProfile } = useActions<typeof ProfileActions>(ProfileActions);

  const [showContent, setShowContent] = useState(false);

  const reqParams = { ...loginRequest, account: accounts[0] };

  const acquireToken = () => {
    instance
      .acquireTokenSilent(reqParams)
      .then(() => {
        getProfile();
        setShowContent(true);
      })
      .catch(async (e) => {
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect(reqParams);
        }

        throw e;
      });
  };

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      acquireToken();
    }
  }, [isAuthenticated, inProgress, instance, accounts]); //eslint-disable-line

  if (inProgress === InteractionStatus.Login) {
    return <SplashScreen />;
  }

  return showContent ? <>{props.children}</> : null;
}
