import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { INewsArticleStatePending } from "./reducers";

export const getNewsArticleRequest = async (
  params: INewsArticleStatePending
) => {
  const { id, plang } = params;
  const response: AxiosResponse = await apiInstance.get(`/news/${id}`, {
    params: { plang },
  });

  return response.data;
};
