import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IMarketplaceBrandProductsState } from "./reducers";

export const marketplaceBrandProductsSelector = (
  state: RootState
): IMarketplaceBrandProductsState => state.marketplaceBrandProducts;

export const selectMarketplaceBrandProducts = createSelector(
  marketplaceBrandProductsSelector,
  (marketplaceBrandProductsState: IMarketplaceBrandProductsState) => ({
    ...marketplaceBrandProductsState,
  })
);
