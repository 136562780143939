import { useSelector } from "react-redux";
import { TableRow, TableCell, Skeleton } from "@mui/material";
import { selectTimesheetActivity } from "@/store/redux";
import { getHoliday } from "../helpers";
import { IOutageHoursState } from "../interfaces";

interface IOutageProps {
  reporting: IOutageHoursState;
  timeButton: any;
}

export function Outage(props: IOutageProps) {
  const { reporting, timeButton: TimeButton } = props;

  const { pending, holidays } = useSelector(selectTimesheetActivity);

  const stringSum =
    typeof reporting?.sum === "string"
      ? reporting?.sum
      : String(reporting?.sum || 0);

  const floatFixedSum = parseFloat(stringSum).toFixed(2);
  const summ =
    parseFloat(floatFixedSum || "0") > 0 ? parseFloat(floatFixedSum || "0") : 0;

  return (
    <TableRow>
      <TableCell>Outage</TableCell>

      <TableCell>
        {pending ? <Skeleton height={12} width={24} /> : summ}
      </TableCell>

      {reporting?.reporting?.map((item, idx) => {
        const hasHoliday = !!getHoliday({ list: holidays, day: item?.day })
          ?.type;

        const reporting = {
          ...(item?.reporting || {}),
          type: "outage",
        };

        const hasWeekend = [5, 6].includes(idx);

        return (
          <TableCell
            key={`outage-${item?.reporting?.start_date}-${item?.reporting?.hours}-${idx}`}
            sx={{
              background:
                hasWeekend || hasHoliday
                  ? "rgba(230, 230, 230, 0.30)"
                  : "transparent",
            }}
          >
            <TimeButton day={item?.day} reporting={reporting} />
          </TableCell>
        );
      })}
    </TableRow>
  );
}
