import { call, put, takeLatest } from "redux-saga/effects";
import { IReferralsFiltersStateSuccess } from "./reducers";
import {
  getReferralsFilters,
  getReferralsFiltersError,
  getReferralsFiltersSuccess,
} from "./slice";
import { getReferralsFiltersRequest } from "./api";

function* getReferralsFiltersSaga() {
  try {
    const filters: IReferralsFiltersStateSuccess = yield call(
      getReferralsFiltersRequest
    );

    yield put(getReferralsFiltersSuccess(filters));
  } catch (error) {
    yield put(getReferralsFiltersError({ error }));
  }
}

function* ReferralsFiltersWatcher() {
  yield takeLatest(getReferralsFilters.type, getReferralsFiltersSaga);
}

export default ReferralsFiltersWatcher;
