import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "MarketplaceBrand",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getMarketplaceBrand,
  getMarketplaceBrandError,
  getMarketplaceBrandSuccess,
} = slice.actions;

reducerRegistry.register("marketplaceBrand", reducer);
