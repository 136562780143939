import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "MyDocuments",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getMyDocuments,
  getMyDocumentsError,
  getMyDocumentsSuccess,
  getMyDocumentAccess,
  getMyDocumentAccessError,
  getMyDocumentAccessSuccess,
} = slice.actions;

reducerRegistry.register("myDocuments", reducer);
