import { useState } from "react";
import { IFilters } from "./TimeOffFilters";

export function useFilters() {
  const [filters, setFilters] = useState<IFilters>({
    ptype: -1,
    pstatus: -1,
    psort: "desc",
  });

  const handleOnFilters = (values: IFilters) => {
    setFilters({ ...filters, ...values });
  };

  return { filters, handleOnFilters };
}
