import { call, put, takeLatest } from "redux-saga/effects";
import { IDeliveryInfoStateSuccess } from "./reducers";
import {
  getCheckoutDeliveryInfo,
  getCheckoutDeliveryInfoError,
  getCheckoutDeliveryInfoSuccess,
} from "./slice";
import { getDeliveryInfoRequest } from "./api";

function* getCheckoutDeliveryInfoSaga() {
  try {
    const info: IDeliveryInfoStateSuccess = yield call(getDeliveryInfoRequest);

    yield put(getCheckoutDeliveryInfoSuccess(info));
  } catch (error) {
    yield put(getCheckoutDeliveryInfoError({ error }));
  }
}

function* CheckoutDeliveryInfoWatcher() {
  yield takeLatest(getCheckoutDeliveryInfo.type, getCheckoutDeliveryInfoSaga);
}

export default CheckoutDeliveryInfoWatcher;
