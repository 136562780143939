import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IMoodCalendarState } from "./reducers";

export const moodCalendarSelector = (state: RootState): IMoodCalendarState =>
  state.moodCalendar;

export const selectMoodCalendar = createSelector(
  moodCalendarSelector,
  (moodCalendarState: IMoodCalendarState) => ({ ...moodCalendarState })
);
