import { styled } from "@mui/material/styles";
import { TableHead, TableRow } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

const sidebarWudth = 250;

export const Wrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 0, 12),
}));

export const SidebarWrapper = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 125,
  flex: `0 0 ${sidebarWudth}px`,
}));

export const Name = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  fontWeight: 500,
}));

export const ExternalOpenIcon = styled(LaunchIcon)(({ theme }) => ({
  color: theme.modeColors({
    dark: theme.palette.primary.main,
    light: theme.palette.common.white,
  }),
  fontSize: "0.875rem",
}));

export const TableHeadStyled = styled(TableHead)(({ theme }) => ({
  background: theme.modeColors({
    light: "#F9F9F9",
    dark: "transparent",
  }),

  "& th": {
    color: "#8E949A",
    padding: theme.spacing(1, 2),

    "&:first-of-type": {
      paddingLeft: theme.spacing(6),
    },
  },
}));

export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
    background: theme.modeColors({
      light: "#f9f9f9",
      dark: "rgba(255,255,255,0.15)",
    }),

    [ExternalOpenIcon as any]: {
      color: theme.modeColors({
        light: theme.palette.primary.main,
        dark: theme.palette.common.white,
      }),
    },
  },
}));

export const FileInfoCell = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center",
}));

export const TableHeadCellTitle = styled("span")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  cursor: "pointer",

  "&:hover": {
    color: theme.modeColors({
      light: theme.palette.primary.main,
      dark: "#fff",
    }),
  },
}));

export const FileExtIcon = styled("span")<{ $color: string }>(
  ({ theme, $color }) => ({
    display: "inline-flex",
    background: $color,
    padding: "6px",
    borderRadius: "4px",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "0.65rem",
    color: theme.palette.primary.main,
  })
);

export const EmptyFolderWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),
  color: "#bbb",
  textAlign: "center",
  fontSize: "1.5rem",
  fontWeight: 500,
}));
