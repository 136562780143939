import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { Title, Bubble } from "@/shared/components";
import {
  selectSwitchUser,
  selectTimesheetActivity,
  TimesheetActivityActions,
  UserProjectsActions,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { getPeriod } from "./helpers";
import { TimesheetDaysWrapp } from "./Timesheet.styled";

export function TimesheetActivities() {
  const { user } = useSelector(selectSwitchUser);
  const { pending, outage_time, working_hours } = useSelector(
    selectTimesheetActivity
  );
  const { getTimesheetActivity } = useActions<typeof TimesheetActivityActions>(
    TimesheetActivityActions
  );
  const { getUserProjects } =
    useActions<typeof UserProjectsActions>(UserProjectsActions);

  const days = [
    {
      color: "yellowBlue",
      value: pending ? (
        <SyncIcon sx={{ fontSize: 32 }} />
      ) : (
        <Title fontSize="60px">{outage_time || 0}</Title>
      ),
      title: "Outage Time",
    },
    {
      color: "bluePink",
      value: pending ? (
        <SyncIcon sx={{ fontSize: 32 }} />
      ) : (
        <Title fontSize="60px">{working_hours || 0}</Title>
      ),
      title: "Working hours",
    },
  ];

  const getTimesheet = () => {
    const { startDate, endDate } = getPeriod(new Date());
    getTimesheetActivity({ start_date: startDate, end_date: endDate });
  };

  useEffect(() => {
    getTimesheet();
    getUserProjects();
  }, [user]); //eslint-disable-line

  return (
    <>
      <Title fontStyle="normal">WORKING ACTIVITIES</Title>
      <TimesheetDaysWrapp>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {days.map(({ color, value, title }) => (
            <Grid key={title} item xs={6} sm="auto">
              <Bubble color={color} sx={{ mb: 2 }}>
                {value}
              </Bubble>
              <p>{title}</p>
            </Grid>
          ))}
        </Grid>
      </TimesheetDaysWrapp>
    </>
  );
}
