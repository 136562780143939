import { useEffect } from "react";
import { Box, Avatar, useTheme, useMediaQuery } from "@mui/material";
import { Carousel } from "@/shared/components";
import { IMoodLiterature } from "@/store/redux";
import { Loader } from "./Loader";
import {
  LiteratureList,
  LiteratureListItem,
  LiteratureListItemContent,
  LiteratureListItemImage,
  LiteratureListItemName,
  LiteratureListItemAuthors,
  LiteratureListItemLink,
} from "./Literature.styled";

interface ILiteratureProps {
  pending: boolean;
  literature: IMoodLiterature[];
  getLiterature: any;
}

export function Literature(props: ILiteratureProps) {
  const { getLiterature, literature, pending } = props;

  const theme = useTheme();
  const hasTablet = useMediaQuery(theme.breakpoints.down("md"));
  const hasMobile = useMediaQuery(theme.breakpoints.down("smd"));

  const slidesToShow = hasMobile ? 1 : hasTablet ? 2 : 3;

  const slides = (literature || []).map(({ image, link, title, author }) => (
    <Box
      key={title}
      sx={{ display: "flex !important", justifyContent: "center" }}
    >
      <LiteratureListItem>
        <LiteratureListItemImage>
          <Avatar
            src={image}
            sx={{ width: 80, height: 120, borderRadius: 0 }}
          />
        </LiteratureListItemImage>
        <LiteratureListItemContent>
          <LiteratureListItemName>{title}</LiteratureListItemName>
          <LiteratureListItemAuthors>{author}</LiteratureListItemAuthors>

          {link ? (
            <LiteratureListItemLink href={link} target="_blank" rel="noopener">
              Amazon link
            </LiteratureListItemLink>
          ) : null}
        </LiteratureListItemContent>
      </LiteratureListItem>
    </Box>
  ));

  useEffect(() => {
    getLiterature();
  }, []); //eslint-disable-line

  if (pending) {
    return (
      <LiteratureList>
        <Loader />
      </LiteratureList>
    );
  }

  return (
    <>
      <Box sx={{ mb: 4, mt: 1 }}>
        <Carousel noArrowsMargin slides={slides} slidesToShow={slidesToShow} />
      </Box>
    </>
  );
}
