import { styled } from "@mui/material/styles";

interface IDescription {
  $fontWeight?: string | number;
}

export const DescriptionStyled = styled("div")<IDescription>(
  ({ theme, $fontWeight }) => ({
    fontWeight: $fontWeight || 500,

    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  })
);
