import { useSelector } from "react-redux";
import { Contacts } from "@/shared/components";
import { ContactsActions, selectContacts } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { ContactsTitle } from "./Contacts.styled";

export function ProfileContacts() {
  const { contacts, pending } = useSelector(selectContacts);
  const { getContacts } = useActions<typeof ContactsActions>(ContactsActions);

  return (
    <>
      <ContactsTitle fontWeight="500">MY CONTACTS</ContactsTitle>

      <Contacts
        pending={pending}
        contacts={contacts}
        getContacts={getContacts}
      />
    </>
  );
}
