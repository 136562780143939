import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const getMoodLiteratureRequest = async () => {
  const response: AxiosResponse = await apiInstance.get(
    "/mood-monitoring/useful-literature"
  );

  return response.data;
};
