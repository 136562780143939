import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { IMarketplaceBrandProductsStatePending } from "./reducers";

export const getMarketplaceBrandProductsRequest = async (
  params: IMarketplaceBrandProductsStatePending
) => {
  const { id, ...restParams } = params;

  const response: AxiosResponse = await apiInstance.get(
    `/marketplace/brand/${id}/products`,
    {
      params: { ...restParams },
    }
  );

  return response.data;
};
