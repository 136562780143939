import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "ReferralsVacancies",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getReferralsVacancies,
  getReferralsVacanciesError,
  getReferralsVacanciesSuccess,
} = slice.actions;

reducerRegistry.register("referralsVacancies", reducer);
