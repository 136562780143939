import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { INewsLocationsState } from "./reducers";

export const newsLocationsSelector = (state: RootState): INewsLocationsState =>
  state.newsLocations;

export const selectNewsLocations = createSelector(
  newsLocationsSelector,
  (newsLocationsState: INewsLocationsState) => ({
    ...newsLocationsState,
  })
);
