import { Box } from "@mui/material";
import {
  ContainerStyled,
  MarketPlaceHeadContainer,
  MarketPlaceHeadImage,
  MarketPlaceHeadDesc,
  MarketPlaceHeadTitle,
} from "./MarketPlace.styled";

export function MarketPlaceHead() {
  return (
    <MarketPlaceHeadContainer>
      <MarketPlaceHeadImage $image="https://brightpictures.blob.core.windows.net/portal-pictures-container/marketplace/MP_header.png" />
      <ContainerStyled>
        <MarketPlaceHeadTitle>
          <Box sx={{ color: (theme) => theme.palette.secondary.main }}>
            Bright merch
          </Box>
          for good cause
        </MarketPlaceHeadTitle>
        <MarketPlaceHeadDesc>
          Here you can buy not only merch but also a variety of other products
          through donations. It&apos;s not just hoodies or water bottles -
          it&apos;s something we&apos;ve created with our hearts for you over
          the years. It&apos;s about bright design, high-quality materials, and
          most importantly, every purchase becomes a donation to the Bright
          Volunteers fund.
        </MarketPlaceHeadDesc>
      </ContainerStyled>
    </MarketPlaceHeadContainer>
  );
}
