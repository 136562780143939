import { styled } from "@mui/material/styles";

export const ListWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const ListHead = styled("div")(({ theme }) => ({
  position: "sticky",
  zIndex: 1,
  top: 100,
  background: theme.modeColors({
    dark: theme.palette.primary.dark,
    light: "#F9F9F9",
  }),
  color: theme.palette.darkGrey.main,
  fontSize: "1rem",
  padding: theme.spacing(1),
}));

export const ListBody = styled("div")(({ theme }) => ({}));

export const ListItem = styled("div")(({ theme }) => ({
  display: "flex",
  borderTop: `1px solid ${theme.modeColors({
    dark: theme.palette.common.black,
    light: "#E6E6E6",
  })}`,
  color: theme.modeColors({ dark: theme.palette.darkGrey.main, light: "#000" }),
  fontWeight: "500",
  fontSize: "1rem",
  padding: theme.spacing(2, 1),

  "&:last-of-type": {
    borderBottom: `1px solid ${theme.modeColors({
      dark: theme.palette.common.black,
      light: "#E6E6E6",
    })}`,
  },
}));

export const ListItemTitle = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: "0.75rem",
}));

export const TypeValue = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const TypeDot = styled("span")<{ $color: string }>(
  ({ theme, $color }) => ({
    display: "inline-flex",
    background: theme.palette[$color].main,
    width: 12,
    height: 12,
    borderRadius: "50%",
    marginRight: theme.spacing(1),
  })
);

export const LoadMoreWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: theme.spacing(5, 0),

  [theme.breakpoints.down("sm")]: {
    "& button": {
      width: "100%",
    },
  },
}));

export const ItemDesc = styled("div")(({ theme }) => ({
  wordBreak: "break-word",
}));
