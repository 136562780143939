import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { IFaqState } from "./reducers";
import { getFaq, getFaqError, getFaqSuccess } from "./slice";
import { getFaqRequest } from "./api";

function* getFaqSaga(action: PayloadAction<IFaqState>) {
  try {
    const list: IFaqState = yield call(getFaqRequest, action.payload);

    yield put(getFaqSuccess(list));
  } catch (error) {
    yield put(getFaqError({ error }));
  }
}

function* faqWatcher() {
  yield takeLatest(getFaq.type, getFaqSaga);
}

export default faqWatcher;
