import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const getMyDocumentsRequest = async (params?: { path?: string }) => {
  const { path } = params || {};
  const requestParams = path?.length
    ? {
        path,
      }
    : {};

  const response: AxiosResponse = await apiInstance.get("/documents", {
    params: requestParams,
  });

  return response?.data;
};

export const getMyDocumentsAccessRequest = async () => {
  const response: AxiosResponse = await apiInstance.get("/documents/access");

  return response?.data;
};
