import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ITimeOffState } from "./reducers";

export const timeOffSelector = (state: RootState): ITimeOffState =>
  state.timeOff;

export const selectTimeOff = createSelector(
  timeOffSelector,
  (timeOffState: ITimeOffState) => ({ ...timeOffState })
);
