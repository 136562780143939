import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IMarketplaceBrandState } from "./reducers";

export const marketplaceBrandSelector = (
  state: RootState
): IMarketplaceBrandState => state.marketplaceBrand;

export const selectMarketplaceBrand = createSelector(
  marketplaceBrandSelector,
  (marketplaceBrandState: IMarketplaceBrandState) => ({
    ...marketplaceBrandState,
  })
);
