import { PayloadAction } from "@reduxjs/toolkit";

export interface ICartProduct {
  id: number;
  images: string;
  title: string;
  price: number;
  size: string;
  quantity: number;
  description: string;
}

export interface ICartState {
  pending: boolean;
  error: any;
  postedProduct: ICartProduct;
  products: ICartProduct[] | null;
  ptotal: number;
  error_message?: string;
}

export interface IPostCartStatePending {
  id: number;
  size: number | string | null | undefined;
  quantity: number | string | null | undefined;
}

export interface IGetCartStateSuccess {
  ptotal: number;
  products: ICartProduct[];
}

export interface IPostCartStateSuccess extends ICartProduct {
  error_message?: string;
}

export interface IDeleteCartStateSuccess {
  ptotal: number;
  products: ICartProduct[];
}

export interface ICartStateError {
  error: any;
}

export const initialState: ICartState = {
  pending: false,
  error: "",
  error_message: "",
  postedProduct: {} as ICartProduct,
  products: null,
  ptotal: 0,
};

export const reducers = {
  //GET cart
  getCart: (state: ICartState) => {
    return {
      ...state,
      pending: true,
      error: "",
    };
  },
  getCartSuccess: (
    state: ICartState,
    action: PayloadAction<IGetCartStateSuccess>
  ) => {
    const { ptotal, products } = action.payload;

    return {
      ...state,
      error: "",
      pending: false,
      ptotal,
      products,
    };
  },
  getCartError: (
    state: ICartState,
    action: PayloadAction<ICartStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),

  //POST product
  postCart: (
    state: ICartState,
    action: PayloadAction<IPostCartStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: "",
      error_message: "",
      postedProduct: {} as ICartProduct,
    };
  },
  postCartSuccess: (
    state: ICartState,
    action: PayloadAction<IPostCartStateSuccess>
  ) => {
    const hasProduct = action.payload.id;
    const hasError = action.payload.error_message;

    return {
      ...state,
      error: "",
      pending: false,
      postedProduct: hasProduct ? action.payload : ({} as ICartProduct),
      error_message: hasError ? action.payload.error_message : "",
    };
  },
  postCartError: (
    state: ICartState,
    action: PayloadAction<ICartStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),

  //DELETE products
  deleteCart: (state: ICartState, action: PayloadAction<number>) => {
    return {
      ...state,
      pending: true,
      error: "",
    };
  },
  deleteCartSuccess: (
    state: ICartState,
    action: PayloadAction<IDeleteCartStateSuccess>
  ) => {
    const { ptotal, products } = action.payload;

    return {
      ...state,
      error: "",
      pending: false,
      ptotal,
      products,
    };
  },
  deleteCartError: (
    state: ICartState,
    action: PayloadAction<ICartStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),

  //RESET error_message
  resetPostCart: (state: ICartState) => ({
    ...state,
    error_message: "",
    postedProduct: {} as ICartProduct,
  }),
  resetPostCartSuccess: (state: ICartState) => ({
    ...state,
    error_message: "",
    postedProduct: {} as ICartProduct,
  }),
  resetPostCartError: (state: ICartState) => ({
    ...state,
  }),
};
