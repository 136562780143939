import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { selectPostThanksGiving } from "@/store/redux";
import {
  Bubble,
  Dialog,
  DialogMessageContent,
  GradientButton,
} from "@/shared/components";
import { ThanksGivingDayForm } from "./ThanksGivingDayForm";

interface IThanksGivingDialogProps {
  open: boolean;
  onClose: () => void;
}

export function ThanksGivingDialog(props: IThanksGivingDialogProps) {
  const { open, onClose } = props;

  const { hasPosted } = useSelector(selectPostThanksGiving);

  const dialogMaxWidth = hasPosted ? "xsm" : "smd";
  const dialogTitle = hasPosted ? "THANK YOU!" : "THANKSGIVING DAY MARATHON!";

  const content = hasPosted ? (
    <DialogMessageContent
      title={`We've got your “Thank you”!`}
      description="We will deliver your messeage to your bright colleague no matter what!"
      icon={
        <Bubble color="yellowPink" width="100px" height="100px">
          <CheckIcon sx={{ fontSize: 56 }} />
        </Bubble>
      }
      action={
        <GradientButton fullWidth onClick={onClose}>
          Got it
        </GradientButton>
      }
    />
  ) : (
    <Box sx={{ px: { xs: 0, sm: 5 } }}>
      <Box
        sx={{
          textAlign: { xs: "justify", sm: "left" },
          fontSize: { xs: 14, sm: 18 },
          fontWeight: 400,
          mb: 4,
        }}
      >
        Today is a day to say “Thank you” to your colleagues, mates and friends
        at work. Write a short text or select a ready-made note.
      </Box>
      <ThanksGivingDayForm />
    </Box>
  );

  return (
    <Dialog
      top
      fullWidth
      scroll="body"
      maxWidth={dialogMaxWidth}
      title={dialogTitle}
      dialogContent={content}
      open={open}
      onClose={onClose}
    />
  );
}
