import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "PostTimeOff",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  postTimeOff,
  postTimeOffError,
  postTimeOffSuccess,
  resetPostTimeOff,
  resetPostTimeOffError,
  resetPostTimeOffSuccess,
} = slice.actions;

reducerRegistry.register("postTimeOff", reducer);
