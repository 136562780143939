import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "Faq",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const { getFaq, getFaqError, getFaqSuccess } = slice.actions;

reducerRegistry.register("faq", reducer);
