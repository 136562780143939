import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Feed } from "@/shared/components";
import { FaqActions, selectFaqs, FaqArticleLikeActions } from "@/store/redux";
import { useActions } from "@/utils/redux";

export function FaqFeed() {
  const { getFaq } = useActions<typeof FaqActions>(FaqActions);
  const { postFaqArticleLike } = useActions<typeof FaqArticleLikeActions>(
    FaqArticleLikeActions
  );

  const { list, pending: FaqPending } = useSelector(selectFaqs);

  const handleOnLike = (id: number | undefined) => {
    postFaqArticleLike(id);
  };

  useEffect(() => {
    getFaq({ plimit: 10, poffset: 0 });
  }, []); //eslint-disable-line

  return <Feed data={list} loading={FaqPending} onLike={handleOnLike} />;
}
