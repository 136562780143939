import { call, put, takeLatest } from "redux-saga/effects";
import { IEmployeeCalendarCountriesStateSuccess } from "./reducers";
import {
  getEmployeeCalendarCountries,
  getEmployeeCalendarCountriesError,
  getEmployeeCalendarCountriesSuccess,
} from "./slice";
import { getEmployeeCalendarCountriesRequest } from "./api";

function* getEmployeeCalendarCountriesSaga() {
  try {
    const countries: IEmployeeCalendarCountriesStateSuccess = yield call(
      getEmployeeCalendarCountriesRequest
    );

    yield put(getEmployeeCalendarCountriesSuccess(countries));
  } catch (error) {
    yield put(getEmployeeCalendarCountriesError({ error }));
  }
}

function* EmployeeCalendarCountriesWatcher() {
  yield takeLatest(
    getEmployeeCalendarCountries.type,
    getEmployeeCalendarCountriesSaga
  );
}

export default EmployeeCalendarCountriesWatcher;
