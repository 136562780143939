import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function LikeIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M1 7.55149C1 4.28119 3.26337 2 6.17723 2C7.87921 2 9.21584 2.79307 10 3.9604C10.802 2.78416 12.1297 2 13.8228 2C16.7455 2 19 4.28119 19 7.55149C19 11.3653 15.8099 15.1257 10.8287 18.3337C10.5614 18.503 10.2317 18.6723 10 18.6723C9.77723 18.6723 9.44753 18.503 9.17129 18.3337C4.1901 15.1257 1 11.3653 1 7.55149ZM2.8 7.55149C2.8 10.7059 6.07921 14.1901 9.83069 16.6851C9.91089 16.7386 9.96436 16.7743 10 16.7743C10.0356 16.7743 10.0891 16.7386 10.1693 16.6851C13.9208 14.1901 17.2089 10.7059 17.2089 7.55149C17.2089 5.28812 15.7297 3.76436 13.7337 3.76436C12.1297 3.76436 11.2475 4.75347 10.6505 5.6C10.3832 5.94752 10.2406 6.07228 10 6.07228C9.76832 6.07228 9.59901 5.95644 9.3495 5.6C8.78812 4.74455 7.88812 3.76436 6.27525 3.76436C4.27921 3.76436 2.8 5.28812 2.8 7.55149Z"
        fill="#8E949A"
      />
    </SvgIcon>
  );
}

export function LikeIconFilled(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M0.833008 7.32031C0.833008 3.98945 3.13829 1.66602 6.10611 1.66602C7.83961 1.66602 9.201 2.47377 9.99967 3.66272C10.8165 2.4647 12.1688 1.66602 13.8932 1.66602C16.8701 1.66602 19.1663 3.98945 19.1663 7.32031C19.1663 11.2048 15.9172 15.0348 10.8437 18.3022C10.5715 18.4746 10.2356 18.647 9.99967 18.647C9.77278 18.647 9.43697 18.4746 9.15562 18.3022C4.08218 15.0348 0.833008 11.2048 0.833008 7.32031Z" />
    </SvgIcon>
  );
}
