import { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import GroupIcon from "@mui/icons-material/Group";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import FolderIcon from "@mui/icons-material/Folder";
import { Title } from "@/shared/components";
import {
  VeryBadSmileIcon,
  BadSmileIcon,
  NormalSmileIcon,
  GoodSmileIcon,
  FineSmileIcon,
  MoodIcon,
} from "@/shared/icons";
import {
  TIMEOFF,
  NEWS,
  TEAMS,
  MOOD,
  TIMESHEET,
  CALENDAR,
  SURVEYS,
  MY_DOCUMENTS,
} from "@/routes/consts";
import {
  selectProfile,
  MyDocumentsActions,
  selectMyDocuments,
} from "@/store/redux";
import { appConfig } from "@/config/appConfig";
import { useActions } from "@/utils/redux";
import { RecentVacancies } from "../RecentVacancies";
import { DotItem } from "./DotItem";
import { Loader } from "./Loader";

export interface IDot {
  title: string;
  desc: ReactNode | null | undefined;
  value: ReactNode | number | string | null | undefined;
  href: string;
  external?: boolean;
  buttonText?: string;
}

const moodSmiles: { [k: number]: any } = {
  0: <MoodIcon sx={{ fontSize: 60 }} />,
  1: <VeryBadSmileIcon sx={{ fontSize: 60 }} />,
  2: <BadSmileIcon sx={{ fontSize: 60 }} />,
  3: <NormalSmileIcon sx={{ fontSize: 60 }} />,
  4: <GoodSmileIcon sx={{ fontSize: 60 }} />,
  5: <FineSmileIcon sx={{ fontSize: 60 }} />,
};

const { WIKI_LINK, HELPDESK_LINK, APP_NODE_ENV } = appConfig;

const hasProductionEnv = APP_NODE_ENV === "production";

export function Dots() {
  const { profile, pending } = useSelector(selectProfile);
  const { hasDocs } = useSelector(selectMyDocuments);
  const { getMyDocumentAccess } =
    useActions<typeof MyDocumentsActions>(MyDocumentsActions);

  const firstItems: IDot[] = [
    {
      title: "TIME OFFS BOOKING",
      desc: (
        <>
          Days <br /> Paid Time Off Left
        </>
      ),
      value: <Title fontSize="50px">{profile?.time_offs || 0}</Title>,
      href: TIMEOFF,
    },
    {
      title: "LATEST NEWS",
      desc: (
        <>
          Unread <br /> News
        </>
      ),
      value: <Title fontSize="50px">{profile?.unread_news}</Title>,
      href: NEWS,
    },
    {
      title: "BRIGHTGROVE WIKI",
      desc: (
        <>
          Find Out About <br /> Brightgrove
        </>
      ),
      value: <InfoIcon sx={{ fontSize: 60 }} />,
      href: WIKI_LINK,
      external: true,
    },
  ];

  const secondItems: IDot[] = [
    {
      title: "HELP DESK",
      desc: (
        <>
          Any Help <br />
          for Bright Talents
        </>
      ),
      value: <HelpIcon sx={{ fontSize: 60 }} />,
      href: HELPDESK_LINK,
      external: true,
    },
    {
      title: "MY TEAM",
      desc: (
        <>
          Find out more about
          <br /> roles and the team
        </>
      ),
      value: <GroupIcon sx={{ fontSize: 60 }} />,
      href: TEAMS,
    },
    {
      title: "MOOD SCORE",
      desc: (
        <>
          Share with us how are <br />
          you doing.
        </>
      ),
      value: moodSmiles[Math.round(profile?.mood_score || 0)],
      href: MOOD,
    },
    {
      title: "Timesheet",
      desc: (
        <>
          Outage & working <br /> hours
        </>
      ),
      value: <WatchLaterIcon sx={{ fontSize: 60 }} />,
      href: TIMESHEET,
    },
    {
      title: "Calendar",
      desc: (
        <>
          Holidays, time offs
          <br /> and more
        </>
      ),
      value: <CalendarMonthIcon sx={{ fontSize: 60 }} />,
      href: CALENDAR,
    },
  ];

  if (hasDocs) {
    secondItems.push({
      title: "MY DOCUMENTS",
      desc: (
        <>
          Docs and files shared
          <br /> with you
        </>
      ),
      value: <FolderIcon sx={{ fontSize: 60 }} />,
      href: MY_DOCUMENTS,
    });
  }

  if (!hasProductionEnv) {
    secondItems.push({
      title: "MY SURVEYS",
      desc: (
        <>
          Your active <br />
          and completed surveys
        </>
      ),
      value: <ChatBubbleIcon sx={{ fontSize: 60 }} />,
      href: SURVEYS,
      buttonText: "CHECK SURVEYS",
    });
  }

  useEffect(() => {
    if (profile?.id) getMyDocumentAccess();
  }, [profile?.id]); //eslint-disable-line

  return (
    <>
      <Grid
        container
        rowSpacing={{ xs: 8 }}
        columnSpacing={{ xs: 2, sm: 2, md: 4, lg: 8 }}
      >
        {pending ? (
          <Loader />
        ) : (
          firstItems.map((item: IDot) => <DotItem key={item.title} {...item} />)
        )}

        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <RecentVacancies />
        </Grid>

        {pending ? (
          <Loader />
        ) : (
          secondItems.map((item: IDot) => (
            <DotItem key={item.title} {...item} />
          ))
        )}
      </Grid>
    </>
  );
}
