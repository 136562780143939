import { useSelector } from "react-redux";
import { Container, Page, TeamDataWrapper } from "@/components";
import { selectTeam } from "@/store/redux";

export function Team() {
  const { team } = useSelector(selectTeam);

  return (
    <Page title={team?.title}>
      <Container>
        <TeamDataWrapper />
      </Container>
    </Page>
  );
}
