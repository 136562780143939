import { PayloadAction } from "@reduxjs/toolkit";

export interface ITimeOffState {
  pending: boolean;
  error: any;
  paid_time_off: number;
  health_time_off: number;
  extra_days: number;
}

export interface ITimeOffStateSuccess {
  paid_time_off: number;
  health_time_off: number;
}

export interface ITimeOffStateError {
  error: any;
}

export const initialState: ITimeOffState = {
  pending: false,
  error: null,
  paid_time_off: 0,
  health_time_off: 0,
  extra_days: 0,
};

export const reducers = {
  getTimeOff: (state: ITimeOffState) => {
    return {
      ...state,
      paid_time_off: 0,
      health_time_off: 0,
      extra_days: 0,
      pending: true,
      error: "",
    };
  },
  getTimeOffSuccess: (
    state: ITimeOffState,
    action: PayloadAction<ITimeOffStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      ...(action.payload || {}),
    };
  },
  getTimeOffError: (
    state: ITimeOffState,
    action: PayloadAction<ITimeOffStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
