import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";
import {
  PostalServiceField,
  CityField,
  PostalCodeField,
  StreetField,
  HouseField,
  ApartmentField,
} from "./Fields";

export function RecipientTypeFields() {
  const { watch } = useFormContext();
  const deliveryType = watch("delivery_type");
  const hasRecipientType = deliveryType === 1;

  if (!hasRecipientType) {
    return null;
  }

  return (
    <>
      <Grid item xs={12}>
        <PostalServiceField />
      </Grid>
      <Grid item xs={12}>
        <CityField />
      </Grid>
      <Grid item xs={12}>
        <PostalCodeField />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StreetField />
      </Grid>
      <Grid item xs={6} sm={3}>
        <HouseField />
      </Grid>
      <Grid item xs={6} sm={3}>
        <ApartmentField />
      </Grid>
    </>
  );
}
