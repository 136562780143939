import { PayloadAction } from "@reduxjs/toolkit";

export interface INewsCommentLike {
  commentId: number;
  likes: number;
  has_like: boolean;
}

export interface INewsCommentLikeState {
  pending: boolean;
  error: any;
  like: INewsCommentLike;
}

export interface INewsCommentLikeStateError {
  error: any;
}

export const initialState: INewsCommentLikeState = {
  pending: false,
  error: null,
  like: {} as INewsCommentLike,
};

export const reducers = {
  postNewsCommentLike: (
    state: INewsCommentLikeState,
    action: PayloadAction<number | null | undefined>
  ) => {
    return {
      ...state,
      like: {} as INewsCommentLike,
      pending: true,
      error: "",
    };
  },
  postNewsCommentLikeSuccess: (
    state: INewsCommentLikeState,
    action: PayloadAction<INewsCommentLike>
  ) => {
    return {
      ...state,
      pending: false,
      like: action.payload,
    };
  },
  postNewsCommentLikeError: (
    state: INewsCommentLikeState,
    action: PayloadAction<INewsCommentLikeStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
