import { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { AxiosResponse } from "axios";
import { Select } from "@/shared/components";
import { apiInstance } from "@/store/configureStore";

export function DepartmentField() {
  const { control } = useFormContext();

  const [departments, setDepartments] = useState<any>([]);

  useEffect(() => {
    departmentsRequest()
      .then((departments) =>
        setDepartments([
          ...transformDepartments(departments),
          { value: -1, title: "I don't know" },
        ])
      )
      .catch(console.log);
  }, []);

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name="department"
      render={({ field }) => (
        <Select
          {...field}
          fullWidth
          disabled={!departments?.length}
          variant="filled"
          label="Delivery Type"
          options={departments}
        />
      )}
    />
  );
}

async function departmentsRequest() {
  const response: AxiosResponse = await apiInstance.get(
    "/ask-question/departments"
  );

  return response.data?.departments;
}

function transformDepartments(departments: { id: number; name: string }[]) {
  return (departments || []).map(({ id, name }) => ({
    value: id,
    title: name,
  }));
}
