import { useContext } from "react";
import { IconButton, useTheme, SxProps } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { AppThemeContext } from "@/context/AppTheme";

interface ISwitchThemeModeProps {
  sx?: SxProps;
}

export function SwitchThemeMode(props: ISwitchThemeModeProps) {
  const { setMode } = useContext(AppThemeContext);
  const { palette } = useTheme();

  return (
    <IconButton sx={{ ml: 1 }} onClick={setMode} color="inherit" {...props}>
      {palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
}
