import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Drawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  ReferralsVacancyActions,
  selectReferralsVacancy,
  PostReferralsActions,
  selectPostReferrals,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { useDevice } from "@/utils/hooks";
import { VacancyDrawerHeader, VacancyDrawerContent } from "./components";
import { ReferCandidate } from "../ReferCandidate";
import { ReferralsQA } from "../ReferralsQA";
import {
  DrawerHeader,
  DrawerHeaderContent,
  DrawerClose,
  DrawerContent,
  VacancyContentSection,
} from "./Vacancies.styled";

interface IVacancyDrawerProps {
  hasOpen: boolean;
  id: string | undefined | null;
  onClose: () => void;
}

export function VacancyDrawer(props: IVacancyDrawerProps) {
  const { id, hasOpen, onClose } = props;
  const { t } = useTranslation();
  const formRef = useRef<any>(null);
  const hasMobile = useDevice("down", "md");

  const { vacancy, pending } = useSelector(selectReferralsVacancy);
  const { hasRequested } = useSelector(selectPostReferrals);
  const { getReferralsVacancy, resetReferralsVacancy } = useActions<
    typeof ReferralsVacancyActions
  >(ReferralsVacancyActions);
  const { resetPostReferrals } =
    useActions<typeof PostReferralsActions>(PostReferralsActions);

  const faq = t("referralsFaq_2", { returnObjects: true });

  const handleOnFormScroll = () =>
    formRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });

  const handleOnClose = () => {
    onClose();

    setTimeout(resetReferralsVacancy, 500);
    setTimeout(resetPostReferrals, 500);
  };

  useEffect(() => {
    if (!id) return;

    getReferralsVacancy({ id });
  }, [id]); //eslint-disable-line

  return (
    <Drawer
      anchor="right"
      open={hasOpen}
      onClose={handleOnClose}
      PaperProps={{
        sx: {
          width: hasMobile ? "100%" : "900px",
          background: (theme) =>
            theme.modeColors({
              dark: theme.palette.primary.main,
              light: "#fff",
            }),
        },
      }}
    >
      <>
        <DrawerHeader>
          <DrawerHeaderContent>
            <VacancyDrawerHeader
              pending={pending}
              hasRequested={hasRequested}
              vacancy={vacancy}
            />
          </DrawerHeaderContent>
          <DrawerClose onClick={handleOnClose}>
            <CloseIcon />
          </DrawerClose>
        </DrawerHeader>
        <DrawerContent>
          <VacancyDrawerContent
            pending={pending}
            hasRequested={hasRequested}
            vacancy={vacancy}
            onClose={handleOnClose}
            onFormScroll={handleOnFormScroll}
          />

          {!pending && !hasRequested ? (
            <VacancyContentSection ref={formRef}>
              <ReferCandidate id={vacancy?.id} />
            </VacancyContentSection>
          ) : null}

          <Box sx={{ mt: 3 }}>
            <ReferralsQA title="WHAT YOU SHOULD KNOW" content={faq} />
          </Box>
        </DrawerContent>
      </>
    </Drawer>
  );
}
