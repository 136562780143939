import { useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { FormInput } from "@/shared/components";

export function Email(props: { pending: boolean }) {
  const { pending } = props;

  const {
    formState: { errors },
  } = useFormContext();

  const emailRegExp =
    /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;

  const emailErrorText = (error: any) => {
    if (error?.type === "required") {
      return "This field must contain valid email address";
    }

    if (error?.type === "pattern") {
      return "Please check the email address: it should look like iambright@brightgrove.com";
    }

    return null;
  };

  return (
    <FormInput
      name="email"
      rules={{
        required: true,
        validate: (value) => !!value?.trim(),
        pattern: emailRegExp,
      }}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          label="Email"
          variant="filled"
          disabled={pending}
          error={!!emailErrorText(errors.email)}
          helperText={emailErrorText(errors.email)}
        />
      )}
    />
  );
}
