import { styled } from "@mui/material/styles";
import { List, ListItemButton } from "@mui/material";

export const MenuList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 0),
  borderRight: "1px solid #E6E6E6",
}));

export const MenuItemTitle = styled("span")(({ theme }) => ({
  color: theme.modeColors({
    dark: "#fff",
    light: "#000",
  }),
  fontSize: "1rem",
  lineHeight: 1,
  fontWeight: 400,
  borderBottom: "1px solid transparent",
}));

export const MenuItem = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1, 0),

  "&:hover": {
    background: "none",

    [MenuItemTitle as any]: {
      borderBottomColor: theme.modeColors({
        dark: "#fff",
        light: "#000",
      }),
    },
  },

  "& .MuiTouchRipple-root": {
    display: "none",
  },

  "&.Mui-selected": {
    background: "none",

    "&:hover": {
      background: "none",
    },

    [MenuItemTitle as any]: {
      fontWeight: 500,
      borderBottomColor: theme.modeColors({
        dark: "#fff",
        light: "#000",
      }),
    },
  },
}));
