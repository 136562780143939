import { IReferralsFilters } from "@/store/redux";
import { FilterControl, Select } from "@/shared/components";
import { IFilters } from "../Positions";
import { FiltersTitle, FiltersTitleCol } from "./Filters.styled";

interface IDesktopProps extends IReferralsFilters {
  pending: boolean;
  values: IFilters;
  onChange: (v: { [k: string]: number }) => void;
}

export function Desktop(props: IDesktopProps) {
  const { values, locations, levels, pending, onChange } = props;

  const handleOnChange = (e: any) => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    onChange({ [name]: value });
  };

  return (
    <FiltersTitle>
      <FiltersTitleCol>Area</FiltersTitleCol>

      <FiltersTitleCol>
        <FilterControl
          label="Location:"
          control={
            <Select
              name="plocation"
              disabled={pending}
              value={values.plocation}
              options={locations}
              onChange={handleOnChange}
            />
          }
        />

        <FilterControl
          label="Seniority Level:"
          control={
            <Select
              name="plevel"
              disabled={pending}
              value={values.plevel}
              options={levels}
              onChange={handleOnChange}
            />
          }
        />
      </FiltersTitleCol>
    </FiltersTitle>
  );
}
