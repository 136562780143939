import { useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { GenerateField } from "./GenerateField";

export function PostalCodeField() {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldName = "postal_code";
  const hasError = !!errors[fieldName]?.type;

  return (
    <GenerateField
      errorText="Required field"
      name="postal_code"
      rules={{
        validate: (value) => !!value?.trim(),
      }}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          error={hasError}
          label="Postal Code"
          variant="filled"
          inputProps={{
            maxLength: 200,
          }}
        />
      )}
    />
  );
}
