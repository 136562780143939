import { Grid } from "@mui/material";
import {
  CountryField,
  DeliveryTypeField,
  RecipientField,
  PhoneNumberField,
  NoteField,
  Agreement,
} from "./Fields";
import { RecipientTypeFields } from "./RecipientTypeFields";
import { ServicePointTypeFields } from "./ServicePointTypeFields";
import { ParcelLockerTypeFields } from "./ParcelLockerTypeFields";

export function FormItems() {
  return (
    <>
      <Grid item xs={12}>
        <CountryField />
      </Grid>
      <Grid item xs={12}>
        <DeliveryTypeField />
      </Grid>
      <Grid item xs={12} sm={7}>
        <RecipientField />
      </Grid>
      <Grid item xs={12} sm={5}>
        <PhoneNumberField />
      </Grid>

      <RecipientTypeFields />
      <ServicePointTypeFields />
      <ParcelLockerTypeFields />

      <Grid item xs={12}>
        <NoteField />
      </Grid>
      <Grid item xs={12}>
        <Agreement />
      </Grid>
    </>
  );
}
