import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { INewsCommentsState, INewsCommentsStatePending } from "./reducers";
import {
  getNewsArticleComments,
  getNewsArticleCommentsError,
  getNewsArticleCommentsSuccess,
} from "./slice";
import { getNewsCommentsRequest } from "./api";

function* getNewsCommentsSaga(
  action: PayloadAction<INewsCommentsStatePending>
) {
  try {
    const comments: INewsCommentsState = yield call(
      getNewsCommentsRequest,
      action.payload
    );

    yield put(getNewsArticleCommentsSuccess(comments));
  } catch (error) {
    yield put(getNewsArticleCommentsError({ error }));
  }
}

function* NewsWatcher() {
  yield takeLatest(getNewsArticleComments.type, getNewsCommentsSaga);
}

export default NewsWatcher;
