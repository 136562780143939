import { styled } from "@mui/material/styles";
import { Snackbar } from "@mui/material";

interface IMessageSnackbar {
  $variant: string;
}

const maxWidth = 745;

export const Message = styled(Snackbar, {
  shouldForwardProp: (prop) => prop !== "$variant",
})<IMessageSnackbar>(({ theme, $variant }) => ({
  ...($variant === "error"
    ? {
        width: "calc(100% - 16px)",
        maxWidth: maxWidth,

        "& .MuiPaper-root": {
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(3),
          padding: theme.spacing(2, 3),
          background: theme.palette.yellowPink.main,
          color: theme.palette.common.black,
          boxShadow: "none",
          borderRadius: 0,
          flexWrap: "nowrap",

          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            textAlign: "center",
          },
        },
      }
    : {
        "& .MuiPaper-root": {
          textAlign: "center",
          padding: theme.spacing(1, 2),
          background: theme.palette.secondary.main,
          color: theme.palette.common.black,
          boxShadow: "none",
          borderRadius: 0,
          flexWrap: "nowrap",
        },
      }),

  "& .MuiSnackbarContent-action": {
    marginLeft: 0,
    paddingLeft: 0,
    marginRight: 0,
  },
}));

export const MessageTitle = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 900,

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

export const MessageDesc = styled("div")(({ theme }) => ({
  fontSize: "0.75rem",

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

export const MessageButton = styled("button")(({ theme }) => ({
  background: "transparent",
  border: `4px solid ${theme.palette.common.white}`,
  color: theme.palette.common.white,
  fontSize: "0.875rem",
  fontWeight: 900,
  padding: theme.spacing(1.5),
  minWidth: 190,
  textTransform: "uppercase",
  cursor: "pointer",
}));
