import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ProfileActions } from "@/store/redux";
import {
  INewsStateSuccess,
  INewsStatePending,
  INewsUpdateStatePending,
} from "./reducers";
import {
  getNews,
  getNewsError,
  getNewsSuccess,
  updateNews,
  updateNewsError,
  updateNewsSuccess,
} from "./slice";
import { getNewsRequest } from "./api";

function* getNewsSaga(action: PayloadAction<INewsStatePending>) {
  try {
    const news: INewsStateSuccess = yield call(getNewsRequest, action.payload);

    yield put(getNewsSuccess(news));
    yield put(ProfileActions.getProfile());
  } catch (error) {
    yield put(getNewsError({ error }));
  }
}

function* updateNewsSaga(action: PayloadAction<INewsUpdateStatePending>) {
  try {
    yield put(updateNewsSuccess(action.payload));
  } catch (error) {
    yield put(updateNewsError({ error }));
  }
}

function* NewsWatcher() {
  yield takeLatest(getNews.type, getNewsSaga);
  yield takeLatest(updateNews.type, updateNewsSaga);
}

export default NewsWatcher;
