import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IPostQuestionsState } from "./reducers";

export const postQuestionsSelector = (state: RootState): IPostQuestionsState =>
  state.postQuestions;

export const selectPostQuestions = createSelector(
  postQuestionsSelector,
  (postQuestionsState: IPostQuestionsState) => ({ ...postQuestionsState })
);
