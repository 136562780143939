import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function BagIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 22 24" {...props}>
      <path
        d="M0.799805 20.6218C0.799805 23.0523 2.00657 24.2617 4.40859 24.2617H17.9358C19.993 24.2617 21.1998 23.0406 21.1998 20.6218V8.46916C21.1998 6.05037 19.9816 4.82923 17.591 4.82923H15.7521C15.6947 2.36348 13.5915 0.261719 10.9941 0.261719C8.39665 0.261719 6.30493 2.36348 6.23597 4.82923H4.40859C2.00657 4.82923 0.799805 6.03863 0.799805 8.46916V20.6218ZM8.08634 4.82923C8.1438 3.30281 9.37355 2.04645 10.9941 2.04645C12.6146 2.04645 13.8558 3.30281 13.9018 4.82923H8.08634Z"
        fill="url(#paint0_linear_7803_7124)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7803_7124"
          x1="21.1998"
          y1="12.2617"
          x2="0.799805"
          y2="12.2617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9C6B9" />
          <stop offset="1" stopColor="#DEFF2B" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
