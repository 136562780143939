import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectPostQuestions, PostQuestionsActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { PopupMessage } from "@/shared/components";

export function AskQuestionErrorMessage() {
  const [showMessage, setShowMessage] = useState(false);
  const { error_message } = useSelector(selectPostQuestions);
  const { resetPostErrorQuestions } =
    useActions<typeof PostQuestionsActions>(PostQuestionsActions);

  const handleOnClose = () => {
    setShowMessage(false);
  };

  const handleOnExit = () => {
    resetPostErrorQuestions();
  };

  useEffect(() => {
    setShowMessage(!!error_message?.length);
  }, [error_message]); //eslint-disable-line

  return (
    <PopupMessage
      variant="error"
      open={showMessage}
      message={error_message}
      onClose={handleOnClose}
      onExit={handleOnExit}
    />
  );
}
