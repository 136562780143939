import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { IMarketplaceBrand } from "./reducers";
import {
  getMarketplaceBrand,
  getMarketplaceBrandError,
  getMarketplaceBrandSuccess,
} from "./slice";
import { getMarketplaceBrandRequest } from "./api";

function* getMarketplaceBrandSaga(action: PayloadAction<number>) {
  try {
    const brand: IMarketplaceBrand = yield call(
      getMarketplaceBrandRequest,
      action.payload
    );

    yield put(getMarketplaceBrandSuccess(brand));
  } catch (error) {
    yield put(getMarketplaceBrandError({ error }));
  }
}

function* MarketplaceBrandWatcher() {
  yield takeLatest(getMarketplaceBrand.type, getMarketplaceBrandSaga);
}

export default MarketplaceBrandWatcher;
