import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

export function Signout() {
  const { instance } = useMsal();

  useEffect(() => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }, []); //eslint-disable-line

  return null;
}
