import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { INewsArticleState } from "./reducers";

export const newsArticleSelector = (state: RootState): INewsArticleState =>
  state.newsArticle;

export const selectNewsArticle = createSelector(
  newsArticleSelector,
  (newsArticleState: INewsArticleState) => ({ ...newsArticleState })
);
