import { styled } from "@mui/material/styles";
import { Grid, LinearProgress, linearProgressClasses } from "@mui/material";

export const ListWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 0, 3),
}));

export const Header = styled("div")(({ theme }) => ({
  background: theme.modeColors({
    dark: "transparent",
    light: "#F9F9F9",
  }),
  borderBottom: `1px solid ${theme.palette.darkGrey.main}`,
  color: theme.palette.darkGrey.main,
  fontWeight: 500,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const Row = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  fontWeight: 500,

  [theme.breakpoints.down("md")]: {
    "& + &": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const Cell = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    justifyContent: "center",
  },
}));

export const Progress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  width: "100%",
  borderRadius: 4,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E6E6E6",
  },

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    background: theme.palette.yellowPink.main,
  },

  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(2),
  },
}));

export const User = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

export const MobileLabel = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 500,
  marginBottom: 4,
  color: theme.palette.darkGrey.main,
}));

export const NoCandidatesWrapper = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export const NoCandidatesTitle = styled("div")(({ theme }) => ({
  fontSize: "1.125rem",
  fontWeight: 700,
  marginBottom: theme.spacing(0.5),
}));

export const NoCandidatesSubtitle = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
}));
