import { ReactNode } from "react";
import {
  IconWithCountWrapper,
  IconWithCountValue,
} from "./IconWithCount.styled";

interface IIconWithCountProps {
  small?: boolean | undefined;
  icon: ReactNode;
  value: string | number;
}

export function IconWithCount(props: IIconWithCountProps) {
  const { icon, value, small } = props;

  return (
    <IconWithCountWrapper>
      {icon}
      <IconWithCountValue $small={small}>{value}</IconWithCountValue>
    </IconWithCountWrapper>
  );
}
