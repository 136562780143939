import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ITimeOffAvailableDaysStateSuccess,
  ITimeOffAvailableDaysStatePending,
} from "./reducers";
import {
  getTimeOffAvailableDays,
  getTimeOffAvailableDaysError,
  getTimeOffAvailableDaysSuccess,
  resetTimeOffAvailableDays,
  resetTimeOffAvailableDaysError,
  resetTimeOffAvailableDaysSuccess,
} from "./slice";
import { getTimeOffAvailableDaysRequest } from "./api";

function* getTimeOffAvailableDaysSaga(
  action: PayloadAction<ITimeOffAvailableDaysStatePending>
) {
  try {
    const days: ITimeOffAvailableDaysStateSuccess = yield call(
      getTimeOffAvailableDaysRequest,
      action.payload
    );

    yield put(getTimeOffAvailableDaysSuccess(days));
  } catch (error) {
    yield put(getTimeOffAvailableDaysError({ error }));
  }
}

function* resetTimeOffAvailableDaysSaga() {
  try {
    yield put(resetTimeOffAvailableDaysSuccess());
  } catch (error) {
    yield put(resetTimeOffAvailableDaysError());
  }
}

function* TimeOffAvailableDaysWatcher() {
  yield takeLatest(getTimeOffAvailableDays.type, getTimeOffAvailableDaysSaga);
  yield takeLatest(
    resetTimeOffAvailableDays.type,
    resetTimeOffAvailableDaysSaga
  );
}

export default TimeOffAvailableDaysWatcher;
