import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ITimesheetActivityState } from "./reducers";

export const timesheetActivitySelector = (
  state: RootState
): ITimesheetActivityState => state.timesheetActivity;

export const selectTimesheetActivity = createSelector(
  timesheetActivitySelector,
  (timesheetActivityState: ITimesheetActivityState) => ({
    ...timesheetActivityState,
  })
);
