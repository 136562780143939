import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { MoreButton } from "@/shared/components";
import {
  TimeOffListActions,
  selectTimeOffList,
  PostTimeOffActions,
  selectSwitchUser,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { TimeOffFilters } from "./TimeOffFilters";
import { TimeOffListItem } from "./TimeOffListItem";
import { TimeOffListHead } from "./TimeOffListHead";
import { CancelConfirm } from "../CancelConfirm";
import { EditRequest } from "../EditRequest";
import { useFilters } from "./hooks";
import {
  ListWrapper,
  ListBody,
  ListItem,
  LoadMoreWrapp,
} from "./TimeOffList.styled";

export function TimeOffList() {
  const { user } = useSelector(selectSwitchUser);
  const { list, poffset, ptotal, plimit, pending } =
    useSelector(selectTimeOffList);
  const { getTimeOffList } =
    useActions<typeof TimeOffListActions>(TimeOffListActions);
  const { resetPostTimeOff } =
    useActions<typeof PostTimeOffActions>(PostTimeOffActions);

  const [showEdit, setShowEdit] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [currentRequestId, setCurrentRequestId] = useState<number | null>(null);
  const { filters, handleOnFilters } = useFilters();

  const currentRequest = (list || []).filter(
    (request) => request.id === currentRequestId
  )[0];

  const getList = (params = {}) => {
    getTimeOffList({ plimit: 5, poffset: 0, ...params });
  };

  const handleOnEditOpen = (id: number) => {
    setCurrentRequestId(id);
    setShowEdit(true);
  };

  const handleOnEditClose = () => {
    setShowEdit(false);
    setCurrentRequestId(null);

    resetPostTimeOff();
  };

  const handleOnCancelOpen = (id: number) => {
    setCurrentRequestId(id);
    setShowCancel(true);
  };

  const handleOnCancelClose = () => {
    setShowCancel(false);
    setCurrentRequestId(null);
  };

  const handleOnMore = () => {
    getList({ ...filters, poffset: poffset + plimit });
  };

  const handleOnFilterChange = (e: any) => {
    const updatedFilters = {
      ...filters,
      [e.target.name]: e.target.value,
    };

    handleOnFilters(updatedFilters);

    getList({ ...updatedFilters, poffset: 0 });
  };

  useEffect(() => {
    getList({ poffset: 0 });
  }, [user]); //eslint-disable-line

  return (
    <>
      <EditRequest
        currentRequest={currentRequest}
        open={showEdit}
        onClose={handleOnEditClose}
      />

      <CancelConfirm
        currentRequest={currentRequest}
        open={showCancel}
        onClose={handleOnCancelClose}
      />

      <TimeOffFilters filters={filters} onChange={handleOnFilterChange} />

      <ListWrapper>
        <TimeOffListHead />

        <ListBody>
          {list?.length ? (
            list.map((item) => (
              <TimeOffListItem
                key={item.id}
                onEditOpen={handleOnEditOpen}
                onCancelOpen={handleOnCancelOpen}
                {...item}
              />
            ))
          ) : (
            <NotFound />
          )}
        </ListBody>
      </ListWrapper>

      <LoadMoreWrapp>
        <MoreButton
          sx={{ minWidth: 265 }}
          limit={plimit}
          offset={poffset}
          total={ptotal}
          loading={pending}
          onClick={handleOnMore}
        >
          Show more
        </MoreButton>
      </LoadMoreWrapp>
    </>
  );
}

function NotFound() {
  return (
    <ListItem>
      <Grid container>
        <Grid item xs={12}>
          Not found
        </Grid>
      </Grid>
    </ListItem>
  );
}
