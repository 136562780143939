import { PayloadAction } from "@reduxjs/toolkit";

export interface ITeamsItem {
  logo: string;
  title: string;
  id: number;
}

export interface ITeamsState {
  pending: boolean;
  error: any;
  teams: ITeamsItem[];
}

export interface ITeamsStateSuccess {
  teams: ITeamsItem[];
}

export interface ITeamsStateError {
  error: any;
}

export const initialState: ITeamsState = {
  pending: false,
  error: "",
  teams: [],
};

export const reducers = {
  getTeams: (state: ITeamsState) => {
    return {
      ...state,
      teams: [],
      pending: true,
      error: "",
    };
  },
  getTeamsSuccess: (
    state: ITeamsState,
    action: PayloadAction<ITeamsStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      teams: action.payload.teams,
    };
  },
  getTeamsError: (
    state: ITeamsState,
    action: PayloadAction<ITeamsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
