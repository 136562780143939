import { call, put, takeLatest } from "redux-saga/effects";
import { IPopularAuthorsState } from "./reducers";
import {
  getPopularAuthors,
  getPopularAuthorsError,
  getPopularAuthorsSuccess,
} from "./slice";
import { getPopularAuthorsRequest } from "./api";

function* getPopularAuthorsSaga() {
  try {
    const authors: IPopularAuthorsState = yield call(getPopularAuthorsRequest);

    yield put(getPopularAuthorsSuccess(authors));
  } catch (error) {
    yield put(getPopularAuthorsError({ error }));
  }
}

function* PopularAuthorsWatcher() {
  yield takeLatest(getPopularAuthors.type, getPopularAuthorsSaga);
}

export default PopularAuthorsWatcher;
