import { format, differenceInCalendarYears } from "date-fns";
import { TableCell, Skeleton } from "@mui/material";
import { IMyDocumentsItem } from "@/store/redux";
import { FileTitle } from "./FileTitle";
import { TableRowStyled, FileInfoCell } from "../MyDocuments.styled";

interface IMyDocumentsListItemProps {
  loading: boolean;
  file: IMyDocumentsItem;
  onClick: (k: IMyDocumentsItem) => void;
}

const getDate = (date: string) => {
  const hasDifference =
    differenceInCalendarYears(new Date(), new Date(date || 0)) < 0;
  const formattedDate = format(
    new Date(date || 0),
    `dd MMM${hasDifference ? " yyyy" : ""}, hh:mm:ss`
  );

  return formattedDate;
};

export function MyDocumentsListItem(props: IMyDocumentsListItemProps) {
  const { file, loading, onClick } = props;

  const modifiedDate = getDate(file?.properties?.TimeLastModified);

  const handleOnFileClick = (item: IMyDocumentsItem) => () => {
    onClick(item);
  };

  return (
    <TableRowStyled onClick={handleOnFileClick(file)}>
      <TableCell>
        <FileInfoCell>
          {loading ? (
            <Skeleton sx={{ width: "75%", height: 12 }} />
          ) : (
            <FileTitle file={file} />
          )}
        </FileInfoCell>
      </TableCell>

      <TableCell>
        {loading ? <Skeleton sx={{ width: 75, height: 12 }} /> : modifiedDate}
      </TableCell>
    </TableRowStyled>
  );
}
