import { useState, MouseEvent } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { DropdownMenuStylesProps } from "@/shared/components";

interface IActionsProps {
  id: number;
  hasCancel: boolean;
  hasEdit: boolean;
  onEditOpen: (k: number) => void;
  onCancelOpen: (k: number) => void;
}

export function Actions(props: IActionsProps) {
  const { id, hasCancel, hasEdit, onEditOpen, onCancelOpen } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOnActionClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOnEditOpen = () => {
    onEditOpen(id);
    setAnchorEl(null);
  };

  const handleOnCancelOpen = () => {
    onCancelOpen(id);
    setAnchorEl(null);
  };

  if (!hasCancel && !hasEdit) {
    return null;
  }

  return (
    <>
      <IconButton
        sx={{ padding: isMobile ? 0.5 : 0 }}
        onClick={handleOnActionClick}
      >
        {isMobile ? <MoreHorizIcon /> : <MoreVertIcon />}
      </IconButton>
      <Menu
        {...DropdownMenuStylesProps}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleOnMenuClose}
      >
        {hasEdit ? (
          <MenuItem onClick={handleOnEditOpen}>Edit request</MenuItem>
        ) : null}

        {hasCancel ? (
          <MenuItem onClick={handleOnCancelOpen}>Cancel request</MenuItem>
        ) : null}
      </Menu>
    </>
  );
}
