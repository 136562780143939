import { useState } from "react";
import { AxiosResponse } from "axios";
import { uniqBy } from "lodash";
import { format, startOfMonth, endOfMonth } from "date-fns";
import { apiInstance } from "@/store/configureStore";

interface IHoliday {
  start_date: string;
  end_date: string;
  title: string;
}

async function holidaysRequest(params: {
  start_date: string;
  end_date: string;
}) {
  const response: AxiosResponse = await apiInstance.get("/holidays", {
    params: {
      ...params,
    },
  });

  return response.data?.holidays;
}

export function useHolidays() {
  const [holidays, setHolidays] = useState<IHoliday[]>([]);

  const getHolidays = (date: Date | null) => {
    const dateFormat = "dd/MM/yyyy";
    const start_date = format(startOfMonth(date || 0), dateFormat);
    const end_date = format(endOfMonth(date || 0), dateFormat);

    holidaysRequest({ start_date, end_date })
      .then((holidays) => {
        setHolidays((prev) => {
          const updatedHolidays = uniqBy(
            [...prev, ...(holidays || [])].filter(Boolean),
            "start_date"
          );

          return updatedHolidays;
        });
      })
      .catch(console.log);
  };

  return { holidays, getHolidays };
}
