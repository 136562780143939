import { TIMEOFF, NEWS, FAQ } from "@/routes/consts";

export interface IMenuItem {
  link?: boolean;
  title: string;
  to: string;
}

export const firstNavigation: Array<IMenuItem> = [
  {
    link: true,
    title: "About Brightgrove",
    to: "https://brightgrove.com/about-us/",
  },
  {
    title: "Time Off Booking",
    to: TIMEOFF,
  },
  {
    title: "Company news",
    to: NEWS,
  },
  {
    title: "Portal FAQ",
    to: FAQ,
  },
];

export const secondNavigation: Array<IMenuItem> = [
  {
    link: true,
    title: "Give feedback or report a bug",
    to: "mailto:portal@brightgrove.com?subject=Bright%20Portal:%20Bugs%20%26%20Suggestions",
  },
];

export const externalLinks: Array<IMenuItem> = [
  {
    link: true,
    title: "Visit brightgrove.com",
    to: "https://brightgrove.com",
  },
  {
    link: true,
    title: "Visit careers.brightgrove.com",
    to: "https://careers.brightgrove.com",
  },
];
