import { Title } from "@/shared/components";

export function MoodMonitoringHead() {
  return (
    <>
      <Title fontSize="60px" sx={{ mb: 1 }}>
        MOOD MONITORING
      </Title>
    </>
  );
}
