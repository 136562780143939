import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Area,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import { format, parseISO, getDaysInMonth } from "date-fns";
import { Box } from "@mui/material";
import { CalendarToolbar } from "@/shared/components";
import {
  VeryBadSmileIcon,
  BadSmileIcon,
  NormalSmileIcon,
  GoodSmileIcon,
  FineSmileIcon,
} from "@/shared/icons";
import { secondaryMain } from "@/theme";
import { MoodCalendarActions, selectMoodCalendar } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { useChartToolbar } from "./hooks";
import { AreaChartWrapper } from "./MoodMonitoring.styled";

export function MoodMonitoringChart() {
  const { scores, pending } = useSelector(selectMoodCalendar);
  const { getMoodCalendar } =
    useActions<typeof MoodCalendarActions>(MoodCalendarActions);
  const { selectedDate, onDateChange } = useChartToolbar();

  const daysInTheMonth = getDaysInMonth(selectedDate);
  const monthDates = Array.from(Array(daysInTheMonth)).map((e, i) => i + 1);
  const transformedScores = (scores || [])
    .map((item) => ({
      ...item,
      date: parseInt(format(parseISO(item.date), "d")),
    }))
    .sort((a: any, b: any) => a.date - b.date);

  const CustomizedDot = (props: any) => {
    const { cx, cy, value } = props;

    const iconProps = {
      x: cx - 11,
      y: cy - 22,
      width: 22,
      height: 22,
    };
    const iconsMap: { [k: number]: any } = {
      1: <VeryBadSmileIcon {...iconProps} />,
      2: <BadSmileIcon {...iconProps} />,
      3: <NormalSmileIcon {...iconProps} />,
      4: <GoodSmileIcon {...iconProps} />,
      5: <FineSmileIcon {...iconProps} />,
    };

    return iconsMap[value[1]];
  };

  useEffect(() => {
    const formattedDate = format(selectedDate, "dd/MM/yyyy");

    getMoodCalendar({ pmonth: formattedDate });
  }, [selectedDate]); //eslint-disable-line

  return (
    <Box mb={4}>
      <CalendarToolbar
        date={selectedDate}
        pending={pending}
        onNavigate={onDateChange}
      />

      <ResponsiveContainer width="100%" height={350}>
        <AreaChartWrapper
          data={transformedScores}
          key={`area_chart_${+new Date()}`}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="20%" stopColor={secondaryMain} stopOpacity={1} />
              <stop offset="95%" stopColor={secondaryMain} stopOpacity={0.1} />
            </linearGradient>
          </defs>

          <XAxis
            dataKey="date"
            type="number"
            ticks={monthDates}
            domain={[1, daysInTheMonth]}
          />
          <YAxis ticks={[1, 2, 3, 4, 5]} domain={[1, 5]} width={20} />

          <CartesianGrid strokeDasharray="1 4" />

          <Area
            connectNulls
            type="linear"
            dataKey="score"
            stroke={secondaryMain}
            fillOpacity={1}
            fill="url(#colorUv)"
            dot={<CustomizedDot />}
          />
        </AreaChartWrapper>
      </ResponsiveContainer>
    </Box>
  );
}
