import { call, put, takeLatest } from "redux-saga/effects";
import { ITimeOffStateSuccess } from "./reducers";
import { getTimeOff, getTimeOffError, getTimeOffSuccess } from "./slice";
import { getTimeOffRequest } from "./api";

function* getTimeOffSaga() {
  try {
    const timeOff: ITimeOffStateSuccess = yield call(getTimeOffRequest);

    yield put(getTimeOffSuccess(timeOff));
  } catch (error) {
    yield put(getTimeOffError({ error }));
  }
}

function* TimeOffWatcher() {
  yield takeLatest(getTimeOff.type, getTimeOffSaga);
}

export default TimeOffWatcher;
