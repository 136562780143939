import { PayloadAction } from "@reduxjs/toolkit";

export interface ITeamMember {
  email: string;
  name: string;
  avatar: string;
  position: string;
  job_type: string; // full-time, part-time, etc.
  birthday: string;
  timeoffs: {
    start_date: string;
    end_date: string;
  };
}

export interface ITeamMembersState {
  pending: boolean;
  error: any;
  plimit: number;
  poffset: number;
  ptotal: number;
  psearch: string;
  team: ITeamMember[];
}

export interface ITeamMembersStatePending {
  id: number | string | undefined;
  plimit?: number;
  poffset?: number;
  psearch?: string;
}

export interface ITeamMembersStateSuccess {
  team: ITeamMember[];
  plimit: number;
  poffset: number;
  ptotal: number;
}

export interface ITeamMembersStateError {
  error: any;
}

export const initialState: ITeamMembersState = {
  pending: false,
  error: "",
  plimit: 10,
  poffset: 0,
  ptotal: 0,
  psearch: "",
  team: [],
};

export const reducers = {
  getTeamMembers: (
    state: ITeamMembersState,
    action: PayloadAction<ITeamMembersStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: "",
      ...action.payload,
    };
  },
  getTeamMembersSuccess: (
    state: ITeamMembersState,
    action: PayloadAction<ITeamMembersStateSuccess>
  ) => {
    const { team, poffset, ...restPayload } = action.payload;
    return {
      ...state,
      ...restPayload,
      pending: false,
      team: poffset ? [...(state.team || []), ...team] : team,
    };
  },
  getTeamMembersError: (
    state: ITeamMembersState,
    action: PayloadAction<ITeamMembersStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
