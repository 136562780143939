import { call, put, takeLatest } from "redux-saga/effects";
import { IRecentVacanciesStateSuccess } from "./reducers";
import {
  getRecentVacancies,
  getRecentVacanciesError,
  getRecentVacanciesSuccess,
} from "./slice";
import { getRecentVacanciesRequest } from "./api";

function* getRecentVacanciesSaga() {
  try {
    const vacancies: IRecentVacanciesStateSuccess = yield call(
      getRecentVacanciesRequest
    );

    yield put(getRecentVacanciesSuccess(vacancies));
  } catch (error) {
    yield put(getRecentVacanciesError({ error }));
  }
}

function* recentVacanciesWatcher() {
  yield takeLatest(getRecentVacancies.type, getRecentVacanciesSaga);
}

export default recentVacanciesWatcher;
