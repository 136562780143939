import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Select } from "@/shared/components";

const options = [
  "Thanks Bro, you're Pro!",
  "I thank you to the moon and back!",
  "Thank you! I believe in you. And unicorns. But especially you.",
  "I truly appreciate seeing your face every meeting.",
  "All bright wishes today go to you! Thanks for your job and help!",
];

export function ReadyNoteField() {
  const { reset, watch, getValues } = useFormContext();
  const [note, setNote] = useState("");

  const noteField = watch("text");

  const handleOnChange = (e: any) => {
    const value = e.target.value;
    const noteLength = noteField?.length;
    const valueLength = value?.length;

    setNote(value);

    if (noteLength + valueLength > 400) {
      return;
    }

    reset({
      ...getValues(),
      text: noteField?.length ? `${noteField}\n${value}` : value,
    });
  };

  return (
    <Select
      fullWidth
      variant="filled"
      label="Or select ready-made note"
      value={note}
      options={options}
      onChange={handleOnChange}
    />
  );
}
