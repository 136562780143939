import { put, takeLatest, delay } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ProfileActions } from "@/store/redux";
import switchUserUtils from "@/utils/switchUser";
import { ISwitchUserStatePending } from "./reducers";
import { switchUser, switchUserError, switchUserSuccess } from "./slice";

function* switchUserSaga(action: PayloadAction<ISwitchUserStatePending>) {
  try {
    yield put(switchUserSuccess(action.payload));
    yield switchUserUtils.setUserData(action.payload);

    yield delay(100);

    yield put(ProfileActions.getProfile());
  } catch (error) {
    yield put(switchUserError({ error }));
  }
}

function* switchUserWatcher() {
  yield takeLatest(switchUser.type, switchUserSaga);
}

export default switchUserWatcher;
