import { PayloadAction } from "@reduxjs/toolkit";

export interface IMarketplaceDonate {
  total: number;
  amount: number;
}

export interface IMarketplaceDonateState {
  pending: boolean;
  error: any;
  donate: IMarketplaceDonate;
}

export interface IMarketplaceDonateStateError {
  error: any;
}

export const initialState: IMarketplaceDonateState = {
  pending: false,
  error: "",
  donate: {} as IMarketplaceDonate,
};

export const reducers = {
  getMarketplaceDonate: (state: IMarketplaceDonateState) => {
    return {
      ...state,
      donate: {} as IMarketplaceDonate,
      pending: true,
      error: "",
    };
  },
  getMarketplaceDonateSuccess: (
    state: IMarketplaceDonateState,
    action: PayloadAction<IMarketplaceDonate>
  ) => {
    return {
      ...state,
      pending: false,
      donate: action.payload,
    };
  },
  getMarketplaceDonateError: (
    state: IMarketplaceDonateState,
    action: PayloadAction<IMarketplaceDonateStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
