import { call, put, takeLatest } from "redux-saga/effects";
import { ITeamsState } from "./reducers";
import { getTeams, getTeamsError, getTeamsSuccess } from "./slice";
import { getTeamsRequest } from "./api";

function* getTeamsSaga() {
  try {
    const teams: ITeamsState = yield call(getTeamsRequest);

    yield put(getTeamsSuccess(teams));
  } catch (error) {
    yield put(getTeamsError({ error }));
  }
}

function* TeamsWatcher() {
  yield takeLatest(getTeams.type, getTeamsSaga);
}

export default TeamsWatcher;
