import { Calendar } from "react-big-calendar";
import { styled, Theme } from "@mui/material/styles";
import { Button, ButtonGroup, darken, lighten } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { calendarStyles } from "@/shared/components";

const timeOffColors: any = (theme: Theme) => ({
  1: {
    background: lighten(theme.palette.secondary.main, 0.85),
    color: theme.palette.secondary.main,
  },
  2: {
    background: lighten(theme.palette.pink.main, 0.85),
    color: theme.palette.pink.main,
  },
  3: {
    background: lighten(theme.palette.violet.main, 0.85),
    color: darken(theme.palette.violet.main, 0.15),
  },
  holiday: {
    background: lighten("#F9C6B9", 0.85),
    color: "#F9C6B9",
  },
});

export const CalendarWrapper = styled(Calendar)(({ theme }) => ({
  ...calendarStyles(theme),

  "& .rbc-day-bg": {
    borderLeft: "1px solid #ddd",

    "&:last-of-type": {
      borderRight: "1px solid #ddd",
    },
  },
}));

export const CalendarToolbarWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(5),
  margin: theme.spacing(5, 0),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "start",
    gap: theme.spacing(2),
  },
}));

export const CalendarToolbarDate = styled("div")(({ theme }) => ({
  fontSize: "1.5rem",

  [theme.breakpoints.down("sm")]: {
    order: 1,
    width: "100%",
  },
}));

export const CalendarToolbarMonth = styled("span")(({ theme }) => ({
  fontWeight: 700,
}));

export const CalendarButtonsWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),

  [theme.breakpoints.down("sm")]: {
    order: 2,
    width: "100%",
  },
}));

export const CalendarToolbarButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  textTransform: "none",
  fontWeight: 500,
  fontSize: "1rem",
  lineHeight: 1,
  height: "42px",
  color:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.primary.main,
  borderColor:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.primary.main,

  "&:hover": {
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.common.white
        : theme.palette.primary.main,
    background:
      theme.palette.mode === "dark"
        ? theme.palette.primary.light
        : theme.palette.common.white,
  },

  "& svg": {
    fontSize: "0.875rem",
  },

  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
}));

export const CalendarToolbarButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
}));

export const DateTitle = styled("span")(({ theme }) => ({
  fontSize: "1rem",
}));

export const HolidayEvent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),

  "& svg": {
    fontSize: "1rem",
    color: theme.palette.error.main,
  },
}));

export const HolidayEventTitle = styled("div")(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const CalendarEvent = styled("div")<{ $type: number | string }>(
  ({ theme, $type }) => {
    const color = timeOffColors(theme)[$type]?.color || "#000";
    const backgroundColor = timeOffColors(theme)[$type]?.background || "#fff";

    return {
      display: "block",
      padding: "1px 1px 1px 4px",
      background: theme.palette.mode === "dark" ? color : backgroundColor,
      borderLeft: `8px solid ${color}`,
      color: theme.palette.primary.main,
      fontSize: "0.875rem",

      [theme.breakpoints.down("md")]: {
        background: color,
        padding: "2px",
        marginTop: 2,
      },
    };
  }
);

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "0.875rem",
  },
}));
