import { PayloadAction } from "@reduxjs/toolkit";

export interface IUserProfile {
  id: number;
  user_name: string;
  phone_mobile: string;
  country: number;
  avatar: string;
  since: string;
  position: string;
  lang: string;
  location: string;
  region: string[];
  project: {
    name: string;
    website: string;
    logo: string;
    domain: string;
    description: string;
  };
  job_type: {
    id: number;
    value: string;
  };
  time_offs: number;
  unread_news: number;
  referral_bonuses: number;
  education_balance: number;
  medical_insuranse_valid_till: string;
  permissions: string[];
  welcome_videos: {
    page: string;
    faq_id: number;
    has_viewed: boolean;
  }[];
  has_thanksgiving: boolean; // for showing thanksgiving modal, when marathon started
  has_thanksgiving_marathon: boolean; // for showing thanksgiving button
  theme: string; // dark, light
  hasMood: boolean; // false - for showing Mood popup, true - if user click to smile or close popup
  mood_score: number;
  holidays_country: number;
  surveys: {
    count: number;
    expire_date: string; // closest expire date from all surveys
  };
  default_team_id: number;
  news_subscriptions: number[];
  time_offs_types: [1 | 2 | 3];
}

export interface IProfileState {
  error: any;
  pending: boolean;
  profile: IUserProfile | null;
}

export interface IProfileStateError {
  error: any;
}

export interface IUpdateProfileStatePending {
  has_thanksgiving?: boolean;
  theme?: string;
}

export const initialState: IProfileState = {
  pending: false,
  error: "",
  profile: null,
};

export const reducers = {
  getProfile: (state: IProfileState) => ({
    ...state,
    pending: true,
    error: "",
  }),
  getProfileSuccess: (
    state: IProfileState,
    action: PayloadAction<IUserProfile>
  ) => {
    return {
      ...state,
      pending: false,
      profile: action.payload,
    };
  },
  getProfileError: (
    state: IProfileState,
    action: PayloadAction<IProfileStateError>
  ) => ({
    ...state,
    pending: false,
    error: action?.payload?.error,
  }),

  //UPDATE PROFILE
  updateProfile: (
    state: IProfileState,
    action: PayloadAction<IUpdateProfileStatePending>
  ) => ({
    ...state,
    pending: true,
    error: "",
  }),
  updateProfileSuccess: (
    state: IProfileState,
    action: PayloadAction<IUserProfile>
  ) => {
    return {
      ...state,
      pending: false,
      profile: action.payload,
    };
  },
  updateProfileError: (
    state: IProfileState,
    action: PayloadAction<IProfileStateError>
  ) => ({
    ...state,
    pending: false,
    error: action?.payload?.error,
  }),
};
