import { GradientButton } from "@/shared/components";
import {
  NoVacanciesSubtitle,
  NoVacanciesTitle,
  NoVacanciesWrapper,
} from "./Vacancies.styled";

interface INoVacanciesProps {
  onDialogOpen: () => void;
}

export function NoVacancies(props: INoVacanciesProps) {
  const { onDialogOpen } = props;

  return (
    <>
      <NoVacanciesWrapper>
        <NoVacanciesTitle>
          Oops! We don't have any open positions in this area
        </NoVacanciesTitle>
        <NoVacanciesSubtitle>
          You can still refer a candidate. Just click the button below!
        </NoVacanciesSubtitle>
      </NoVacanciesWrapper>

      <GradientButton sx={{ minWidth: 270 }} onClick={onDialogOpen}>
        REFER A CANDIDATE
      </GradientButton>
    </>
  );
}
