import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  differenceInCalendarDays,
} from "date-fns";
import { sortBy, groupBy } from "lodash";
import { ITeamsItem, IEmployeeCalendarDate } from "@/store/redux";

export function getPeriod(date: Date, type?: "week" | "month") {
  const dateFormat = "dd/MM/yyyy";

  const firstMonthDay = startOfMonth(new Date(date));
  const lastMonthDay = endOfMonth(new Date(date));

  const startDate = format(firstMonthDay, dateFormat);
  const endDate = format(lastMonthDay, dateFormat);

  return { startDate, endDate };
}

export function getFlagEmoji(countryCode: string) {
  return (countryCode || "")
    .toUpperCase()
    .replace(/./g, (ch) =>
      String.fromCodePoint(0x1f1a5 + ch.toUpperCase().charCodeAt(0))
    );
}

export function getEventDate({
  start_date,
  end_date,
}: {
  start_date: string;
  end_date: string;
}) {
  const dateFormat = "dd MMM yyyy";
  const startDate = format(new Date(start_date), dateFormat);
  const endDate = format(new Date(end_date), dateFormat);

  return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
}

export function getEventTypeTitle(eventType: string) {
  const labelsMap: { [k: string]: any } = {
    health_time_off: "Health Time Off",
    paid_time_off: "Paid Time Off",
    birthday: "Birthday",
    anniversary: "Anniversary",
    holiday: "Holiday",
  };

  return labelsMap[eventType] || "";
}

export function transformTeams(teams: Array<ITeamsItem>) {
  return (teams || []).map(({ id, title }) => ({
    title,
    value: id,
  }));
}

export function groupEvents(events: Array<IEmployeeCalendarDate>) {
  const expandedEvents = (events || [])
    .map((item) => {
      const start = new Date(item.start_date);
      const end = new Date(item.end_date);

      if (differenceInCalendarDays(end, start) > 0) {
        const days = eachDayOfInterval({
          start,
          end,
        });
        const expandedDays = (days || []).map((day) => ({
          ...item,
          start_date: day.toString(),
          end_date: day.toString(),
        }));

        return expandedDays;
      } else {
        return {
          ...item,
          start_date: new Date(item.start_date).toString(),
          end_date: new Date(item.end_date).toString(),
        };
      }
    })
    .flat();

  const groupByDate = groupBy(expandedEvents, "start_date");
  const groupedEvents = sortBy(
    Object.keys(groupByDate).map((key) => ({
      date: key,
      events: groupByDate[key as any],
    })),
    ({ date }) => new Date(date)
  );

  return groupedEvents;
}
