import {
  ITimesheetActivityStatePending,
  ITimesheetActivityItem,
  IHoliday,
} from "./reducers";
import { ITimeOffsDate } from "./interfaces";

export function getTimeOffsParams(payload: ITimesheetActivityStatePending) {
  const { start_date, end_date } = payload;

  return {
    ptype: -1,
    pstatus: 2,
    start_date,
    end_date,
  };
}

export function getTimeoffs(list: ITimeOffsDate[]) {
  const types: { [k: number | string]: string } = {
    1: "paid_time_off",
    2: "health_time_off",
  };

  const transformedList = (list || [])
    .filter((item) => Number(item.type) !== 3)
    .map((item) => ({ ...item, type: types[item.type] }));

  return transformedList;
}

export function getTimesheet(list: ITimesheetActivityItem[]) {
  const types: { [k: number | string]: string } = {
    1: "project",
    2: "outage",
  };

  const transformedList = (list || []).map((item) => ({
    ...item,
    type: types[item.type],
  }));

  return transformedList;
}

export function getHolidays(list: IHoliday[]) {
  const transformedList = (list || []).map((item) => ({
    ...item,
    type: "holiday",
  }));

  return transformedList;
}
