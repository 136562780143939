import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { INewsStatePending } from "./reducers";

const removeEmpty = (obj: {}) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => Boolean(v)));
};

export const getNewsRequest = async (params: INewsStatePending = {}) => {
  const { psubs, ...restParams } = params;
  const transformedParams = {
    ...(restParams || {}),
    psubs: (psubs || []).join(","),
  };
  const cleanParams = removeEmpty(transformedParams);

  const response: AxiosResponse = await apiInstance.get("/news", {
    params: cleanParams,
  });

  return response.data;
};
