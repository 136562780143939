import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Grid, Skeleton, useTheme, useMediaQuery } from "@mui/material";
import { ITimeOffListItem, selectTimeOffList } from "@/store/redux";
import { statusesMap, typesColorMap, typesMap } from "../consts";
import { Actions } from "./Actions";
import {
  ListItem,
  ListItemTitle,
  TypeDot,
  TypeValue,
  ItemDesc,
} from "./TimeOffList.styled";

interface ITimeOffListItemProps extends ITimeOffListItem {
  onEditOpen: (k: number) => void;
  onCancelOpen: (k: number) => void;
}

export function TimeOffListItem(props: ITimeOffListItemProps) {
  const {
    id,
    booked_days,
    can_cancel,
    can_edit,
    date_string,
    description,
    status,
    type,
    onEditOpen,
    onCancelOpen,
  } = props;
  const { pending } = useSelector(selectTimeOffList);

  const getStatus = () => {
    return statusesMap[status];
  };
  const getType = () => {
    return typesMap[type];
  };
  const getTypeDotColor = () => {
    return typesColorMap[type];
  };

  return (
    <ListItem key={date_string}>
      <Grid
        container
        spacing={2}
        sx={{ alignItems: { xs: "flex-start", md: "center" } }}
      >
        <Grid item xs={6} md={3.5} sx={{ order: 1 }}>
          <ItemHead>Date</ItemHead>
          {pending ? <Skeleton height={34} /> : date_string}
        </Grid>
        <Grid item xs={4} md={1.5} sx={{ order: 2 }}>
          <ItemHead>Days Booked</ItemHead>
          {pending ? <Skeleton height={34} /> : <>{booked_days} Days</>}
        </Grid>
        <Grid item xs={6} md={1.5} sx={{ order: { xs: 4, md: 3 } }}>
          <ItemHead>Status</ItemHead>
          {pending ? <Skeleton height={34} /> : getStatus()}
        </Grid>
        <Grid item xs={6} md={2} sx={{ order: { xs: 5, md: 4 } }}>
          <ItemHead>Type</ItemHead>
          {pending ? (
            <Skeleton height={34} />
          ) : (
            <TypeValue>
              <TypeDot $color={getTypeDotColor()} />
              {getType()}
            </TypeValue>
          )}
        </Grid>
        <Grid item xs={12} md={3} sx={{ order: { xs: 6, md: 5 } }}>
          {description ? (
            <>
              <ItemHead>Description</ItemHead>
              {pending ? (
                <Skeleton height={34} />
              ) : (
                <ItemDesc>{description}</ItemDesc>
              )}
            </>
          ) : null}
        </Grid>
        <Grid
          item
          xs={2}
          md={0.5}
          sx={{
            order: { xs: 3, md: 6 },
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {pending ? (
            <Skeleton height={34} />
          ) : (
            <Actions
              id={id}
              hasCancel={can_cancel}
              hasEdit={can_edit}
              onEditOpen={onEditOpen}
              onCancelOpen={onCancelOpen}
            />
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
}

function ItemHead(props: PropsWithChildren) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("md"));

  return isMobile ? <ListItemTitle>{props.children}</ListItemTitle> : null;
}
