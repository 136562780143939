import { useSelector } from "react-redux";
import { selectTaskReporting, selectPostTaskReporting } from "@/store/redux";
import { GradientButton } from "@/shared/components";
import {
  MessageBg,
  MessageWrapper,
  ErrorMessageWrapper,
} from "../TaskReporting.styled";

interface IMessagesProps {
  date: string;
}

function GotItButton() {
  const handleOnClick = () => {
    window.location.href = "https://portal.brightgrove.com";
  };

  return (
    <GradientButton sx={{ mt: 1, minWidth: 270 }} onClick={handleOnClick}>
      Got it
    </GradientButton>
  );
}

export function Messages(props: IMessagesProps) {
  const { date } = props;

  const { alreadyReported, error: getError } = useSelector(selectTaskReporting);
  const { hasReported, error: postError } = useSelector(
    selectPostTaskReporting
  );

  const hasGetError = getError?.statusCode && getError?.statusCode >= 500;
  const hasPostError = postError?.statusCode && postError?.statusCode >= 500;
  const hasErrors = hasGetError || hasPostError;

  if (alreadyReported) {
    return (
      <>
        <MessageWrapper>
          <MessageBg>
            You already reported tasks for <b>{date}</b>
          </MessageBg>
        </MessageWrapper>

        <GotItButton />
      </>
    );
  }

  if (hasReported) {
    return (
      <>
        <MessageWrapper>
          <MessageBg>
            Congratulations! Your report has been successfully submitted
          </MessageBg>
        </MessageWrapper>

        <GotItButton />
      </>
    );
  }

  if (hasErrors) {
    return (
      <ErrorMessageWrapper>
        <MessageBg>Something went wrong. Please, try again later</MessageBg>
      </ErrorMessageWrapper>
    );
  }

  return null;
}
