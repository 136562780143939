import {
  FAQ,
  MARKETPLACE,
  NEWS,
  PROFILE,
  TIMEOFF,
  TEAMS,
  TIMESHEET,
  MOOD,
  CALENDAR,
} from "@/routes/consts";
import { appConfig } from "@/config/appConfig";

export interface IMenuItem {
  title: string;
  to: string;
  hidden?: boolean;
  external?: boolean;
}

const { WIKI_LINK, HELPDESK_LINK, MARKETPLACE_ENABLE } = appConfig;

const hasMarketplaceAvailable = MARKETPLACE_ENABLE === "true";

export const menuItems: IMenuItem[] = [
  {
    title: "Profile",
    to: PROFILE,
  },
  {
    title: "Time Off Booking",
    to: TIMEOFF,
  },
  {
    title: "Latest News",
    to: NEWS,
  },
  {
    title: "Brightgrove Wiki",
    to: WIKI_LINK,
    external: true,
  },
  {
    title: "Help Desk",
    to: HELPDESK_LINK,
    external: true,
  },
  {
    title: "My Team",
    to: TEAMS,
  },
  {
    title: "Mood Score",
    to: MOOD,
  },
  {
    title: "Bright Marketplace",
    to: MARKETPLACE,
    hidden: !hasMarketplaceAvailable,
  },
  {
    title: "Timesheet",
    to: TIMESHEET,
  },
  {
    title: "Portal Faq",
    to: FAQ,
  },
  {
    title: "Calendar",
    to: CALENDAR,
  },
];
