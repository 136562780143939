import { styled } from "@mui/material/styles";

export const NewsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
}));

export const NewsContent = styled("div")(({ theme }) => ({
  flex: 1,
}));

export const NewsSidebar = styled("div")(({ theme }) => ({
  width: "270px",
  marginLeft: "120px",
}));

export const NewsSidebarContent = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const NewsSidebarSectionStyled = styled("div")(({ theme }) => ({
  "& + &": {
    marginTop: theme.spacing(5),
  },
}));

export const NewsSidebarSectionContent = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
}));
