import type { IPostTaskReporting } from "./reducers";
import Axios, { AxiosResponse } from "axios";
import { appConfig } from "@/config/appConfig";

export const postTaskReportingRequest = async (params: IPostTaskReporting) => {
  const { id, reporting } = params || {};

  const response: AxiosResponse = await Axios.post(
    `${appConfig.API_URL}/task-reporting/${id}`,
    {
      reporting,
    }
  );

  return response.data;
};
