import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IMarketplaceDonateState } from "./reducers";

export const marketplaceDonateSelector = (
  state: RootState
): IMarketplaceDonateState => state.marketplaceDonate;

export const selectMarketplaceDonate = createSelector(
  marketplaceDonateSelector,
  (marketplaceDonateState: IMarketplaceDonateState) => ({
    ...marketplaceDonateState,
  })
);
