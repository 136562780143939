import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import { useDevice } from "@/utils/hooks";
import { IBreadcrumb } from "../MyDocuments";

interface IMyDocumentsBreadcrumbsProps {
  data: Array<IBreadcrumb>;
  onSelect: (k: IBreadcrumb) => void;
}

export function MyDocumentsBreadcrumbs(props: IMyDocumentsBreadcrumbsProps) {
  const { data, onSelect } = props;

  const hasMobile = useDevice("down", "sm");

  const handleOnClick =
    (item: IBreadcrumb) =>
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();

      onSelect(item);
    };

  const breadcrumbs = data.map((item, idx) =>
    idx === data.length - 1 ? (
      <Typography key={item.url} color="text.primary">
        {item.title}
      </Typography>
    ) : (
      <Link
        key={item.url}
        underline="hover"
        href={item.url}
        onClick={handleOnClick(item)}
      >
        {item.title}
      </Link>
    )
  );

  return (
    <Breadcrumbs
      maxItems={hasMobile ? 3 : 10}
      separator={<NavigateNextIcon fontSize="small" />}
      sx={{ margin: (theme) => theme.spacing(1, 0, 3) }}
    >
      {breadcrumbs?.length ? (
        <Link
          key="home"
          underline="none"
          href=""
          onClick={handleOnClick({ title: "", url: "" })}
        >
          <HomeIcon sx={{ display: "flex" }} />
        </Link>
      ) : null}

      {breadcrumbs}
    </Breadcrumbs>
  );
}
