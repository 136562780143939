import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "Profile",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getProfile,
  getProfileError,
  getProfileSuccess,
  updateProfile,
  updateProfileError,
  updateProfileSuccess,
} = slice.actions;

reducerRegistry.register("profile", reducer);
