import { Select } from "@/shared/components";
import { IFieldProps } from "../interfaces";
import { GenerateField } from "./GenerateField";

const options = [
  {
    title: "Working Time",
    value: "project",
  },
  {
    title: "Power Outage",
    value: "outage",
  },
];

export function RecordingTypeField(props: IFieldProps) {
  const { pending, selectedEvent } = props;

  return (
    <GenerateField
      name="type"
      render={({ field }) => (
        <Select
          {...field}
          fullWidth
          disabled={Boolean(pending || selectedEvent?.id)}
          variant="filled"
          label="Recording Type"
          options={options}
        />
      )}
    />
  );
}
