import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const ButtonStyles = ({ theme }: any) => ({
  fontSize: "0.875rem",
  textTransform: "none",
  padding: 0,
  minWidth: 0,
});

export const AddMoreButton = styled(Button)(({ theme }) => ({
  ...(ButtonStyles({ theme }) as any),
  color: theme.modeColors({
    dark: theme.palette.secondary.main,
    light: theme.palette.primary.main,
  }),
  fontWeight: 500,
}));

export const RemoveButton = styled(Button)(({ theme }) => ({
  ...(ButtonStyles({ theme }) as any),
  color: theme.palette.error.dark,
  fontWeight: 400,
  fontSize: "0.75rem",
}));

export const QuestionsWrapp = styled("div")(({ theme }) => ({}));

export const QuestionFieldWrapp = styled("div")(({ theme }) => ({
  margin: theme.spacing(1.5, 0),

  "&:last-of-type": {
    margin: 0,
  },
}));

export const QuestionFieldHead = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "baseline",
  gap: 8,
  fontWeight: 500,
  marginBottom: theme.spacing(1.5),
}));
