import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IEmployeeCalendarStateSuccess,
  IEmployeeCalendarStatePending,
} from "./reducers";
import {
  getEmployeeCalendar,
  getEmployeeCalendarError,
  getEmployeeCalendarSuccess,
} from "./slice";
import { getEmployeeCalendarRequest } from "./api";

function* getEmployeeCalendarSaga(
  action: PayloadAction<IEmployeeCalendarStatePending>
) {
  try {
    const events: IEmployeeCalendarStateSuccess = yield call(
      getEmployeeCalendarRequest,
      action.payload
    );

    yield put(getEmployeeCalendarSuccess(events));
  } catch (error) {
    yield put(getEmployeeCalendarError({ error }));
  }
}

function* EmployeeCalendarWatcher() {
  yield takeLatest(getEmployeeCalendar.type, getEmployeeCalendarSaga);
}

export default EmployeeCalendarWatcher;
