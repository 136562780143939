import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

export const Content = styled("div")``;

export const Title = styled("div")(({ theme }) => ({
  fontWeight: "700",

  [theme.breakpoints.down("sm")]: {
    fontWeight: "900",
    fontSize: "0.875rem",
  },
}));

export const Subtitle = styled("div")(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.darkGrey.main,

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

export const SubtitlePrefix = styled("span")(({ theme }) => ({
  color: theme.palette.grey[600],
  margin: "0 4px 0 0",
}));

export const Date = styled("div")(({ theme }) => ({
  color: theme.palette.grey[600],
}));
