import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectReferrals } from "@/store/redux";
import { Tabs } from "@/shared/components";
import {
  Candidates,
  Positions,
  ReferralsQA,
  VacancyDrawer,
} from "./components";
import { ReferralsBadge } from "./Referrrals.styled";

interface IReferralsActivityProps {
  hasOpenDialog: boolean;
  onDialogOpen: () => void;
  onDialogClose: () => void;
}

export function ReferralsActivity(props: IReferralsActivityProps) {
  const { hasOpenDialog, onDialogClose, onDialogOpen } = props;

  const { t } = useTranslation();
  const { id } = useParams();
  const { my_candidates } = useSelector(selectReferrals);

  const faq = t("referralsFaq", { returnObjects: true });

  const myCandidatesTitle = (
    <>
      {my_candidates ? <ReferralsBadge>{my_candidates}</ReferralsBadge> : ""}
      My Candidates
    </>
  );

  return (
    <>
      <VacancyDrawer id={id} hasOpen={hasOpenDialog} onClose={onDialogClose} />

      <Tabs
        titles={["Open Positions", myCandidatesTitle]}
        contents={[
          <Positions onDialogOpen={onDialogOpen} />,
          <Candidates onDialogOpen={onDialogOpen} />,
        ]}
        sx={{ mb: 8 }}
      />

      <ReferralsQA title="ABOUT OUR REFERRAL PROGRAM" content={faq} />
    </>
  );
}
