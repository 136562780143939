import { ComponentType, ReactNode, SyntheticEvent } from "react";
import {
  ToolbarProps,
  dateFnsLocalizer,
  DateCellWrapperProps,
  View,
  NavigateAction,
  SlotInfo,
  Event,
} from "react-big-calendar";
import {
  format,
  parse,
  getDay,
  addDays,
  startOfWeek as dateFnsStartOfWeek,
} from "date-fns";
import { useTheme, useMediaQuery } from "@mui/material";
import { EventDialog } from "./EventDialog";
import { EventCalendarToolbar } from "./EventCalendarToolbar";
import { DateTitle, CalendarWrapper } from "./EventCalendar.styled";
import "react-big-calendar/lib/css/react-big-calendar.css";

interface IEventCalendar {
  events: any[];
  pending: boolean;
  selectable?: boolean;
  eventDialogOpen?: boolean;
  eventDialogContent?: ReactNode;
  eventDialogTitle?: string | undefined;
  onEventDialogClose?: () => void;
  onNavigate?:
    | ((newDate: Date, view: View, action: NavigateAction) => void)
    | undefined;
  customEventWrapper?: ({ event }: any) => any;
  customDateCellWrapper?: ComponentType<DateCellWrapperProps> | undefined;
  onSelectSlot?: ((slotInfo: SlotInfo) => void) | undefined;
  onSelectEvent?:
    | ((event: Event, e: SyntheticEvent<HTMLElement>) => void)
    | undefined;
}

const locales = {
  "en-US": require("date-fns"),
};
const startOfWeek = () => dateFnsStartOfWeek(new Date(), { weekStartsOn: 1 });

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export function EventCalendar(props: IEventCalendar) {
  const {
    events,
    pending,
    selectable,
    customEventWrapper,
    customDateCellWrapper,
    eventDialogOpen,
    eventDialogTitle,
    eventDialogContent,
    onEventDialogClose,
    onNavigate,
    onSelectSlot,
    onSelectEvent,
    ...restProps
  } = props;

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("md"));

  const handleOnSelectSlot = (info: SlotInfo) => {
    if (onSelectSlot && onSelectSlot instanceof Function) {
      onSelectSlot(info);
    }
  };

  const customDayPropGetter = () => {
    return isMobile
      ? {
          style: {
            border: "none",
            "text-align": "center",
          },
        }
      : {};
  };

  const customTollbar = (props: ToolbarProps) => (
    <EventCalendarToolbar {...props} pending={pending} />
  );

  const customMonthEvent = ({ event }: any) => {
    return isMobile ? null : event.title;
  };

  const customDateHeader = ({ label }: any) => <DateTitle>{label}</DateTitle>;

  const customStartAccessor = (event: any) => {
    return new Date(event?.start_date);
  };

  const customEndAccessor = (event: any) => {
    return addDays(new Date(event?.end_date), 1);
  };

  const calendarProps = {
    startAccessor: customStartAccessor,
    endAccessor: customEndAccessor,
    dayPropGetter: customDayPropGetter,
    longPressThreshold: 200,
    views: {
      month: true,
    },
    formats: {
      weekdayFormat: (date: Date) => format(date, "eee"),
      dateFormat: (date: Date) => format(date, "d"),
    },
    components: {
      eventWrapper: customEventWrapper,
      dateCellWrapper: customDateCellWrapper,
      toolbar: customTollbar,
      month: {
        dateHeader: customDateHeader,
        event: customMonthEvent,
      },
    },
  };

  return (
    <>
      <EventDialog
        title={eventDialogTitle}
        content={eventDialogContent}
        open={!!eventDialogOpen}
        onClose={onEventDialogClose}
      />

      <CalendarWrapper
        {...calendarProps}
        {...restProps}
        showAllEvents
        defaultView="month"
        selectable={selectable}
        events={events}
        localizer={localizer}
        onNavigate={onNavigate}
        onSelectSlot={handleOnSelectSlot}
        onSelectEvent={onSelectEvent}
      />
    </>
  );
}
