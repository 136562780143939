import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Skeleton, useTheme, useMediaQuery } from "@mui/material";
import {
  PostTimesheetActivityActions,
  TimesheetActivityActions,
  selectPostTimesheetActivity,
  selectTimesheetActivity,
  ITimesheetActivityItem,
  selectUserProjects,
} from "@/store/redux";
import { useDialog } from "@/utils/hooks";
import { useActions } from "@/utils/redux";
import { CalendarToolbar, EventDialog } from "@/shared/components";
import { getPeriod, getDisabledDate } from "../helpers";
import { useWeekViewToolbar, useReporting } from "../hooks";
import { DayOffTypes } from "../DayOffTypes";
import { TimesheetEventDialogContent } from "../TimesheetEventDialogContent";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
import { HoursButton } from "../Timesheet.styled";

interface ITimeButtonProps {
  day: Date;
  reporting: ITimesheetActivityItem;
  hasTimeOff: boolean;
}

export function TimesheetWeekView() {
  const theme = useTheme();
  const hasMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { projects } = useSelector(selectUserProjects);
  const { hasPosted } = useSelector(selectPostTimesheetActivity);
  const { pending, list } = useSelector(selectTimesheetActivity);

  const { getTimesheetActivity } = useActions<typeof TimesheetActivityActions>(
    TimesheetActivityActions
  );
  const { resetPostTimesheetActivity } = useActions<
    typeof PostTimesheetActivityActions
  >(PostTimesheetActivityActions);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedEvent, setSelectedEvent] =
    useState<ITimesheetActivityItem | null>(null);

  const { currentDate, onDateChange } = useWeekViewToolbar();
  const [openDialog, onDialogOpen, onDialogClose] = useDialog();

  const { outageHours, projectsHours, totalColumnHours } = useReporting({
    type: "week",
    currentDate,
    userProjects: projects,
    timesheet: list,
  });

  const { startDate, endDate } = getPeriod(currentDate, "week");

  const totalColumnHoursObj: { [k: string | number]: number } = Object.assign(
    {},
    ...(totalColumnHours || [])
  );

  const handleOnDialogClose = () => {
    setSelectedEvent(null);
    onDialogClose();

    setTimeout(resetPostTimesheetActivity, 500);
  };

  const content = (
    <TimesheetEventDialogContent
      weekView
      hasPosted={hasPosted}
      selectedDate={selectedDate}
      selectedEvent={selectedEvent}
      onDialogClose={handleOnDialogClose}
    />
  );

  const TimeButton = (props: ITimeButtonProps) => {
    const { day, reporting, hasTimeOff } = props;
    const hasDisabled = getDisabledDate(day);

    const handleOnClick = () => {
      setSelectedDate(day);
      onDialogOpen();

      setSelectedEvent(reporting);
    };

    return (
      <HoursButton
        disabled={hasDisabled}
        $hasTimeOff={hasTimeOff}
        $hasEmpty={!reporting?.hours}
        onClick={handleOnClick}
      >
        {pending ? (
          <Skeleton height={12} width={24} />
        ) : (
          reporting?.hours || (hasDisabled ? null : "+")
        )}
      </HoursButton>
    );
  };

  useEffect(() => {
    getTimesheetActivity({ start_date: startDate, end_date: endDate });
  }, [currentDate]); // eslint-disable-line

  return (
    <>
      <EventDialog
        title="Time recording"
        open={openDialog}
        content={content}
        onClose={handleOnDialogClose}
      />

      <DayOffTypes />

      <CalendarToolbar
        weekView
        date={currentDate}
        pending={pending}
        onNavigate={onDateChange}
      />

      {hasMobile ? (
        <Mobile
          timeButton={TimeButton}
          currentDate={currentDate}
          outageHours={outageHours}
          projectsHours={projectsHours}
          totalColumnHours={totalColumnHoursObj}
        />
      ) : (
        <Desktop
          timeButton={TimeButton}
          currentDate={currentDate}
          outageHours={outageHours}
          projectsHours={projectsHours}
          totalColumnHours={totalColumnHours}
        />
      )}
    </>
  );
}
