import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Skeleton, Box } from "@mui/material";
import {
  SurveysListActions,
  selectSurveysList,
  selectProfile,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { Tabs } from "@/shared/components";
import { SurveysList } from "./SurveysList";

export function SurveysTabs() {
  const { profile } = useSelector(selectProfile);
  const { surveys, pending } = useSelector(selectSurveysList);
  const { getSurveysList } =
    useActions<typeof SurveysListActions>(SurveysListActions);

  useEffect(() => {
    if (profile?.id) {
      getSurveysList();
    }
  }, [profile?.id]); //eslint-disable-line

  if (pending) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Skeleton sx={{ width: "100%", height: "24px" }} />
        <Skeleton sx={{ width: "100%", height: "24px" }} />
        <Skeleton sx={{ width: "100%", height: "24px" }} />
      </Box>
    );
  }

  return (
    <Tabs
      titles={["Actual", "Finished"]}
      contents={[
        <SurveysList list={surveys["actual"]} />,
        <SurveysList hasFinished list={surveys["finished"]} />,
      ]}
      sx={{ mb: 8 }}
    />
  );
}
