import { call, put, takeLatest } from "redux-saga/effects";
import { IRecentItem } from "./reducers";
import {
  getFaqRecents,
  getFaqRecentsError,
  getFaqRecentsSuccess,
} from "./slice";
import { getFaqRecentsRequest } from "./api";

function* getFaqRecentSaga() {
  try {
    const recent: IRecentItem[] = yield call(getFaqRecentsRequest);

    yield put(getFaqRecentsSuccess(recent));
  } catch (error) {
    yield put(getFaqRecentsError({ error }));
  }
}

function* faqRecentWatcher() {
  yield takeLatest(getFaqRecents.type, getFaqRecentSaga);
}

export default faqRecentWatcher;
