import { useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { FormInput } from "@/shared/components";

export function FirstName(props: { pending: boolean }) {
  const { pending } = props;

  const {
    formState: { errors },
  } = useFormContext();

  const getErrorText = () => {
    if (
      errors.first_name?.type === "required" ||
      errors.first_name?.type === "validate"
    ) {
      return "This field must contain 2-100 symbols";
    }

    return null;
  };

  return (
    <FormInput
      name="first_name"
      rules={{
        required: true,
        validate: (value) => {
          const trimmedValue = (value || "").trim();
          const validValue =
            trimmedValue.length >= 2 && trimmedValue.length <= 100;

          return validValue;
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          label="First name"
          variant="filled"
          disabled={pending}
          error={!!getErrorText()}
          helperText={getErrorText()}
        />
      )}
    />
  );
}
