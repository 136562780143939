import { useEffect } from "react";
import { useSelector } from "react-redux";
import { NewsLocationsActions, selectNewsLocations } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { FilterControl, Select } from "@/shared/components";
import { INewsFilters } from "../NewsFeed";

interface ILocationsProps {
  disabled: boolean | undefined;
  value: INewsFilters["plocation"];
  onChange: (k: any) => void;
}

export function Locations(props: ILocationsProps) {
  const { disabled, value, onChange } = props;

  const { pending, locations } = useSelector(selectNewsLocations);
  const { getNewsLocations } =
    useActions<typeof NewsLocationsActions>(NewsLocationsActions);

  useEffect(() => {
    getNewsLocations();
  }, []); //eslint-disable-line

  return (
    <FilterControl
      label="Location:"
      control={
        <Select
          name="plocation"
          disabled={disabled || pending}
          options={locations}
          value={value}
          onChange={onChange}
        />
      }
    />
  );
}
