import { Box } from "@mui/material";
import { GradientButton } from "@/shared/components";
import { IDot } from "./Dots";
import {
  ItemTitle,
  DotTitle,
  LinkButton,
  LinkButtonExternal,
  GridStyled,
  BubbleStyled,
} from "./Dots.styled";

export function DotItem(props: IDot) {
  const { desc, href, title, value, external, buttonText } = props;
  const LinkComponent = (props: any) =>
    external ? (
      <LinkButtonExternal
        {...props}
        href={props.to}
        target="_blank"
        rel="noopener"
      />
    ) : (
      <LinkButton {...props} to={props.to} />
    );

  return (
    <GridStyled key={title} item xs={6} sm={4} md={3} lg={3}>
      <Box sx={{ mb: 2 }}>
        <DotTitle>{title}</DotTitle>
      </Box>

      {value ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "4px",
          }}
        >
          <BubbleStyled>{value}</BubbleStyled>
        </Box>
      ) : null}

      {desc ? (
        <Box sx={{ height: 42 }}>
          <ItemTitle>{desc}</ItemTitle>
        </Box>
      ) : null}

      <Box sx={{ mt: 2 }}>
        <LinkComponent to={href}>
          <GradientButton fullWidth>{buttonText || "SEE MORE"}</GradientButton>
        </LinkComponent>
      </Box>
    </GridStyled>
  );
}
