import { useNavigate } from "react-router-dom";
import Player from "react-player";
import { format, parseISO, differenceInCalendarYears } from "date-fns";
import { Avatar, Box, Skeleton } from "@mui/material";
import {
  UserCardSmall,
  LikeButton,
  ShareButton,
  Badge,
  CommentButton,
} from "@/shared/components";
import {
  FeedItemStyled,
  FeedItemNavigateArea,
  DateStyled,
  DescriptionStyled,
  Actions,
  TitleStyled,
  Tags,
} from "./Feed.styled";
import { appConfig } from "@/config/appConfig";

export interface IFeedItemProps {
  id: number;
  title: string;
  created_at: string;
  description: string;
  likes: number;
  has_like: boolean;
  has_comment?: boolean;
  comments?: number | undefined;
  tags?: string[];
  href?: string;
  author?: {
    avatar: string;
    name: string;
  };
  date?: string;
  image_url?: string;
  video_url?: string;
  loading?: boolean;
  isArticle?: boolean;
  isNews?: boolean;
  onLike?: (id: number, likes: number, hasLike: boolean) => void;
  onComment?: (k: string) => void;
  onTagSelect?: (k: any) => void;
}

export function FeedItem(props: IFeedItemProps) {
  const {
    isNews,
    isArticle,
    id,
    href = "/",
    author,
    title,
    created_at,
    description,
    image_url,
    video_url,
    likes,
    has_like,
    has_comment,
    comments,
    tags,
    loading,
    onLike,
    onComment,
    onTagSelect,
  } = props;
  const navigate = useNavigate();
  const hasLikeButton = onLike && onLike instanceof Function;
  const hasCommentButton = onComment && onComment instanceof Function;
  const hasOnTagSelect = onTagSelect && onTagSelect instanceof Function;
  const copyLink = `${appConfig.HOST}${href}`;

  const hasDifference = differenceInCalendarYears(
    new Date(),
    new Date(created_at || "")
  );
  const formatTemplate = hasDifference ? "dd MMM yyyy HH:mm" : "dd MMM HH:mm";
  const formattedPostDate = created_at
    ? format(parseISO(created_at), formatTemplate)
    : "";

  const AuthorComponent = () =>
    author ? (
      <UserCardSmall
        avatar={<Avatar src={author.avatar} sx={{ width: 48, height: 48 }} />}
        title={author.name}
        subtitle={formattedPostDate}
      />
    ) : null;

  const handleOnNavigate = () => {
    if (!isArticle) navigate(href);
  };

  const handleOnComment = () => {
    if (onComment && onComment instanceof Function) onComment(href);
  };

  const handleOnLike = (likes: number, hasLike: boolean) => {
    if (hasLikeButton) onLike(id, likes, hasLike);
  };

  const handleOnTagSelect = (value: string) => () => {
    if (hasOnTagSelect) onTagSelect({ target: { name: "ptag", value } });
  };

  if (loading) {
    return <LoadingItems />;
  }

  return id ? (
    <FeedItemStyled>
      {author && !isArticle ? (
        <Box mb={1}>
          <AuthorComponent />
        </Box>
      ) : null}

      <FeedItemNavigateArea $article={isArticle} onClick={handleOnNavigate}>
        <TitleStyled>{title}</TitleStyled>

        {author && isArticle ? (
          <Box mt={1} mb={1.5}>
            <AuthorComponent />
          </Box>
        ) : null}

        {formattedPostDate && !isNews ? (
          <DateStyled>{formattedPostDate}</DateStyled>
        ) : null}

        <DescriptionStyled>
          <div dangerouslySetInnerHTML={{ __html: description }} />

          {video_url ? (
            <Player
              controls
              url={video_url}
              width="100%"
              height="100%"
              volume={0.5}
              style={{ marginTop: "8px", aspectRatio: "16 / 9", width: "100%" }}
            />
          ) : null}

          {!isArticle && image_url ? <img src={image_url} alt="" /> : null}
        </DescriptionStyled>
      </FeedItemNavigateArea>

      {!isArticle && tags?.length ? (
        <Tags>
          {(tags || []).map((item) => (
            <Badge
              key={item}
              tag
              color="bluePink"
              onClick={handleOnTagSelect(item)}
            >
              #{item}
            </Badge>
          ))}
        </Tags>
      ) : null}

      <Actions>
        {hasLikeButton ? (
          <LikeButton count={likes} hasLike={has_like} onLike={handleOnLike} />
        ) : null}

        {hasCommentButton && (!isArticle || isNews) ? (
          <CommentButton
            hasComment={has_comment}
            count={comments}
            onComment={handleOnComment}
          />
        ) : null}

        <ShareButton link={copyLink} />
      </Actions>
    </FeedItemStyled>
  ) : null;
}

function LoadingItems() {
  return (
    <Box sx={{ mt: 5 }}>
      <Skeleton sx={{ fontSize: "1.5rem" }} width="75%" />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton sx={{ mt: 2 }} variant="rectangular" height="250px" />
    </Box>
  );
}
