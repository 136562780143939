import { PayloadAction } from "@reduxjs/toolkit";

export interface IPostQuestionsState {
  department: number | null; //department ID
  questions: string[];
  hasPosted: boolean;
  anonymous: boolean;
  pending: boolean;
  error_message: string;
  error: any;
}

export interface IPostQuestionsStatePending {
  department: number; //department ID
  questions: string[];
  anonymous: boolean;
}

export interface IPostQuestionsStateSuccess {
  error_message?: string;
}

export interface IPostQuestionsStateError {
  error: any;
}

export const initialState: IPostQuestionsState = {
  pending: false,
  error: null,
  hasPosted: false,
  department: null,
  questions: [],
  anonymous: false,
  error_message: "",
};

export const reducers = {
  postQuestions: (
    state: IPostQuestionsState,
    action: PayloadAction<IPostQuestionsStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      hasPosted: false,
      error_message: "",
      error: "",
      ...(action.payload || {}),
    };
  },
  postQuestionsSuccess: (
    state: IPostQuestionsState,
    action: PayloadAction<IPostQuestionsStateSuccess>
  ) => {
    const { error_message } = action.payload;

    return {
      ...state,
      pending: false,
      hasPosted: !error_message?.length,
      error_message: error_message || "",
    };
  },
  postQuestionsError: (
    state: IPostQuestionsState,
    action: PayloadAction<IPostQuestionsStateError>
  ) => ({
    ...state,
    pending: false,
    hasPosted: false,
    error: action.payload.error,
  }),

  //RESET
  resetPostQuestions: (state: IPostQuestionsState) => ({
    ...state,
    pending: false,
    error: null,
    hasPosted: false,
    department: null,
    questions: [],
    anonymous: false,
    error_message: "",
  }),
  resetPostQuestionsSuccess: (state: IPostQuestionsState) => ({
    ...state,
  }),
  resetPostQuestionsError: (state: IPostQuestionsState) => ({
    ...state,
  }),

  //Reset post error
  resetPostErrorQuestions: (state: IPostQuestionsState) => ({
    ...state,
    error_message: "",
  }),
  resetPostErrorQuestionsSuccess: (state: IPostQuestionsState) => ({
    ...state,
  }),
  resetPostErrorQuestionsError: (state: IPostQuestionsState) => ({
    ...state,
  }),
};
