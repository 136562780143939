import { Container, CheckoutForm, Page } from "@/components";

export function Checkout() {
  return (
    <Page title="Cart">
      <Container>
        <CheckoutForm />
      </Container>
    </Page>
  );
}
