import { styled } from "@mui/material/styles";
import { Link as MuiLink } from "@mui/material";

export const SocialWrapper = styled("div")(({ theme }) => ({
  order: 2,

  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    order: 0,
    marginBottom: theme.spacing(2),
  },
}));

export const SocialLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.common.white,
  textDecoration: "none",
  display: "inline-flex",
  margin: theme.spacing(0, 2),

  "&:hover, &:active": {
    color: theme.palette.secondary.main,
  },

  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(0, 1.5),
  },

  "& svg": {
    fontSize: "2.75rem",

    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "2.75rem",
    },
  },
}));
