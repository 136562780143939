import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ITeamState } from "./reducers";

export const teamSelector = (state: RootState): ITeamState => state.team;

export const selectTeam = createSelector(
  teamSelector,
  (teamState: ITeamState) => ({ ...teamState })
);
