import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { ITeamMembersStatePending } from "./reducers";

export const getTeamMembersRequest = async (
  params: ITeamMembersStatePending
) => {
  const { id, ...restParams } = params;
  const response: AxiosResponse = await apiInstance.get(
    `/teams/${id}/members`,
    {
      params: {
        ...restParams,
      },
    }
  );

  return response.data;
};
