import { Box } from "@mui/material";
import { UserData, Dots, ProfileContacts, Container, Page } from "@/components";

export function Profile() {
  return (
    <Page>
      <Container>
        <Box sx={{ mb: 5 }}>
          <UserData />
        </Box>

        <Box sx={{ mb: 8 }}>
          <ProfileContacts />
        </Box>

        <Box sx={{ mb: 10 }}>
          <Dots />
        </Box>
      </Container>
    </Page>
  );
}
