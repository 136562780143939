import { PayloadAction } from "@reduxjs/toolkit";
import { NEWS } from "@/routes/consts";

export interface INewsItem {
  id: number;
  author: {
    name: string;
    avatar: string;
  };
  created_at: string;
  title: string;
  description: string;
  likes: number;
  has_like: boolean;
  has_comment: boolean;
  comments: number;
  views: number;
  image_url: string;
}

export interface INewsState {
  pending: boolean;
  error: any;
  plocation: string | number | null | undefined;
  psubs: number[] | null;
  plang: string | null | undefined;
  psearch: string | null;
  plimit: number;
  poffset: number;
  ptotal: number;
  news: INewsItem[];
  subs: INewsItem[];
  unread: INewsItem[];
}

export interface INewsStatePending {
  poffset?: number;
  plimit?: number;
  plocation?: string | number | null | undefined;
  plang?: string | null | undefined;
  psearch?: string | null;
  psubs?: number[] | null;
}

export interface INewsStateSuccess {
  plocation: string | number | null | undefined;
  plang: string | null | undefined;
  psearch: string | null;
  psubs: number[];
  plimit: number;
  poffset: number;
  news: INewsItem[];
  subs: INewsItem[];
  unread: INewsItem[];
}

export interface INewsStateError {
  error: any;
}

export interface INewsUpdateStatePending {
  newsId: number;
  likes: number;
  has_like: boolean;
}

export const initialState: INewsState = {
  pending: false,
  error: "",
  plimit: 10,
  poffset: 0,
  ptotal: 0,
  plang: null,
  plocation: null,
  psearch: null,
  psubs: null,
  news: [],
  subs: [],
  unread: [],
};

function transformNews(data: INewsItem[]) {
  return (data || []).map((item) => ({
    ...item,
    href: `${NEWS}/${item.id}`,
  }));
}

function updateNews({
  currentNews,
  newsList,
}: {
  currentNews: INewsUpdateStatePending;
  newsList: INewsItem[];
}) {
  return (newsList || []).map((item) => {
    if (item.id === currentNews?.newsId) {
      return {
        ...item,
        ...currentNews,
      };
    }

    return item;
  });
}

export const reducers = {
  getNews: (state: INewsState, action: PayloadAction<INewsStatePending>) => {
    const { poffset, ...restPayload } = action.payload || {};

    return {
      ...state,
      ...restPayload,
      error: "",
      pending: true,
      poffset: poffset || 0,
      news: (poffset as any) > 0 ? state.news : [],
    };
  },
  getNewsSuccess: (
    state: INewsState,
    action: PayloadAction<INewsStateSuccess>
  ) => {
    const { poffset, news, subs, unread, ...restOptions } = action?.payload;
    const transformedNews = transformNews(news);
    const transformedSubs = transformNews(subs);
    const transformedUnread = transformNews(unread);

    return {
      ...state,
      ...restOptions,
      pending: false,
      unread: transformedUnread,
      subs: transformedSubs,
      news: poffset
        ? [...(state.news || []), ...transformedNews]
        : transformedNews,
    };
  },
  getNewsError: (
    state: INewsState,
    action: PayloadAction<INewsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),

  //UPDATE
  updateNews: (
    state: INewsState,
    action: PayloadAction<INewsUpdateStatePending>
  ) => ({
    ...state,
  }),
  updateNewsSuccess: (
    state: INewsState,
    action: PayloadAction<INewsUpdateStatePending>
  ) => {
    const { news, subs, unread } = state;
    const updatedNews = updateNews({
      currentNews: action.payload,
      newsList: news,
    });
    const updatedSubs = updateNews({
      currentNews: action.payload,
      newsList: subs,
    });
    const updatedUnread = updateNews({
      currentNews: action.payload,
      newsList: unread,
    });

    return {
      ...state,
      news: updatedNews,
      subs: updatedSubs,
      unread: updatedUnread,
    };
  },
  updateNewsError: (
    state: INewsState,
    action: PayloadAction<INewsStateError>
  ) => ({
    ...state,
    error: action.payload.error,
  }),
};
