import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function CommentIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M6.84289 19C6.22889 19 5.86817 18.5558 5.86817 17.8371V15.8423H5.5535C3.32777 15.8423 2 14.4532 2 12.0546V5.78765C2 3.38907 3.29707 2 5.60722 2H15.3928C17.7029 2 19 3.38907 19 5.78765V12.0546C19 14.4532 17.7029 15.8423 15.3928 15.8423H10.7494L8.11693 18.2974C7.59503 18.7819 7.27269 19 6.84289 19ZM7.21896 17.2233L9.65192 14.6955C9.97427 14.3401 10.2045 14.2594 10.665 14.2594H15.3467C16.7973 14.2594 17.4957 13.4922 17.4957 12.0062V5.8361C17.4957 4.35012 16.7973 3.5829 15.3467 3.5829H5.6456C4.19503 3.5829 3.50429 4.35012 3.50429 5.8361V12.0062C3.50429 13.4922 4.19503 14.2594 5.6456 14.2594H6.58962C7.02709 14.2594 7.21896 14.4451 7.21896 14.9216V17.2233Z"
        fill="#8E949A"
      />
    </SvgIcon>
  );
}

export function CommentIconFilled(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M5.86817 17.8371C5.86817 18.5558 6.22889 19 6.84289 19C7.27269 19 7.59503 18.7819 8.11693 18.2974L10.7494 15.8423H15.3928C17.7029 15.8423 19 14.4532 19 12.0546V5.78765C19 3.38907 17.7029 2 15.3928 2H5.60722C3.29707 2 2 3.38907 2 5.78765V12.0546C2 14.4532 3.32777 15.8423 5.5535 15.8423H5.86817V17.8371Z" />
    </SvgIcon>
  );
}
