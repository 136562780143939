import { styled } from "@mui/material/styles";
import { Filters } from "@/shared/components";

export const NewsFiltersWrapp = styled(Filters)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.common.white,
  margin: theme.spacing(2.5, 0, 5),
  padding: theme.spacing(1, 0, 1),
  gap: theme.spacing(1.5),
  position: "sticky",
  zIndex: 2,
  top: 101,
}));
