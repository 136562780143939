import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PostMoodActions, selectPostMood } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { PopupMessage } from "@/shared/components";

export function MoodMonitoringError() {
  const [showMessage, setShowMessage] = useState(false);
  const { error_message } = useSelector(selectPostMood);
  const { resetPostMood } = useActions<typeof PostMoodActions>(PostMoodActions);

  const handleOnClose = () => {
    setShowMessage(false);
  };

  const handleOnExit = () => {
    resetPostMood();
  };

  useEffect(() => {
    if (error_message) {
      setShowMessage(true);
    }
  }, [error_message]); //eslint-disable-line

  useEffect(() => {
    return () => {
      resetPostMood();
    };
  }, []); //eslint-disable-line

  return (
    <PopupMessage
      variant="error"
      open={showMessage}
      message={error_message}
      onClose={handleOnClose}
      onExit={handleOnExit}
    />
  );
}
