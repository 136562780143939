import { styled } from "@mui/material/styles";

export const TasksWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(-3, 0, 0),
}));

export const TaskEditWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(1.5, 0),

  [theme.breakpoints.down("sm")]: {
    alignItems: "stretch",
  },
}));

export const TaskEditButtons = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),

  "& button": {
    minWidth: "125px",

    "&:first-of-type": {
      minWidth: "180px",
      border: "4px solid #e6e6e6",

      "&:hover": {
        border: `4px solid ${theme.palette.darkGrey.main}`,
      },
    },
  },

  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(1),

    "& button": {
      flex: 1,
      minWidth: "auto",
    },
  },
}));

export const AddTaskWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),

  "& button": {
    textTransform: "none",
  },
}));
