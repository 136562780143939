import { useMemo, useState, useEffect, forwardRef } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { debounce } from "@mui/material/utils";

interface IAutocompleteAsyncProps {
  name?: string;
  label?: string;
  placeholder?: string;
  getRequest: (k: any) => Promise<any>;
  onChange?: (k: any) => void;
  getOptionLabel?: (k: any) => void;
  onOptionEqualToValue?: (k: any) => boolean;
}

export const AutocompleteAsync = forwardRef(
  (props: IAutocompleteAsyncProps, ref: any) => {
    const {
      name,
      label,
      placeholder,
      getRequest,
      getOptionLabel,
      onOptionEqualToValue,
      onChange,
    } = props;

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<any>([]);

    const handleOnChange = (event: any, newValue: any) => {
      setOptions(newValue ? [newValue, ...options] : options);
      setValue(newValue);

      onChange && onChange({ name, value: newValue });
    };

    const handleOnInputChange = (event: any, newInputValue: any) => {
      setInputValue(newInputValue);
    };

    const handleOnOptionLabel = (option: any) => {
      if (getOptionLabel && getOptionLabel instanceof Function) {
        return getOptionLabel(option);
      }

      return typeof option === "string" ? option : option.name;
    };

    const handleOnOptionEqualToValue = (option: any, value: any) => {
      if (onOptionEqualToValue && onOptionEqualToValue instanceof Function) {
        return onOptionEqualToValue(option);
      }

      return option.value === value.value;
    };

    const fetch = useMemo(
      () =>
        debounce(async (request: string, callback: (results?: any) => void) => {
          return callback(await getRequest(request));
        }, 400),
      [] //eslint-disable-line
    );

    useEffect(() => {
      let active = true;

      if (inputValue === "") {
        setOptions(value ? [value] : []);
        return undefined;
      }

      fetch(inputValue, (results?: any) => {
        if (active) {
          let newOptions: any = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...results];
          }

          setOptions(newOptions);
        }
      });

      return () => {
        active = false;
      };
    }, [value, inputValue, fetch]);

    return (
      <Autocomplete
        ref={ref}
        autoComplete
        includeInputInList
        options={options}
        value={value}
        getOptionLabel={handleOnOptionLabel}
        isOptionEqualToValue={handleOnOptionEqualToValue}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            variant="filled"
            fullWidth
          />
        )}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              boxShadow: "0 0 6px #ccc",
              borderRadius: 0,
              maxHeight: 420,
            },
          },
        }}
      />
    );
  }
);
