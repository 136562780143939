import { useDispatch } from "react-redux";

export function useActions<T>(actions: any) {
  const dispatch = useDispatch();

  return Object.keys(actions).reduce(
    (summ, curr) => ({
      ...summ,
      [curr]: (params: any) => dispatch(actions[curr](params)),
    }),
    {} as T
  );
}
