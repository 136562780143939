import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Title } from "@/shared/components";

export const TitleStyled = styled(Title)(({ theme }) => ({
  fontWeight: 500,

  [theme.breakpoints.down("md")]: {
    fontSize: "1.75rem",
  },
}));

export const LinkButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));

export const LinkButtonExternal = styled("a")(({ theme }) => ({
  textDecoration: "none",
}));

export const Vacancy = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0, 2.5),
  textTransform: "uppercase",
  fontSize: "0.875rem",

  "& + &": {
    padding: theme.spacing(2.5, 0, 0),
    borderTop: "1px solid #E6E6E6",
  },

  [theme.breakpoints.down("smd")]: {
    padding: theme.spacing(0, 0, 1.5),

    "& + &": {
      padding: theme.spacing(1.5, 0, 0),
    },
  },
}));

export const VacancyLink = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  textDecoration: "none",
  fontWeight: 900,
  textAlign: "left",
  display: "block",
}));

export const VacancyBonus = styled("span")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 1),
  marginTop: theme.spacing(0.25),
  fontSize: "0.75rem",
  background: theme.palette.secondary.main,
  color: theme.palette.common.black,
}));
