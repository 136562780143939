import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { Title, Bubble, GradientButton } from "@/shared/components";
import {
  TimeOffActions,
  selectTimeOff,
  selectSwitchUser,
  selectProfile,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { apiInstance } from "@/store/configureStore";
import { TimeOffDaysWrapp } from "./TimeOff.styled";

function s2ab(s: any) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);

  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }

  return buf;
}

export function TimeOffDays() {
  const { user } = useSelector(selectSwitchUser);
  const { profile } = useSelector(selectProfile);
  const { health_time_off, paid_time_off, extra_days, pending } =
    useSelector(selectTimeOff);
  const { getTimeOff } = useActions<typeof TimeOffActions>(TimeOffActions);

  const hasFulltime = profile?.job_type.id === 1;

  const exportTimeOff = () => {
    apiInstance.get("/time-off/export").then((res) => {
      const fileName = `${profile?.user_name} Paid Time Off.xlsx`;
      const file = new Blob([s2ab(atob(res.data?.file))], { type: "" });
      const fileUrl = URL.createObjectURL(file);
      const a = document.createElement("a");

      a.href = fileUrl;
      a.target = "_blank";
      a.download = fileName;

      document.body.appendChild(a);
      a.click();
    });
  };

  const handleOnExport = () => {
    exportTimeOff();
  };

  const days = [
    {
      color: "yellowBlue",
      value: pending ? (
        <SyncIcon sx={{ fontSize: 32 }} />
      ) : (
        <Title fontSize="60px">{paid_time_off}</Title>
      ),
      title: "Paid Time Off",
    },
    {
      color: "bluePink",
      value: pending ? (
        <SyncIcon sx={{ fontSize: 32 }} />
      ) : (
        <Title fontSize="60px">{health_time_off}</Title>
      ),
      title: "Health Time Off",
    },
    {
      color: "pinkViolet",
      value: pending ? (
        <SyncIcon sx={{ fontSize: 32 }} />
      ) : (
        <Title fontSize="60px">{extra_days}</Title>
      ),
      title: "Extra Days",
    },
  ];

  useEffect(() => {
    getTimeOff();
  }, [user]); //eslint-disable-line

  return (
    <>
      <Title fontStyle="normal">WELL-DESERVED DAYS</Title>
      <TimeOffDaysWrapp>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {days.map(({ color, value, title }) => (
            <Grid key={title} item xs={6} sm={4} md="auto">
              <Bubble color={color} sx={{ mb: 2 }}>
                {value}
              </Bubble>
              <p>{title}</p>
            </Grid>
          ))}
        </Grid>
      </TimeOffDaysWrapp>

      {hasFulltime ? (
        <Box>
          <GradientButton
            sx={{ minWidth: 275, width: { xs: "100%", sm: "auto" } }}
            onClick={handleOnExport}
          >
            Export Paid Time off History
          </GradientButton>
        </Box>
      ) : null}
    </>
  );
}
