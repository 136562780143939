import { useSelector } from "react-redux";
import { selectReferralsCandidates } from "@/store/redux";
import { Title, Collapse } from "@/shared/components";
import { CandidatesList } from "./CandidatesList";

export function History() {
  const { candidates, progress_total_steps } = useSelector(
    selectReferralsCandidates
  );
  const list = candidates.filter((item) => item.candidate_state === "history");

  return list.length ? (
    <Collapse
      title={<Title fontWeight="500">Referral history — {list.length}</Title>}
      content={
        <CandidatesList list={list} progressTotalSteps={progress_total_steps} />
      }
    />
  ) : null;
}
