import { orderBy } from "lodash";
import { IMyDocumentsItem } from "@/store/redux";

export function orderDocuments({
  data = [],
  type = "asc",
  orderByField,
}: {
  data: Array<IMyDocumentsItem>;
  type: "asc" | "desc" | undefined;
  orderByField: "name" | "modifiedDate";
}) {
  const { folders, files } = data.reduce(
    (
      acc: { folders: Array<IMyDocumentsItem>; files: Array<IMyDocumentsItem> },
      curr: IMyDocumentsItem
    ) => {
      const hasFolder = curr.type === "folder";

      return {
        ...acc,
        folders: hasFolder ? acc.folders : acc.folders.concat(curr),
        files: hasFolder ? acc.files.concat(curr) : acc.files,
      };
    },
    { folders: [], files: [] }
  );

  const orderFunc = (file: IMyDocumentsItem) =>
    orderByField === "name"
      ? file["name"]
      : +new Date(file?.["properties"]?.["TimeLastModified"]);

  const folderType = orderBy(folders, orderFunc, type);
  const fileType = orderBy(files, orderFunc, type);

  return [...folderType, ...fileType];
}
