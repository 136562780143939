import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectSwitchUser, TeamActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { TeamHeader } from "./TeamHeader";
import { TeamMembers } from "./TeamMembers";

export function TeamDataWrapper() {
  const { id } = useParams();
  const { user } = useSelector(selectSwitchUser);
  const { getTeam } = useActions<typeof TeamActions>(TeamActions);

  useEffect(() => {
    getTeam(id);
  }, [user]); //eslint-disable-line

  return (
    <>
      <TeamHeader />

      <TeamMembers />
    </>
  );
}
