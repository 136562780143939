import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectPostThanksGiving } from "@/store/redux";
import { PopupMessage } from "@/shared/components";
import { ErrorMessageDesc } from "./ThanksGivingDay.styled";

export function ThanksGivingDayErrorMessage() {
  const [showMessage, setShowMessage] = useState(false);
  const { error_message } = useSelector(selectPostThanksGiving);

  const handleOnClose = () => {
    setShowMessage(false);
  };

  useEffect(() => {
    setShowMessage(!!error_message);
  }, [error_message]); //eslint-disable-line

  return (
    <PopupMessage
      variant="error"
      open={showMessage}
      message={<ErrorMessageDesc>{error_message}</ErrorMessageDesc>}
      onClose={handleOnClose}
    />
  );
}
