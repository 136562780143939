import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { selectCart, CartActions, selectSwitchUser } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { CART, MARKETPLACE } from "@/routes/consts";
import {
  CartButtonIcon,
  CartButtonValue,
  CartButtonWrapp,
} from "./UserInfo.styled";

export function CartButton() {
  const navigate = useNavigate();
  const { products } = useSelector(selectCart);
  const { user } = useSelector(selectSwitchUser);
  const { getCart } = useActions<typeof CartActions>(CartActions);

  const cartCount =
    products?.reduce((acc, currentValue) => acc + currentValue?.quantity, 0) ||
    null;

  const handleOnCartClick = () => {
    navigate(cartCount ? CART : MARKETPLACE);
  };

  useEffect(() => {
    getCart();
  }, [user]); //eslint-disable-line

  return (
    <IconButton size="small" color="inherit" onClick={handleOnCartClick}>
      <CartButtonWrapp>
        <CartButtonIcon />
        <CartButtonValue>{cartCount}</CartButtonValue>
      </CartButtonWrapp>
    </IconButton>
  );
}
