import { format, subMonths } from "date-fns";
import {
  NewsContent,
  NewsSidebar,
  NewsSidebarContent,
  NewsSidebarSection,
  Newcomers,
  HotTopics,
  PopularAuthors,
} from "@/components";
import { Title, Description } from "@/shared/components";
import { NewsFeed } from "./NewsFeed";
import { desc, title } from "./News";

export function NewsDesktopView() {
  const prevMonth = format(subMonths(new Date(), 1), "MMMM");

  return (
    <>
      <NewsContent>
        <Title fontSize="60px" sx={{ mb: 1 }}>
          {title}
        </Title>

        <Description>{desc}</Description>

        <NewsFeed />
      </NewsContent>

      <NewsSidebar>
        <NewsSidebarContent>
          <NewsSidebarSection title="Actual news" content={<HotTopics />} />

          <NewsSidebarSection
            title="Popular authors"
            content={<PopularAuthors />}
          />

          <NewsSidebarSection
            title={`Newcomers in ${prevMonth}`}
            content={<Newcomers />}
          />
        </NewsSidebarContent>
      </NewsSidebar>
    </>
  );
}
