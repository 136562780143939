interface IStatusesMap {
  1: "Approving";
  2: "Approved";
  3: "Declined";
  4: "Cancelled";
  [k: string | number]: string;
}

export const statusesMap: IStatusesMap = {
  1: "Approving",
  2: "Approved",
  3: "Declined",
  4: "Cancelled",
};

interface ITypesMap {
  1: "Paid Time Off";
  2: "Health Time Off";
  3: "Unpaid Time Off";
  [k: string | number]: string;
}

export const typesMap: ITypesMap = {
  1: "Paid Time Off",
  2: "Health Time Off",
  3: "Unpaid Time Off",
};

interface ITypesColorMap {
  1: "yellow";
  2: "pink";
  3: "violet";
  [k: string | number]: string;
}

export const typesColorMap: ITypesColorMap = {
  1: "yellow",
  2: "pink",
  3: "violet",
};

interface ITypesGradientColorMap {
  1: "yellowBlue";
  2: "bluePink";
  3: "pinkViolet";
  [k: string | number]: string;
}

export const typesGradientColorMap: ITypesGradientColorMap = {
  1: "yellowBlue",
  2: "bluePink",
  3: "pinkViolet",
};
