import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { Title } from "@/shared/components";

export const TitleStyled = styled(Title)(({ theme }) => ({
  fontSize: 36,
  fontWeight: 500,

  [theme.breakpoints.down("sm")]: {
    fontSize: 30,
  },
}));

export const Product = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2.5),
  padding: theme.spacing(2, 0),

  [theme.breakpoints.down("smd")]: {
    borderBottom: "1px solid #ccc",
  },
}));

export const ProductImage = styled("div")(({ theme }) => ({
  width: "31%",

  "& img": {
    display: "block",
    maxWidth: "100%",
    width: "auto",
  },
}));

export const ProductContent = styled("div")(({ theme }) => ({
  flex: 1,
}));

export const ProductTitle = styled("div")(({ theme }) => ({
  position: "relative",
  paddingRight: theme.spacing(4),
  fontSize: "1.125rem",
  fontWeight: 700,
}));

export const ProductPrice = styled("div")(({ theme }) => ({
  fontSize: "1.25rem",
}));

export const ProductOptions = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
  margin: theme.spacing(1, 0),
}));

export const ProductOption = styled("div")(({ theme }) => ({}));

export const ProductOptionKey = styled("span")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  marginRight: theme.spacing(1),
}));

export const ProductOptionValue = styled("span")(({ theme }) => ({}));

export const ProductDesc = styled("div")(({ theme }) => ({
  fontSize: "0.75rem",
}));

export const FlexRow = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  padding: 2,
  position: "absolute",
  right: 0,
  top: 2,

  "& svg": {
    color: theme.palette.darkGrey.main,
    fontSize: 20,
  },
}));
