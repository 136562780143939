import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ITeamMembersState } from "./reducers";

export const teamMembersSelector = (state: RootState): ITeamMembersState =>
  state.teamMembers;

export const selectTeamMembers = createSelector(
  teamMembersSelector,
  (teamMembersState: ITeamMembersState) => ({ ...teamMembersState })
);
