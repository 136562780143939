import { Container, Page, TeamsHeader, TeamsList } from "@/components";

export function Teams() {
  return (
    <Page>
      <Container>
        <TeamsHeader />

        <TeamsList />
      </Container>
    </Page>
  );
}
