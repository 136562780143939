import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import {
  TextField,
  Autocomplete,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { apiInstance } from "@/store/configureStore";
import { GenerateField } from "./GenerateField";

export function UsersField() {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const [users, setUsers] = useState<any>([]);

  useEffect(() => {
    usersRequest()
      .then((users) => setUsers(transformUsers(users)))
      .catch(console.log);
  }, []);

  return (
    <GenerateField
      name="users"
      rules={{
        required: true,
        validate: (value) => !!value.length,
      }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          fullWidth
          multiple
          disableCloseOnSelect
          disabled={!users?.length}
          getOptionLabel={(option) => option.title}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          options={users}
          onChange={(evt, value) => field.onChange(value)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="filled"
                label={option.title}
                size="small"
                sx={{
                  background: palette.bluePink.main,
                  color: palette.common.black,

                  "& .MuiChip-deleteIcon": {
                    color: palette.common.black,

                    "&:hover": {
                      color: palette.common.black,
                    },
                  },
                }}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Colleague email"
              placeholder="Start typing ..."
            />
          )}
          ListboxProps={{
            style: {
              maxHeight: isMobile ? 130 : 270,
            },
          }}
        />
      )}
    />
  );
}

export const usersRequest = async () => {
  const response: AxiosResponse = await apiInstance.get("/thanksgiving/users");

  return response.data?.users;
};

function transformUsers(users: []) {
  return (users || [])
    .sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    })
    .map(({ id, name, email }) => ({
      value: id,
      title: `${name} (${email})`,
    }));
}
