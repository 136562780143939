import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Badge } from "@/shared/components";

export const ProductsList = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(7),
}));

export const ProductItem = styled("div")(({ theme }) => ({
  position: "relative",
}));

export const ProductItemStatus = styled(Badge)(({ theme }) => ({
  position: "absolute",
  top: -4,
  right: -4,
  zIndex: 1,
  borderRadius: 0,
  border: `4px solid ${theme.palette.common.white}`,
  fontSize: "1.125rem",
  fontWeight: 700,
  padding: theme.spacing(1, 2.5),

  [theme.breakpoints.down("sm")]: {
    border: `2px solid ${theme.palette.common.white}`,
    padding: theme.spacing(0.5, 1.5),
    fontSize: "0.875rem",
    fontWeight: 900,
    top: -2,
    right: -2,
  },
}));

export const ProductItemTitle = styled("div")(({ theme }) => ({
  fontSize: "1.25rem",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.125rem",
  },
}));

export const ProductItemPrice = styled("div")(({ theme }) => ({
  fontWeight: 900,
}));

export const ProductItemLink = styled(Link)(({ theme }) => ({
  color: theme.modeColors({
    dark: theme.palette.common.white,
    light: theme.palette.common.black,
  }),
  textDecoration: "none",
}));

export const LoadMoreWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: theme.spacing(5, 0),

  "& button": {
    minWidth: 275,
  },

  [theme.breakpoints.down("sm")]: {
    "& button": {
      width: "100%",
    },
  },
}));
