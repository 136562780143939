import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IFaqRecentState } from "./reducers";

export const faqRecentSelector = (state: RootState): IFaqRecentState =>
  state.faqRecent;

export const selectFaqRecent = createSelector(
  faqRecentSelector,
  (faqRecentState: IFaqRecentState) => ({ ...faqRecentState })
);
