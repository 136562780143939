import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { htmlEncode } from "@/utils/helpers";
import { Box } from "@mui/material";
import { useActions } from "@/utils/redux";
import { PostThanksGivingActions, selectPostThanksGiving } from "@/store/redux";
import { GradientButton } from "@/shared/components";
import { Anonymous, NoteField, ReadyNoteField, UsersField } from "./Fields";

interface IThanksGivingDayForm {
  text: string;
  users: any[];
  anonymous: boolean;
}

export function ThanksGivingDayForm() {
  const { pending } = useSelector(selectPostThanksGiving);
  const { postThanksGiving } = useActions<typeof PostThanksGivingActions>(
    PostThanksGivingActions
  );

  const methods = useForm<IThanksGivingDayForm>({
    defaultValues: {
      text: "",
      users: [],
      anonymous: false,
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSubmit = (data: IThanksGivingDayForm) => {
    const { text, users, anonymous } = data;
    const usersIds = users.map((item) => item?.value);
    const requestData = {
      users: usersIds,
      anonymous,
      text: htmlEncode(text),
    };

    postThanksGiving(requestData);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={1}>
          <NoteField />
        </Box>
        <Box mb={4}>
          <ReadyNoteField />
        </Box>
        <Box mb={4}>
          <UsersField />
        </Box>
        <Box mb={4}>
          <Anonymous />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <GradientButton
            type="submit"
            sx={{ width: { xs: "100%", sm: 270 } }}
            disabled={!isValid || pending}
          >
            Send
          </GradientButton>
        </Box>
      </form>
    </FormProvider>
  );
}
