import { styled } from "@mui/material/styles";

export const MessageWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
}));

export const MessageTitle = styled("div")(({ theme }) => ({
  fontWeight: 900,
  marginTop: theme.spacing(1),
}));

export const MessageDesc = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.darkGrey.main,
  fontWeight: 500,
  marginBottom: theme.spacing(2),
  maxWidth: 205,
}));
