import { IGradientButtonProps, GradientButton } from "@/shared/components";
import { SxProps } from "@mui/material";

interface IMoreButtonProps extends IGradientButtonProps {
  offset: number;
  limit: number;
  total: number;
  loading: boolean;
  sx?: SxProps;
}

export function MoreButton(props: IMoreButtonProps) {
  const { limit, loading, offset, total, children, ...restProps } = props;
  const hasPaging = limit + offset < total;

  return hasPaging || (!hasPaging && loading) ? (
    <GradientButton {...restProps} disabled={loading} loading={loading}>
      {children}
    </GradientButton>
  ) : null;
}
