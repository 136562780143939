import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { INewsCommentLikeState } from "./reducers";

export const newsCommentLikeSelector = (
  state: RootState
): INewsCommentLikeState => state.newsCommentLike;

export const selectNewsCommentLike = createSelector(
  newsCommentLikeSelector,
  (newsCommentLikeState: INewsCommentLikeState) => newsCommentLikeState.like
);
