import { useFormContext } from "react-hook-form";
import { TextField, Box } from "@mui/material";
import { GenerateField } from "./GenerateField";

export function NoteField() {
  const { setValue } = useFormContext();
  const name = "text";
  const maxValue = 400;
  const getCounter = (length: number) => (
    <Box component="span" sx={{ display: "block", textAlign: "right" }}>
      {length} / {maxValue}
    </Box>
  );

  const handleOnChange = (e: any) => {
    const value = e.target.value;

    if (value?.length > maxValue) {
      return;
    }

    setValue(name, value);
  };

  return (
    <GenerateField
      name={name}
      rules={{
        required: true,
        maxLength: maxValue,
        validate: (value) => !!value?.trim(),
      }}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          multiline
          minRows={6}
          maxRows={10}
          onChange={handleOnChange}
          helperText={getCounter(field.value?.length)}
          variant="filled"
          label=""
          placeholder="Add a note for your colleagues. Please, don't use emoji. Our portal doesn't understand them yet. (optional)"
        />
      )}
    />
  );
}
