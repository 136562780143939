import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { INewsArticle, INewsArticleStatePending } from "./reducers";
import {
  getNewsArticle,
  getNewsArticleError,
  getNewsArticleSuccess,
} from "./slice";
import { getNewsArticleRequest } from "./api";

function* getNewsArticleSaga(action: PayloadAction<INewsArticleStatePending>) {
  try {
    const article: INewsArticle = yield call(
      getNewsArticleRequest,
      action.payload
    );

    yield put(getNewsArticleSuccess(article));
  } catch (error) {
    yield put(getNewsArticleError({ error }));
  }
}

function* NewsArticleWatcher() {
  yield takeLatest(getNewsArticle.type, getNewsArticleSaga);
}

export default NewsArticleWatcher;
