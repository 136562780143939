import { format, parseISO } from "date-fns";
import { Box, Skeleton } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { CoffeeIcon, GiftIcon } from "@/shared/icons";
import { ITeamMember } from "@/store/redux";
import {
  CardWrapper,
  CardContent,
  CardDates,
  CardImage,
  CardJobType,
  CardJobTypeBadge,
  CardSubTitle,
  CardTitle,
  GradientButtonStyled,
  LinkButton,
} from "./Card.styled";

interface ICardProps extends ITeamMember {
  loading?: boolean;
}

export function Card(props: ICardProps) {
  const {
    loading,
    avatar,
    birthday,
    email,
    job_type,
    name,
    position,
    timeoffs,
    ...restProps
  } = props;

  const hasTimeoffs = timeoffs.start_date;
  const formattedBirthday = birthday
    ? format(parseISO(birthday), "d MMM")
    : null;
  const formattedTimeoffs = hasTimeoffs
    ? `${format(parseISO(timeoffs.start_date), "d MMM")} - ${format(
        parseISO(timeoffs.end_date),
        "d MMM yyyy"
      )}`
    : null;

  return (
    <CardWrapper {...restProps}>
      <CardContent>
        <CardJobType>
          {loading ? null : <CardJobTypeBadge>{job_type}</CardJobTypeBadge>}
        </CardJobType>

        {loading ? (
          <Skeleton
            sx={{ margin: "24px auto" }}
            animation="wave"
            variant="circular"
            width={96}
            height={96}
          />
        ) : (
          <CardImage src={avatar} />
        )}

        <CardTitle>{loading ? <Skeleton height={15} /> : name}</CardTitle>

        <CardSubTitle>
          {loading ? <Skeleton height={15} /> : position}
        </CardSubTitle>

        <CardDates>
          {loading ? (
            <Skeleton height={15} width={125} />
          ) : formattedBirthday ? (
            <>
              <GiftIcon />
              {formattedBirthday}
            </>
          ) : null}
        </CardDates>

        <CardDates>
          {loading ? (
            <Skeleton height={15} width={125} />
          ) : formattedTimeoffs ? (
            <>
              <CoffeeIcon />
              {formattedTimeoffs}
            </>
          ) : null}
        </CardDates>

        <Box sx={{ mb: 3, mt: 3, minHeight: 52 }}>
          {loading ? (
            <Skeleton height={35} width="100%" />
          ) : email ? (
            <LinkButton
              target="_blank"
              rel="noopener"
              href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}
            >
              <GradientButtonStyled startIcon={<PeopleIcon />}>
                Teams Chat
              </GradientButtonStyled>
            </LinkButton>
          ) : null}
        </Box>
      </CardContent>
    </CardWrapper>
  );
}
