import { call, put, takeLatest } from "redux-saga/effects";
import { IReferralsStateSuccess } from "./reducers";
import { getReferrals, getReferralsError, getReferralsSuccess } from "./slice";
import { getReferralsRequest } from "./api";

function* getReferralsSaga() {
  try {
    const referrals: IReferralsStateSuccess = yield call(getReferralsRequest);

    yield put(getReferralsSuccess(referrals));
  } catch (error) {
    yield put(getReferralsError({ error }));
  }
}

function* ReferralsWatcher() {
  yield takeLatest(getReferrals.type, getReferralsSaga);
}

export default ReferralsWatcher;
