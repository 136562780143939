import { call, put, takeLatest } from "redux-saga/effects";
import { IUserProjectsStateSuccess } from "./reducers";
import {
  getUserProjects,
  getUserProjectsError,
  getUserProjectsSuccess,
} from "./slice";
import { getUserProjectsRequest } from "./api";

function* getUserProjectsSaga() {
  try {
    const projects: IUserProjectsStateSuccess = yield call(
      getUserProjectsRequest
    );

    yield put(getUserProjectsSuccess(projects));
  } catch (error) {
    yield put(getUserProjectsError({ error }));
  }
}

function* UserProjectsWatcher() {
  yield takeLatest(getUserProjects.type, getUserProjectsSaga);
}

export default UserProjectsWatcher;
