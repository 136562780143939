import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import {
  Container,
  FaqArticleContent,
  FaqArticleSidebar,
  FaqArticleWrapper,
  Page,
} from "@/components";
import { Title, ShortTopics, FeedItem } from "@/shared/components";
import {
  FaqArticleActions,
  selectFaqArticle,
  FaqRecentActions,
  selectFaqRecent,
  FaqArticleLikeActions,
} from "@/store/redux";
import { useActions } from "@/utils/redux";

export function FaqArticle() {
  const { id } = useParams();
  const { article: faqArticle, pending: articlePending } =
    useSelector(selectFaqArticle);
  const { recent, pending: recentPending } = useSelector(selectFaqRecent);

  const { getFaqRecents } =
    useActions<typeof FaqRecentActions>(FaqRecentActions);
  const { getFaqArticle } =
    useActions<typeof FaqArticleActions>(FaqArticleActions);
  const { postFaqArticleLike } = useActions<typeof FaqArticleLikeActions>(
    FaqArticleLikeActions
  );

  const handleOnLike = () => {
    postFaqArticleLike(faqArticle.id);
  };

  useEffect(() => {
    if (id) getFaqArticle(id);
  }, [id]); //eslint-disable-line

  useEffect(() => {
    getFaqRecents();
  }, []); //eslint-disable-line

  return (
    <Page title={faqArticle.title}>
      <Container>
        <FaqArticleWrapper>
          <FaqArticleContent>
            <FeedItem
              isArticle
              {...faqArticle}
              loading={articlePending}
              onLike={handleOnLike}
            />
          </FaqArticleContent>

          <FaqArticleSidebar>
            <Box mt={2}>
              <Title fontStyle="normal">RECENT VIDEOS</Title>
            </Box>

            <ShortTopics data={recent} loading={recentPending} />
          </FaqArticleSidebar>
        </FaqArticleWrapper>
      </Container>
    </Page>
  );
}
