import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Avatar, Skeleton } from "@mui/material";
import { NewcomersActions, selectNewcomers } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { MoreButton } from "@/shared/components";
import { TeamsIcon } from "@/shared/icons";
import {
  NewcomerAvatar,
  NewcomerContent,
  NewcomerName,
  NewcomerPosition,
  NewcomerWrapper,
  NewcomersWrapper,
  NewcomerAction,
} from "./Newcomers.styled";

interface INewcomer {
  avatar: string;
  name: string;
  position: string;
  email: string;
}

export function Newcomers() {
  const { newcomers, plimit, poffset, ptotal, pending } =
    useSelector(selectNewcomers);
  const { getNewcomers } =
    useActions<typeof NewcomersActions>(NewcomersActions);

  const getNewEmployees = (params = {}) => {
    getNewcomers({ plimit: 5, poffset: 0, ...params });
  };

  const handleOnMore = () => {
    getNewEmployees({ poffset: poffset + plimit });
  };

  useEffect(() => {
    if (newcomers?.length) return;

    getNewEmployees();
  }, [newcomers]); //eslint-disable-line

  if (!newcomers?.length && pending) {
    return <Loading />;
  }

  return (
    <NewcomersWrapper>
      {(newcomers || []).map((item: INewcomer) => (
        <Newcomer key={`newcomer-${item.name}`} {...item} />
      ))}

      <MoreButton
        sx={{ mt: 1 }}
        limit={plimit}
        offset={poffset}
        total={ptotal}
        loading={pending}
        onClick={handleOnMore}
      >
        Show more
      </MoreButton>
    </NewcomersWrapper>
  );
}

function Newcomer(props: INewcomer) {
  const { avatar, name, position, email } = props;

  return (
    <NewcomerWrapper>
      <NewcomerAvatar>
        <Avatar src={avatar} sx={{ width: 48, height: 48 }} />
      </NewcomerAvatar>
      <NewcomerContent>
        <NewcomerName>{name}</NewcomerName>
        <NewcomerPosition>{position}</NewcomerPosition>
        <NewcomerAction
          rel="noopener"
          target="_blank"
          href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}
        >
          <TeamsIcon />
          Say Hello!
        </NewcomerAction>
      </NewcomerContent>
    </NewcomerWrapper>
  );
}

function Loading() {
  return (
    <NewcomersWrapper>
      {Array.from(Array(3).keys()).map((item) => (
        <NewcomerWrapper key={item}>
          <NewcomerAvatar>
            <Skeleton variant="circular" sx={{ width: 48, height: 48 }} />
          </NewcomerAvatar>
          <NewcomerContent>
            <NewcomerName>
              <Skeleton height={12} width="100%" />
            </NewcomerName>
            <NewcomerPosition>
              <Skeleton height={12} width="80%" />
              <Skeleton height={12} width="65%" />
              <Skeleton height={12} width="50%" />
            </NewcomerPosition>
          </NewcomerContent>
        </NewcomerWrapper>
      ))}
    </NewcomersWrapper>
  );
}
