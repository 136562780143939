import { Grid, Skeleton } from "@mui/material";
import { IReferralsVacancy } from "@/store/redux";
import { GradientButton } from "@/shared/components";
import { ShareButton } from "../../ShareButton";
import { Recruiter } from "../Recruiter";
import {
  VacancyContentLabel,
  VacancyContentSection,
  ButtonsWrapp,
} from "../Vacancies.styled";

interface IVacancyDrawerContentProps {
  hasRequested: boolean;
  pending: boolean;
  vacancy: IReferralsVacancy;
  onClose: () => void;
  onFormScroll: () => void;
}

export function VacancyDrawerContent(props: IVacancyDrawerContentProps) {
  const { hasRequested, pending, vacancy, onClose, onFormScroll } = props;

  const {
    about_client,
    area,
    benefits,
    level,
    location,
    project_details,
    recruiter,
    responsibilities,
    skills,
    your_team,
    legacy_url,
  } = vacancy || {};

  if (hasRequested) {
    return (
      <div>
        <p>
          <b>
            Our recruiters have received your candidate and will get back to you
            in case any questions arise.
          </b>
        </p>

        <p>
          Otherwise, keep an eye on the "My Candidates" tab on "My Referrals"
          page."
        </p>

        <GradientButton onClick={onClose} sx={{ mt: 3, minWidth: 200 }}>
          Got it
        </GradientButton>
      </div>
    );
  }

  if (pending) {
    return (
      <>
        <Skeleton width="100%" height="24px" />
        <Skeleton width="100%" height="24px" />
        <Skeleton width="100%" height="24px" />
        <Skeleton width="100%" height="24px" />
        <Skeleton width="100%" height="24px" />
      </>
    );
  }

  if (vacancy?.id) {
    return (
      <>
        <VacancyContentSection>
          <VacancyContentLabel>Your Personal Recruiter</VacancyContentLabel>
          <Recruiter data={recruiter} />
        </VacancyContentSection>

        <VacancyContentSection>
          <Grid container columnSpacing={3}>
            <Grid item xs="auto">
              <VacancyContentLabel>Location</VacancyContentLabel>
              <p>{location}</p>
            </Grid>
            <Grid item xs="auto">
              <VacancyContentLabel>Area</VacancyContentLabel>
              <p>{area}</p>
            </Grid>
            <Grid item xs="auto">
              <VacancyContentLabel>Tech Level</VacancyContentLabel>
              <p>{level}</p>
            </Grid>
          </Grid>
        </VacancyContentSection>

        <VacancyContentSection>
          <ButtonsWrapp>
            <GradientButton sx={{ width: 270 }} onClick={onFormScroll}>
              REFER A CANDIDATE
            </GradientButton>

            <ShareButton link={legacy_url} />
          </ButtonsWrapp>
        </VacancyContentSection>

        <VacancyContentSection>
          <VacancyContentLabel>About the client</VacancyContentLabel>
          <div dangerouslySetInnerHTML={{ __html: about_client }} />
        </VacancyContentSection>

        <VacancyContentSection>
          <VacancyContentLabel>Project Details</VacancyContentLabel>
          <div dangerouslySetInnerHTML={{ __html: project_details }} />
        </VacancyContentSection>

        <VacancyContentSection>
          <VacancyContentLabel>Your Team</VacancyContentLabel>
          <div dangerouslySetInnerHTML={{ __html: your_team }} />
        </VacancyContentSection>

        {benefits ? (
          <VacancyContentSection>
            <VacancyContentLabel>What's in for you</VacancyContentLabel>
            <div dangerouslySetInnerHTML={{ __html: benefits }} />
          </VacancyContentSection>
        ) : null}

        <VacancyContentSection>
          <VacancyContentLabel>Responsibilities</VacancyContentLabel>
          <div dangerouslySetInnerHTML={{ __html: responsibilities }} />
        </VacancyContentSection>

        <VacancyContentSection>
          <VacancyContentLabel>Skills</VacancyContentLabel>
          <div dangerouslySetInnerHTML={{ __html: skills }} />
        </VacancyContentSection>
      </>
    );
  }

  return null;
}
