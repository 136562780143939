import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { HotTopicsActions } from "@/store/redux";
import { INewsCommentLike } from "./reducers";
import {
  postNewsCommentLike,
  postNewsCommentLikeError,
  postNewsCommentLikeSuccess,
} from "./slice";
import { postNewsCommentLikeRequest } from "./api";

function* postNewsCommentLikeSaga(action: PayloadAction<number>) {
  try {
    const like: INewsCommentLike = yield call(
      postNewsCommentLikeRequest,
      action.payload
    );

    yield put(postNewsCommentLikeSuccess(like));

    yield put(HotTopicsActions.getHotTopics());
  } catch (error) {
    yield put(postNewsCommentLikeError({ error }));
  }
}

function* NewsCommentLikeWatcher() {
  yield takeLatest(postNewsCommentLike.type, postNewsCommentLikeSaga);
}

export default NewsCommentLikeWatcher;
