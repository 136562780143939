import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function FolderIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 23 19" fill="none" htmlColor="transparent" {...props}>
      <svg
        width="23"
        height="19"
        viewBox="0 0 23 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.490234 6.51172V3.89453C0.490234 1.93164 1.47656 0.955078 3.18555 0.955078H5.96875C6.93555 0.955078 7.43359 1.13086 8.07812 1.67773L8.67383 2.16602C9.16211 2.58594 9.5625 2.74219 10.2266 2.74219H19.6016C21.6328 2.74219 22.668 3.75781 22.668 5.76953V6.51172H0.490234ZM0.490234 15.7988V7.79102H22.668V15.7988C22.668 17.8105 21.6426 18.8262 19.8945 18.8262H3.55664C1.51562 18.8262 0.490234 17.8203 0.490234 15.7988Z"
          fill="url(#paint0_linear_11664_6044)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_11664_6044"
            x1="-2"
            y1="10"
            x2="26"
            y2="10"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F7C5BB" />
            <stop offset="1" stopColor="#B1A1FF" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}
