import { PayloadAction } from "@reduxjs/toolkit";

export interface IPostTimeOffState {
  id?: number | null;
  type: number | string;
  start_date: string;
  end_date: string;
  note: string;
  hasRequested: boolean;
  pending: boolean;
  error_message: string;
  error: any;
}

export interface IPostTimeOffStatePending {
  id?: number;
  type: number;
  start_date: string;
  end_date: string;
  note: string;
  filters: { ptype: number; pstatus: number; psort: string };
}

export interface IPostTimeOffStateSuccess {
  id: number;
  error_message: string;
}

export interface IPostTimeOffStateError {
  error: any;
}

export const initialState: IPostTimeOffState = {
  pending: false,
  error: null,
  id: null,
  hasRequested: false,
  error_message: "",
  start_date: "",
  end_date: "",
  type: "",
  note: "",
};

export const reducers = {
  postTimeOff: (
    state: IPostTimeOffState,
    action: PayloadAction<IPostTimeOffStatePending>
  ) => {
    return {
      ...state,
      hasRequested: false,
      pending: true,
      error_message: "",
      error: "",
      id: null,
      ...(action.payload || {}),
    };
  },
  postTimeOffSuccess: (
    state: IPostTimeOffState,
    action: PayloadAction<IPostTimeOffStateSuccess>
  ) => {
    const { error_message, ...restPayload } = action.payload;

    return {
      ...state,
      pending: false,
      hasRequested: !error_message?.length,
      error_message: error_message || "",
      ...(restPayload || {}),
    };
  },
  postTimeOffError: (
    state: IPostTimeOffState,
    action: PayloadAction<IPostTimeOffStateError>
  ) => ({
    ...state,
    pending: false,
    hasRequested: false,
    error: action.payload.error,
  }),

  //RESET
  resetPostTimeOff: (state: IPostTimeOffState) => ({
    ...state,
    hasRequested: false,
    error: null,
    id: null,
    error_message: "",
    start_date: "",
    end_date: "",
    type: "",
    note: "",
  }),
  resetPostTimeOffSuccess: (state: IPostTimeOffState) => ({
    ...state,
  }),
  resetPostTimeOffError: (state: IPostTimeOffState) => ({
    ...state,
  }),
};
