import { useState, MouseEvent } from "react";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
  Button,
  Avatar,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import {
  selectProfile,
  selectSwitchUser,
  SwitchUserActions,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { CartButton } from "./CartButton";
import { UserInfoDropdown } from "./UserInfoDropdown";

export function UserInfo() {
  const { breakpoints, palette } = useTheme();
  const { instance } = useMsal();
  const { profile } = useSelector(selectProfile);
  const { original_user } = useSelector(selectSwitchUser);
  const { switchUser } =
    useActions<typeof SwitchUserActions>(SwitchUserActions);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMobile = useMediaQuery(breakpoints.down("md"));

  const handleOnMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOnMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOnLogout = () => {
    setAnchorEl(null);

    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const handleOnResetUser = () => {
    switchUser({ user: null, original_user: null });
  };

  return profile ? (
    <>
      {original_user ? (
        <IconButton size="small" onClick={handleOnResetUser}>
          <CloseIcon fontSize="small" htmlColor="#fff" />
        </IconButton>
      ) : null}

      <Button
        size="small"
        onClick={handleOnMenuOpen}
        sx={{
          color: palette.common.white,
          textTransform: "none",
          fontWeight: 500,
          fontSize: "1rem",
          gap: 1,
          padding: 0,
          borderRadius: 16,
          mr: 0.5,
        }}
      >
        <Avatar src={profile?.avatar} sx={{ width: 40, height: 40 }} />

        {!isMobile ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            {profile?.user_name}
            <KeyboardArrowDownIcon
              sx={{
                transition: "all 0.5s ease",
                transform: `rotate(${anchorEl ? "-180deg" : 0})`,
              }}
            />
          </Box>
        ) : null}
      </Button>

      <UserInfoDropdown
        anchorEl={anchorEl}
        onClose={handleOnMenuClose}
        onLogout={handleOnLogout}
      />

      <CartButton />
    </>
  ) : null;
}
