import { ReactNode } from "react";
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  SxProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Wrapper } from "./Accordion.styled";

interface IAccordionProps {
  data: {
    title: string;
    desc: string | ReactNode;
  }[];
  sx?: SxProps;
}

export function Accordion(props: IAccordionProps) {
  const { data, ...restProps } = props;

  return (
    <Wrapper {...restProps}>
      {data.map(({ title, desc }) => (
        <MuiAccordion
          key={title}
          disableGutters
          sx={{
            background: (theme) =>
              theme.modeColors({
                dark: theme.palette.primary.main,
                light: theme.palette.common.white,
              }),
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: (theme) =>
                    theme.modeColors({
                      dark: theme.palette.common.white,
                      light: theme.palette.common.black,
                    }),
                }}
              />
            }
          >
            <Typography sx={{ fontSize: "1.125rem", fontWeight: 700 }}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div">{desc}</Typography>
          </AccordionDetails>
        </MuiAccordion>
      ))}
    </Wrapper>
  );
}
