import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ICountriesState } from "./reducers";

export const countriesSelector = (state: RootState): ICountriesState =>
  state.countries;

export const selectCountries = createSelector(
  countriesSelector,
  (countriesState: ICountriesState) => ({
    ...countriesState,
  })
);
