import { pink, violet } from "@/theme";
import {
  DayOffTypesColor,
  DayOffTypesDesc,
  DayOffTypesItem,
  DayOffTypesWrapper,
} from "./DayOffTypes.styled";

const colors = [
  {
    color: pink,
    desc: "Health Time Off",
  },
  {
    color: violet,
    desc: "Paid Time Off",
  },
];

export function DayOffTypes() {
  return (
    <DayOffTypesWrapper>
      {colors.map(({ color, desc }) => (
        <DayOffTypesItem key={`${color}-${desc}`}>
          <DayOffTypesColor color={color} /> -
          <DayOffTypesDesc>{desc}</DayOffTypesDesc>
        </DayOffTypesItem>
      ))}
    </DayOffTypesWrapper>
  );
}
