import { useFormContext, Controller } from "react-hook-form";
import { FormControlLabel, Checkbox, Box } from "@mui/material";

export function Anonymous() {
  const { control } = useFormContext();

  return (
    <Controller
      name="anonymous"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          control={<Checkbox checked={value} onChange={onChange} />}
          label={
            <Box component="span" sx={{ fontSize: "0.875rem" }}>
              I want to remain anonymous
            </Box>
          }
        />
      )}
    />
  );
}
