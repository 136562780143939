import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Bubble, GradientButton, Dialog } from "@/shared/components";
import {
  ITimeOffListItem,
  CancelTimeOffActions,
  selectTimeOffList,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import {
  MessageDesc,
  MessageTitle,
  MessageWrapp,
} from "./CancelConfirm.styled";

interface ICancelConfirmProps {
  open: boolean;
  currentRequest: ITimeOffListItem;
  onClose: () => void;
}

export function CancelConfirm(props: ICancelConfirmProps) {
  const { currentRequest, open, onClose } = props;
  const { id, description, date_string } = currentRequest || {};

  const { psort, pstatus, ptype } = useSelector(selectTimeOffList);
  const { cancelTimeOff } =
    useActions<typeof CancelTimeOffActions>(CancelTimeOffActions);

  const handleOnCancel = () => {
    cancelTimeOff({
      id,
      filters: { psort, pstatus, ptype },
    });

    onClose();
  };

  const content = (
    <MessageWrapp>
      <Bubble color="pink" width="100px" height="100px">
        <CloseIcon sx={{ fontSize: 56 }} />
      </Bubble>
      <MessageTitle>{description || date_string}</MessageTitle>
      <MessageDesc>Are you sure to cancel request?</MessageDesc>

      <GradientButton fullWidth onClick={handleOnCancel}>
        CANCEL REQUEST
      </GradientButton>
    </MessageWrapp>
  );

  return (
    <Dialog
      top
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xsm"
      title="CANCEL REQUEST"
      dialogContent={content}
    />
  );
}
