import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { Skeleton } from "@mui/material";
import { selectCheckoutDeliveryInfo } from "@/store/redux";
import { Select } from "@/shared/components";
import { GenerateField } from "./GenerateField";
import { filterPostalServiceByCountry } from "../helpers";

export function PostalServiceField() {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { postal_service, delivery_type } = useSelector(
    selectCheckoutDeliveryInfo
  );

  const country = watch("country");
  const deliveryType = watch("delivery_type");

  const options = filterPostalServiceByCountry(country, postal_service);

  const fieldName = "postal_service";
  const hasError = !!errors[fieldName]?.type;

  useEffect(() => {
    setValue("postal_service", options?.[0]?.value || "", {
      shouldValidate: true,
    });
  }, [country, deliveryType]); //eslint-disable-line

  if (!postal_service.length && !delivery_type.length) {
    return <Skeleton height={48} />;
  }

  return (
    <GenerateField
      errorText="Choose one of the options"
      name={fieldName}
      render={({ field }) => (
        <Select
          {...field}
          fullWidth
          error={hasError}
          variant="filled"
          label="Postal Service"
          options={options}
        />
      )}
    />
  );
}
