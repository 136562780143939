import { PayloadAction } from "@reduxjs/toolkit";
import { NEWS } from "@/routes/consts";

export interface IHotTopicsItem {
  id: number;
  href: string;
  title: string;
  likes: number;
  views: number;
}

export interface IHotTopicsState {
  pending: boolean;
  error: any;
  topics: IHotTopicsItem[];
}

export interface IHotTopicsStateSuccess {
  topics: IHotTopicsItem[];
}

export interface IHotTopicsStateError {
  error: any;
}

export const initialState: IHotTopicsState = {
  pending: false,
  error: "",
  topics: [],
};

export const reducers = {
  getHotTopics: (state: IHotTopicsState) => {
    return {
      ...state,
      pending: true,
      error: "",
    };
  },
  getHotTopicsSuccess: (
    state: IHotTopicsState,
    action: PayloadAction<IHotTopicsStateSuccess>
  ) => {
    const transformedList = (action?.payload.topics || []).map(
      (item: IHotTopicsItem) => ({
        ...item,
        href: `${NEWS}/${item.id}`,
      })
    );

    return {
      ...state,
      pending: false,
      topics: transformedList,
    };
  },
  getHotTopicsError: (
    state: IHotTopicsState,
    action: PayloadAction<IHotTopicsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
