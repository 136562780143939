import { ButtonProps } from "@mui/material";
import { StyledButton, ButtonBg } from "./GradientButton.styled";

export interface IGradientButtonProps extends ButtonProps {
  loading?: boolean;
}

export function GradientButton(props: IGradientButtonProps) {
  const { children, loading, ...rest } = props;

  return (
    <StyledButton {...rest}>
      <ButtonBg />

      {loading ? "Loading ..." : children}
    </StyledButton>
  );
}
