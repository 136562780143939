import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: "24px",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    textAlign: "center",
  },
}));

export const Content = styled("div")``;

export const Title = styled("div")`
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
`;

export const Subtitle = styled("div")`
  font-size: 1rem;
  font-weight: 900;
`;

export const SubtitlePrefix = styled("span")(({ theme }) => ({
  color: theme.palette.grey[600],
  margin: "0 4px 0 0",
}));

export const Date = styled("div")(({ theme }) => ({
  color: theme.palette.grey[600],
  fontWeight: 500,
}));
