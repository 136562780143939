import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const postThanksGivingRequest = async (params: any) => {
  const response: AxiosResponse = await apiInstance.post("/thanksgiving", {
    ...params,
  });

  return response.data;
};
