import PeopleIcon from "@mui/icons-material/People";
import { IReferralsVacancy } from "@/store/redux";
import {
  RecruiterName,
  RecruiterPhoto,
  RecruiterWrapper,
  RecruitetChat,
  RecruiterContent,
} from "./Vacancies.styled";

interface IRecruiterProps {
  data: IReferralsVacancy["recruiter"];
}

export function Recruiter(props: IRecruiterProps) {
  const { data } = props;

  return (
    <RecruiterWrapper>
      <RecruiterPhoto src={data?.photo} />

      <RecruiterContent>
        <RecruiterName>{data?.name}</RecruiterName>
        <RecruitetChat
          target="_blank"
          rel="noopener"
          href={`https://teams.microsoft.com/l/chat/0/0?users=${data?.email}`}
        >
          <PeopleIcon />
          <span>Teams Chat</span>
        </RecruitetChat>
      </RecruiterContent>
    </RecruiterWrapper>
  );
}
