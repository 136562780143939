import { styled } from "@mui/material/styles";

interface IBadge {
  $color?: string;
  $tag?: boolean;
}

export const BadgeStyled = styled("div")<IBadge>(({ theme, $color, $tag }) => ({
  display: "inline-flex",
  color: theme.palette.common.black,
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  borderRadius: "30px",
  padding: theme.spacing(0.25, 1),
  background: theme.palette[$color || "yellow"].main,

  ...($tag
    ? {
        cursor: "pointer",
        fontSize: "0.75rem",
        padding: theme.spacing(0, 1),
        gap: theme.spacing(0.5),
      }
    : {}),
}));
