import { styled } from "@mui/material/styles";
import { Typography, Avatar, Grid, Link } from "@mui/material";
import { Badge, GradientButton } from "@/shared/components";
import { bluePink, pinkViolet, yellowBlue, violetBlue } from "@/theme";

export const CardWrapper = styled("div")({
  background: yellowBlue,
  padding: "4px",
});

export const CardContent = styled("div")(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.common.white,
  padding: theme.spacing(0.25, 1),
  height: "100%",
  textAlign: "center",
}));

export const CardImage = styled(Avatar)(({ theme }) => ({
  margin: "0 auto 24px",
  width: "96px",
  height: "96px",
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  fontWeight: 900,
}));

export const CardSubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.darkGrey.main,
  height: "45px",
  marginBottom: theme.spacing(0.5),
  overflow: "hidden",
}));

export const CardDates = styled("div")(({ theme }) => ({
  minHeight: theme.spacing(4),
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& .MuiSvgIcon-root": {
    verticalAlign: "bottom",
    marginRight: theme.spacing(1),
  },
}));

export const CardJobType = styled("div")(({ theme }) => ({
  width: "100%",
  textAlign: "right",
  marginBottom: theme.spacing(0.25),
}));

export const CardJobTypeBadge = styled(Badge)({
  fontSize: "0.75rem",
  background: yellowBlue,
});

export const GridStyled = styled(Grid)({
  "&:nth-of-type(4n+2)": {
    [CardWrapper as any]: {
      background: bluePink,
    },

    [CardJobTypeBadge as any]: {
      background: bluePink,
    },
  },

  "&:nth-of-type(4n+3)": {
    [CardWrapper as any]: {
      background: pinkViolet,
    },

    [CardJobTypeBadge as any]: {
      background: pinkViolet,
    },
  },

  "&:nth-of-type(4n+4)": {
    [CardWrapper as any]: {
      background: violetBlue,
    },

    [CardJobTypeBadge as any]: {
      background: violetBlue,
    },
  },

  // "&:nth-of-type(7n)": {
  //   [CardWrapper as any]: {
  //     background: "linear-gradient(90deg, #F7C5BB 0%, #B1A1FF 100%)",
  //   },

  //   [CardJobTypeBadge as any]: {
  //     background: "linear-gradient(90deg, #F7C5BB 0%, #B1A1FF 100%)",
  //   },
  // },

  // "&:nth-of-type(11n)": {
  //   [CardWrapper as any]: {
  //     background: "linear-gradient(90deg, #F7C5BB 0%, #B1A1FF 100%)",
  //   },

  //   [CardJobTypeBadge as any]: {
  //     background: "linear-gradient(90deg, #F7C5BB 0%, #B1A1FF 100%)",
  //   },
  // },
});

export const GradientButtonStyled = styled(GradientButton)(({ theme }) => ({
  fontFamily: "Cera Pro",
  fontWeight: 500,
  fontSize: "1rem",
  textTransform: "none",
  padding: theme.spacing(1.5, 3),

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    fontWeight: 900,
  },
}));

export const LinkButton = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));
