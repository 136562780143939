import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function GiftIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 21 20" fill="none" htmlColor="transparent" {...props}>
      <path
        d="M17.1666 10V18.3333H3.83325V10"
        // fill="#fff"
        stroke="#8E949A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8334 5.83398H2.16675V10.0007H18.8334V5.83398Z"
        // fill="#fff"
        stroke="#8E949A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 18.334V5.83398"
        // fill="#fff"
        stroke="#8E949A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5001 5.83268H6.75008C6.19755 5.83268 5.66764 5.61319 5.27694 5.22249C4.88624 4.83179 4.66675 4.30188 4.66675 3.74935C4.66675 3.19681 4.88624 2.66691 5.27694 2.27621C5.66764 1.88551 6.19755 1.66602 6.75008 1.66602C9.66675 1.66602 10.5001 5.83268 10.5001 5.83268Z"
        // fill="#fff"
        stroke="#8E949A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 5.83268H14.25C14.8025 5.83268 15.3324 5.61319 15.7231 5.22249C16.1138 4.83179 16.3333 4.30188 16.3333 3.74935C16.3333 3.19681 16.1138 2.66691 15.7231 2.27621C15.3324 1.88551 14.8025 1.66602 14.25 1.66602C11.3333 1.66602 10.5 5.83268 10.5 5.83268Z"
        // fill="#fff"
        stroke="#8E949A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
