import { PayloadAction } from "@reduxjs/toolkit";

export interface INewsArticleLike {
  articleId: number;
  likes: number;
  has_like: boolean;
}

export interface INewsArticleLikeState {
  pending: boolean;
  error: any;
  like: INewsArticleLike;
}

export interface INewsArticleLikeStateError {
  error: any;
}

export const initialState: INewsArticleLikeState = {
  pending: false,
  error: null,
  like: {} as INewsArticleLike,
};

export const reducers = {
  postNewsArticleLike: (
    state: INewsArticleLikeState,
    action: PayloadAction<number | undefined>
  ) => {
    return {
      ...state,
      like: {} as INewsArticleLike,
      pending: true,
      error: "",
    };
  },
  postNewsArticleLikeSuccess: (
    state: INewsArticleLikeState,
    action: PayloadAction<INewsArticleLike>
  ) => {
    return {
      ...state,
      pending: false,
      like: action.payload,
    };
  },
  postNewsArticleLikeError: (
    state: INewsArticleLikeState,
    action: PayloadAction<INewsArticleLikeStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
