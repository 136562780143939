import { useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { FormInput } from "@/shared/components";

export function Link(props: { pending: boolean }) {
  const { pending } = props;

  const {
    formState: { errors },
  } = useFormContext();

  const linkRegExp =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

  const linkErrorText = (error: any) => {
    if (error?.type === "required" || error?.type === "pattern") {
      return "This field must contain valid link";
    }

    return null;
  };

  return (
    <FormInput
      name="portfolio_link"
      rules={{
        required: true,
        validate: (value) => !!value?.trim(),
        pattern: linkRegExp,
      }}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          label="LinkedIn or portfolio link"
          variant="filled"
          disabled={pending}
          error={!!linkErrorText(errors.portfolio_link)}
          helperText={linkErrorText(errors.portfolio_link)}
        />
      )}
    />
  );
}
