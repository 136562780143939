import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDialog } from "@/utils/hooks";
import { REFERRALS } from "@/routes/consts";
import { ReferralsHead } from "./ReferralsHead";
import { ReferralsActivity } from "./ReferralsActivity";

export function ReferralsPage() {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const { hasReferralsCandidate, scrollPosition } = state || {};

  const handleOnClose = () => {
    if (hasReferralsCandidate) {
      navigate(REFERRALS, {
        state: { hasReferralsCandidate: false },
      });
    } else if (id) {
      navigate(REFERRALS, {
        state: {
          scrollPosition:
            scrollPosition > 1 ? scrollPosition - 1 : scrollPosition, // dirty hack for saving scroll position
        },
      });
    } else {
      onCloseDialog();
    }
  };

  useEffect(() => {
    window.history.replaceState({ scrollPosition: 0 }, document.title);
  }, []); // dirty hack for reset scroll position

  useEffect(() => {
    window.scrollTo(0, parseInt(scrollPosition, 10));
  }, [scrollPosition]); // dirty hack for saving scroll position

  useEffect(() => {
    if (id || hasReferralsCandidate) {
      onOpenDialog();
    } else {
      onCloseDialog();
    }
  }, [id, hasReferralsCandidate]); //eslint-disable-line

  return (
    <>
      <ReferralsHead onDialogOpen={onOpenDialog} />

      <ReferralsActivity
        hasOpenDialog={openDialog}
        onDialogOpen={onOpenDialog}
        onDialogClose={handleOnClose}
      />
    </>
  );
}
