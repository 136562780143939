import { styled } from "@mui/material/styles";
import { BagIcon } from "@/shared/icons";

export const UserInfoWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const CartButtonWrapp = styled("div")(({ theme }) => ({
  position: "relative",
  display: "inline-flex",
}));

export const CartButtonValue = styled("div")(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  left: 0,
  top: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: 5,
  color: theme.palette.common.black,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 900,
}));

export const CartButtonIcon = styled(BagIcon)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
}));
