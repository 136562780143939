import { PayloadAction } from "@reduxjs/toolkit";

export interface IMoodContact {
  name: string;
  position: string;
  position_desc: string;
  phone_number: string;
  email: string;
  photo_url: string;
  has_viber: boolean;
}

export interface IMoodContacts {
  contacts: IMoodContact[];
}

export interface IMoodContactsState {
  error: any;
  pending: boolean;
  contacts: IMoodContact[];
}

export interface IMoodContactsStateError {
  error: any;
}

export const initialState: IMoodContactsState = {
  error: "",
  pending: false,
  contacts: [],
};

export const reducers = {
  getMoodContacts: (state: IMoodContactsState) => ({
    ...state,
    pending: true,
    error: "",
  }),
  getMoodContactsSuccess: (
    state: IMoodContactsState,
    action: PayloadAction<IMoodContacts>
  ) => {
    return {
      ...state,
      pending: false,
      contacts: action.payload?.contacts,
    };
  },
  getMoodContactsError: (
    state: IMoodContactsState,
    action: PayloadAction<IMoodContactsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload,
  }),
};
