import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Player from "react-player";
import { Skeleton } from "@mui/material";
import {
  FaqArticleActions,
  selectFaqArticle,
  selectProfile,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { Dialog, GradientButton } from "@/shared/components";
import { PROFILE, FAQ } from "@/routes/consts";
import { ContentWrapper, ButtonsWrapper } from "./WelcomeVideo.styled";

export function WelcomeVideo() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { profile } = useSelector(selectProfile);
  const { article } = useSelector(selectFaqArticle);
  const { getFaqArticle } =
    useActions<typeof FaqArticleActions>(FaqArticleActions);

  const [openDialog, setOpenDialog] = useState(false);

  const handleOnDialogClose = () => {
    setOpenDialog(false);
  };

  const handleOnProfile = () => {
    navigate(PROFILE);
    setOpenDialog(false);
  };
  const handleOnFaq = () => {
    navigate(FAQ);
    setOpenDialog(false);
  };

  const content = (
    <ContentWrapper>
      {article?.id ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: article.description }} />

          <Player
            controls
            url={article.video_url}
            width="100%"
            height="100%"
            volume={0.5}
            style={{ marginTop: "8px", aspectRatio: "16 / 9", width: "100%" }}
          />
          <ButtonsWrapper>
            <GradientButton onClick={handleOnProfile}>
              GO TO PROFILE PAGE
            </GradientButton>
            <GradientButton onClick={handleOnFaq}>
              WATCH ALL TUTORIALS
            </GradientButton>
          </ButtonsWrapper>
        </>
      ) : (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton
            sx={{
              marginTop: "8px",
              aspectRatio: "16 / 9",
              width: "100%",
              transform: "none",
            }}
          />
        </>
      )}
    </ContentWrapper>
  );

  useEffect(() => {
    if (!profile?.id) return;

    (profile?.welcome_videos || []).forEach((item) => {
      if (item.page === pathname && !item.has_viewed) {
        getFaqArticle(item.faq_id);

        setOpenDialog(true);
      }
    });
  }, [pathname, profile]); //eslint-disable-line

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={openDialog}
      onClose={handleOnDialogClose}
      title="Welcome on Bright portal!"
      dialogContent={content}
    />
  );
}
