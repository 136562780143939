import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { Bubble, Badge } from "@/shared/components";
import { yellowBlue, bluePink, pinkViolet, violetBlue } from "@/theme";

export const BubbleStyled = styled(Bubble)(({ theme }) => ({})); // magic for styled-components for GridStyled

export const GridStyled = styled(Grid)({
  "&:nth-of-type(4n+1)": {
    [BubbleStyled as any]: {
      background: yellowBlue,
    },
  },

  "&:nth-of-type(4n+2)": {
    [BubbleStyled as any]: {
      background: bluePink,
    },
  },

  "&:nth-of-type(4n+3)": {
    [BubbleStyled as any]: {
      background: pinkViolet,
    },
  },

  "&:nth-of-type(4n+4)": {
    [BubbleStyled as any]: {
      background: violetBlue,
    },
  },
});

export const BubbleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  marginBottom: "4px",
}));

export const TitleStyled = styled("div")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "900",
  textAlign: "center",
  marginTop: theme.spacing(1),

  [theme.breakpoints.down("md")]: {
    fontSize: "0.875rem",
  },
}));

export const ReferralsBadge = styled(Badge)(({ theme }) => ({
  marginRight: theme.spacing(1),
  display: "inline-flex",
  color: theme.modeColors({
    dark: "#000",
    light: "#fff",
  }),
  background: theme.modeColors({
    dark: theme.palette.secondary.main,
    light: theme.palette.primary.main,
  }),
}));
