import { FaqContent } from "@/components";
import { Title, Description, Tabs, FaqRecents } from "@/shared/components";
import { FaqFeed } from "./FaqFeed";
import { desc, title } from "./Faq";

export function FaqMobileView() {
  return (
    <FaqContent>
      <Title fontSize="60px">{title}</Title>

      <Description>{desc}</Description>

      <Tabs
        sticky
        titles={["Guides", "Recent Videos"]}
        contents={[<FaqFeed />, <FaqRecents />]}
      />
    </FaqContent>
  );
}
