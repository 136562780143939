import { Box, Typography, SxProps } from "@mui/material";
import { FeedItem, IFeedItemProps } from "./FeedItem";

interface IFeedProps {
  data: IFeedItemProps[];
  isNews?: boolean;
  loading?: boolean;
  sx?: SxProps;
  onLike?: (id: number) => void;
  onComment?: (k: string) => void;
  onTagSelect?: (k: any) => void;
}

export function Feed(props: IFeedProps) {
  const { data, isNews, loading, sx, onLike, onComment, onTagSelect } = props;

  return (
    <Box sx={sx}>
      <Box mt={3} mb={3}>
        {(data || []).map((props: IFeedItemProps, idx: number) => (
          <FeedItem
            key={`feed-${idx}`}
            {...props}
            isNews={isNews}
            loading={loading}
            onLike={onLike}
            onComment={onComment}
            onTagSelect={onTagSelect}
          />
        ))}
      </Box>

      {loading ? (
        <Typography textAlign="center" variant="h6">
          Loading ...
        </Typography>
      ) : null}
    </Box>
  );
}
