import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { setDefaultOptions } from "date-fns";
import { enUS } from "date-fns/locale";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { IPublicClientApplication } from "@azure/msal-browser";
import CssBaseline from "@mui/material/CssBaseline";
import { store } from "@/store/configureStore";
import { AppRoutes, router } from "@/routes/Routes";
import { CustomNavigationClient } from "@/utils/NavigationClient";
import { AppThemeProvider } from "@/context/AppTheme";
import { Notifier } from "@/components";

import "./fonts/CeraPro/stylesheet.css";
import "./fonts/Druk/stylesheet.css";

interface AppProps {
  msalInstance: IPublicClientApplication;
}

setDefaultOptions({ weekStartsOn: 1, locale: enUS });

function App({ msalInstance }: AppProps) {
  const navigate = router.navigate;
  const navigationClient = new CustomNavigationClient(navigate);

  msalInstance.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={msalInstance}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <SnackbarProvider>
            <AppThemeProvider>
              <CssBaseline />

              <Notifier />

              <AppRoutes />
            </AppThemeProvider>
          </SnackbarProvider>
        </Provider>
      </LocalizationProvider>
    </MsalProvider>
  );
}

export default App;
