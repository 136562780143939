import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { INewsCommentsState } from "./reducers";

export const newsCommentsSelector = (state: RootState): INewsCommentsState =>
  state.newsArticleComments;

export const selectNewsComments = createSelector(
  newsCommentsSelector,
  (newsCommentsState: INewsCommentsState) => ({ ...newsCommentsState })
);
