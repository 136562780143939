import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DateCellWrapperProps, SlotInfo } from "react-big-calendar";
import { format } from "date-fns";
import { useTheme, useMediaQuery } from "@mui/material";
import {
  PostTimesheetActivityActions,
  TimesheetActivityActions,
  selectPostTimesheetActivity,
  selectTimesheetActivity,
  ITimesheetActivityItem,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import {
  EventCalendar,
  CalendarEvent,
  LightTooltip,
} from "@/shared/components";
import { getPeriod, getDisabledDate, getHoliday } from "../helpers";
import { TimesheetEventDialogContent } from "../TimesheetEventDialogContent";

interface ITimesheetCalendarProps {
  selectedDate: Date;
  selectedEvent: ITimesheetActivityItem | null;
  openDialog: boolean;
  onSetSelectedEvent: (event: ITimesheetActivityItem | null) => void;
  onDialogOpen: () => void;
  onDialogClose: () => void;
  onSelectSlot: (info: SlotInfo) => void;
}

export function TimesheetCalendar(props: ITimesheetCalendarProps) {
  const {
    selectedEvent,
    selectedDate,
    openDialog,
    onSetSelectedEvent,
    onSelectSlot,
    onDialogClose,
    onDialogOpen,
  } = props;

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("md"));

  const { list, holidays, pending } = useSelector(selectTimesheetActivity);
  const { hasPosted } = useSelector(selectPostTimesheetActivity);

  const { getTimesheetActivity } = useActions<typeof TimesheetActivityActions>(
    TimesheetActivityActions
  );
  const { resetPostTimesheetActivity } = useActions<
    typeof PostTimesheetActivityActions
  >(PostTimesheetActivityActions);

  const getTimesheet = (date: Date) => {
    const { startDate, endDate } = getPeriod(date);

    getTimesheetActivity({ start_date: startDate, end_date: endDate });
  };

  const handleOnCalendarChange = (newDate: Date) => {
    getTimesheet(newDate);
  };

  const handleOnSelectEvent = (event: any) => {
    const hasDisabelDate = getDisabledDate(event.start_date);
    const hasTimeOff = ["paid_time_off", "health_time_off"].includes(
      event.type
    );

    if (hasDisabelDate || hasTimeOff) return;

    onSetSelectedEvent(event as ITimesheetActivityItem);
    onDialogOpen();
  };

  const handleOnDialogClose = () => {
    onSetSelectedEvent(null);
    onDialogClose();

    setTimeout(resetPostTimesheetActivity, 500);
  };

  const eventDialogContent = (
    <TimesheetEventDialogContent
      hasPosted={hasPosted}
      selectedDate={selectedDate}
      selectedEvent={selectedEvent}
      onDialogClose={handleOnDialogClose}
    />
  );

  const eventWrapper = (eventProps: any) => {
    const { type, hours, project, task, start_date, end_date } =
      eventProps?.event as any;
    const dateFormat = "dd/MM/yyyy";
    const startDate = format(new Date(start_date || 0), dateFormat);
    const endDate = format(new Date(end_date || 0), dateFormat);

    const labelMap: { [k: string | number]: any } = {
      project: <b>{project?.name}</b>,
      outage: <b>Outage</b>,
      health_time_off: <b>Health Time Off</b>,
      paid_time_off: <b>Paid Time Off</b>,
    };

    const hasProject = type === "project";
    const hasOutage = type === "outage";
    const hasTimeoff = ["health_time_off", "paid_time_off"].includes(type);

    const title = (
      <>
        <div>{labelMap[type]}</div>

        {hasTimeoff ? (
          <div>
            {startDate} - {endDate}
          </div>
        ) : null}

        {hasProject || hasOutage ? (
          <div>
            Hours: <b>{hours}h</b>
          </div>
        ) : null}

        {task ? (
          <div>
            Task: <b>{task}</b>
          </div>
        ) : null}
      </>
    );

    return (
      <LightTooltip title={title}>
        <CalendarEvent $type={type}>
          {isMobile ? null : (
            <>
              {!hasTimeoff ? (
                <>
                  <b>{hours}h</b> -{" "}
                </>
              ) : null}

              {labelMap[type]}
            </>
          )}
          {eventProps.children}
        </CalendarEvent>
      </LightTooltip>
    );
  };

  const dateCellWrapper = ({ value, children }: DateCellWrapperProps) => {
    const hasHoliday = !!getHoliday({ day: value, list: holidays })?.type;
    const dayNumber = value ? new Date(value).getDay() : -1;
    const hasWeekend = [0, 6].includes(dayNumber);

    const getClassNames = () => {
      const hasDisabledClassname =
        getDisabledDate(value) || hasWeekend || hasHoliday
          ? ["rbc-date-disabled"]
          : [];

      return ["rbc-date", ...hasDisabledClassname].filter(Boolean).join(" ");
    };

    return <div className={getClassNames()}>{children}</div>;
  };

  useEffect(() => {
    getTimesheet(new Date());
  }, []); //eslint-disable-line

  return (
    <EventCalendar
      selectable
      events={list}
      pending={pending}
      customEventWrapper={eventWrapper}
      customDateCellWrapper={dateCellWrapper}
      eventDialogOpen={openDialog}
      eventDialogTitle="TIME RECORDING"
      eventDialogContent={eventDialogContent}
      onEventDialogClose={handleOnDialogClose}
      onNavigate={handleOnCalendarChange}
      onSelectSlot={onSelectSlot}
      onSelectEvent={handleOnSelectEvent}
    />
  );
}
