import { Link as RouterLink } from "react-router-dom";
import { ListItem, Link as MuiLink } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrapper = styled("footer")(({ theme }) => ({
  background: theme.palette.primary.main,
  boxShadow: theme.modeColors({
    dark: "0px 2px 4px 6px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    light: "none",
  }),
  padding: theme.spacing(16, 0, 5),
  color: theme.palette.common.white,

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(5, 0),
  },
}));

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const LinkStyled = ({ theme }: any) => ({
  cursor: "pointer",
  color: theme.palette.common.white,
  textDecoration: "none",
  fontSize: "1.5rem",

  "&:hover, &:active": {
    color: theme.palette.secondary.main,
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "0.875rem",
  },
});

export const RouteLink = styled(RouterLink)(LinkStyled);
export const ExternalLink = styled(MuiLink)(LinkStyled);

export const Content = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  flex: 1,

  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "column",
  },
}));

export const MenusWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(15),
  order: 0,

  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(3),
    order: 1,
  },
}));

export const CopyWrapper = styled("div")(({ theme }) => ({
  fontSize: "1.25rem",
  order: 1,

  [theme.breakpoints.down("md")]: {
    fontSize: "0.75rem",
  },

  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    order: 2,
  },
}));
