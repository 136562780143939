import { Theme } from "@mui/material/styles";

const getShadow = (theme: Theme) =>
  `0 0 6px ${theme.modeColors({
    dark: theme.palette.primary.dark,
    light: "#ccc",
  })}`;

const getBorder = (theme: Theme) =>
  theme.modeColors({
    dark: `1px solid ${theme.palette.primary.dark}`,
    light: "none",
  });

const getBottomBorder = (theme: Theme) =>
  `1px solid ${theme.modeColors({
    dark: theme.palette.primary.dark,
    light: "#000",
  })}`;

const getHoverColor = (theme: Theme) =>
  theme.modeColors({
    dark: theme.palette.secondary.main,
    light: "inherit",
  });

const getHoverBg = (theme: Theme) =>
  theme.modeColors({
    dark: theme.palette.primary.light,
    light: theme.palette.secondary.main,
  });

const getBackground = (theme: Theme) =>
  theme.modeColors({
    dark: theme.palette.primary.main,
    light: theme.palette.common.white,
  });

export const DropdownMenuStylesProps = {
  PaperProps: {
    sx: {
      boxShadow: getShadow,
      border: getBorder,
      borderRadius: 0,
      maxHeight: 420,
    },
  },

  MenuListProps: {
    sx: {
      padding: 0,
      background: getBackground,

      "& .MuiMenuItem-root": {
        padding: "8px 24px",
        borderBottom: getBottomBorder,

        "&:hover": {
          background: getHoverBg,
          color: getHoverColor,
        },

        "&:active": {
          background: "#000",
          color: (theme: Theme) => theme.palette.common.white,
        },

        "&.Mui-selected": {
          background: "#000",
          color: (theme: Theme) => theme.palette.common.white,
        },
      },
    },
  },
};
