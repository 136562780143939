import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IPostQuestionsStateSuccess,
  IPostQuestionsStatePending,
} from "./reducers";
import {
  postQuestions,
  postQuestionsError,
  postQuestionsSuccess,
  resetPostQuestions,
  resetPostQuestionsError,
  resetPostQuestionsSuccess,
  resetPostErrorQuestions,
  resetPostErrorQuestionsError,
  resetPostErrorQuestionsSuccess,
} from "./slice";
import { postQuestionsRequest } from "./api";

function* postQuestionsSaga(action: PayloadAction<IPostQuestionsStatePending>) {
  try {
    const postQuestions: IPostQuestionsStateSuccess = yield call(
      postQuestionsRequest,
      action.payload
    );

    yield put(postQuestionsSuccess(postQuestions));
  } catch (error) {
    yield put(postQuestionsError({ error }));
  }
}

function* resetPostQuestionsSaga() {
  try {
    yield put(resetPostQuestionsSuccess());
  } catch (error) {
    yield put(resetPostQuestionsError());
  }
}

function* resetPostErrorQuestionsSaga() {
  try {
    yield put(resetPostErrorQuestionsSuccess());
  } catch (error) {
    yield put(resetPostErrorQuestionsError());
  }
}

function* PostQuestionsWatcher() {
  yield takeLatest(postQuestions.type, postQuestionsSaga);
  yield takeLatest(resetPostQuestions.type, resetPostQuestionsSaga);
  yield takeLatest(resetPostErrorQuestions.type, resetPostErrorQuestionsSaga);
}

export default PostQuestionsWatcher;
