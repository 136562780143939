import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "News",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getNews,
  getNewsError,
  getNewsSuccess,
  updateNews,
  updateNewsError,
  updateNewsSuccess,
} = slice.actions;

reducerRegistry.register("news", reducer);
