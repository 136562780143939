import { useEffect } from "react";
import { useSelector } from "react-redux";
import { SelectChangeEvent } from "@mui/material";
import {
  NewsSubscriptionsActions,
  selectNewsSubscriptions,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { FilterControl, Select } from "@/shared/components";
import { INewsFilters } from "../NewsFeed";

interface ISubscriptionsProps {
  disabled: boolean | undefined;
  value: INewsFilters["psubs"];
  onChange: (k: any) => void;
}

export function Subscriptions(props: ISubscriptionsProps) {
  const { disabled, value, onChange } = props;

  const { pending, categories } = useSelector(selectNewsSubscriptions);
  const { getNewsSubscriptions } = useActions<typeof NewsSubscriptionsActions>(
    NewsSubscriptionsActions
  );

  const handleOnChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value;
    const transformedValue =
      typeof value === "string" ? value.split(",") : value;

    onChange({
      ...e,
      target: {
        ...e.target,
        value: transformedValue,
      },
    });
  };

  useEffect(() => {
    getNewsSubscriptions();
  }, []); //eslint-disable-line

  return (
    <FilterControl
      label="My subscriptions:"
      control={
        <Select
          multiple
          hasCheckmarks
          name="psubs"
          placeholder="All"
          disabled={disabled || pending}
          options={categories || []}
          value={value || []}
          onChange={handleOnChange}
        />
      }
    />
  );
}
