import { useState, ReactNode } from "react";
import { CustomArrowProps, Settings } from "react-slick";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  NavigatonButtonWrapp,
  NavigatonButton,
  DotsButtons,
  DotButton,
  SliderWrapper,
} from "./Carousel.styled";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ICarouselProps extends Settings {
  slides: any[];
  noArrowsMargin?: boolean;
}

export function Carousel(props: ICarouselProps) {
  const {
    slides,
    infinite,
    dots,
    noArrowsMargin,
    slidesToShow = 1,
    ...restProps
  } = props;

  const [currentSlideIdx, setCurrentSlideIdx] = useState<number>(0);

  const hasLastSlide = (currentSlide = 0, slideCount = 0) =>
    slidesToShow + currentSlide !== slideCount;

  const PrevArrow = ({ currentSlide, onClick }: CustomArrowProps) => {
    return infinite || currentSlide !== 0 ? (
      <NavigatonButtonWrapp $noArrowsMargin={noArrowsMargin} onClick={onClick}>
        <NavigatonButton color="yellowPink">
          <KeyboardArrowLeftIcon />
        </NavigatonButton>
      </NavigatonButtonWrapp>
    ) : null;
  };
  const NextArrow = ({
    currentSlide,
    slideCount,
    onClick,
  }: CustomArrowProps) => {
    return infinite ||
      (currentSlide !== slides?.length - 1 &&
        hasLastSlide(currentSlide, slideCount)) ? (
      <NavigatonButtonWrapp
        $next
        $noArrowsMargin={noArrowsMargin}
        onClick={onClick}
      >
        <NavigatonButton color="yellowPink">
          <KeyboardArrowRightIcon />
        </NavigatonButton>
      </NavigatonButtonWrapp>
    ) : null;
  };

  const handleOnSlideChange = (prev: number, next: number) => {
    setCurrentSlideIdx(next);
  };

  const settings: Settings = {
    ...restProps,
    dots,
    infinite,
    slidesToShow,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: handleOnSlideChange,
    appendDots: (dots: ReactNode[]) => (
      <DotsButtons>
        {dots.map((dot: any, idx: number) => dot.props.children)}
      </DotsButtons>
    ),
    customPaging: (idx: number) => (
      <DotButton $isActive={idx === currentSlideIdx} />
    ),
  };

  return (
    <SliderWrapper {...settings}>
      {slides.map((item: any) => item)}
    </SliderWrapper>
  );
}
