import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "UserProjects",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const { getUserProjects, getUserProjectsError, getUserProjectsSuccess } =
  slice.actions;

reducerRegistry.register("userProjects", reducer);
