import { PayloadAction } from "@reduxjs/toolkit";
import { FAQ } from "@/routes/consts";

export interface IFaqItem {
  id: number;
  href: string;
  title: string;
  created_at: string;
  description: string;
  video_url: string;
  likes: number;
  has_like: boolean;
}

export interface IFaqState {
  pending: boolean;
  error: any;
  plimit: number;
  poffset: number;
  ptotal: number;
  list: IFaqItem[];
}

export interface IFaqStatePending {
  poffset: number;
  plimit: number;
}

export interface IFaqStateSuccess {
  poffset: number;
  plimit: number;
  list: IFaqItem[];
}

export interface IFaqStateError {
  error: any;
}

export const initialState: IFaqState = {
  pending: false,
  error: "",
  plimit: 10,
  poffset: 0,
  ptotal: 0,
  list: [],
};

export const reducers = {
  getFaq: (state: IFaqState, action: PayloadAction<IFaqStatePending>) => {
    const { plimit, poffset } = action?.payload;

    return {
      ...state,
      pending: true,
      error: "",
      plimit,
      poffset,
    };
  },
  getFaqSuccess: (
    state: IFaqState,
    action: PayloadAction<IFaqStateSuccess>
  ) => {
    const { poffset, list = [] } = action?.payload;
    const transformedList = list.map((item) => ({
      ...item,
      href: `${FAQ}/${item.id}`,
    }));

    return {
      ...state,
      pending: false,
      list: poffset
        ? [...(state.list || []), ...transformedList]
        : transformedList,
    };
  },
  getFaqError: (state: IFaqState, action: PayloadAction<IFaqStateError>) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
