import { ChangeEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { FormControlLabel, Checkbox } from "@mui/material";
import {
  EmployeeCalendarCountriesActions,
  selectEmployeeCalendarCountries,
  TeamsActions,
  selectTeams,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { FilterControl, Select } from "@/shared/components";
import { transformTeams } from "./helpers";
import { IFilters } from "./EmployeeCalendar";
import { FiltersWrapp } from "./EmployeeCalendar.styled";

interface IEmployeeCalendarFiltersProps {
  loading: boolean;
  filters: IFilters;
  onChange: (k: { name: string; value: Array<string> | number }) => void;
}

const checkboxes: Array<{ label: string; value: string }> = [
  {
    label: "Time Offs",
    value: "paid_time_off,health_time_off",
  },
  {
    label: "Birthdays & Anniversaries",
    value: "birthday,anniversary",
  },
  {
    label: "Public Holidays",
    value: "holiday",
  },
];

export function EmployeeCalendarFilters(props: IEmployeeCalendarFiltersProps) {
  const { loading, filters, onChange } = props;

  const { countries, pending: countriesPending } = useSelector(
    selectEmployeeCalendarCountries
  );
  const { teams, pending: teamsPending } = useSelector(selectTeams);
  const { getEmployeeCalendarCountries } = useActions<
    typeof EmployeeCalendarCountriesActions
  >(EmployeeCalendarCountriesActions);
  const { getTeams } = useActions<typeof TeamsActions>(TeamsActions);

  const hasShowTeamsFilter = teams?.length > 1;

  const hasDisabled =
    loading ||
    countriesPending ||
    teamsPending ||
    !countries?.length ||
    !teams?.length;

  const types = filters?.types || [];

  const countriesOptions = [{ title: "All countries", value: -1 }].concat(
    countries || []
  );
  const teamsOptions = [{ title: "All teams", value: -1 }].concat(
    transformTeams(teams)
  );

  const getChecked = (value: string) => {
    return !!types.filter((item) => value?.indexOf(item) !== -1)?.length;
  };

  const handleOnCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const hasChecked = e.target.checked;
    const updatedFilters = hasChecked
      ? [...types, value]
      : types.filter((item) => item !== value);

    onChange({ name: "types", value: updatedFilters });
  };

  const handleOnChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    onChange({ name, value });
  };

  useEffect(() => {
    getEmployeeCalendarCountries();
    getTeams();
  }, []); //eslint-disable-line

  return (
    <div>
      {hasShowTeamsFilter ? (
        <FilterControl
          label="Team:"
          control={
            <Select
              name="team"
              disabled={hasDisabled}
              options={teamsOptions}
              value={filters.team}
              onChange={handleOnChange}
            />
          }
          sx={{ mb: 2 }}
        />
      ) : null}

      <FiltersWrapp>
        {checkboxes.map(({ label, value }) => (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                disabled={loading}
                value={value}
                checked={getChecked(value)}
                onChange={handleOnCheckboxChange}
              />
            }
            label={label}
          />
        ))}

        <FilterControl
          label="Public Holidays for:"
          control={
            <Select
              name="country"
              disabled={hasDisabled}
              options={countriesOptions}
              value={filters.country}
              onChange={handleOnChange}
            />
          }
        />
      </FiltersWrapp>
    </div>
  );
}
