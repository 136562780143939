import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ICheckoutState } from "./reducers";

export const checkoutSelector = (state: RootState): ICheckoutState =>
  state.checkout;

export const selectCheckout = createSelector(
  checkoutSelector,
  (checkoutState: ICheckoutState) => ({ ...checkoutState })
);
