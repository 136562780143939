import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { INotifierState } from "./reducers";

export const notifierSelector = (state: RootState): INotifierState =>
  state.notifier;

export const selectNotifications = createSelector(
  notifierSelector,
  (notificationsState: INotifierState) => notificationsState.notifications
);
