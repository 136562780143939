import Slide, { SlideProps } from "@mui/material/Slide";
import { Message, MessageButton } from "./PopupMessage.styled";

interface IPopupMessageProps {
  variant: "error" | "success";
  open: boolean;
  message: any;
  onClose: () => void;
  onExit?: () => void;
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

export function PopupMessage(props: IPopupMessageProps) {
  const { open, message, variant, onClose, onExit = () => {} } = props;

  const hasError = variant === "error";

  const messageParams = {
    open: open,
    onClose: onClose,
    TransitionComponent: SlideTransition,
    TransitionProps: { onExited: onExit },
    autoHideDuration: variant === "error" ? 10000 : 2500,
  };

  return (
    <Message
      {...messageParams}
      $variant={variant}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      message={message}
      action={
        hasError ? <MessageButton onClick={onClose}>Close</MessageButton> : null
      }
    />
  );
}
