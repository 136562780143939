import { Skeleton, Tooltip, IconButton, Link } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IReferralsVacancy } from "@/store/redux";
import { Bubble } from "@/shared/components";
import { DrawerHeaderTitle, DrawerHeaderSubtitle } from "../Vacancies.styled";

interface IVacancyDrawerHeaderProps {
  hasRequested: boolean;
  pending: boolean;
  vacancy: IReferralsVacancy;
}

export function VacancyDrawerHeader(props: IVacancyDrawerHeaderProps) {
  const { pending, hasRequested, vacancy } = props;
  const { id, title, company, legacy_url } = vacancy || {};

  if (hasRequested) {
    return (
      <DrawerHeaderTitle
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
        }}
      >
        <Bubble width="100px" height="100px" color="violetBlue">
          <CheckIcon sx={{ fontSize: "3.5rem" }} />
        </Bubble>
        CANDIDATE REFERRED, THANK YOU!
      </DrawerHeaderTitle>
    );
  }

  if (pending) {
    return <Skeleton width="100%" height="24px" />;
  }

  if (id) {
    return (
      <>
        <DrawerHeaderTitle>{title}</DrawerHeaderTitle>
        <DrawerHeaderSubtitle>for {company}</DrawerHeaderSubtitle>
        <div>
          <Tooltip title="Vacancy on Brightgrove Careers">
            <IconButton
              component={Link}
              sx={{ p: 0, color: "#000" }}
              href={legacy_url}
              target="_blank"
              rel="noopener"
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </div>
      </>
    );
  }

  return (
    <>
      <DrawerHeaderTitle>REFER A CANDIDATE</DrawerHeaderTitle>
      <DrawerHeaderSubtitle>
        Your candidate will be added to our talent pool. Keep monitoring our
        referral digests for matching positions. Thanks!
      </DrawerHeaderSubtitle>
    </>
  );
}
