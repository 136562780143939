import { useMediaQuery, useTheme } from "@mui/material";
import {
  NewsWrapper,
  NewsDesktopView,
  NewsMobileView,
  Container,
  Page,
} from "@/components";

export function NewsFeed() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const PageContent = () =>
    isMobile ? <NewsMobileView /> : <NewsDesktopView />;

  return (
    <Page>
      <Container>
        <NewsWrapper>
          <PageContent />
        </NewsWrapper>
      </Container>
    </Page>
  );
}
