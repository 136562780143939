import { useSelector } from "react-redux";
import { DialogMessageContent, GradientButton } from "@/shared/components";
import {
  VeryBadSmileIcon,
  BadSmileIcon,
  NormalSmileIcon,
  GoodSmileIcon,
  FineSmileIcon,
} from "@/shared/icons";
import { selectPostMood } from "@/store/redux";
import { secondaryMain } from "@/theme";
import { SmileBg } from "./MoodMonitoring.styled";

const scores: any = {
  1: (
    <SmileBg $color="#F9C6B9">
      <VeryBadSmileIcon sx={{ fontSize: 74 }} />
    </SmileBg>
  ),
  2: (
    <SmileBg $color="#F9C6B9">
      <BadSmileIcon sx={{ fontSize: 74 }} />
    </SmileBg>
  ),
  3: (
    <SmileBg $color="#97E2F7">
      <NormalSmileIcon sx={{ fontSize: 74 }} />
    </SmileBg>
  ),
  4: (
    <SmileBg $color={secondaryMain}>
      <GoodSmileIcon sx={{ fontSize: 74 }} />
    </SmileBg>
  ),
  5: (
    <SmileBg $color={secondaryMain}>
      <FineSmileIcon sx={{ fontSize: 74 }} />
    </SmileBg>
  ),
};

export function MoodMonitoringSuccess(props: { onSuccess: () => void }) {
  const { onSuccess } = props;

  const { score, message } = useSelector(selectPostMood);

  return (
    <DialogMessageContent
      icon={scores[score || 5]}
      title={
        <div
          style={{ fontWeight: 500 }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      }
      action={
        <GradientButton fullWidth onClick={onSuccess}>
          GOT IT
        </GradientButton>
      }
    />
  );
}
