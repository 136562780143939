import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "MarketplaceBrandProducts",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getMarketplaceBrandProducts,
  getMarketplaceBrandProductsError,
  getMarketplaceBrandProductsSuccess,
} = slice.actions;

reducerRegistry.register("marketplaceBrandProducts", reducer);
