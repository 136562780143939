import Axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from "axios";
import { InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";
import { appConfig } from "@/config/appConfig";
import { loginRequest } from "@/config/msalConfig";
import switchUserUtils from "@/utils/switchUser";
import msalInstance from "@/utils/msalInstance";
import { NotifierActions, ErrorsActions } from "@/store/redux";
import { AppDispatch } from "@/store/configureStore";

export default function apiInstance(storeDispatch: AppDispatch): AxiosInstance {
  const instance = Axios.create({
    baseURL: appConfig.API_URL,
  });

  instance.interceptors.request.use(
    async function (options: AxiosRequestConfig) {
      const account = msalInstance.getActiveAccount();

      const switchedUser = switchUserUtils.getUserData().user;
      const additionalHeaders = switchedUser
        ? { "LOGIN-AS-USER": switchedUser }
        : {};

      if (!account) {
        return await msalInstance.loginRedirect(loginRequest);

        // throw Error(
        //   "No active account! Verify a user has been signed in and setActiveAccount has been called."
        // );
      }

      try {
        const { idToken } = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account,
        });

        return {
          ...(options as any),
          headers: {
            ...options.headers,
            Authorization: `Bearer ${idToken}`,
            ...additionalHeaders,
          },
        };
      } catch (e) {
        return await msalInstance.acquireTokenRedirect(loginRequest);
      }
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError<any>) => {
      const reqParams = {
        ...loginRequest,
        account: msalInstance.getActiveAccount() as AccountInfo,
      };

      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenSilent(reqParams).catch(async () => {
          return await msalInstance.loginRedirect(loginRequest);
        });
      }

      if (error?.response?.status === 401) {
        return await msalInstance.loginRedirect(loginRequest);
      }

      if (error?.response?.status === 404) {
        storeDispatch(ErrorsActions.addError({ statusCode: 404 }));

        return;
      }

      if (
        (error?.response?.status as number) >= 500 ||
        error?.code === "ERR_NETWORK"
      ) {
        storeDispatch(
          NotifierActions.addNotification({
            message: `500: ${
              error?.response?.data?.message ||
              error?.response?.statusText ||
              "Something went wrong! Please, try again later"
            }`,
            variant: "error",
          })
        );

        return;
      }

      throw error;
    }
  );

  return instance;
}
