import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { IUserProfile, IUpdateProfileStatePending } from "./reducers";
import {
  getProfile,
  getProfileError,
  getProfileSuccess,
  updateProfile,
  updateProfileError,
  updateProfileSuccess,
} from "./slice";
import { getProfileRequest, updateProfileRequest } from "./api";

function* getProfileSaga() {
  try {
    const profile: IUserProfile = yield call(getProfileRequest);

    yield put(getProfileSuccess(profile));
  } catch (error) {
    yield put(getProfileError({ error }));
  }
}

function* updateProfileSaga(action: PayloadAction<IUpdateProfileStatePending>) {
  try {
    const profile: IUserProfile = yield call(
      updateProfileRequest,
      action.payload
    );

    yield put(updateProfileSuccess(profile));
  } catch (error) {
    yield put(updateProfileError({ error }));
  }
}

function* profileWatcher() {
  yield takeLatest(getProfile.type, getProfileSaga);
  yield takeLatest(updateProfile.type, updateProfileSaga);
}

export default profileWatcher;
