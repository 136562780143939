import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "TaskReporting",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getTaskReporting,
  getTaskReportingError,
  getTaskReportingSuccess,
} = slice.actions;

reducerRegistry.register("taskReporting", reducer);
