export function htmlEncode(value: string) {
  const cyrillicValidationRegex = /^[\p{Script=Cyrl}\s]*$/u;
  let result = "";

  for (let char of value) {
    const code = char.codePointAt(0);

    if (code && code > 127 && !cyrillicValidationRegex.test(char)) {
      result += "&#" + code + ";";
    } else {
      result += char;
    }
  }
  return result;
}
