import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ISurveysListState } from "./reducers";

export const surveysListSelector = (state: RootState): ISurveysListState =>
  state.surveysList;

export const selectSurveysList = createSelector(
  surveysListSelector,
  (surveysListState: ISurveysListState) => ({ ...surveysListState })
);
