import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { IFieldProps } from "../interfaces";
import { GenerateField } from "./GenerateField";

interface IHoursField extends IFieldProps {
  onGetValidHours: (value: any) => boolean;
  onSetHasValidHours: (value: boolean) => void;
}

export function HoursField(props: IHoursField) {
  const { pending, onGetValidHours, onSetHasValidHours } = props;

  const { watch, trigger } = useFormContext();

  const selectedDate = watch("start_date");
  const hours = watch("hours");

  const getFractionalNumbers = (e: any) => {
    const value = e?.target?.value || "";
    const cleanValue = value.replace(
      /([^\d]*)(\d{1,2}([\\,.]\d{0,2})?)(.*)/,
      "$2"
    );
    const transformedValue = (cleanValue || "").replace(",", ".");

    return transformedValue;
  };

  const onValidate = (value: any) => {
    const hasValidHours = onGetValidHours(value);
    const hasValid = hasValidHours && value > 0;

    onSetHasValidHours(hasValidHours);

    return hasValid;
  };

  useEffect(() => {
    trigger();
  }, [selectedDate, hours]); //eslint-disable-line

  return (
    <GenerateField
      name="hours"
      rules={{
        validate: onValidate,
      }}
      render={({ field: { onChange, value, ...restProps } }) => (
        <TextField
          {...restProps}
          fullWidth
          disabled={pending}
          label="Hours"
          variant="filled"
          value={value}
          onChange={(e) => onChange(getFractionalNumbers(e))}
          error={!onGetValidHours(value)}
          inputProps={{
            pattern: "^[0-9,.]*$",
            inputMode: "decimal",
          }}
        />
      )}
    />
  );
}
