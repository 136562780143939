import type {} from "@mui/x-date-pickers/themeAugmentation";

import { createTheme, lighten, darken } from "@mui/material";

// From DEFF2B to B5FFFF  (жовто-синій)
// From B5FFFF to F9C6B9 (синьо-рожевий)
// From F7C5BB to B1A1FF (рожевий-фіолетовий)
// From B0A3FF to 97E2F7 (фіолетово-синій)

export const yellow = "#DEFF2B";
export const pink = "#F9C6B9";
export const violet = "#B1A1FF";
export const blue = "#B5FFFF";
export const turquoise = "#97E2F7";

export const darkGrey = "#8E949A";

export const violetBlue = `linear-gradient(90deg, #B0A3FF 0%, ${turquoise} 100%)`;
export const yellowBlue = `linear-gradient(270deg, ${blue} 0%, ${yellow} 100%)`;
export const pinkViolet = `linear-gradient(90deg, ${pink} 0%, ${violet} 100%)`;
export const bluePink = `linear-gradient(270deg, ${pink} 0%, ${blue} 100%)`;
export const yellowPink = `linear-gradient(270deg, ${pink} 0%, ${yellow} 100%)`;

export const primaryMain = "#000F1B";
export const primaryDark = "#000a12";
export const primaryLight = "#333f48";

export const secondaryMain = yellow;
export const secondaryDark = "#9bb21e";
export const secondaryLight = "#e4ff55";

export const timeOffColors: {
  [k: string | number]: { background: string; color: string };
} = {
  1: {
    background: lighten(secondaryMain, 0.85),
    color: secondaryMain,
  },
  2: {
    background: lighten(pink, 0.85),
    color: pink,
  },
  3: {
    background: lighten(violet, 0.85),
    color: darken(violet, 0.15),
  },
  4: {
    background: lighten(turquoise, 0.85),
    color: turquoise,
  },
  health_time_off: {
    background: lighten(pink, 0.85),
    color: pink,
  },
  paid_time_off: {
    background: lighten(violet, 0.85),
    color: darken(violet, 0.15),
  },
  project: {
    background: lighten(secondaryMain, 0.85),
    color: secondaryMain,
  },
  holiday: {
    background: lighten(pink, 0.85),
    color: pink,
  },
  outage: {
    background: lighten(turquoise, 0.85),
    color: turquoise,
  },
};

declare module "@mui/material/styles" {
  interface Palette {
    darkGrey: Palette["primary"];
    yellow: Palette["primary"];
    pink: Palette["primary"];
    violet: Palette["primary"];
    violetBlue: Palette["primary"];
    yellowBlue: Palette["primary"];
    pinkViolet: Palette["primary"];
    bluePink: Palette["primary"];
    yellowPink: Palette["primary"];
    [key: string]: Palette["primary"];
  }
  interface PaletteOptions {
    darkGrey: PaletteOptions["primary"];
    yellow: PaletteOptions["primary"];
    pink: PaletteOptions["primary"];
    violet: PaletteOptions["primary"];
    violetBlue: PaletteOptions["primary"];
    yellowBlue: PaletteOptions["primary"];
    pinkViolet: PaletteOptions["primary"];
    bluePink: PaletteOptions["primary"];
    yellowPink: PaletteOptions["primary"];
  }

  interface BreakpointOverrides {
    xs: true;
    xsm: true;
    sm: true;
    smd: true;
    md: true;
    lg: true;
    xl: true;
  }

  interface Theme {
    modeColors: ({
      light,
      dark,
    }: {
      light: string | any;
      dark: string | any;
    }) => string;
  }

  interface ThemeOptions {
    modeColors: ({
      light,
      dark,
    }: {
      light: string | any;
      dark: string | any;
    }) => string;
  }
}

export const theme = (prefersDarkMode: boolean) =>
  createTheme({
    spacing: 8,
    breakpoints: {
      values: {
        // Default values from Mui
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,

        //Custom values
        xsm: 375,
        smd: 756,
      },
    },
    typography: {
      fontFamily: "Cera Pro, Arial, Helvetica, sans-serif",
    },
    palette: {
      mode: prefersDarkMode ? "dark" : "light",
      primary: {
        main: primaryMain,
        dark: primaryDark,
        light: primaryLight,
      },
      secondary: {
        main: secondaryMain,
        dark: secondaryDark,
        light: secondaryLight,
      },

      darkGrey: { main: darkGrey },
      yellow: { main: yellow },
      pink: { main: pink },
      violet: { main: violet },
      violetBlue: { main: violetBlue },
      yellowBlue: { main: yellowBlue },
      pinkViolet: { main: pinkViolet },
      bluePink: { main: bluePink },
      yellowPink: { main: yellowPink },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: (theme) => ({
          body: {
            backgroundColor: theme.modeColors({
              light: "#fff",
              dark: primaryMain,
            }),
          },
          p: {
            margin: 0,

            "& + &": {
              margin: theme.spacing(2, 0, 0),
            },
          },
          a: {
            color: theme.modeColors({ dark: "#fff", light: "#000" }),
            textDecorationColor: yellow,
            textDecorationThickness: "2px",
          },
        }),
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: primaryMain,
            backgroundImage: "none",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 900,
            borderRadius: 0,
            color: prefersDarkMode ? "#fff" : primaryMain,

            "&:hover": {
              background: "inherit",
            },
          },
          containedSecondary: {
            borderRadius: 0,
            color: primaryMain,

            "&:hover": {
              background: yellow,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: prefersDarkMode ? "#fff" : "#000",
            textDecorationColor: yellow,
            textDecorationThickness: "2px",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: "4px",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: 900,
            fontSize: "1rem",
            color: darkGrey,
            textTransform: "none",
            padding: "24px 16px",

            "&.Mui-selected": {
              color: prefersDarkMode ? "#fff" : "#000",
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 0,
            background: prefersDarkMode ? primaryMain : "#E6E6E6",
            border: prefersDarkMode ? `1px solid ${primaryLight}` : "none",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            textAlign: "center",
            paddingRight: "56px",
            paddingLeft: "56px",
            position: "relative",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: "center",
            padding: "24px",
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          ol: {
            flexWrap: "nowrap",
          },
          li: {
            color: prefersDarkMode ? "#fff" : "#000F1B",
            fontWeight: 500,
            fontSize: "14px",
            whiteSpace: "nowrap",

            "& a, & a:focus, & a:active": {
              textDecoration: "none",
            },

            "& *": {
              whiteSpace: "nowrap",
            },

            "&:last-child": {
              display: "block",
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          },
          separator: {
            color: prefersDarkMode ? "#fff" : primaryMain,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: prefersDarkMode ? "rgba(255,255,255,0.7)" : primaryMain,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          standard: {
            padding: "0 36px 0 0",
            fontWeight: "500",

            "&:focus": {
              background: "none",
            },

            "& .MuiList-root": {
              padding: 0,
            },
          },
          outlined: {
            padding: "12px 36px 12px 12px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          multiline: {
            "&:after, &:before": {
              display: "none",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: prefersDarkMode ? "transparent" : "#fff",
            border: `1px solid ${darkGrey}`,
            borderRadius: 0,

            "&:before, &:after": {
              display: "none",
            },

            "&:hover, &:focus": {
              backgroundColor: prefersDarkMode ? "transparent" : "#fff",
            },

            "&.Mui-focused": {
              backgroundColor: prefersDarkMode ? "transparent" : "#fff",
            },

            "&.Mui-error": {
              borderColor: "#d32f2f",
            },

            "&.Mui-disabled": {
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.12)",
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "4px solid #e6e6e6",
              borderRadius: "0",
            },

            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e6e6e6",
              borderWidth: 4,
            },

            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e6e6e6",
            },

            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e6e6e6",
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: 0,
            borderBottom: "1px solid #E6E6E6",

            "&:before": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: "24px 16px",
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            display: "flex",
            borderRadius: 0,
            gap: "4px",
          },

          grouped: {
            "&:not(:first-of-type)": {
              marginLeft: 0,
              border: "4px solid #e6e6e6",
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            border: "4px solid #e6e6e6",
            lineHeight: 1,
            padding: "13px 14px 13px",
            fontSize: "0.875rem",
            fontWeight: 900,
            color: prefersDarkMode ? "#fff" : "#000",

            "&:hover": {
              background: prefersDarkMode ? primaryLight : yellow,
            },

            "&.Mui-selected": {
              background: prefersDarkMode ? yellow : "#000",
              color: prefersDarkMode ? primaryMain : "#fff",

              "&:hover": {
                background: prefersDarkMode ? yellow : "#000",
              },
            },
            "&.Mui-disabled": {
              border: "4px solid #ececec !important",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginRight: 0,
            marginLeft: 0,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              color: prefersDarkMode ? "#fff" : primaryMain,
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&:focus": {
              "&.Mui-selected": {
                background: secondaryLight,
                color: primaryMain,
              },
            },

            "&.Mui-selected": {
              background: secondaryLight,
              color: primaryMain,

              "&:hover, &:active, &:active": {
                background: secondaryLight,
                color: primaryMain,
              },
            },
          },
        },
      },
    },
    modeColors: ({ light, dark }) => {
      return prefersDarkMode ? dark : light;
    },
  });
