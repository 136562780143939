import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const getMoodContactsRequest = async () => {
  const response: AxiosResponse = await apiInstance.get(
    "/mood-monitoring/contacts"
  );

  return response.data;
};
