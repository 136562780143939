import type { orderField, orderDirection } from "./SurveysList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ListHeadItem } from "./SurveysList.styled";

interface ISurveysListHeadProps {
  title: string;
  name: orderField;
  orderByField: orderField;
  sortType: orderDirection;
  onSort: (name: orderField) => void;
}

export function SurveysListHeadItem(props: ISurveysListHeadProps) {
  const { name, title, sortType, orderByField, onSort } = props;

  const hasAscSort = sortType === "asc";
  const hasOrder = name === orderByField;
  const sortIcon = hasAscSort ? (
    <KeyboardArrowDownIcon />
  ) : (
    <KeyboardArrowUpIcon />
  );

  const handleOnSort = () => {
    onSort(name);
  };

  return (
    <ListHeadItem onClick={handleOnSort}>
      <span>{title}</span>

      {hasOrder ? sortIcon : null}
    </ListHeadItem>
  );
}
