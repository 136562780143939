import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ISwitchUserState } from "./reducers";

export const switchUserSelector = (state: RootState): ISwitchUserState =>
  state.switchUser;

export const selectSwitchUser = createSelector(
  switchUserSelector,
  (switchUserState: ISwitchUserState) => ({ ...switchUserState })
);
