import { FormControlLabelProps } from "@mui/material";
import { ActionButtonWrapper } from "./ActionButton.styled";

interface IActionButtonProps extends FormControlLabelProps {
  hasCount?: boolean;
}

export function ActionButton(props: IActionButtonProps) {
  const { hasCount, ...restProps } = props;

  return <ActionButtonWrapper {...restProps} $hasCount={hasCount} />;
}
