import { PayloadAction } from "@reduxjs/toolkit";

export interface INewsCommentState {
  pending: boolean;
  error: any;
  id: number | null | undefined;
}

export interface INewsCommentStateSuccess {
  id: number;
}

export interface INewsCommentStateError {
  error: any;
}

export const initialState: INewsCommentState = {
  pending: false,
  error: "",
  id: null,
};

export const reducers = {
  deleteNewsComment: (
    state: INewsCommentState,
    action: PayloadAction<number | null | undefined>
  ) => {
    return {
      ...state,
      id: null,
      pending: true,
      error: "",
    };
  },
  deleteNewsCommentSuccess: (
    state: INewsCommentState,
    action: PayloadAction<INewsCommentStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      id: action?.payload.id,
    };
  },
  deleteNewsCommentError: (
    state: INewsCommentState,
    action: PayloadAction<INewsCommentStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
