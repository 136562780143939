import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "FaqArticleLike",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  postFaqArticleLike,
  postFaqArticleLikeError,
  postFaqArticleLikeSuccess,
} = slice.actions;

reducerRegistry.register("faqArticleLike", reducer);
