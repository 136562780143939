import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ShortTopics } from "@/shared/components";
import { FaqRecentActions, selectFaqRecent } from "@/store/redux";
import { useActions } from "@/utils/redux";

export function FaqRecents() {
  const { recent, pending } = useSelector(selectFaqRecent);
  const { getFaqRecents } =
    useActions<typeof FaqRecentActions>(FaqRecentActions);

  useEffect(() => {
    getFaqRecents();
  }, []); //eslint-disable-line

  return <ShortTopics data={recent} loading={pending} />;
}
