import { all, fork } from "redux-saga/effects";

import {
  FaqSagas,
  FaqArticleSagas,
  FaqRecentSagas,
  FaqArticleLikeSagas,
} from "./Faq";

import {
  NewsSagas,
  NewsArticleSagas,
  NewsArticleLikeSagas,
  HotTopicsSagas,
  PopularAuthorsSagas,
} from "./News";

import {
  MarketplaceSagas,
  MarketplaceDonateSagas,
  MarketplaceBrandSagas,
  MarketplaceBrandProductsSagas,
  CartSagas,
  CheckoutSagas,
  CheckoutDeliveryInfoSagas,
} from "./Marketplace";

import {
  NewsCommentsSagas,
  NewsAddCommentsSagas,
  NewsCommentLikeSagas,
  NewsDeleteCommentsSagas,
  NewsLocationsSagas,
  NewsSubscriptionsSagas,
} from "./News";

import {
  TimeOffSagas,
  PostTimeOffSagas,
  CancelTimeOffSagas,
  TimeOffListSagas,
  TimeOffCalendarSagas,
  TimeOffAvailableDaysSagas,
} from "./TimeOff";

import {
  MoodCalendarSagas,
  PostMoodSagas,
  MoodContactsSagas,
  MoodLiteratureSagas,
} from "./MoodMonitoring";

import {
  ReferralsSagas,
  PostReferralsSagas,
  ReferralsFiltersSagas,
  ReferralsVacanciesSagas,
  ReferralsVacancySagas,
  ReferralsCandidatesSagas,
} from "./Referrals";

import {
  TimesheetActivitySagas,
  PostTimesheetActivitySagas,
} from "./Timesheet";

import {
  EmployeeCalendarSagas,
  EmployeeCalendarCountriesSagas,
} from "./EmployeeCalendar";

import { ProfileSagas } from "./Profile";
import { ContactsSagas } from "./Contacts";
import { RecentVacanciesSagas } from "./RecentVacancies";

import { TeamsSagas, TeamSagas, TeamMembersSagas } from "./Teams";

import { SwitchUserSagas } from "./SwitchUser";

import { CountriesSagas } from "./Countries";

import { PostThanksGivingSagas } from "./ThanksGivingDay";

import { PostQuestionsSagas } from "./AskQuestion";

import { UserProjectsSagas } from "./UserProjects";

import { NewcomersSagas } from "./Newcomers";

import { SurveysListSagas } from "./Surveys";

import { MyDocumentsSagas } from "./MyDocuments";

import { TaskReportingSagas, PostTaskReportingSagas } from "./TaskReporting";

export default function* RootSaga() {
  yield all([
    fork(FaqSagas),
    fork(FaqArticleSagas),
    fork(FaqRecentSagas),
    fork(ProfileSagas),
    fork(ContactsSagas),
    fork(RecentVacanciesSagas),
    fork(FaqArticleLikeSagas),
    fork(NewsSagas),
    fork(NewcomersSagas),
    fork(HotTopicsSagas),
    fork(PopularAuthorsSagas),
    fork(NewsArticleSagas),
    fork(NewsArticleLikeSagas),
    fork(MarketplaceSagas),
    fork(MarketplaceDonateSagas),
    fork(MarketplaceBrandSagas),
    fork(MarketplaceBrandProductsSagas),
    fork(TeamsSagas),
    fork(TeamSagas),
    fork(TeamMembersSagas),
    fork(NewsCommentsSagas),
    fork(NewsAddCommentsSagas),
    fork(NewsCommentLikeSagas),
    fork(NewsDeleteCommentsSagas),
    fork(NewsLocationsSagas),
    fork(NewsSubscriptionsSagas),
    fork(CartSagas),
    fork(CheckoutSagas),
    fork(TimeOffSagas),
    fork(PostTimeOffSagas),
    fork(CancelTimeOffSagas),
    fork(TimeOffListSagas),
    fork(TimeOffAvailableDaysSagas),
    fork(TimeOffCalendarSagas),
    fork(SwitchUserSagas),
    fork(CheckoutDeliveryInfoSagas),
    fork(CountriesSagas),
    fork(PostThanksGivingSagas),
    fork(PostQuestionsSagas),
    fork(MoodCalendarSagas),
    fork(MoodContactsSagas),
    fork(MoodLiteratureSagas),
    fork(PostMoodSagas),
    fork(TimesheetActivitySagas),
    fork(PostTimesheetActivitySagas),
    fork(UserProjectsSagas),
    fork(ReferralsSagas),
    fork(PostReferralsSagas),
    fork(ReferralsFiltersSagas),
    fork(ReferralsVacanciesSagas),
    fork(ReferralsVacancySagas),
    fork(ReferralsCandidatesSagas),
    fork(EmployeeCalendarSagas),
    fork(EmployeeCalendarCountriesSagas),
    fork(SurveysListSagas),
    fork(MyDocumentsSagas),
    fork(TaskReportingSagas),
    fork(PostTaskReportingSagas),
  ]);
}
