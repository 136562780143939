import { useFormContext, Controller } from "react-hook-form";
import { FormControlLabel, Checkbox, Box, FormHelperText } from "@mui/material";

export function Agreement() {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const hasError = errors["agree"]?.type === "required";

  return (
    <Controller
      name="agree"
      control={control}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => (
        <>
          <FormControlLabel
            control={<Checkbox checked={value} onChange={onChange} />}
            label={
              <Box component="span" sx={{ fontSize: "0.875rem" }}>
                I consent to the processing of my personal data for shipping
                purposes
              </Box>
            }
          />

          {hasError ? (
            <FormHelperText error>
              We need your permission to process data
            </FormHelperText>
          ) : null}
        </>
      )}
    />
  );
}
