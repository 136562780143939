import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { INewsSubscriptionsState } from "./reducers";

export const newsSubscriptionsSelector = (
  state: RootState
): INewsSubscriptionsState => state.newsSubscriptions;

export const selectNewsSubscriptions = createSelector(
  newsSubscriptionsSelector,
  (newsSubscriptionsState: INewsSubscriptionsState) => ({
    ...newsSubscriptionsState,
  })
);
