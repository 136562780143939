import { ButtonProps } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "@/config/msalConfig";
import { SigninButtonStyled } from "./Signin.styled";

export function SigninButton(props: ButtonProps) {
  const { instance } = useMsal();

  const handleLOnLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <SigninButtonStyled {...props} variant="contained" onClick={handleLOnLogin}>
      Sign in
    </SigninButtonStyled>
  );
}
