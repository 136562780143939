import { AxiosError } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { IPostTaskReportingStateSuccess, IPostTaskReporting } from "./reducers";
import {
  postTaskReporting,
  postTaskReportingError,
  postTaskReportingSuccess,
} from "./slice";
import { postTaskReportingRequest } from "./api";

function* postTaskReportingSaga(action: PayloadAction<IPostTaskReporting>) {
  try {
    const reporting: IPostTaskReportingStateSuccess = yield call(
      postTaskReportingRequest,
      action.payload
    );

    yield put(postTaskReportingSuccess(reporting));
  } catch (e) {
    const error = e as AxiosError<{ message: string; statusCode: number }>;

    yield put(postTaskReportingError({ error: error.response?.data }));
  }
}

function* PostTaskReportingWatcher() {
  yield takeLatest(postTaskReporting.type, postTaskReportingSaga);
}

export default PostTaskReportingWatcher;
