import { useSelector } from "react-redux";
import { selectReferralsVacancy } from "@/store/redux";
import { Container, Page, ReferralsPage } from "@/components";

export function Referrals() {
  const { vacancy } = useSelector(selectReferralsVacancy);
  const title = vacancy?.title || "";

  return (
    <Page title={title}>
      <Container>
        <ReferralsPage />
      </Container>
    </Page>
  );
}
