import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IFaqState } from "./reducers";

export const faqSelector = (state: RootState): IFaqState => state.faq;

export const selectFaqs = createSelector(
  faqSelector,
  (faqState: IFaqState) => ({ ...faqState })
);
