import { Skeleton } from "@mui/material";
import {
  ContactsListItem,
  ContactsListItemAvatar,
  ContactsListItemContent,
  ContactsListItemTitle,
  ContactsListItemName,
} from "./Contacts.styled";

export function Loader() {
  return (
    <>
      {Array.from(Array(3).keys()).map((item) => (
        <ContactsListItem key={item} sx={{ width: "33.3333%" }}>
          <ContactsListItemAvatar>
            <Skeleton
              animation="wave"
              variant="circular"
              width={80}
              height={80}
            />
          </ContactsListItemAvatar>
          <ContactsListItemContent>
            <ContactsListItemTitle>
              <Skeleton
                animation="wave"
                height={25}
                width="40%"
                sx={{ mt: 1 }}
              />
            </ContactsListItemTitle>
            <ContactsListItemName>
              <Skeleton animation="wave" height={15} width="80%" />
            </ContactsListItemName>

            <Skeleton animation="wave" height={15} width="80%" />
            <Skeleton animation="wave" height={15} width="80%" />
          </ContactsListItemContent>
        </ContactsListItem>
      ))}
    </>
  );
}
