import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { format } from "date-fns";
import { FormControl, InputLabel, Grid } from "@mui/material";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import SyncIcon from "@mui/icons-material/Sync";
import {
  GradientButton,
  Select,
  DatePicker,
  Title,
  Bubble,
} from "@/shared/components";
import {
  selectTimeOffAvailableDays,
  TimeOffAvailableDaysActions,
  PostTimeOffActions,
  ITimeOffListItem,
  selectTimeOffList,
  selectProfile,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { typesMap, typesGradientColorMap } from "../consts";
import { useHolidays } from "../hooks";
import { TimeOffRequestNote } from "./TimeOffRequestNote";
import {
  RequestButtonWrapp,
  RequestCaption,
  RequestHead,
  RequestHeadTitle,
  RequestHeadSubTitle,
  DaysMessage,
} from "./TimeOffRequest.styled";

interface ITimeOffRequestFormProps {
  editRequest?: boolean;
  currentRequest?: ITimeOffListItem;
}

const getTypeOptions = (availableTypes: number[]) =>
  Object.entries(typesMap)
    .map(([k, v]) => ({
      value: k,
      title: v,
    }))
    .filter((item) => availableTypes.includes(Number(item.value)));

export function TimeOffRequestForm(props: ITimeOffRequestFormProps) {
  const { editRequest, currentRequest } = props;

  const { profile } = useSelector(selectProfile);
  const { psort, pstatus, ptype } = useSelector(selectTimeOffList);
  const { days, message, pending } = useSelector(selectTimeOffAvailableDays);
  const { getTimeOffAvailableDays, resetTimeOffAvailableDays } = useActions<
    typeof TimeOffAvailableDaysActions
  >(TimeOffAvailableDaysActions);
  const { postTimeOff } =
    useActions<typeof PostTimeOffActions>(PostTimeOffActions);

  const availableTypes = profile?.time_offs_types || [];
  const startOfTheDay = new Date();
  startOfTheDay.setUTCHours(0, 0, 0, 0);

  const startDate =
    transformedDate(currentRequest?.start_date || "") || startOfTheDay;
  const endDate = transformedDate(currentRequest?.end_date || "") || new Date();

  const selectedType = currentRequest?.type || availableTypes?.[0];

  const [end, setEnd] = useState<Date>(endDate);
  const [start, setStart] = useState<Date>(startDate);
  const [type, setType] = useState<any>(selectedType); // 1 - Paid Time Off, 2 - Health Time Off, 3 - Unpaid Time Off
  const [note, setNote] = useState(currentRequest?.description || "");

  const { holidays, getHolidays } = useHolidays();

  const typeOptions = getTypeOptions(availableTypes);

  const requestDateFormat = "dd/MM/yyyy";
  const today = format(new Date(), "dd MMM yyyy");
  const lastDayErrorMessage =
    new Date(start).getTime() > new Date(end).getTime()
      ? "Choose the future last date"
      : "";
  const isSendDisabled =
    !type ||
    !start ||
    !end ||
    new Date(start).getTime() > new Date(end).getTime();

  const hasDisabledPast = parseInt(type) === 1;
  const hasDisabledType = editRequest || availableTypes?.length < 2;

  const getHolidayDate = (date: Date | null) => {
    const hasHoliday = holidays.some((holiday) =>
      [
        new Date(holiday.start_date).getTime(),
        new Date(holiday.end_date).getTime(),
      ].includes(new Date(date || 0).getTime())
    );

    return hasHoliday;
  };

  const getDays = () => {
    return parseInt(type) === 3 ? (
      <AllInclusiveIcon sx={{ fontSize: 60 }} />
    ) : (
      <Title fontSize="60px">{days || 0}</Title>
    );
  };
  const getColorByType = () => {
    return typesGradientColorMap[type || 1];
  };

  const getAvailableDays = (params = {}) =>
    getTimeOffAvailableDays({
      type,
      start_date: format(start, requestDateFormat),
      ...params,
    });

  const handleOnTypeChange = (e: any) => {
    const value = e.target.value;

    setType(value);

    getAvailableDays({ type: value });
  };

  const handleOnStartChange = (value: any) => {
    setStart(value);

    getAvailableDays({
      start_date: format(value as any, requestDateFormat),
    });
  };
  const handleOnEndChange = (value: any) => {
    setEnd(value);
  };

  const handleOnNoteChange = (value: string) => {
    setNote(value);
  };

  const handleOnMonthChange = (date: Date | null) => {
    getHolidays(date);
  };

  const handleOnSubmit = () => {
    postTimeOff({
      id: currentRequest?.id,
      start_date: format(start as any, "dd/MM/yyyy"),
      end_date: format(end as any, "dd/MM/yyyy"),
      note: sanitizeHtml(note),
      filters: { psort, pstatus, ptype },
      type,
    });
  };

  useEffect(() => {
    const currentDate = new Date();

    getHolidays(currentDate);
  }, []); //eslint-disable-line

  useEffect(() => {
    if (type && start) {
      getAvailableDays({
        type,
        start_date: currentRequest?.start_date,
      });
    }

    return () => {
      resetTimeOffAvailableDays();
    };
  }, []); //eslint-disable-line

  return (
    <>
      <RequestHead>
        <Bubble width="100px" height="100px" color={getColorByType()}>
          {pending ? <SyncIcon sx={{ fontSize: 32 }} /> : getDays()}
        </Bubble>

        <RequestHeadTitle>WELL-DESERVED DAYS</RequestHeadTitle>

        {message ? (
          <DaysMessage>{message}</DaysMessage>
        ) : (
          <RequestHeadSubTitle>
            on {start ? format(start as any, "dd MMM yyyy") : today}
          </RequestHeadSubTitle>
        )}
      </RequestHead>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="filled" disabled={hasDisabledType}>
            <InputLabel>Select type of request</InputLabel>
            <Select
              variant="filled"
              options={typeOptions}
              value={type}
              onChange={handleOnTypeChange}
            />
          </FormControl>
        </Grid>

        {type ? (
          <>
            <Grid item xs={12}>
              <b>When will you be away?</b>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                disablePast={hasDisabledPast}
                label="First day"
                value={start}
                onChange={handleOnStartChange}
                shouldDisableDate={getHolidayDate}
                onMonthChange={handleOnMonthChange}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                defaultCalendarMonth={start}
                disablePast={hasDisabledPast}
                helperText={lastDayErrorMessage}
                label="Last day"
                minDate={start}
                value={end}
                onChange={handleOnEndChange}
                shouldDisableDate={getHolidayDate}
                onMonthChange={handleOnMonthChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TimeOffRequestNote value={note} onChange={handleOnNoteChange} />
            </Grid>
            <Grid item xs={12}>
              <RequestCaption>
                Write to your manager in order to let him know if you need any
                help, or just a fast approve of your bright new vacation.
              </RequestCaption>
            </Grid>
            <Grid item xs={12}>
              <RequestButtonWrapp>
                <GradientButton
                  fullWidth
                  disabled={isSendDisabled}
                  onClick={handleOnSubmit}
                >
                  SEND REQUEST
                </GradientButton>
              </RequestButtonWrapp>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
}

const transformedDate = (dateString: string) => {
  if (!dateString) return;

  const splittedDate = (dateString || "")
    .split("/")
    .map((item) => parseInt(item));
  const [day, month, year] = splittedDate;
  const date = new Date(year, month - 1, day);

  return date;
};
