import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { Breadcrumbs as MuiBreadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  CART,
  FAQ,
  MARKETPLACE,
  NEWS,
  TEAMS,
  TIMEOFF,
  MOOD,
  TIMESHEET,
  REFERRALS,
  ERROR_404,
  ERROR_500,
} from "@/routes/consts";
import { BreadcrumbsContext } from "@/context/Breadcrumbs";
import { selectProfile } from "@/store/redux";
import { BreadcrumbsContainer } from "./Breadcrumbs.styled";

const BREADCRUMBS: { [k: string]: string } = {
  [TEAMS]: "My Teams",
  [NEWS]: "Bright News",
  [MARKETPLACE]: "Marketplace",
  [CART]: "Checkout",
  [TIMEOFF]: "Time Off Booking",
  [FAQ]: "Portal FAQ",
  [MOOD]: "Mood Monitoring",
  [TIMESHEET]: "Outage & Time Reports",
  [REFERRALS]: "My referrals",
};

export function Breadcrumbs() {
  const { pathname } = useLocation();
  const { title: breadcrumbTitle = "" } = useContext(BreadcrumbsContext);

  const { profile } = useSelector(selectProfile);

  const pathnames = pathname.split("/").filter((x) => x);
  const baseTitle = profile?.id ? "My Profile" : "Bright Portal";

  const windowTitle = pathnames.length
    ? BREADCRUMBS[`/${pathnames[pathnames.length - 1]}`] || breadcrumbTitle
    : baseTitle;
  const cleanedErrorsRoutes = [ERROR_404, ERROR_500].map((item) =>
    item.replace("/", "")
  );
  const isErrorPage = cleanedErrorsRoutes.some((e) => pathnames.includes(e));
  const isHidded = !pathnames.length || isErrorPage;
  const isCartPage = pathname.includes(CART);
  const isMarketPlacePage =
    pathnames[pathnames.length - 1] === MARKETPLACE.replace("/", "");
  const isInsideMarketPlacePage = pathname.includes(`${MARKETPLACE}/`);

  const getFirstItem = () => {
    if (isInsideMarketPlacePage) {
      return null;
    }

    if (isCartPage) {
      return (
        <Link to={MARKETPLACE} component={RouterLink}>
          {BREADCRUMBS[MARKETPLACE]}
        </Link>
      );
    }

    return (
      <Link to="/" component={RouterLink}>
        My Profile
      </Link>
    );
  };

  useEffect(() => {
    document.title = windowTitle || "Bright Portal";
  }, [windowTitle]);

  if (isHidded) {
    return null;
  }

  return (
    <BreadcrumbsContainer $isStatic={isMarketPlacePage}>
      <MuiBreadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        {getFirstItem()}

        {pathnames.map((value: string, index: number) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const last = index === pathnames.length - 1;
          const title = BREADCRUMBS[to] || breadcrumbTitle;

          return !!title ? (
            last ? (
              <span key={title}>{title}</span>
            ) : (
              <Link to={to} key={title} component={RouterLink}>
                {title}
              </Link>
            )
          ) : null;
        })}
      </MuiBreadcrumbs>
    </BreadcrumbsContainer>
  );
}
