import { styled } from "@mui/material/styles";

export const ContentWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 8),

  [theme.breakpoints.down("md")]: {
    padding: 0,
  },
}));

export const ButtonsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(3),
  margin: theme.spacing(4, 0, 1),

  "& button": {
    minWidth: 270,
  },

  [theme.breakpoints.down("smd")]: {
    flexDirection: "column",

    "& button": {
      width: "100%",
    },
  },
}));
