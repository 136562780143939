import { useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { GenerateField } from "./GenerateField";

export function RecipientField() {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldName = "recipient";
  const hasError = !!errors[fieldName]?.type;

  return (
    <GenerateField
      errorText="Please, provide recipient full name"
      name={fieldName}
      rules={{
        validate: (value) => !!value?.trim(),
      }}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          error={hasError}
          label="Recipient"
          variant="filled"
          inputProps={{
            maxLength: 2000,
          }}
        />
      )}
    />
  );
}
