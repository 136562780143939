import { format } from "date-fns";
import { Avatar, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { SwitchThemeMode } from "@/components";
import { Title } from "@/shared/components";
import {
  Wrapper,
  Content,
  Subtitle,
  SubtitlePrefix,
  Date,
} from "./UserCard.styled";

interface IUserCard {
  avatar: string | null | undefined;
  title: string | null | undefined;
  position: string | null | undefined;
  project: string | null | undefined;
  date: Date;
  pending?: boolean;
}

export function UserCard(props: IUserCard) {
  const { avatar, title, position, project, date, pending } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const avatarParams = isMobile ? 116 : 148;
  const formattedDate = format(date, "dd MMM yyyy");

  return pending ? (
    <Loader avatarParams={avatarParams} />
  ) : (
    <Wrapper>
      <Avatar
        src={avatar || ""}
        sx={{ width: avatarParams, height: avatarParams }}
      />

      <Content>
        <Title fontStyle="normal">{title}</Title>

        <Subtitle>
          {position}
          <br />
          <SubtitlePrefix>at</SubtitlePrefix>
          {project}
        </Subtitle>
        <Date>On the Bright side since {formattedDate}</Date>
      </Content>

      <SwitchThemeMode sx={{ position: "absolute", top: 0, right: 0 }} />
    </Wrapper>
  );
}

function Loader(props: any) {
  const { avatarParams } = props;

  return (
    <Wrapper>
      <Skeleton
        animation="wave"
        variant="circular"
        width={avatarParams}
        height={avatarParams}
      />

      <Content>
        <Skeleton
          animation="wave"
          height={25}
          width="175px"
          style={{ marginBottom: 6 }}
        />

        <Skeleton
          animation="wave"
          height={15}
          width="275px"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={15}
          width="275px"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={15}
          width="275px"
          style={{ marginBottom: 6 }}
        />
      </Content>
    </Wrapper>
  );
}
