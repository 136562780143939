import { LogoIcon } from "@/shared/icons";

interface ILogoProps {
  variant?: "default" | "black";
  className?: any;
}

export function Logo(props: ILogoProps) {
  return <LogoIcon {...props} />;
}
