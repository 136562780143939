import { startOfISOWeek, endOfISOWeek, eachDayOfInterval } from "date-fns";
import { DayView } from "../DayView";
import { IProjectsHoursState, IOutageHoursState } from "../interfaces";

interface IMobileWeekViewProps {
  currentDate: Date;
  totalColumnHours: { [k: string | number]: number };
  projectsHours: IProjectsHoursState[];
  outageHours: IOutageHoursState;
  timeButton: any;
}

export function Mobile(props: IMobileWeekViewProps) {
  const {
    timeButton,
    currentDate,
    outageHours,
    projectsHours,
    totalColumnHours,
  } = props;

  const startWeek = startOfISOWeek(currentDate);
  const endWeek = endOfISOWeek(currentDate);
  const daysOfWeek = eachDayOfInterval({
    start: startWeek,
    end: endWeek,
  });

  return (
    <>
      {daysOfWeek.map((day, idx) => (
        <DayView
          key={`${day}-${idx}`}
          day={day}
          timeButton={timeButton}
          outageHours={outageHours}
          projectsHours={projectsHours}
          totalColumnHours={totalColumnHours}
        />
      ))}
    </>
  );
}
