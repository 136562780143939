import { PayloadAction } from "@reduxjs/toolkit";

export interface IPostTimesheetActivityState {
  hours: number;
  start_date: string;
  type: number;
  project_id: number;
  task: string;
  hasPosted: boolean;
  pending: boolean;
  error_message: string;
  error: any;
}

export interface IPostTimesheetActivityStatePending {
  id?: number;
  project_id?: number;
  task?: string;
  hours: number;
  start_date: string;
  period: {
    start_date: string;
    end_date: string;
  };
  type: number;
}

export interface IPostTimesheetActivityStateSuccess {
  id: number;
  error_message: string;
}

export interface IPostTimesheetActivityStateError {
  error: any;
}

export const initialState: IPostTimesheetActivityState = {
  hours: 0,
  start_date: "",
  type: 0,
  project_id: 0,
  task: "",
  hasPosted: false,
  pending: false,
  error_message: "",
  error: null,
};

export const reducers = {
  postTimesheetActivity: (
    state: IPostTimesheetActivityState,
    action: PayloadAction<IPostTimesheetActivityStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      hasPosted: false,
      error_message: "",
      error: "",
      id: null,
      ...(action.payload || {}),
    };
  },
  postTimesheetActivitySuccess: (
    state: IPostTimesheetActivityState,
    action: PayloadAction<IPostTimesheetActivityStateSuccess>
  ) => {
    const { error_message, ...restPayload } = action.payload;

    return {
      ...state,
      pending: false,
      hasPosted: !error_message?.length,
      error_message: error_message || "",
      ...(restPayload || {}),
    };
  },
  postTimesheetActivityError: (
    state: IPostTimesheetActivityState,
    action: PayloadAction<IPostTimesheetActivityStateError>
  ) => ({
    ...state,
    pending: false,
    hasPosted: false,
    error: action.payload.error,
  }),

  //RESET
  resetPostTimesheetActivity: (state: IPostTimesheetActivityState) => ({
    ...state,
    hasPosted: false,
    hours: 0,
    type: 0,
    project_id: 0,
    start_date: "",
    task: "",
    pending: false,
    error_message: "",
    error: null,
  }),
  resetPostTimesheetActivitySuccess: (state: IPostTimesheetActivityState) => ({
    ...state,
  }),
  resetPostTimesheetActivityError: (state: IPostTimesheetActivityState) => ({
    ...state,
  }),
};
