import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IPopularAuthorsState } from "./reducers";

export const popularAuthorsSelector = (
  state: RootState
): IPopularAuthorsState => state.popularAuthors;

export const selectPopularAuthors = createSelector(
  popularAuthorsSelector,
  (popularAuthorsState: IPopularAuthorsState) => ({ ...popularAuthorsState })
);
