import { useSelector } from "react-redux";
import { format } from "date-fns";
import { Box } from "@mui/material";
import { selectEmployeeCalendar } from "@/store/redux";
import { useDevice } from "@/utils/hooks";
import { groupEvents } from "./helpers";
import { getEmoji } from "./EmployeeCalendar";
import {
  EventTitle,
  EventTitleDate,
  EventDescWrapper,
  EmojiWrapper,
  EventDesc,
  MobileListView,
  MobileListViewItem,
} from "./EmployeeCalendar.styled";

export function EmployeeCalendarMobile() {
  const hasMobile = useDevice("down", "md");

  const { events, pending } = useSelector(selectEmployeeCalendar);

  const groupedEvents = groupEvents(events);

  if (!hasMobile) {
    return null;
  }

  if (pending) {
    return <Box sx={{ textAlign: "center" }}>Loading ...</Box>;
  }

  return (
    <MobileListView>
      {groupedEvents.map(({ date, events }) => (
        <MobileListViewItem key={date}>
          <EventTitle>
            <EventTitleDate>Date</EventTitleDate>
            <b>{format(new Date(date), "dd MMM yyyy")}</b>
          </EventTitle>

          {(events || []).map(
            ({ country_code, event_type, description }, idx) => (
              <EventDescWrapper key={`${description}-${idx}`}>
                <EmojiWrapper>
                  {getEmoji({
                    countryCode: country_code,
                    eventType: event_type,
                  })}
                </EmojiWrapper>
                <EventDesc sx={{ whiteSpace: "normal" }}>
                  <b>{description}</b>
                </EventDesc>
              </EventDescWrapper>
            )
          )}
        </MobileListViewItem>
      ))}
    </MobileListView>
  );
}
