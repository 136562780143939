import { useSelector } from "react-redux";
import { Filters, FilterControl, Select } from "@/shared/components";
import { selectTimeOffList } from "@/store/redux";
import { typesMap, statusesMap } from "../consts";

export interface IFilters {
  ptype: number;
  pstatus: number;
  psort: string;
}

export interface ITimeOffFiltersProps {
  filters: IFilters;
  onChange: (k: any) => void;
}

const types = [
  { value: "-1", title: "All Bookings" },
  ...Object.entries(typesMap).map(([k, v]) => ({ value: k, title: v })),
];
const statuses = [
  { value: "-1", title: "All Statuses" },
  ...Object.entries(statusesMap).map(([k, v]) => ({
    value: k,
    title: v,
  })),
];

const sort = [
  { value: "desc", title: "Newest First" },
  { value: "asc", title: "Oldest First" },
];

export function TimeOffFilters(props: ITimeOffFiltersProps) {
  const { filters, onChange } = props;
  const { pending } = useSelector(selectTimeOffList);

  return (
    <Filters>
      <FilterControl
        label="Type:"
        control={
          <Select
            disabled={pending}
            name="ptype"
            options={types}
            value={filters.ptype}
            onChange={onChange}
          />
        }
      />
      <FilterControl
        label="Status:"
        control={
          <Select
            disabled={pending}
            name="pstatus"
            options={statuses}
            value={filters.pstatus}
            onChange={onChange}
          />
        }
      />
      <FilterControl
        label="Sort by:"
        control={
          <Select
            disabled={pending}
            name="psort"
            options={sort}
            value={filters.psort}
            onChange={onChange}
          />
        }
      />
    </Filters>
  );
}
