import { PayloadAction } from "@reduxjs/toolkit";

export interface ICheckoutState {
  pending: boolean;
  error: any;
  message: string;
  hasSubmitted: boolean;
}

export interface ICheckoutStatePending {
  donate: number | string;
  country: number | string;
  delivery_type: number | string;
  recipient: string;
  phone_number: string;
  city?: string;
  note?: string;
  postal_service?: number | string;
  postal_point?: string;
  house?: string;
  street?: string;
  apartments?: string;
  postal_code?: string;
}

export interface ICheckoutStateSuccess {
  message: string;
  hasSubmitted: boolean;
}

export interface ICheckoutStateError {
  error: any;
}

export const initialState: ICheckoutState = {
  pending: false,
  error: "",
  message: "",
  hasSubmitted: false,
};

export const reducers = {
  postCheckout: (
    state: ICheckoutState,
    action: PayloadAction<ICheckoutStatePending>
  ) => {
    return {
      ...state,
      message: "",
      hasSubmitted: false,
      pending: true,
      error: "",
    };
  },
  postCheckoutSuccess: (
    state: ICheckoutState,
    action: PayloadAction<ICheckoutStateSuccess>
  ) => {
    const { message } = action.payload;

    return {
      ...state,
      pending: false,
      hasSubmitted: !message || message === "Final Node issue", //TODO: remove this dirty hack
      message,
    };
  },
  postCheckoutError: (
    state: ICheckoutState,
    action: PayloadAction<ICheckoutStateError>
  ) => ({
    ...state,
    pending: false,
    hasSubmitted: false,
    message: "",
    error: action.payload.error,
  }),

  resetCheckout: (state: ICheckoutState) => ({
    ...state,
    hasSubmitted: false,
  }),
  resetCheckoutSuccess: (state: ICheckoutState) => ({
    ...state,
  }),
  resetCheckoutError: (state: ICheckoutState) => ({
    ...state,
  }),
};
