import { useSelector } from "react-redux";
import {
  NewsWrapper,
  Container,
  Page,
  NewsArticleContent,
  NewsArticleSidebar,
} from "@/components";
import { selectNewsArticle } from "@/store/redux";

export function NewsArticle() {
  const { article } = useSelector(selectNewsArticle);

  return (
    <Page title={article?.title}>
      <Container>
        <NewsWrapper>
          <NewsArticleContent />
          <NewsArticleSidebar />
        </NewsWrapper>
      </Container>
    </Page>
  );
}
