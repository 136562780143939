import { IMyDocumentsItem } from "@/store/redux";
import { FileExtIcon } from "../MyDocuments.styled";

interface IFileIconProps {
  file: IMyDocumentsItem;
}

const colorsMap: { [k: string]: string } = {
  doc: "#97E2F7",
  docx: "#97E2F7",
  xls: "#20E9A9",
  xlsx: "#20E9A9",
  ppt: "#A790CB",
  pptx: "#A790CB",
  jpg: "#F9C6B9",
  jpeg: "#F9C6B9",
  png: "#F9C6B9",
  mp4: "#DEFF2B",
  pdf: "#6E9AED",
};

export function FileIcon(props: IFileIconProps) {
  const { file } = props;

  const fileNameArr = (file?.name || "").split(".");
  const fileExt = fileNameArr[fileNameArr?.length - 1];
  const color = colorsMap[fileExt] || "#e6e6e6";

  const icon = <FileExtIcon $color={color}>.{fileExt}</FileExtIcon>;

  return icon;
}
