import { ITaskReportingItemSuccess } from "./reducers";

export function transformReporting(data: Array<ITaskReportingItemSuccess>) {
  return (data || []).reduce(
    (acc, curr) => ({
      ...acc,
      [curr.project.id]: {
        project_name: curr?.project?.name,
        tasks: (curr.tasks || []).map((value, idx) => ({
          value,
          id: `${curr?.project?.name}_${idx}`,
          checked: false,
        })),
      },
    }),
    {}
  );
}
