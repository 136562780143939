import { styled } from "@mui/material/styles";
import { FormControlLabel } from "@mui/material";

export const ActionButtonWrapper = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== "$hasCount",
})<{
  $hasCount?: boolean;
}>(({ theme, $hasCount }) => ({
  padding: theme.spacing(0.5, 1.5),
  border: "1px solid transparent",
  transition: "all 0.5s ease",
  textTransform: "capitalize",
  borderRadius: 2,
  marginLeft: 0,
  marginRight: 0,

  "&:hover": {
    borderColor: theme.palette.darkGrey.main,
  },

  "& .MuiButtonBase-root": {
    padding: 0,
    marginRight: theme.spacing(0.5),

    "&:hover": {
      background: "none",
    },
  },

  "& .MuiFormControlLabel-label": {
    fontWeight: 700,
    color:
      $hasCount && theme.palette.mode !== "dark"
        ? "#000"
        : theme.palette.darkGrey.main,
  },

  "& svg": {
    fontSize: "1.5rem",
  },
}));
