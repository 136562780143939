interface IAppConfig {
  HOST: string;
  API_URL: string;
  MSAL_CLIENT_ID: string;
  MSAL_TENANT_ID: string;
  MSAL_REDIRECT_URI: string;
  HELPDESK_LINK: string;
  WIKI_LINK: string;
  RECENT_VACANCIES_LINK: string;
  MARKETPLACE_ENABLE: string;
  APP_NODE_ENV: string;
}

export const appConfig: IAppConfig = {
  HOST: process.env.REACT_APP_HOST || "",
  API_URL: process.env.REACT_APP_API_URL || "",
  MSAL_CLIENT_ID: process.env.REACT_APP_MSAL_CLIENT_ID || "",
  MSAL_TENANT_ID: process.env.REACT_APP_MSAL_TENANT_ID || "",
  MSAL_REDIRECT_URI: process.env.REACT_APP_MSAL_REDIRECT_URI || "",
  HELPDESK_LINK: process.env.REACT_APP_HELPDESK_LINK || "",
  WIKI_LINK: process.env.REACT_APP_WIKI_LINK || "",
  RECENT_VACANCIES_LINK: process.env.REACT_APP_RECENT_VACANCIES_LINK || "",
  MARKETPLACE_ENABLE: process.env.REACT_APP_MARKETPLACE_ENABLE || "",
  APP_NODE_ENV: process.env.REACT_APP_NODE_ENV || "",
};
