import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "PostMood",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  postMood,
  postMoodError,
  postMoodSuccess,
  resetPostMood,
  resetPostMoodError,
  resetPostMoodSuccess,
} = slice.actions;

reducerRegistry.register("postMood", reducer);
