import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Skeleton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { GradientButton } from "@/shared/components";
import { RecentVacanciesActions, selectRecentVacancies } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { REFERRALS } from "@/routes/consts";
import {
  TitleStyled,
  LinkButton,
  Vacancy,
  VacancyLink,
  VacancyBonus,
} from "./RecentVacancies.styled";

export function RecentVacancies() {
  const { vacancies, pending } = useSelector(selectRecentVacancies);
  const { getRecentVacancies } = useActions<typeof RecentVacanciesActions>(
    RecentVacanciesActions
  );

  useEffect(() => {
    if (!vacancies.length) getRecentVacancies();
  }, []); //eslint-disable-line

  return pending ? (
    <Loader />
  ) : (
    <>
      <div>
        <Box sx={{ mb: 2 }}>
          <TitleStyled>OPEN VACANCIES</TitleStyled>
        </Box>

        {(vacancies || []).map(({ referral_bonus, title, id }) => (
          <Vacancy key={id}>
            <VacancyLink to={`${REFERRALS}/${id}`}>
              <div>{title}</div>

              {referral_bonus ? (
                <VacancyBonus>
                  Get ${referral_bonus}
                  <KeyboardArrowRightIcon />
                </VacancyBonus>
              ) : null}
            </VacancyLink>
          </Vacancy>
        ))}
      </div>

      <Box sx={{ mt: 2 }}>
        <LinkButton to={REFERRALS}>
          <GradientButton fullWidth>MY REFERRALS</GradientButton>
        </LinkButton>
      </Box>
    </>
  );
}

function Loader() {
  return (
    <>
      <Skeleton animation="wave" height={25} sx={{ mb: 1 }} />

      <Skeleton animation="wave" height={15} />
      <Skeleton animation="wave" height={15} />
      <Skeleton animation="wave" height={15} />

      <Skeleton animation="wave" height={15} sx={{ mt: 2 }} />
      <Skeleton animation="wave" height={15} />
      <Skeleton animation="wave" height={15} />
    </>
  );
}
