import {
  Container,
  LayoutWrapper,
  LayoutContent,
  Header,
  Footer,
  TaskReportinfWrapper,
} from "@/components";

export function TaskReporting() {
  return (
    <LayoutWrapper>
      <Header />

      <LayoutContent>
        <Container>
          <TaskReportinfWrapper />
        </Container>
      </LayoutContent>

      <Footer />
    </LayoutWrapper>
  );
}
