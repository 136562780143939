import { useState } from "react";
import { ISurveysListItem } from "@/store/redux";
import { orderSurveys } from "./helpers";
import { SurveysListHead } from "./SurveysListHead";
import { SurveysListItem } from "./SurveysListItem";
import { ListBody, ListWrapper } from "./SurveysList.styled";

interface ISurveysListProps {
  list: Array<ISurveysListItem>;
  hasFinished?: boolean;
}

export type orderDirection = "asc" | "desc";
export type orderField = "name" | "expire_date" | "status";

export function SurveysList(props: ISurveysListProps) {
  const { list, hasFinished } = props;

  const [orderByField, setOrderByField] = useState<orderField>("expire_date");
  const [orderType, setOrderType] = useState<orderDirection>("asc");

  const orderedSurveys = orderSurveys({
    data: list,
    orderByField,
    type: orderType,
  });

  const handleOnSort = (name: orderField) => {
    setOrderByField(name);
    setOrderType((prevType) => (prevType === "asc" ? "desc" : "asc"));
  };

  return (
    <>
      {list?.length ? (
        <ListWrapper>
          <SurveysListHead
            hasFinished={hasFinished}
            sortType={orderType}
            orderByField={orderByField}
            onSort={handleOnSort}
          />

          <ListBody>
            {orderedSurveys.map((item, idx) => (
              <SurveysListItem key={idx} {...item} hasFinished={hasFinished} />
            ))}
          </ListBody>
        </ListWrapper>
      ) : hasFinished ? (
        "No finished surveys"
      ) : (
        "No active surveys"
      )}
    </>
  );
}
