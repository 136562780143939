import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const postTimesheetActivityRequest = async (params: any) => {
  const response: AxiosResponse = await apiInstance.post(
    "/timesheet/activity",
    {
      ...params,
    }
  );

  return response.data;
};

export const putTimesheetActivityRequest = async (params: any) => {
  const { id, ...restParams } = params;

  const response: AxiosResponse = await apiInstance.put(
    `/timesheet/activity/${id}`,
    {
      ...restParams,
    }
  );

  return response.data;
};
