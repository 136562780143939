import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IMoodCalendarStateSuccess,
  IMoodCalendarStatePending,
} from "./reducers";
import {
  getMoodCalendar,
  getMoodCalendarError,
  getMoodCalendarSuccess,
} from "./slice";
import { getMoodCalendarRequest } from "./api";

function* getMoodCalendarSaga(
  action: PayloadAction<IMoodCalendarStatePending>
) {
  try {
    const MoodCalendar: IMoodCalendarStateSuccess = yield call(
      getMoodCalendarRequest,
      action.payload
    );

    yield put(getMoodCalendarSuccess(MoodCalendar));
  } catch (error) {
    yield put(getMoodCalendarError({ error }));
  }
}

function* MoodCalendarWatcher() {
  yield takeLatest(getMoodCalendar.type, getMoodCalendarSaga);
}

export default MoodCalendarWatcher;
