import { useState, FormEvent } from "react";
import { useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { selectProfile, INewsCommentsItem } from "@/store/redux";
import { htmlEncode } from "@/utils/helpers";
import { AuthorComment } from "./AuthorComment";
import { FieldWithCounter } from "./FieldWithCounter";
import { AddCommentForm, AddCommentButton } from "./Comments.styled";

interface IAddCommentProps {
  onSubmit: (k: any) => void;
}

export function AddComment(props: IAddCommentProps) {
  const { onSubmit } = props;
  const { profile } = useSelector(selectProfile);

  const [text, setText] = useState("");
  const [comment, setComment] = useState<INewsCommentsItem>({
    text: "",
    author: {
      id: null,
      avatar: "",
      name: "",
    },
    has_like: false,
    likes: 0,
    id: undefined,
    created_at: "",
  });

  const handleOnCommentChange = (value: string) => {
    setText(value);
  };

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const cleanComment = sanitizeHtml(text);

    if (!cleanComment) return;

    const updatedComment: INewsCommentsItem = {
      ...comment,
      author: {
        id: profile?.id,
        avatar: profile?.avatar || "",
        name: profile?.user_name || "",
      },
      id: +new Date(),
      created_at: new Date().toISOString(),
      text: htmlEncode(cleanComment.trim()),
    };

    setComment(updatedComment);
    onSubmit(updatedComment);

    setText("");
  };

  return (
    <AddCommentForm onSubmit={handleOnSubmit}>
      <AuthorComment image={profile?.avatar} name={profile?.user_name || ""} />

      <FieldWithCounter
        maxValue={1000}
        value={text}
        onChange={handleOnCommentChange}
      />

      <AddCommentButton type="submit" disabled={!text.trim()}>
        Send
      </AddCommentButton>
    </AddCommentForm>
  );
}
