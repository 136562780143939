import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IPostReferralsState } from "./reducers";

export const postReferralsSelector = (state: RootState): IPostReferralsState =>
  state.postReferrals;

export const selectPostReferrals = createSelector(
  postReferralsSelector,
  (postReferralsState: IPostReferralsState) => ({ ...postReferralsState })
);
