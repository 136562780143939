import { ToggleButtonGroup, ToggleButton, OutlinedInput } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Title } from "@/shared/components";

export const TotalWrapper = styled(Title)(({ theme }) => ({
  fontSize: 36,
  fontStyle: "normal",
  textTransform: "none",
  color: theme.palette.darkGrey.main,
  display: "inline-flex",
  gap: theme.spacing(1),
}));

export const TotalValue = styled("span")(({ theme }) => ({
  color: theme.modeColors({
    dark: theme.palette.common.white,
    light: theme.palette.common.black,
  }),
}));

export const TotalDesc = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  maxWidth: "500px",

  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

export const DonateWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  gap: theme.spacing(1.5),

  "& .MuiInputBase-input": {
    padding: theme.spacing(1.5, 1.75, 1.5, 0),
    fontWeight: 900,

    "&::placeholder": {
      fontWeight: 500,
    },
  },

  "& .MuiToggleButtonGroup-root": {
    gap: theme.spacing(1.5),
  },

  "& .MuiInputAdornment-root .MuiTypography-root": {
    fontWeight: 900,
    color: theme.modeColors({
      dark: theme.palette.common.white,
      light: theme.palette.common.black,
    }),
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "inherit",
    gap: theme.spacing(1),

    "& .MuiToggleButtonGroup-root": {
      gap: theme.spacing(0.5),
    },
  },
}));

export const DonateField = styled(OutlinedInput)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    order: 1,
  },
}));

export const DonateButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    order: 2,
  },
}));

export const DonateButton = styled(ToggleButton)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "25%",
  },
}));
