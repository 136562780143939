export const PROFILE: string = "/";
export const TEAMS: string = "/teams";
export const NEWS: string = "/news";
export const MARKETPLACE: string = "/marketplace";
export const CART: string = `${MARKETPLACE}/cart`;
export const TIMEOFF: string = "/time-off";
export const FAQ: string = "/faq";
export const MOOD: string = "/mood-monitoring";
export const TIMESHEET: string = "/timesheet";
export const REFERRALS: string = "/referrals";
export const CALENDAR: string = "/calendar";
export const SURVEYS: string = "/surveys";
export const MY_DOCUMENTS: string = "/my-documents";

export const TASK_REPORTING: string = "/task-reporting";

export const ERROR_404: string = "/404";
export const ERROR_500: string = "/500";
export const LOGOUT: string = "/logout";
