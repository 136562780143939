import { useFormContext } from "react-hook-form";
import { Box, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import { FormInput } from "@/shared/components";

export function Agreement(props: { pending: boolean }) {
  const { pending } = props;

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <FormInput
      name="agree"
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => (
        <>
          <FormControlLabel
            sx={{ alignItems: "start" }}
            control={
              <Checkbox
                disabled={pending}
                checked={value}
                onChange={onChange}
              />
            }
            label={
              <Box component="span" sx={{ fontSize: "0.875rem" }}>
                I am informed about processing of my personal data and the right
                to withdraw my consent. By submitting the completed “Contact Us”
                form, your personal data will be processed by Brightgrove. All
                the information how we store and use the data you can find in
                our “Terms”. Which are short and written in human language.
              </Box>
            }
          />

          {errors.agree?.type === "required" && (
            <FormHelperText error>
              We need your permission to process data
            </FormHelperText>
          )}
        </>
      )}
    />
  );
}
