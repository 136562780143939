import type { Location, useMatches } from "react-router-dom";
import { useCallback, useEffect, Suspense } from "react";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { BreadcrumbsProvider } from "@/context/Breadcrumbs";
import { selectErrors, ErrorsActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import {
  AppData,
  Signin,
  Header,
  Footer,
  WelcomeVideo,
  ThanksGivingDay,
  MoodMonitoringDialog,
  NotFound,
  ServerError,
} from "@/components";
import { SuspenseLoader } from "@/shared/components";

import { Wrapper, Content } from "./Layout.styled";

export function Layout() {
  const { pathname } = useLocation();
  const errorStatusCode = useSelector(selectErrors);
  const { removeError } = useActions<typeof ErrorsActions>(ErrorsActions);

  const errorsMap: { [k: number]: any } = {
    404: <NotFound />,
    500: <ServerError />,
  };
  const currentError = errorsMap[errorStatusCode];

  const getKey = useCallback(
    (location: Location, matches: ReturnType<typeof useMatches>) => {
      const match = matches.find((m) => (m.handle as any)?.scrollMode);

      if ((match?.handle as any)?.scrollMode === "pathname") {
        return location.pathname;
      }

      return location.key;
    },
    []
  );

  useEffect(() => {
    if (errorStatusCode > 0) removeError();
  }, [pathname]); //eslint-disable-line

  return (
    <Wrapper>
      <AuthenticatedTemplate>
        <AppData>
          {/* Header */}
          <Header />
          {/* end Header */}

          {errorStatusCode ? (
            currentError
          ) : (
            <>
              {/* Modals */}
              <WelcomeVideo />
              <ThanksGivingDay />
              <MoodMonitoringDialog />
              {/* end Modals */}

              {/* Content */}
              <Content>
                <BreadcrumbsProvider>
                  <Suspense fallback={<SuspenseLoader />}>
                    <Outlet />
                  </Suspense>
                </BreadcrumbsProvider>
              </Content>
              {/* end Content */}
            </>
          )}

          {/* Footer */}
          <Footer />
          {/* end Footer */}
        </AppData>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Content>
          <Signin />
        </Content>
      </UnauthenticatedTemplate>

      <ScrollRestoration getKey={getKey} />
    </Wrapper>
  );
}
