import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ITeamMembersState, ITeamMembersStatePending } from "./reducers";
import {
  getTeamMembers,
  getTeamMembersError,
  getTeamMembersSuccess,
} from "./slice";
import { getTeamMembersRequest } from "./api";

function* getTeamMembersSaga(action: PayloadAction<ITeamMembersStatePending>) {
  try {
    const team: ITeamMembersState = yield call(
      getTeamMembersRequest,
      action.payload
    );

    yield put(getTeamMembersSuccess(team));
  } catch (error) {
    yield put(getTeamMembersError({ error }));
  }
}

function* TeamMembersWatcher() {
  yield takeLatest(getTeamMembers.type, getTeamMembersSaga);
}

export default TeamMembersWatcher;
