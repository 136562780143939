import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { ReferralsActions, selectReferrals } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { Title, GradientButton } from "@/shared/components";
import {
  GridStyled,
  BubbleWrapper,
  BubbleStyled,
  TitleStyled,
} from "./Referrrals.styled";

interface IReferralsHeadProps {
  onDialogOpen: () => void;
}

export function ReferralsHead(props: IReferralsHeadProps) {
  const { onDialogOpen } = props;

  const { pending, candidates_referred, earning_next_month, total_bonuses } =
    useSelector(selectReferrals);
  const { getReferrals } =
    useActions<typeof ReferralsActions>(ReferralsActions);

  const bubbles = [
    {
      value: pending ? (
        <SyncIcon sx={{ fontSize: 32 }} />
      ) : (
        candidates_referred || 0
      ),
      title: "Accepted Referrals",
    },
    {
      value: pending ? <SyncIcon sx={{ fontSize: 32 }} /> : total_bonuses || 0,
      title: "Total Bonuses",
    },
    {
      value: pending ? (
        <SyncIcon sx={{ fontSize: 32 }} />
      ) : (
        earning_next_month || 0
      ),
      title: "Earning next month",
    },
  ];

  useEffect(() => {
    getReferrals();
  }, []); //eslint-disable-line

  return (
    <>
      <Title>MY REFERRALS</Title>

      <Box sx={{ mt: 3, mb: 8 }}>
        <Grid container rowSpacing={{ xs: 4, sm: 0 }} columnSpacing={3}>
          {bubbles.map(({ value, title }) => (
            <GridStyled key={title} item xs={6} sm="auto">
              <BubbleWrapper>
                <BubbleStyled>
                  <Title fontSize="52px">{value}</Title>
                </BubbleStyled>
              </BubbleWrapper>
              <TitleStyled>{title}</TitleStyled>
            </GridStyled>
          ))}
        </Grid>
      </Box>

      <Box sx={{ mt: 8, mb: 5 }}>
        <GradientButton
          sx={{ width: { xs: "100%", sm: 275 } }}
          onClick={onDialogOpen}
        >
          REFER TO TALENT POOL
        </GradientButton>
      </Box>
    </>
  );
}
