import { Menu, MenuItem, Box, useTheme, PopoverProps } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { SwitchUser } from "@/components";

interface IUserInfoDropdownProps {
  anchorEl: PopoverProps["anchorEl"];
  onClose: () => void;
  onLogout: () => void;
}

export function UserInfoDropdown(props: IUserInfoDropdownProps) {
  const { anchorEl, onClose, onLogout } = props;
  const { palette, spacing } = useTheme();

  return (
    <Menu
      keepMounted
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          sx: {
            background: palette.primary.main,
            // marginTop: spacing(1),
          },
          elevation: 0,
          square: true,
        },
      }}
      MenuListProps={{
        sx: {
          padding: 0,
          color: palette.common.white,

          "& li": {
            padding: spacing(2, 3),

            "&:hover": {
              color: palette.secondary.main,
            },
          },
        },
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <SwitchUser onClose={onClose} />

      <MenuItem onClick={onLogout}>
        <ExitToAppIcon />
        <Box sx={{ ml: 1 }}>Sign out</Box>
      </MenuItem>
    </Menu>
  );
}
