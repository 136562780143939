import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ITeam } from "./reducers";
import { getTeam, getTeamError, getTeamSuccess } from "./slice";
import { getTeamRequest } from "./api";

function* getTeamSaga(action: PayloadAction<number | string | undefined>) {
  try {
    const team: ITeam = yield call(getTeamRequest, action.payload);

    yield put(getTeamSuccess(team));
  } catch (error) {
    yield put(getTeamError({ error }));
  }
}

function* TeamWatcher() {
  yield takeLatest(getTeam.type, getTeamSaga);
}

export default TeamWatcher;
