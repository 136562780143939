import { ReactNode } from "react";
import { Box } from "@mui/material";
import { Accordion, Title } from "@/shared/components";

interface IMarketPlaceQAItem {
  title: string;
  desc: string | ReactNode;
}

const data: IMarketPlaceQAItem[] = [
  {
    title: "How should I pay?",
    desc: "There is no need to pay when ordering items at the marketplace. We will deduct the sum for your order from your monthly compensation. The recipient pays for delivery at the point of collection. The cost is determined by the carrier's tariffs.",
  },
  {
    title:
      "Can I purchase items if I'm outside of Ukraine, say from countries in LATAM or European countries?",
    desc: "You can order items to any country, however, the cost of delivery is paid by the recipient. If you plan to visit Ukraine, we can hold your purchase at our office. Please note that delivery to America, LATAM, or Canada will cost between $60-200 depending on the size and weight of the package.",
  },
  {
    title:
      "The prices are in dollars, but I get my compensation in hryvnyas. What should I do?",
    desc: "We will do all the exchange operations with decent rates. No worries.",
  },
  {
    title: "How many items can I buy?",
    desc: "You can purchase items for yourself, your significant other, your friends, and even your friends' significant others! There is no limit on the number of items in a single cart, only a limited quantity of branded items.",
  },
  {
    title: "Can I cancel my order or change it?",
    desc: (
      <>
        Yes, you can. To cancel or change the order, please write to{" "}
        <a href="mailto:marketplace@brightgrove.com">
          marketplace@brightgrove.com
        </a>
        .
      </>
    ),
  },
  {
    title: "How long will the shipping take?",
    desc: "We don't know exactly. It depends on the delivery service provider and your luck :) But no more than three weeks.",
  },
  {
    title:
      "Can I book an item for some time before I finally decide what to buy?",
    desc: "Sure. When you add an item to cart, we reserve it for you during the next six hours.",
  },
  {
    title: "Is there any option to deliver an item to someone else?",
    desc: "No, sorry.",
  },
];

export function MarketPlaceQA() {
  return (
    <Box mb={20}>
      <Title fontWeight="500">FREQUENTLY ASKED QUESTIONS</Title>

      <Box mt={4}>
        <Accordion data={data} />
      </Box>
    </Box>
  );
}
