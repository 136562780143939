import { useState, forwardRef, PropsWithChildren } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from "react-router-dom";
import { IconButton, Box, List, ListItemIcon } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  CART,
  FAQ,
  MARKETPLACE,
  MOOD,
  NEWS,
  PROFILE,
  TEAMS,
  TIMEOFF,
  TIMESHEET,
} from "@/routes/consts";
import { menuItems } from "@/config/navigationMenu";
import { ReferrButton } from "./ReferrButton";
import {
  DrawerStyled,
  MenuItem,
  MenuItemText,
  MenuItemExternal,
  Close,
  CloseWrapper,
} from "./UserMenu.styled";

interface IListItemLinkProps {
  primary: string;
  to: string;
  isActive: boolean;
  icon?: React.ReactElement;
  external?: boolean;
  onClick?: () => void;
}

interface IItemComponentProps extends PropsWithChildren {
  selected: boolean;
  to: string;
}

interface IUserMenuProps {
  window?: any;
}

const routesMap: { [k: string]: string } = {
  [PROFILE]: "",
  [TEAMS]: TEAMS,
  [NEWS]: NEWS,
  [MARKETPLACE]: MARKETPLACE,
  [CART]: CART,
  [TIMEOFF]: TIMEOFF,
  [FAQ]: FAQ,
  [TIMESHEET]: TIMESHEET,
  [MOOD]: MOOD,
};

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(
  itemProps,
  ref
) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

export function ListItemLink(props: IListItemLinkProps) {
  const { icon, primary, to, external, isActive, onClick } = props;

  const ItemComponent = (props: IItemComponentProps) => {
    const { children, selected, to } = props;

    return external ? (
      <MenuItemExternal
        href={to}
        target="_blank"
        rel="noopener"
        onClick={onClick}
      >
        {children}
      </MenuItemExternal>
    ) : (
      <MenuItem to={to} selected={selected} component={Link} onClick={onClick}>
        {children}
      </MenuItem>
    );
  };

  return (
    <li>
      <ItemComponent to={to} selected={isActive}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <MenuItemText>{primary}</MenuItemText>
      </ItemComponent>
    </li>
  );
}

export function UserMenu(props: IUserMenuProps) {
  const { window } = props;
  const { pathname } = useLocation();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const getActiveItem = (to: string) => {
    const pathnames = pathname.split("/").filter(Boolean);
    const cleanToPath = routesMap[to]?.replace("/", "");

    return (
      (!pathnames.length && to === PROFILE) || pathnames.includes(cleanToPath)
    );
  };

  const handleOnUserMenuToggle = () => {
    setUserMenuOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box>
      <CloseWrapper>
        <IconButton onClick={handleOnUserMenuToggle}>
          <Close />
        </IconButton>
      </CloseWrapper>

      <List>
        {menuItems.map(({ title, to, hidden, external }) =>
          !hidden ? (
            <ListItemLink
              key={title}
              to={to}
              primary={title}
              external={external}
              isActive={getActiveItem(to)}
              onClick={handleOnUserMenuToggle}
            />
          ) : null
        )}
      </List>

      <ReferrButton onClose={handleOnUserMenuToggle} />
    </Box>
  );

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={handleOnUserMenuToggle}
      >
        <MenuIcon />
      </IconButton>

      <DrawerStyled
        container={container}
        variant="temporary"
        open={userMenuOpen}
        onClose={handleOnUserMenuToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </DrawerStyled>
    </>
  );
}
