import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IRecentVacanciesState } from "./reducers";

export const recentVacanciesSelector = (
  state: RootState
): IRecentVacanciesState => state.recentVacancies;

export const selectRecentVacancies = createSelector(
  recentVacanciesSelector,
  (recentVacanciesState: IRecentVacanciesState) => ({ ...recentVacanciesState })
);
