import { useState } from "react";

export function useDialog(): [boolean, () => void, () => void] {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOnDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleOnDialogClose = () => {
    setOpenDialog(false);
  };

  return [openDialog, handleOnDialogOpen, handleOnDialogClose];
}
