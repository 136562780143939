import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "EmployeeCalendar",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getEmployeeCalendar,
  getEmployeeCalendarError,
  getEmployeeCalendarSuccess,
} = slice.actions;

reducerRegistry.register("employeeCalendar", reducer);
