import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IReferralsVacanciesStateSuccess,
  IReferralsVacanciesStatePending,
} from "./reducers";
import {
  getReferralsVacancies,
  getReferralsVacanciesError,
  getReferralsVacanciesSuccess,
} from "./slice";
import { getReferralsVacanciesRequest } from "./api";

function* getReferralsVacanciesSaga(
  action: PayloadAction<IReferralsVacanciesStatePending>
) {
  try {
    const vacancies: IReferralsVacanciesStateSuccess = yield call(
      getReferralsVacanciesRequest,
      action.payload
    );

    yield put(getReferralsVacanciesSuccess(vacancies));
  } catch (error) {
    yield put(getReferralsVacanciesError({ error }));
  }
}

function* ReferralsVacanciesWatcher() {
  yield takeLatest(getReferralsVacancies.type, getReferralsVacanciesSaga);
}

export default ReferralsVacanciesWatcher;
