import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { IPostReferralsStatePending } from "./reducers";

export interface IPostReferralsCVRequest {
  cv: FormData;
  fileName: string;
  referralId: number;
}
export interface IPostReferralsCVResponse {
  status: string;
  fileName: string;
  fileLength: number;
}

export const postReferralsRequest = async (
  params: Partial<IPostReferralsStatePending>
) => {
  const response: AxiosResponse = await apiInstance.post("/referrals", {
    ...params,
  });

  return response.data;
};

export const postReferralsCvRequest = async (
  params: IPostReferralsCVRequest
) => {
  const { cv, fileName, referralId } = params;

  const response: AxiosResponse<IPostReferralsCVResponse> =
    await apiInstance.post(`/referrals/${referralId}/uploadcv`, cv, {
      headers: {
        "Content-Type": "multipart/form-data",
        "File-Name": fileName,
      },
    });

  return response.data;
};
