import { MenuList, MenuItem, MenuItemTitle } from "./Menu.styled";

interface IMenuProps {
  menu: { id: number; title: string; value: number }[];
  selected: number | string;
  type: string;
  onChange: (v: { [k: string]: number }) => void;
}

export function Menu(props: IMenuProps) {
  const { menu, type, selected, onChange } = props;

  const handleOnChange =
    (value: number) =>
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onChange({ [type]: value });
    };

  return (
    <MenuList>
      {menu.map(({ id, title, value }) => (
        <MenuItem
          key={id}
          selected={selected === value}
          onClick={handleOnChange(value)}
        >
          <MenuItemTitle>{title}</MenuItemTitle>
        </MenuItem>
      ))}
    </MenuList>
  );
}
