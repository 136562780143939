import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectTimesheetActivity, selectUserProjects } from "@/store/redux";
import { useReporting } from "./hooks";
import { TimesheetAddForm } from "./TimesheetAddForm";

interface ITimesheetEventDialogContentProps {
  hasPosted: boolean;
  selectedDate: Date | null;
  selectedEvent: any;
  weekView?: boolean;
  onDialogClose: () => void;
}

export function TimesheetEventDialogContent(
  props: ITimesheetEventDialogContentProps
) {
  const { weekView, hasPosted, selectedDate, selectedEvent, onDialogClose } =
    props;

  const [currentDate, setCurrentDate] = useState<Date | null>(null);

  const { projects } = useSelector(selectUserProjects);
  const { list } = useSelector(selectTimesheetActivity);

  const { totalColumnHours } = useReporting({
    type: weekView ? "week" : "month",
    currentDate: currentDate as Date,
    userProjects: projects,
    timesheet: list,
  });

  const totalColumnHoursObj = Object.assign({}, ...(totalColumnHours || []));

  useEffect(() => {
    setCurrentDate(
      selectedEvent?.start_date
        ? new Date(selectedEvent?.start_date)
        : selectedDate
    );
  }, [selectedEvent, selectedDate]);

  useEffect(() => {
    if (hasPosted) onDialogClose();
  }, [hasPosted]); //eslint-disable-line

  return (
    <TimesheetAddForm
      weekView={weekView}
      selectedDate={selectedDate}
      selectedEvent={selectedEvent}
      totalColumnHours={totalColumnHoursObj}
    />
  );
}
