import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "CancelTimeOff",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const { cancelTimeOff, cancelTimeOffError, cancelTimeOffSuccess } =
  slice.actions;

reducerRegistry.register("cancelTimeOff", reducer);
