import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "ReferralsVacancy",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getReferralsVacancy,
  getReferralsVacancyError,
  getReferralsVacancySuccess,
  resetReferralsVacancy,
  resetReferralsVacancyError,
  resetReferralsVacancySuccess,
} = slice.actions;

reducerRegistry.register("referralsVacancy", reducer);
