import { Title, Description } from "@/shared/components";

export function TeamsHeader() {
  return (
    <>
      <Title fontSize="50px">MY TEAMS</Title>
      <Description>Here you can find all your bright teams.</Description>
    </>
  );
}
