import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MoreButton } from "@/shared/components";
import { useActions } from "@/utils/redux";
import {
  MarketplaceBrandProductsActions,
  selectMarketplaceBrandProducts,
  IMarketplaceBrandProduct,
  IMarketplaceBrandProductsStatePending,
} from "@/store/redux";
import { ProductItem } from "./MarketPlaceBrandProduct";
import {
  List,
  TitleStyled,
  LoadMoreWrapp,
} from "./MarketPlaceBrandProducts.styled";

export function MarketPlaceBrandProducts() {
  const { id } = useParams();
  const { products, poffset, plimit, ptotal, pending } = useSelector(
    selectMarketplaceBrandProducts
  );
  const { getMarketplaceBrandProducts } = useActions<
    typeof MarketplaceBrandProductsActions
  >(MarketplaceBrandProductsActions);

  const getProducts = (params: IMarketplaceBrandProductsStatePending = {}) => {
    getMarketplaceBrandProducts({ id, plimit: 10, poffset: 0, ...params });
  };

  const handleOnMore = () => {
    getProducts({ poffset: poffset + plimit });
  };

  useEffect(() => {
    getProducts({ poffset: 0 });
  }, []); //eslint-disable-line

  if (pending) {
    return <></>;
  }

  return (
    <>
      <TitleStyled fontWeight="500">BRIGHT COLLABORATIONS</TitleStyled>

      <List>
        {(products || []).map((item: IMarketplaceBrandProduct) => (
          <ProductItem key={item.id} {...item} />
        ))}
      </List>

      <LoadMoreWrapp>
        <MoreButton
          limit={plimit}
          offset={poffset}
          total={ptotal}
          loading={pending}
          onClick={handleOnMore}
        >
          Show more
        </MoreButton>
      </LoadMoreWrapp>
    </>
  );
}
