import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "TimeOffAvailableDays",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getTimeOffAvailableDays,
  getTimeOffAvailableDaysError,
  getTimeOffAvailableDaysSuccess,
  resetTimeOffAvailableDays,
  resetTimeOffAvailableDaysError,
  resetTimeOffAvailableDaysSuccess,
} = slice.actions;

reducerRegistry.register("timeOffAvailableDays", reducer);
