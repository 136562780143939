import { styled } from "@mui/material/styles";
import { GradientButton, ButtonBg } from "@/shared/components";

export const ContrastButton = styled(GradientButton)(({ theme }) => ({
  color: theme.modeColors({
    dark: theme.palette.common.white,
    light: theme.palette.common.black,
  }),
  border: theme.modeColors({
    dark: `4px solid ${theme.palette.common.white}`,
    light: `4px solid #e6e6e6`,
  }),
  background: "transparent",

  "&:hover": {
    background: theme.modeColors({
      dark: "rgba(255,255,255,0.15)",
      light: "rgba(0,0,0,0.05)",
    }),
  },

  "&:before, &:after": {
    display: "none",
  },

  [ButtonBg as any]: {
    display: "none",
  },
}));
