import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IPostCartStateSuccess,
  IPostCartStatePending,
  IDeleteCartStateSuccess,
  IGetCartStateSuccess,
} from "./reducers";
import {
  getCart,
  getCartError,
  getCartSuccess,
  postCart,
  postCartError,
  postCartSuccess,
  deleteCart,
  deleteCartError,
  deleteCartSuccess,
  resetPostCart,
  resetPostCartError,
  resetPostCartSuccess,
} from "./slice";
import { getCartRequest, postCartRequest, deleteCartRequest } from "./api";

function* getCartSaga() {
  try {
    const { products, ptotal }: IGetCartStateSuccess = yield call(
      getCartRequest
    );

    yield put(getCartSuccess({ products, ptotal }));
  } catch (error) {
    yield put(getCartError({ error }));
  }
}

function* postCartSaga(action: PayloadAction<IPostCartStatePending>) {
  try {
    const response: IPostCartStateSuccess = yield call(
      postCartRequest,
      action.payload
    );

    yield put(postCartSuccess(response));

    if (response.id) {
      yield put(getCart());
    }
  } catch (error) {
    yield put(postCartError({ error }));
  }
}

function* deleteCartSaga(action: PayloadAction<number>) {
  try {
    const response: IDeleteCartStateSuccess = yield call(
      deleteCartRequest,
      action.payload
    );

    yield put(deleteCartSuccess({ ...response }));
  } catch (error) {
    yield put(deleteCartError({ error }));
  }
}

function* resetPostCartSaga() {
  try {
    yield put(resetPostCartSuccess());
  } catch (error) {
    yield put(resetPostCartError());
  }
}

function* CartWatcher() {
  yield takeLatest(getCart.type, getCartSaga);
  yield takeLatest(postCart.type, postCartSaga);
  yield takeLatest(deleteCart.type, deleteCartSaga);
  yield takeLatest(resetPostCart.type, resetPostCartSaga);
}

export default CartWatcher;
