import { useSelector } from "react-redux";
import { Dialog } from "@/shared/components";
import { ITimeOffListItem, selectPostTimeOff } from "@/store/redux";
import { TimeOffRequestForm, TimeOffRequestSuccess } from "../Request";

interface ITimeOffRequestModalProps {
  open: boolean;
  title: string;
  editRequest?: boolean;
  currentRequest?: ITimeOffListItem;
  onClose: () => void;
}

export function TimeOffRequestModal(props: ITimeOffRequestModalProps) {
  const { editRequest, currentRequest, title, open, onClose } = props;

  const { hasRequested } = useSelector(selectPostTimeOff);

  const dialogContent = hasRequested ? (
    <TimeOffRequestSuccess onClose={onClose} />
  ) : (
    <TimeOffRequestForm
      editRequest={editRequest}
      currentRequest={currentRequest}
    />
  );

  return (
    <Dialog
      top
      fullWidth
      maxWidth="xsm"
      open={open}
      onClose={onClose}
      title={title}
      dialogContent={dialogContent}
    />
  );
}
