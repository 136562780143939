import { useState, forwardRef } from "react";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { TextField, InputAdornment } from "@mui/material";
import Event from "@mui/icons-material/Event";

interface IDatePickerProps {
  value: Date | null;
  defaultCalendarMonth?: null | Date;
  helperText?: string;
  disabled?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  label?: string;
  minDate?: null | Date;
  maxDate?: null | Date;
  showToolbar?: boolean;
  shouldDisableDate?: ((date: Date | null) => boolean) | undefined;
  shouldDisableMonth?: ((month: Date | null) => boolean) | undefined;
  shouldDisableYear?: ((year: Date | null) => boolean) | undefined;
  onMonthChange?: (month: Date | null) => void | Promise<any>;
  onChange: (k: any) => void;
}

export const DatePicker = forwardRef((props: IDatePickerProps, ref: any) => {
  const {
    value,
    helperText,
    minDate,
    maxDate,
    defaultCalendarMonth,
    onChange,
    ...restProps
  } = props;

  const [open, setOpen] = useState(false);

  const handleOnOpen = () => {
    setOpen(true);
  };
  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <MuiDatePicker
      {...restProps}
      disableMaskedInput
      defaultCalendarMonth={defaultCalendarMonth}
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      onChange={onChange}
      inputFormat="dd MMM yyyy"
      views={["year", "month", "day"]}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Event />
          </InputAdornment>
        ),
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="filled"
          helperText={helperText}
          onClick={handleOnOpen}
          inputProps={{
            ...params.inputProps,
            disabled: true,
            placeholder: "",
          }}
          sx={{
            "& input:disabled": {
              WebkitTextFillcolor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.common.white
                  : theme.palette.common.black,
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.common.white
                  : theme.palette.common.black,
              opacity: "1",
            },
          }}
        />
      )}
      open={open}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
    />
  );
});
