import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Feed, MoreButton } from "@/shared/components";
import {
  selectNews,
  NewsActions,
  selectProfileNewsSubs,
  selectProfileLocation,
  selectProfileLang,
  NewsArticleLikeActions,
} from "@/store/redux";
import { BreadcrumbsContext } from "@/context/Breadcrumbs";
import { useActions } from "@/utils/redux";
import { usePrevious } from "@/utils/hooks";
import { NewsFilters } from "../Filters";
import { LoadMoreWrapp, UpToDate, UpToDateTitle } from "./NewsFeed.styled";

export interface INewsFilters {
  psubs?: number[] | null;
  plang?: string | null;
  plocation?: string | number | null; // TODO: news locations
  psearch?: string | null;
}

export function NewsFeed() {
  const navigate = useNavigate();
  const { from } = useContext(BreadcrumbsContext);
  const {
    news,
    subs,
    unread,
    plang,
    plocation,
    psubs,
    poffset,
    plimit,
    psearch,
    ptotal,
    pending,
  } = useSelector(selectNews);

  const profileLang = useSelector(selectProfileLang);
  const profileLocation = useSelector(selectProfileLocation);
  const profileNewsSubs = useSelector(selectProfileNewsSubs);

  const { getNews } = useActions<typeof NewsActions>(NewsActions);
  const { postNewsArticleLike } = useActions<typeof NewsArticleLikeActions>(
    NewsArticleLikeActions
  );

  const prevProfileLang = usePrevious(profileLang);
  const prevProfileLocation = usePrevious(profileLocation);

  const [filters, setFilters] = useState<INewsFilters>({
    psearch: psearch || "",
    plang: plang || profileLang || "",
    psubs: psubs || profileNewsSubs || [],
    plocation: plocation || profileLocation || -1,
  });

  const hasUnreadNews = unread?.length > 0;
  const hasUnreadSubsNews = subs?.length > 0;

  const hasFilters = profileLang && profileLocation;
  const hasEqualProfile =
    profileLang === prevProfileLang || profileLocation === prevProfileLocation;
  const hasBackFromArticle = (from || "").includes("/news/") && !!news?.length;
  const shouldNotGetNews =
    !profileLang || !profileLocation || hasEqualProfile || hasBackFromArticle;

  const getNewsList = (params = {}) => {
    const newsParams = {
      plimit,
      poffset: 0,
      ...filters,
      ...params,
    };

    getNews(newsParams);
  };

  const handleOnChange = (e: any) => {
    const name = e.target.name;
    const value: string = e.target.value;
    const updatedFilters = {
      ...filters,
      [name]: value,
    };

    setFilters(updatedFilters);

    getNewsList({ ...updatedFilters, poffset: 0 });
  };

  const handleOnComment = (href: string) => {
    navigate(href, { state: { toComments: true } });
  };

  const handleOnLike = (id: number) => {
    postNewsArticleLike(id);
  };

  const handleOnMore = () => {
    getNewsList({ poffset: poffset + plimit });
  };

  useEffect(() => {
    if (shouldNotGetNews) return;

    const updatedFilters = {
      ...filters,
      plang: plang || profileLang,
      psubs: psubs || profileNewsSubs || [],
      plocation: plocation || profileLocation || -1,
    };

    setFilters(updatedFilters);
    getNewsList(updatedFilters);
  }, [profileLang, profileLocation, profileNewsSubs]); //eslint-disable-line

  return (
    <>
      {hasFilters ? (
        <NewsFilters
          loading={pending}
          filters={filters}
          onChange={handleOnChange}
        />
      ) : null}

      {hasUnreadSubsNews ? (
        <Feed
          isNews
          data={subs}
          onComment={handleOnComment}
          onLike={handleOnLike}
          onTagSelect={handleOnChange}
        />
      ) : null}

      {hasUnreadNews ? (
        <Feed
          isNews
          data={unread}
          onComment={handleOnComment}
          onLike={handleOnLike}
          onTagSelect={handleOnChange}
        />
      ) : null}

      {hasUnreadNews || hasUnreadSubsNews ? (
        <UpToDate>
          <UpToDateTitle>You are up to date</UpToDateTitle>
        </UpToDate>
      ) : null}

      <Feed
        isNews
        data={news}
        onComment={handleOnComment}
        onLike={handleOnLike}
        onTagSelect={handleOnChange}
      />

      <LoadMoreWrapp>
        <MoreButton
          sx={{ minWidth: "265px" }}
          limit={plimit}
          offset={poffset}
          total={ptotal}
          loading={pending}
          onClick={handleOnMore}
        >
          Show more
        </MoreButton>
      </LoadMoreWrapp>
    </>
  );
}
