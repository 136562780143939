import { useSelector } from "react-redux";
import { FormGroup } from "@mui/material";
import { selectPostTaskReporting } from "@/store/redux";
import { TaskItem } from "./TaskItem";
import { AddTask } from "./AddTask";
import { TasksWrapper } from "./Tasks.styled";

interface ITasksListProps {
  tasks: Array<any>;
  projectId: number;
  onAdd: (
    projectId: number,
    value: { checked: boolean; value: string }
  ) => void;
  onSave: (projectId: number, taskIdx: number, value: string) => void;
  onSelect: (projectId: number, taskIdx: number, value: boolean) => void;
}

export function TasksList(props: ITasksListProps) {
  const { tasks, projectId, onAdd, onSave, onSelect } = props;

  const { pending } = useSelector(selectPostTaskReporting);

  const handleOnSave = (idx: number, value: string) => {
    onSave(projectId, idx, value);
  };

  const handleOnSelect = (idx: number, value: boolean) => {
    onSelect(projectId, idx, value);
  };

  const handleOnAddTask = (value: string) => {
    onAdd(projectId, { checked: true, value });
  };

  return (
    <TasksWrapper>
      <FormGroup>
        {tasks.map((item, idx) => (
          <TaskItem
            key={idx}
            task={item}
            taskIndx={idx}
            disabled={pending}
            onSave={handleOnSave}
            onSelect={handleOnSelect}
          />
        ))}
      </FormGroup>

      <AddTask disabled={pending} onAdd={handleOnAddTask} />
    </TasksWrapper>
  );
}
