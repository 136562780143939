import { useState } from "react";
import { SlotInfo } from "react-big-calendar";
import { useSelector } from "react-redux";
import { useTheme, useMediaQuery, Skeleton } from "@mui/material";
import {
  selectUserProjects,
  selectTimesheetActivity,
  ITimesheetActivityItem,
} from "@/store/redux";
import { useDialog } from "@/utils/hooks";
import { getDisabledDate } from "../helpers";
import { useReporting } from "../hooks";
import { DayOffTypes } from "../DayOffTypes";
import { TimesheetCalendar } from "./TimesheetCalendar";
import { SelectedDayView } from "./SelectedDayView";
import { HoursButton } from "../Timesheet.styled";

interface ITimeButtonProps {
  day: Date;
  reporting: ITimesheetActivityItem;
  hasTimeOff: boolean;
}

export function TimesheetMonthView() {
  const { projects } = useSelector(selectUserProjects);
  const { pending, list } = useSelector(selectTimesheetActivity);

  const theme = useTheme();
  const hasMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [openDialog, onDialogOpen, onDialogClose] = useDialog();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] =
    useState<ITimesheetActivityItem | null>(null);

  const { outageHours, projectsHours, totalColumnHours } = useReporting({
    type: "month",
    currentDate,
    userProjects: projects,
    timesheet: list,
  });

  const handleOnSelectSlot = (info: SlotInfo) => {
    if (!info) return;

    const { start } = info;
    const hasDisabelDate = getDisabledDate(start);

    setCurrentDate(start);

    if (hasDisabelDate || hasMobile) return;

    onDialogOpen();
  };

  const TimeButton = (props: ITimeButtonProps) => {
    const { day, reporting, hasTimeOff } = props;
    const hasDisabled = getDisabledDate(day);

    const handleOnClick = () => {
      setCurrentDate(day);
      onDialogOpen();

      setSelectedEvent(reporting);
    };

    return (
      <HoursButton
        disabled={hasDisabled}
        $hasTimeOff={hasTimeOff}
        $hasEmpty={!reporting?.hours}
        onClick={handleOnClick}
      >
        {pending ? (
          <Skeleton height={12} width={24} />
        ) : (
          reporting?.hours || (hasDisabled ? null : "+")
        )}
      </HoursButton>
    );
  };

  return (
    <>
      <DayOffTypes />

      <TimesheetCalendar
        selectedEvent={selectedEvent}
        selectedDate={currentDate}
        openDialog={openDialog}
        onDialogOpen={onDialogOpen}
        onDialogClose={onDialogClose}
        onSelectSlot={handleOnSelectSlot}
        onSetSelectedEvent={setSelectedEvent}
      />

      {hasMobile ? (
        <SelectedDayView
          outageHours={outageHours}
          projectsHours={projectsHours}
          totalColumnHours={totalColumnHours}
          currentDate={currentDate}
          timeButton={TimeButton}
        />
      ) : null}
    </>
  );
}
