import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { INewsCommentState } from "./reducers";

export const addNewsCommentSelector = (state: RootState): INewsCommentState =>
  state.newsPostComment;

export const selectAddNewsComment = createSelector(
  addNewsCommentSelector,
  (addNewsCommentState: INewsCommentState) => ({ ...addNewsCommentState })
);
