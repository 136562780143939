import { useState } from "react";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { TaskField } from "./TaskField";
import { AddTaskWrapper } from "./Tasks.styled";

interface IAddTaskProps {
  disabled: boolean;
  onAdd: (value: string) => void;
}

export function AddTask(props: IAddTaskProps) {
  const { disabled, onAdd } = props;

  const [hasShow, setHasShow] = useState(false);

  const handleOnShow = () => {
    setHasShow(true);
  };

  const handleOnSave = (value: string) => {
    onAdd(value);
    setHasShow(false);
  };

  const handleOnCancel = () => {
    setHasShow(false);
  };

  return hasShow ? (
    <TaskField
      disabled={disabled}
      onSave={handleOnSave}
      onCancel={handleOnCancel}
    />
  ) : (
    <AddTaskWrapper>
      <Button
        color="primary"
        variant="text"
        disabled={disabled}
        onClick={handleOnShow}
        startIcon={<AddCircleIcon />}
      >
        Add additional tasks
      </Button>
    </AddTaskWrapper>
  );
}
