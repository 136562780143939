import {
  Container,
  Page,
  TimesheetHead,
  TimesheetActivities,
  TimesheetHistory,
} from "@/components";

export function Timesheet() {
  return (
    <Page title="Timesheet">
      <Container>
        <TimesheetHead />

        <TimesheetActivities />

        <TimesheetHistory />
      </Container>
    </Page>
  );
}
