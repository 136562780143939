import { AxiosError } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ITaskReportingStateSuccess } from "./reducers";
import {
  getTaskReporting,
  getTaskReportingError,
  getTaskReportingSuccess,
} from "./slice";
import { getTaskReportingRequest } from "./api";

function* getTaskReportingSaga(action: PayloadAction<{ id: string }>) {
  try {
    const reporting: ITaskReportingStateSuccess = yield call(
      getTaskReportingRequest,
      action.payload
    );

    yield put(getTaskReportingSuccess(reporting));
  } catch (e) {
    const error = e as AxiosError<{ message: string; statusCode: number }>;

    yield put(getTaskReportingError({ error: error.response?.data }));
  }
}

function* TaskReportingWatcher() {
  yield takeLatest(getTaskReporting.type, getTaskReportingSaga);
}

export default TaskReportingWatcher;
