import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { IPostCartStatePending } from "./reducers";

export const getCartRequest = async () => {
  const response: AxiosResponse = await apiInstance.get("/marketplace/cart");

  return response.data;
};

export const postCartRequest = async (params: IPostCartStatePending) => {
  const response: AxiosResponse = await apiInstance.post("/marketplace/cart", {
    ...params,
  });

  return response.data;
};

export const deleteCartRequest = async (id: number) => {
  const response: AxiosResponse = await apiInstance.delete(
    `/marketplace/cart/${id}`
  );

  return response.data;
};
