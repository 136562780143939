import { useNavigate } from "react-router-dom";
import { IReferralsVacancy } from "@/store/redux";
import { REFERRALS } from "@/routes/consts";
import {
  VacancyItem,
  VacancyDesc,
  VacancyLocation,
  VacancySubtitle,
  VacancyTitle,
  Arrow,
} from "./Vacancies.styled";

interface IVacancyProps {
  vacancy: IReferralsVacancy;
}

export function Vacancy(props: IVacancyProps) {
  const { vacancy } = props;
  const navigate = useNavigate();

  const { id, location, title, company, about_client } = vacancy || {};

  const handleOnClick = () => {
    navigate(`${REFERRALS}/${id}`, {
      state: { scrollPosition: window.scrollY },
    });
  };

  return (
    <VacancyItem onClick={handleOnClick}>
      <VacancyLocation>{location}</VacancyLocation>
      <VacancyTitle>
        {title}

        <Arrow />
      </VacancyTitle>
      <VacancySubtitle>for {company}</VacancySubtitle>
      <VacancyDesc dangerouslySetInnerHTML={{ __html: about_client }} />
    </VacancyItem>
  );
}
