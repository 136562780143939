import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@mui/material";
import { useToggle } from "@/utils/hooks";
import { ContrastButton } from "@/shared/components";
import { ShareMessageWrapper } from "./ShareButton.styled";

interface IShareProps {
  link: string | undefined;
}

export function ShareButton(props: IShareProps) {
  const { link } = props;
  const [open, handleOnToggle] = useToggle();

  return (
    <>
      <CopyToClipboard text={link || ""}>
        <ContrastButton sx={{ width: 270 }} onClick={handleOnToggle}>
          COPY JOB'S LINK
        </ContrastButton>
      </CopyToClipboard>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleOnToggle}
      >
        <ShareMessageWrapper>Link Copied</ShareMessageWrapper>
      </Snackbar>
    </>
  );
}
