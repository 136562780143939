import { PayloadAction } from "@reduxjs/toolkit";

interface ICountry {
  code: string;
  name: string;
  value: number;
  region: string[];
}

export interface ICountriesState {
  pending: boolean;
  error: any;
  countries: ICountry[];
}

export interface ICountriesStateSuccess {
  countries: ICountry[];
}

export interface ICountriesStateError {
  error: any;
}

export const initialState: ICountriesState = {
  pending: false,
  error: "",
  countries: [] as ICountry[],
};

export const reducers = {
  getCountries: (state: ICountriesState) => {
    return {
      ...state,
      countries: [],
      pending: true,
      error: "",
    };
  },
  getCountriesSuccess: (
    state: ICountriesState,
    action: PayloadAction<ICountriesStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      countries: (action.payload.countries || []).map((country: any) => ({
        code: country.code,
        value: country.id,
        name: country.title,
        region: country.region,
      })),
    };
  },
  getCountriesError: (
    state: ICountriesState,
    action: PayloadAction<ICountriesStateError>
  ) => ({
    ...state,
    pending: false,
    countries: [],
    error: action.payload.error,
  }),
};
