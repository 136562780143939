import { call, put, takeLatest } from "redux-saga/effects";
import { IMarketplaceDonate } from "./reducers";
import {
  getMarketplaceDonate,
  getMarketplaceDonateError,
  getMarketplaceDonateSuccess,
} from "./slice";
import { getMarketplaceDonateRequest } from "./api";

function* getMarketplaceDonateSaga() {
  try {
    const donate: IMarketplaceDonate = yield call(getMarketplaceDonateRequest);

    yield put(getMarketplaceDonateSuccess(donate));
  } catch (error) {
    yield put(getMarketplaceDonateError({ error }));
  }
}

function* MarketplaceDonateWatcher() {
  yield takeLatest(getMarketplaceDonate.type, getMarketplaceDonateSaga);
}

export default MarketplaceDonateWatcher;
