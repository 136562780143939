import { Avatar } from "@mui/material";
import { Bubble, Title } from "@/shared/components";

export function TeamLogo(props: {
  logo: string;
  title: string;
  bubleParams: string;
}) {
  const { logo, title = "", bubleParams } = props;

  if (logo)
    return (
      <Avatar src={logo} sx={{ width: bubleParams, height: bubleParams }} />
    );

  const splittedTitle = title
    .split(" ")
    .filter(Boolean)
    .map((word) => word[0]);

  const titleLogo =
    splittedTitle.length > 1
      ? `${splittedTitle[0]}${splittedTitle[splittedTitle.length - 1]}`
      : splittedTitle[0];

  return (
    <Bubble color="bluePink">
      <Title fontSize="56px" fontWeight="900">
        {titleLogo}
      </Title>
    </Bubble>
  );
}
