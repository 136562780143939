import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function ShareIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.5778 15.861C9.21358 15.861 8.90763 15.5551 8.90763 15.1981V7.86276L8.96591 6.77739L8.50699 7.30915L7.48718 8.40181C7.37063 8.53293 7.1958 8.59848 7.02826 8.59848C6.70047 8.59848 6.43094 8.3581 6.43094 8.01573C6.43094 7.84091 6.50379 7.70979 6.62762 7.58596L9.07518 5.2331C9.24272 5.05828 9.40297 5 9.5778 5C9.75991 5 9.92016 5.05828 10.0877 5.2331L12.5353 7.58596C12.6591 7.70979 12.7247 7.84091 12.7247 8.01573C12.7247 8.3581 12.4551 8.59848 12.1273 8.59848C11.9598 8.59848 11.7922 8.53293 11.6757 8.40181L10.6486 7.30915L10.197 6.77739L10.2552 7.86276V15.1981C10.2552 15.5551 9.9493 15.861 9.5778 15.861Z"
        fill="#8E949A"
      />
      <path
        d="M5.38928 18.6667C3.81585 18.6667 3 17.8581 3 16.3065V13.7139C3 13.3196 3.31961 13 3.71387 13C4.10813 13 4.42774 13.3196 4.42774 13.7139V16.1827C4.42774 16.8893 4.79924 17.2389 5.47669 17.2389H13.6862C14.3564 17.2389 14.7351 16.8893 14.7351 16.1827V13.7139C14.7351 13.3196 15.0548 13 15.449 13C15.8433 13 16.1629 13.3196 16.1629 13.7139V16.3065C16.1629 17.8581 15.347 18.6667 13.7736 18.6667H5.38928Z"
        fill="#8E949A"
      />
    </SvgIcon>
  );
}
