import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { ITimeOffCalendarStatePending } from "./reducers";

export const getTimeOffCalendarRequest = async (
  params: ITimeOffCalendarStatePending
) => {
  const response: AxiosResponse = await apiInstance.get("/time-off/calendar", {
    params: {
      ...params,
    },
  });

  return response.data;
};

export const getHolidaysRequest = async (params: {
  start_date: string;
  end_date: string;
}) => {
  const response: AxiosResponse = await apiInstance.get("/holidays", {
    params: {
      ...params,
    },
  });

  return response.data;
};
