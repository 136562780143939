import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IMoodLiteratureState } from "./reducers";

export const moodLiteratureSelector = (
  state: RootState
): IMoodLiteratureState => state.moodMonitoringLiterature;

export const selectMoodLiterature = createSelector(
  moodLiteratureSelector,
  (moodLiteratureState: IMoodLiteratureState) => ({ ...moodLiteratureState })
);
