import { useEffect } from "react";
import { useSelector } from "react-redux";
import { UserCard } from "@/shared/components";
import { selectProfile, ProfileActions } from "@/store/redux";
import { useActions } from "@/utils/redux";

export function UserData() {
  const { profile, pending: profilePending } = useSelector(selectProfile);
  const { getProfile } = useActions<typeof ProfileActions>(ProfileActions);

  useEffect(() => {
    getProfile();
  }, []); //eslint-disable-line

  return (
    <UserCard
      pending={profilePending}
      avatar={profile?.avatar}
      title={profile?.user_name}
      project={profile?.project?.name}
      position={profile?.position}
      date={new Date(profile?.since || 0)}
    />
  );
}
