import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Box, Skeleton } from "@mui/material";
import { useActions } from "@/utils/redux";
import { selectTaskReporting, TaskReportingActions } from "@/store/redux";
import { Title } from "@/shared/components";
import { Messages, Content } from "./components";

export function TaskReportinfWrapper() {
  const { id } = useParams();

  const { getTaskReporting } =
    useActions<typeof TaskReportingActions>(TaskReportingActions);
  const { date, pending } = useSelector(selectTaskReporting);

  const formattedDate = date ? format(new Date(date), "MMMM yyyy") : "";

  useEffect(() => {
    if (id) getTaskReporting({ id });
  }, [id]); //eslint-disable-line

  if (pending) {
    return (
      <>
        <Skeleton width="100%" height="24px" />
        <Skeleton width="100%" height="24px" />
        <Skeleton width="100%" height="24px" />
        <Skeleton width="100%" height="24px" />
      </>
    );
  }

  return (
    <Box sx={{ mb: 8 }}>
      <Title fontSize="54px" sx={{ mb: 2 }}>
        Task report. {formattedDate}
      </Title>

      <Messages date={formattedDate} />

      <Content date={formattedDate} />
    </Box>
  );
}
