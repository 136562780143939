import { PayloadAction } from "@reduxjs/toolkit";

export interface INewsSubscriptions {
  categories: {
    id: number;
    value: number;
    title: string;
  }[];
}

export interface INewsSubscriptionsState extends INewsSubscriptions {
  pending: boolean;
  error: any;
}

export interface INewsSubscriptionsStateSuccess {
  categories: {
    id: number;
    value: number;
    title: string;
  }[];
}

export interface INewsSubscriptionsStateError {
  error: any;
}

export const initialState: INewsSubscriptionsState = {
  pending: false,
  error: null,
  categories: [],
};

function transfromCategories(categories: Array<any>) {
  return (categories || []).map((item) => ({ ...item, value: item.id }));
}

export const reducers = {
  getNewsSubscriptions: (state: INewsSubscriptionsState) => {
    return {
      ...state,
      pending: true,
      error: "",
    };
  },
  getNewsSubscriptionsSuccess: (
    state: INewsSubscriptionsState,
    action: PayloadAction<INewsSubscriptionsStateSuccess>
  ) => ({
    ...state,
    pending: false,
    categories: transfromCategories(action?.payload?.categories || []),
  }),
  getNewsSubscriptionsError: (
    state: INewsSubscriptionsState,
    action: PayloadAction<INewsSubscriptionsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
