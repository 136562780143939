import { Tooltip, Link } from "@mui/material";
import { IMyDocumentsItem } from "@/store/redux";
import { FolderIcon } from "@/shared/icons";
import { FileIcon } from "./FileIcon";
import { Name, ExternalOpenIcon } from "../MyDocuments.styled";

interface IFileTitleProps {
  file: IMyDocumentsItem;
}

export function FileTitle(props: IFileTitleProps) {
  const { file } = props;

  const { type, name, sharepoint_url, properties } = file;

  const hasFolder = type === "folder";

  const getFileIcon = () => {
    return hasFolder ? <FolderIcon /> : <FileIcon file={file} />;
  };

  return (
    <>
      <Name>
        {getFileIcon()}

        <div>{name}</div>
      </Name>

      {hasFolder ? (
        <Tooltip title="Open in SharePoint">
          <Link
            href={hasFolder ? sharepoint_url : properties?.LinkingUri}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ display: "flex" }}
            onClick={(e) => e.stopPropagation()}
          >
            <ExternalOpenIcon />
          </Link>
        </Tooltip>
      ) : null}
    </>
  );
}
