import { PayloadAction } from "@reduxjs/toolkit";
import { orderBy } from "lodash";

export interface IHoliday {
  start_date: string;
  end_date: string;
  title: string;
}
export interface ITimesheetActivityItem {
  id: number;
  type:
    | 1
    | 2
    | 3
    | 4
    | "project"
    | "outage"
    | "health_time_off"
    | "paid_time_off";
  start_date: string | number | Date;
  end_date: string | number | Date;
  project: {
    id: number;
    name: string;
  };
  hours: number;
  task: string;
}

export interface ITimesheetActivityState {
  pending: boolean;
  error: any;
  outage_time: number;
  working_hours: number;
  list: ITimesheetActivityItem[];
  holidays: IHoliday[];
}

export interface ITimesheetActivityStatePending {
  start_date: string;
  end_date: string;
}

export interface ITimesheetActivityStateSuccess {
  outage_time: number;
  working_hours: number;
  list: ITimesheetActivityItem[];
  holidays: IHoliday[];
}

export interface ITimesheetActivityStateError {
  error: any;
}

export const initialState: ITimesheetActivityState = {
  pending: false,
  error: "",
  outage_time: 0,
  working_hours: 0,
  list: [],
  holidays: [],
};

export const reducers = {
  getTimesheetActivity: (
    state: ITimesheetActivityState,
    action: PayloadAction<ITimesheetActivityStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: "",
      ...(action.payload || {}),
    };
  },
  getTimesheetActivitySuccess: (
    state: ITimesheetActivityState,
    action: PayloadAction<ITimesheetActivityStateSuccess>
  ) => {
    const { list, ...restOptions } = action?.payload;
    const transformedList = (list || []).map((item) => ({
      ...item,
      end_date: item.end_date || item.start_date,
    }));
    const orderedList = orderBy(
      transformedList,
      [(obj) => new Date(obj.start_date)],
      ["asc"]
    );

    return {
      ...state,
      ...restOptions,
      pending: false,
      list: orderedList,
    };
  },
  getTimesheetActivityError: (
    state: ITimesheetActivityState,
    action: PayloadAction<ITimesheetActivityStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
