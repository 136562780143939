import { Table, TableBody, TableHead, TableFooter } from "@mui/material";
import { Total } from "./Total";
import { Header } from "./Header";
import { Outage } from "./Outage";
import { Projects } from "./Projects";
import { IProjectsHoursState, IOutageHoursState } from "../interfaces";
import { TableContainer } from "../Timesheet.styled";

interface IDesktopWeekViewProps {
  currentDate: Date;
  totalColumnHours: { [k: string | number]: number }[];
  projectsHours: IProjectsHoursState[];
  outageHours: IOutageHoursState;
  timeButton: (day: any, reporting: any) => JSX.Element;
}

export function Desktop(props: IDesktopWeekViewProps) {
  const {
    currentDate,
    outageHours,
    projectsHours,
    timeButton,
    totalColumnHours,
  } = props;

  return (
    <TableContainer>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <Header currentDate={currentDate} />
        </TableHead>

        <TableBody>
          <Projects hours={projectsHours} timeButton={timeButton} />

          <Outage reporting={outageHours} timeButton={timeButton} />
        </TableBody>
        <TableFooter>
          <Total total={totalColumnHours} />
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
