import { Box, Skeleton, Grid } from "@mui/material";

export function Loader() {
  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Skeleton height={55} width="30%" />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={6} sm={6} md={3}>
          <Skeleton height={35} width="35%" />

          <Skeleton
            sx={{ mt: 2 }}
            animation="wave"
            variant="circular"
            width={175}
            height={175}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Skeleton height={35} width="35%" />

          <Skeleton
            sx={{ mt: 2 }}
            animation="wave"
            variant="circular"
            width={175}
            height={175}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Skeleton height={35} width="50%" sx={{ mb: 2 }} />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
            <Skeleton height={15} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
