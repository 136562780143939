import {
  Container,
  Page,
  MyDocuments as MyDocumentsWrapper,
} from "@/components";
import { Title } from "@/shared/components";

export function MyDocuments() {
  return (
    <Page title="My Documents">
      <Container>
        <Title sx={{ fontSize: "60px" }}>MY DOCUMENTS</Title>

        <MyDocumentsWrapper />
      </Container>
    </Page>
  );
}
