import { useMediaQuery, useTheme } from "@mui/material";
import { Carousel, MarketplaceLabel } from "@/shared/components";
import { IMarketplaceItem } from "@/store/redux";
import { MARKETPLACE } from "@/routes/consts";
import {
  ProductItem,
  ProductItemPrice,
  ProductItemTitle,
  ProductItemLink,
} from "./ProductsList.styled";

export function ProductListItem(props: IMarketplaceItem) {
  const { brand_id, images, title, price, quantity } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const hasInfinity = images?.length > 1;
  const hasDots = !isMobile && hasInfinity;

  const slidesImages = (images || []).map((image: string) => (
    <img key={image} src={image} alt="" />
  ));

  return (
    <ProductItem>
      <ProductItemLink to={`${MARKETPLACE}/${brand_id}`}>
        <MarketplaceLabel quantity={quantity} />

        <Carousel infinite={hasInfinity} dots={hasDots} slides={slidesImages} />

        <ProductItemTitle>{title}</ProductItemTitle>
        <ProductItemPrice>{price}</ProductItemPrice>
      </ProductItemLink>
    </ProductItem>
  );
}
