import { format } from "date-fns";
import { IFieldProps } from "../interfaces";
import { FakeInput, FakeInputLabel } from "../Timesheet.styled";
import { GenerateField } from "./GenerateField";

export function DateField(props: IFieldProps) {
  return (
    <GenerateField
      name="start_date"
      render={({ field }) => (
        <>
          <FakeInput>
            <FakeInputLabel>Date</FakeInputLabel>
            <span>{format(new Date(field.value), "dd MMM yyyy")}</span>
          </FakeInput>
        </>
      )}
    />
  );
}
