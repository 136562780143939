import { useState } from "react";
import { GradientButton } from "@/shared/components";
import { useActions } from "@/utils/redux";
import { PostTimeOffActions } from "@/store/redux";
import { TimeOffRequestModal } from "./TimeOffRequestModal";
import { TimeOffRequestMessage } from "./TimeOffRequestMessage";

export function TimeOffRequest() {
  const [open, setOpen] = useState(false);
  const { resetPostTimeOff } =
    useActions<typeof PostTimeOffActions>(PostTimeOffActions);

  const handleOnOpen = () => {
    setOpen(true);
  };
  const handleOnClose = () => {
    setOpen(false);

    resetPostTimeOff();
  };

  return (
    <>
      <GradientButton
        sx={{ minWidth: 270, mt: 0.5, width: { xs: "100%", sm: "auto" } }}
        onClick={handleOnOpen}
      >
        Book now
      </GradientButton>

      <TimeOffRequestModal
        title="TIME OFF REQUEST"
        open={open}
        onClose={handleOnClose}
      />

      <TimeOffRequestMessage />
    </>
  );
}
