import { styled } from "@mui/material/styles";
import { Badge } from "@/shared/components";

export const Label = styled(Badge)(({ theme }) => ({
  position: "absolute",
  top: -4,
  right: -4,
  zIndex: 1,
  borderRadius: 0,
  border: `4px solid ${theme.palette.common.white}`,
  fontSize: "1.125rem",
  fontWeight: 700,
  padding: theme.spacing(1, 2.5),

  [theme.breakpoints.down("md")]: {
    border: `2px solid ${theme.palette.common.white}`,
    padding: theme.spacing(0.5, 1.5),
    fontSize: "0.875rem",
    fontWeight: 900,
    top: -2,
    right: -2,
  },
}));
