import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { pick } from "lodash";
import { Box } from "@mui/material";
import {
  CheckoutActions,
  selectCart,
  selectCheckout,
  selectProfile,
} from "@/store/redux";
import { useFormPersist } from "@/utils/hooks";
import { useActions } from "@/utils/redux";
import { MARKETPLACE } from "@/routes/consts";
import { CheckoutHead } from "./Head";
import { CheckoutList } from "./List";
import { Total } from "./Total";
import { DeliveryForm } from "./DeliveryForm";

const fieldsMap: any = {
  1: ["postal_service", "city", "postal_code", "street", "house", "apartment"],
  2: ["postal_service", "city", "postal_point"],
  3: ["postal_service", "city", "postal_point"],
  4: [],
};

function getFieldsByType(type: number) {
  return fieldsMap[type];
}

function getSubmitFields(state: any, fields: string[]) {
  return pick(state, fields);
}

export function CheckoutForm() {
  const navigate = useNavigate();
  const { products } = useSelector(selectCart);
  const { profile } = useSelector(selectProfile);
  const { hasSubmitted } = useSelector(selectCheckout);
  const { postCheckout } = useActions<typeof CheckoutActions>(CheckoutActions);

  const methods = useForm({
    defaultValues: {
      donate: 0,
      agree: false,
      recipient: profile?.user_name || "",
      phone_number: profile?.phone_mobile || "",
      postal_service: "",
      delivery_type: "",
      postal_point: "",
      postal_code: "",
      apartment: "",
      country: "",
      street: "",
      house: "",
      note: "",
      city: "",
    },
  });

  const { handleSubmit, watch, setValue } = methods;

  const { clear } = useFormPersist("checkoutDeliveryForm", {
    watch,
    setValue,
    validate: true,
  });

  const onSubmit = (data: any) => {
    const { delivery_type, donate, country, recipient, phone_number, note } =
      data;
    const pickedFields = getSubmitFields(data, getFieldsByType(delivery_type));
    const transformedData = {
      ...pickedFields,
      note: sanitizeHtml(note),
      country: country.value,
      delivery_type,
      phone_number,
      recipient,
      donate,
    };

    postCheckout(transformedData);
  };

  useEffect(() => {
    if (!hasSubmitted) return;

    navigate(MARKETPLACE);
  }, [hasSubmitted, navigate]);

  useEffect(() => {
    if (!products?.length) {
      setTimeout(clear, 1000);
    }
  }, [products]); //eslint-disable-line

  return (
    <Box sx={{ mb: 12 }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CheckoutHead />

          {products?.length ? (
            <>
              <CheckoutList />

              <Total />

              <DeliveryForm />
            </>
          ) : null}
        </form>
      </FormProvider>
    </Box>
  );
}
