import { ReactNode } from "react";
import { TypographyProps } from "@mui/material";
import { DescriptionStyled } from "./Description.styled";

interface IDescriptionProps extends TypographyProps {
  children: ReactNode | string | null | undefined;
  fontWeight?: string | number;
}

export function Description(props: IDescriptionProps) {
  const { children, fontWeight, className } = props;

  return (
    <DescriptionStyled className={className} $fontWeight={fontWeight}>
      {children}
    </DescriptionStyled>
  );
}
