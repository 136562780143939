import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import {
  ReferralsCandidatesActions,
  selectReferralsCandidates,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { InProgress } from "./InProgress";
import { History } from "./History";
import { NoCandidates } from "./NoCandidates";

interface ICandidatesProps {
  onDialogOpen: () => void;
}

export function Candidates(props: ICandidatesProps) {
  const { onDialogOpen } = props;

  const { candidates, pending } = useSelector(selectReferralsCandidates);
  const { getReferralsCandidates } = useActions<
    typeof ReferralsCandidatesActions
  >(ReferralsCandidatesActions);

  useEffect(() => {
    getReferralsCandidates();
  }, []); //eslint-disable-line

  return (
    <>
      <div>
        {pending ? <Skeleton width="100%" height="24px" /> : null}

        {!pending && !candidates?.length ? (
          <NoCandidates onDialogOpen={onDialogOpen} />
        ) : null}
      </div>

      <InProgress />
      <History />
    </>
  );
}
