import { useState } from "react";
import { format, parseISO, differenceInCalendarYears } from "date-fns";
import sanitizeHtml from "sanitize-html";
import { INewsCommentsItem, NewsCommentLikeActions } from "@/store/redux";
import { useActions } from "@/utils/redux";
import { LikeButton } from "@/shared/components";
import { AuthorComment } from "../AuthorComment";
import { ReplyCommentDialog } from "../ReplyComment";
import { DeleteComment } from "../DeleteComment";
import {
  CommentItemStyled,
  CommentBody,
  CommentFooter,
  CommentHead,
  CommentReplyButton,
} from "../Comments.styled";

export interface ICommentItemProps {
  comment: INewsCommentsItem;
  selectedReplyId: number | null;
  selectedCommentRef: any;
  loading?: boolean;
  onReply: (k: INewsCommentsItem) => void;
  onDelete: (k: INewsCommentsItem) => void;
  onParentSelect: (k: number | null | undefined) => void;
}

export function CommentItemTemplate(props: ICommentItemProps) {
  const {
    loading,
    comment,
    selectedReplyId,
    selectedCommentRef,
    onReply,
    onDelete,
    onParentSelect,
  } = props;

  const { postNewsCommentLike } = useActions<typeof NewsCommentLikeActions>(
    NewsCommentLikeActions
  );

  const [showReply, setShowReply] = useState(false);

  const {
    id,
    author,
    created_at,
    text,
    likes,
    has_like,
    parent_reply_id,
    parent_reply_name,
  } = comment;
  const cleanText = sanitizeHtml(text).replace(/\n/g, "<br />");
  const hasDifference = differenceInCalendarYears(
    new Date(),
    new Date(created_at)
  );
  const formatTemplate = hasDifference ? "dd MMM yyyy HH:mm" : "dd MMM HH:mm";
  const formatedDate = format(parseISO(created_at), formatTemplate);

  const handleOnOpenReply = () => {
    setShowReply(true);
  };
  const handleOnCloseReply = () => {
    setShowReply(false);
  };

  const handleOnReply = (comment: INewsCommentsItem) => {
    setShowReply(false);

    onReply(comment);
  };

  const handleOnLike = () => {
    postNewsCommentLike(id);
  };

  const handleOnScrollToReply = () => {
    onParentSelect(parent_reply_id);
  };

  return (
    <div
      ref={id === selectedReplyId ? selectedCommentRef : null}
      style={{ scrollMarginTop: 125 }}
    >
      <ReplyCommentDialog
        parentComment={comment}
        open={showReply}
        onClose={handleOnCloseReply}
        onReply={handleOnReply}
      />

      <CommentItemStyled>
        <CommentHead>
          <AuthorComment
            image={author?.avatar || ""}
            name={author?.name}
            date={formatedDate}
            replyParentName={parent_reply_name}
            hasParent={Boolean(parent_reply_id)}
            onScrollToReply={handleOnScrollToReply}
          />

          <LikeButton
            comment
            disabled={loading}
            count={likes || 0}
            hasLike={has_like || false}
            onLike={handleOnLike}
          />
        </CommentHead>
        <CommentBody dangerouslySetInnerHTML={{ __html: cleanText }} />
        <CommentFooter>
          <CommentReplyButton
            size="small"
            disabled={loading}
            onClick={handleOnOpenReply}
          >
            Reply
          </CommentReplyButton>

          <DeleteComment
            comment={comment}
            loading={!!loading}
            onDelete={onDelete}
          />
        </CommentFooter>
      </CommentItemStyled>
    </div>
  );
}
