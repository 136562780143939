import { PayloadAction } from "@reduxjs/toolkit";
import { FAQ } from "@/routes/consts";

export interface IRecentItem {
  id: number;
  href: string;
  title: string;
  likes: number;
  views: number;
}

export interface IFaqRecentState {
  pending: boolean;
  error: any;
  recent: IRecentItem[];
}

export interface IFaqRecentStateSuccess {
  recent: IRecentItem[];
}

export interface IFaqRecentStateError {
  error: any;
}

export const initialState: IFaqRecentState = {
  pending: false,
  error: "",
  recent: [],
};

export const reducers = {
  getFaqRecents: (state: IFaqRecentState) => ({
    ...state,
    pending: true,
    error: "",
  }),
  getFaqRecentsSuccess: (
    state: IFaqRecentState,
    action: PayloadAction<IRecentItem[]>
  ) => {
    const transformedList = (action?.payload || []).map((item) => ({
      ...item,
      href: `${FAQ}/${item.id}`,
    }));

    return {
      ...state,
      pending: false,
      recent: transformedList,
    };
  },
  getFaqRecentsError: (
    state: IFaqRecentState,
    action: PayloadAction<IFaqRecentStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload,
  }),
};
