import { useFormContext, Controller } from "react-hook-form";
import {
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";

export function Anonymous() {
  const { control } = useFormContext();

  return (
    <Controller
      name="anonymous"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl>
          <FormGroup>
            <FormControlLabel
              sx={{
                "& .MuiCheckbox-root": { paddingTop: 0, paddingBottom: 0 },
              }}
              control={<Checkbox checked={value} onChange={onChange} />}
              label={
                <Box component="span" sx={{ fontSize: "0.875rem" }}>
                  Make my message anonymous
                </Box>
              }
            />
          </FormGroup>
        </FormControl>
      )}
    />
  );
}
