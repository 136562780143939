import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IPostMoodState } from "./reducers";

export const postMoodSelector = (state: RootState): IPostMoodState =>
  state.postMood;

export const selectPostMood = createSelector(
  postMoodSelector,
  (postMoodState: IPostMoodState) => ({ ...postMoodState })
);
