import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IDeliveryInfoState } from "./reducers";

export const checkoutDeliveryInfoSelector = (
  state: RootState
): IDeliveryInfoState => state.checkoutDeliveryInfo;

export const selectCheckoutDeliveryInfo = createSelector(
  checkoutDeliveryInfoSelector,
  (checkoutDeliveryInfoState: IDeliveryInfoState) => ({
    ...checkoutDeliveryInfoState,
  })
);
