import { PayloadAction } from "@reduxjs/toolkit";

export interface IDocument {
  name: string;
  type: string | "folder" | "file";
  sharepoint_url: string;
  properties: {
    Exists: boolean;
    ExistsAllowThrowForPolicyFailures: boolean;
    IsWOPIEnabled: boolean;
    ItemCount: number;
    Name: string;
    ProgID: any;
    ServerRelativeUrl: string;
    TimeCreated: string;
    TimeLastModified: string;
    UniqueId: string;
    WelcomePage: string;
    LinkingUri: string;
    LinkingUrl: string;
  };
  go_path: string;
}

export interface IMyDocumentsItem extends IDocument {
  items?: Array<IDocument>;
  [k: string]: any;
}

export interface IMyDocumentsState {
  pending: boolean;
  error: any;
  hasDocs: boolean;
  documents: IMyDocumentsItem[];
}

export interface IMyDocumentStatePending {
  path?: string;
}

export interface IMyDocumentsStateSuccess {
  documents: IMyDocumentsItem[];
}

export interface IMyDocumentsAccesStateSuccess {
  has_docs: boolean;
}

export interface IMyDocumentsStateError {
  error: any;
}

export const initialState: IMyDocumentsState = {
  pending: false,
  error: "",
  hasDocs: false,
  documents: [],
};

export const reducers = {
  getMyDocuments: (
    state: IMyDocumentsState,
    action: PayloadAction<IMyDocumentStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: "",
    };
  },
  getMyDocumentsSuccess: (
    state: IMyDocumentsState,
    action: PayloadAction<IMyDocumentsStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      documents: action?.payload?.documents || [],
    };
  },
  getMyDocumentsError: (
    state: IMyDocumentsState,
    action: PayloadAction<IMyDocumentsStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),

  getMyDocumentAccess: (state: IMyDocumentsState) => ({
    ...state,
    error: "",
    pending: true,
    hasDocs: false,
    documents: [],
  }),
  getMyDocumentAccessSuccess: (
    state: IMyDocumentsState,
    action: PayloadAction<IMyDocumentsAccesStateSuccess>
  ) => ({
    ...state,
    error: "",
    pending: false,
    hasDocs: action?.payload?.has_docs,
  }),
  getMyDocumentAccessError: (
    state: IMyDocumentsStateError,
    action: PayloadAction<IMyDocumentsStateError>
  ) => ({
    ...state,
    error: action.payload.error,
    pending: false,
    hasDocs: false,
    documents: [],
  }),
};
