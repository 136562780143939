import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ICancelTimeOffState } from "./reducers";

export const cancelTimeOffSelector = (state: RootState): ICancelTimeOffState =>
  state.timeOff;

export const selectCancelTimeOff = createSelector(
  cancelTimeOffSelector,
  (cancelTimeOffState: ICancelTimeOffState) => ({ ...cancelTimeOffState })
);
