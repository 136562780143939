import { format } from "date-fns";
import { IDayReporting } from "../interfaces";
import { ProjectItem, Col, ProjectName, Dot } from "../Timesheet.styled";

interface IOutageProps {
  date: string;
  reporting: IDayReporting[];
  timeButton: any;
}

export function Outage(props: IOutageProps) {
  const { date, reporting, timeButton: TimeButton } = props;

  const outageObj = Object.assign(
    {},
    ...reporting.map((item) => ({
      [format(item.day, "dd/MM/yyyy")]: {
        ...item,
        reporting: { ...item?.reporting, type: "outage" },
      },
    }))
  );

  const outageReporting = {
    ...outageObj?.[date],
  };

  return (
    <ProjectItem>
      <Col>
        <ProjectName>
          <Dot $hasOutage={true} />
          Power Outage
        </ProjectName>
      </Col>
      <Col>
        <TimeButton
          day={outageReporting?.day}
          reporting={outageReporting?.reporting}
        />
      </Col>
    </ProjectItem>
  );
}
