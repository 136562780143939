import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { INewsState } from "./reducers";

export const newsSelector = (state: RootState): INewsState => state.news;

export const selectNews = createSelector(
  newsSelector,
  (newsState: INewsState) => ({ ...newsState })
);
