import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ITimeOffListStateSuccess, ITimeOffListStatePending } from "./reducers";
import {
  getTimeOffList,
  getTimeOffListError,
  getTimeOffListSuccess,
} from "./slice";
import { getTimeOffListRequest } from "./api";

function* getTimeOffListSaga(action: PayloadAction<ITimeOffListStatePending>) {
  try {
    const timeOffList: ITimeOffListStateSuccess = yield call(
      getTimeOffListRequest,
      action.payload
    );

    yield put(getTimeOffListSuccess(timeOffList));
  } catch (error) {
    yield put(getTimeOffListError({ error }));
  }
}

function* TimeOffListWatcher() {
  yield takeLatest(getTimeOffList.type, getTimeOffListSaga);
}

export default TimeOffListWatcher;
