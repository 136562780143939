import { PayloadAction } from "@reduxjs/toolkit";

export interface IPostTaskReportingItem {
  project_id: number;
  tasks: Array<string>;
}
export interface IPostTaskReporting {
  id: string;
  reporting: Array<IPostTaskReportingItem>;
}

export interface IPostTaskReportingState {
  pending: boolean;
  error: { message: string; statusCode: number } | null | undefined;
  id: string | null;
  reporting: Array<IPostTaskReportingItem>;
  hasReported: boolean;
}

export interface IPostTaskReportingStateSuccess {
  message?: string;
}

export interface IPostTaskReportingStateError {
  error: { message: string; statusCode: number } | null | undefined;
}

export const initialState: IPostTaskReportingState = {
  pending: false,
  error: null,
  id: null,
  reporting: [],
  hasReported: false,
};

export const reducers = {
  postTaskReporting: (
    state: IPostTaskReportingState,
    action: PayloadAction<IPostTaskReporting>
  ) => {
    return {
      ...state,
      ...action?.payload,
      error: null,
      pending: true,
      hasReported: false,
    };
  },
  postTaskReportingSuccess: (
    state: IPostTaskReportingState,
    action: PayloadAction<IPostTaskReportingStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      hasReported: !action?.payload?.message,
    };
  },
  postTaskReportingError: (
    state: IPostTaskReportingState,
    action: PayloadAction<IPostTaskReportingStateError>
  ) => ({
    ...state,
    pending: false,
    error: action?.payload?.error,
  }),
};
