import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Title } from "@/shared/components";

export const TeamTitleStyled = styled(Title)(({ theme }) => ({
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down("md")]: {
    fontSize: "1.875rem",
  },
}));

export const TeamInfo = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  marginBottom: theme.spacing(0.5),

  [theme.breakpoints.down("md")]: {
    fontSize: "0.875rem",
  },
}));

export const TeamDescription = styled(Typography)(({ theme }) => ({
  marginTop: "1.5rem",

  [theme.breakpoints.down("md")]: {
    fontSize: "0.875rem",
    fontWeight: "500",
  },
}));

export const LoadMoreWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(5),

  [theme.breakpoints.down("sm")]: {
    "& button": {
      width: "100%",
    },
  },
}));
