import { Grid, Avatar } from "@mui/material";
import { IReferralsCandidate } from "@/store/redux";
import { TeamsBlackIcon } from "@/shared/icons";
import { Label } from "./Label";
import { Progress, Row, Cell, User } from "./Candidates.styled";

interface ICandidateProps {
  progressTotalSteps: number;
  data: IReferralsCandidate;
}

export function Candidate(props: ICandidateProps) {
  const { data, progressTotalSteps } = props;

  const { bonus, name, position, recruiter, status } = data;

  const currentProgress =
    status?.progress_step && progressTotalSteps
      ? (status.progress_step * 100) / progressTotalSteps
      : 0;

  return (
    <Row>
      <Grid container columnSpacing={1} rowSpacing={{ xs: 2.5, md: 0 }}>
        <Cell item xs={12} md={2.5} order={0}>
          <Label>Candidate</Label>
          {name}
        </Cell>
        <Cell item xs={12} md={3} order={1}>
          <Label>Position</Label>
          {position}
        </Cell>
        <Cell item xs={12} md={3} order={{ xs: 4, md: 2 }}>
          {recruiter?.name ? (
            <>
              <Label>Recruiter</Label>
              <User>
                <Avatar
                  src={recruiter?.avatar}
                  sx={{ width: 28, height: 28 }}
                />
                {recruiter?.name}
                <TeamsBlackIcon />
              </User>
            </>
          ) : null}
        </Cell>
        <Cell item xs={6} md={1} order={{ xs: 3, md: 3 }}>
          {bonus ? (
            <>
              <Label>Bonus</Label>${bonus}
            </>
          ) : null}
        </Cell>
        <Cell item xs={6} md={2.5} order={{ xs: 2, md: 4 }}>
          <Label>Status</Label>
          {status?.title}
        </Cell>
        <Cell item xs={12} order={5}>
          <Progress value={currentProgress} variant="determinate" />
        </Cell>
      </Grid>
    </Row>
  );
}
