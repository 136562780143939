import { styled } from "@mui/material/styles";
import { Description, Title } from "@/shared/components";

export const FeedItemStyled = styled("div")(({ theme }) => ({
  "& + &": {
    marginTop: theme.spacing(6),
  },
}));

export const FeedItemNavigateArea = styled("div")<{ $article?: boolean }>(
  ({ theme, $article }) => ({
    cursor: !$article ? "pointer" : "inherit",
  })
);

export const TitleStyled = styled(Title)(({ theme }) => ({
  fontWeight: 400,

  "& a": {
    textDecoration: "none",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "1.875rem",
  },
}));

export const DateStyled = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontWeight: 500,
  marginBottom: theme.spacing(0.5),
}));

export const DescriptionStyled = styled(Description)(({ theme }) => ({
  fontWeight: 400,

  "& img": {
    display: "block",
    width: "auto",
    maxWidth: "100%",
  },

  "& h2": { fontSize: theme.typography.pxToRem(22), fontWeight: 700 },
  "& h3": { fontSize: theme.typography.pxToRem(20), fontWeight: 700 },
  "& h4": { fontSize: theme.typography.pxToRem(18), fontWeight: 700 },
  "& h5": { fontSize: theme.typography.pxToRem(16), fontWeight: 700 },
}));

export const Actions = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1.75),
}));

export const ActionItem = styled("span")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  fontWeight: 500,
}));

export const Tags = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  margin: theme.spacing(2, 0, 1),
}));
