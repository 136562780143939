export const filterDeliveryTypesByCountry = (
  country: any,
  types: Array<any>
) => {
  //626 - Ukraine

  return country?.value !== 626
    ? (types || []).filter((type) => type.value !== 4)
    : types;
};

export const filterPostalServiceByCountry = (
  country: any,
  services: Array<any>
) => {
  //626 - Ukraine
  //631 - Poland

  if (country?.value !== 626 && country?.value !== 631) {
    return (services || []).filter((service) => [2].includes(service.value));
  }

  if (country?.value === 631) {
    return (services || []).filter((service) => [1, 2].includes(service.value));
  }

  return services;
};

export const countriesAlphabeticallySort = (array: any) => {
  return [...(array || [])].sort((a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });
};

export const getDefaultCountry = (countries: any, profile: any) => {
  if (!countries.length) return;

  return countries.filter(
    (country: any) => country.value === profile?.country
  )[0];
};
