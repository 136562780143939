import { useState } from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Select } from "@/shared/components";
import { IMarketplaceBrandProductSize } from "@/store/redux";
import {
  Options,
  OptionItem,
  OptionTitle,
  OptionValues,
} from "./MarketPlaceBrandProducts.styled";

export interface IOptions {
  size?: number | string | null;
  quantity?: number | string | null;
}
interface IProductOptionsProps {
  sizes: IMarketplaceBrandProductSize[];
  quantity: number;
  hasError: boolean;
  onOptionsChange: (k: IOptions) => void;
}

export function ProductOptions(props: IProductOptionsProps) {
  const { sizes, quantity, hasError, onOptionsChange } = props;

  const [size, setSize] = useState<IMarketplaceBrandProductSize | null>(null);
  const [count, setCount] = useState<number>(1);

  const hasSizeError = hasError && !size;
  const hasCountError = hasError && !count;

  const handleOnSizeChange = (
    event: React.MouseEvent<HTMLElement>,
    size: IMarketplaceBrandProductSize
  ) => {
    if (!size) {
      setSize(null);

      onOptionsChange({ size: null });

      return;
    }

    setSize(size);

    onOptionsChange({ size: size?.value });
  };

  const handleOnCountChange = (e: any) => {
    const count = e.target.value;

    setCount(count);

    onOptionsChange({ quantity: count });
  };

  return (
    <Options>
      <OptionItem>
        <OptionTitle required error={hasSizeError}>
          Sizes:
        </OptionTitle>
        <OptionValues>
          <ToggleButtonGroup
            exclusive
            value={size}
            onChange={handleOnSizeChange}
          >
            {(sizes || []).map((size) => (
              <ToggleButton
                key={size.value}
                disabled={!size.quantity}
                value={size}
              >
                {size.title}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </OptionValues>
      </OptionItem>

      <OptionItem>
        <OptionTitle required error={hasCountError}>
          Quantity:
        </OptionTitle>
        <OptionValues>
          <Select
            placeholder="Please, select size"
            variant="outlined"
            disabled={!quantity}
            options={[1, 2]}
            value={count}
            onChange={handleOnCountChange}
          />
        </OptionValues>
      </OptionItem>
    </Options>
  );
}
