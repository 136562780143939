import { useFormContext } from "react-hook-form";
import { Box, FormHelperText, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Dropzone, FormInput } from "@/shared/components";

export function Files(props: { pending: boolean }) {
  const { pending } = props;

  const { setValue } = useFormContext();

  const maxSize = 20 * Math.pow(1024, 2);

  const handleOnDrop = (acceptedFiles: File[]) => {
    setValue("cv", acceptedFiles as unknown as FileList);
  };

  const handleOnRemove = () => {
    setValue("cv", null);
  };

  return (
    <>
      <FormInput
        name="cv"
        rules={{
          validate: (files: File[]) => {
            if (!files?.length) return true;

            return files?.[0]?.size <= maxSize;
          },
        }}
        render={({ field: { onChange, value } }) => (
          <>
            <Dropzone
              disabled={pending}
              onDrop={handleOnDrop}
              onChange={onChange}
            />

            {value?.[0]?.name ? (
              <Box
                sx={{
                  mt: 1,
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AttachFileIcon sx={{ fontSize: "1.25rem" }} />
                {value?.[0]?.name}

                <IconButton onClick={handleOnRemove}>
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}

            {value?.[0]?.size > maxSize ? (
              <FormHelperText sx={{ fontSize: "1rem" }} error>
                File size must be up to 20 MB
              </FormHelperText>
            ) : null}
          </>
        )}
      />
    </>
  );
}
