import { ReactNode } from "react";
import { Wrapper, Title, Subtitle, Content } from "./UserCardSmall.styled";

interface IUserCardSmallProps {
  title: string;
  subtitle: string;
  avatar?: ReactNode;
  content?: ReactNode;
}

export function UserCardSmall(props: IUserCardSmallProps) {
  const { avatar, title, subtitle, content } = props;

  return (
    <Wrapper>
      {avatar}

      <Content>
        <Title>{title}</Title>

        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}

        {content}
      </Content>
    </Wrapper>
  );
}
