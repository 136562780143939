import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { useActions } from "@/utils/redux";
import { CartActions, selectCart, selectSwitchUser } from "@/store/redux";
import { ListItem } from "./ListItem";
import { TitleStyled } from "./List.styled";

export function CheckoutList() {
  const { user } = useSelector(selectSwitchUser);
  const { products } = useSelector(selectCart);
  const { getCart } = useActions<typeof CartActions>(CartActions);

  useEffect(() => {
    getCart();
  }, [user]); //eslint-disable-line

  if (!products?.length) {
    return null;
  }

  return (
    <>
      <TitleStyled>Cart</TitleStyled>

      <Box sx={{ margin: "0 0 24px" }}>
        <Grid container columnSpacing={2}>
          {products &&
            products.map((product) => (
              <Grid key={product.id} item xs={12} sm={12} smd={6} md={6}>
                <ListItem {...product} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
}
