import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "Cart",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getCart,
  getCartError,
  getCartSuccess,
  postCart,
  postCartError,
  postCartSuccess,
  deleteCart,
  deleteCartError,
  deleteCartSuccess,
  resetPostCart,
  resetPostCartError,
  resetPostCartSuccess,
} = slice.actions;

reducerRegistry.register("cart", reducer);
