import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function TeamsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7Z"
        fill="#E6E6E6"
      />
      <path
        d="M21 11.5C21 13.433 19.433 15 17.5 15C15.567 15 14 13.433 14 11.5C14 9.567 15.567 8 17.5 8C19.433 8 21 9.567 21 11.5Z"
        fill="#E6E6E6"
      />
      <path
        d="M22 21V20.5C22 18.0147 19.9853 16 17.5 16C15.0147 16 13 18.0147 13 20.5V21H22Z"
        fill="#E6E6E6"
      />
      <path
        d="M2 17V21H12V17C12 14.2386 9.76142 12 7 12C4.23858 12 2 14.2386 2 17Z"
        fill="#E6E6E6"
      />
    </SvgIcon>
  );
}

export function TeamsBlackIcon(props: SvgIconProps) {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" {...props}>
      <path
        d="M9.6665 6.33333C9.6665 8.17428 8.17412 9.66667 6.33317 9.66667C4.49222 9.66667 2.99984 8.17428 2.99984 6.33333C2.99984 4.49238 4.49222 3 6.33317 3C8.17412 3 9.6665 4.49238 9.6665 6.33333Z"
        fill="#000F1B"
      />
      <path
        d="M17.9998 10.0833C17.9998 11.6942 16.694 13 15.0832 13C13.4723 13 12.1665 11.6942 12.1665 10.0833C12.1665 8.4725 13.4723 7.16667 15.0832 7.16667C16.694 7.16667 17.9998 8.4725 17.9998 10.0833Z"
        fill="#000F1B"
      />
      <path
        d="M18.8332 18V17.5833C18.8332 15.5123 17.1542 13.8333 15.0832 13.8333C13.0121 13.8333 11.3332 15.5123 11.3332 17.5833V18H18.8332Z"
        fill="#000F1B"
      />
      <path
        d="M2.1665 14.6667V18H10.4998V14.6667C10.4998 12.3655 8.63436 10.5 6.33317 10.5C4.03198 10.5 2.1665 12.3655 2.1665 14.6667Z"
        fill="#000F1B"
      />
    </svg>
  );
}
