import {
  Container,
  MarketPlaceHead,
  MarketPlaceRules,
  MarketPlaceProductsList,
  MarketPlaceQA,
  CheckoutSuccessModal,
  Page,
} from "@/components";

export function MarketPlace() {
  return (
    <Page>
      <CheckoutSuccessModal />

      <MarketPlaceHead />

      <Container>
        <MarketPlaceRules />

        <MarketPlaceProductsList />

        <MarketPlaceQA />
      </Container>
    </Page>
  );
}
