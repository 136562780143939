import { SxProps } from "@mui/material";
import { BadgeStyled } from "./Badge.styled";

export interface IBadgeProps {
  children: React.ReactNode;
  tag?: boolean;
  color?:
    | "yellow"
    | "pink"
    | "violet"
    | "violetBlue"
    | "yellowBlue"
    | "pinkViolet"
    | "bluePink"
    | "yellowPink"
    | string;
  onClick?: (k: any) => void;
  sx?: SxProps;
}

export function Badge(props: IBadgeProps) {
  const { color, tag, children, onClick, ...rest } = props;

  return (
    <BadgeStyled {...rest} $color={color} $tag={tag} onClick={onClick}>
      {children}
    </BadgeStyled>
  );
}
