import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { INewsComment, INewsCommentStatePending } from "./reducers";
import {
  postNewsComment,
  postNewsCommentError,
  postNewsCommentSuccess,
} from "./slice";
import { postNewsCommentsRequest } from "./api";

function* postNewsCommentSaga(action: PayloadAction<INewsCommentStatePending>) {
  try {
    const comment: INewsComment = yield call(
      postNewsCommentsRequest,
      action.payload
    );

    yield put(postNewsCommentSuccess(comment));
  } catch (error) {
    yield put(postNewsCommentError({ error }));
  }
}

function* PostCommentWatcher() {
  yield takeLatest(postNewsComment.type, postNewsCommentSaga);
}

export default PostCommentWatcher;
