import { call, put, takeLatest, delay } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IPostThanksGivingStateSuccess,
  IPostThanksGivingStatePending,
} from "./reducers";
import {
  postThanksGiving,
  postThanksGivingError,
  postThanksGivingSuccess,
  resetPostThanksGiving,
  resetPostThanksGivingError,
  resetPostThanksGivingSuccess,
} from "./slice";
import { postThanksGivingRequest } from "./api";

function* postThanksGivingSaga(
  action: PayloadAction<IPostThanksGivingStatePending>
) {
  try {
    const postThanksGiving: IPostThanksGivingStateSuccess = yield call(
      postThanksGivingRequest,
      action.payload
    );

    yield put(postThanksGivingSuccess(postThanksGiving));
  } catch (error) {
    yield put(postThanksGivingError({ error }));
  }
}

function* resetPostThanksGivingSaga() {
  try {
    yield delay(500);

    yield put(resetPostThanksGivingSuccess());
  } catch (error) {
    yield put(resetPostThanksGivingError());
  }
}

function* PostThanksGivingWatcher() {
  yield takeLatest(postThanksGiving.type, postThanksGivingSaga);
  yield takeLatest(resetPostThanksGiving.type, resetPostThanksGivingSaga);
}

export default PostThanksGivingWatcher;
