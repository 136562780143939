import { IconButton, useTheme } from "@mui/material";
import { ActionButton } from "@/shared/components";
import { CommentIcon, CommentIconFilled } from "@/shared/icons";

interface ICommentButtonProps {
  count: number | undefined;
  hasComment: boolean | undefined | null;
  onComment: () => void;
}

export function CommentButton(props: ICommentButtonProps) {
  const { hasComment, count, onComment, ...restProps } = props;
  const { modeColors, palette } = useTheme();
  const iconColor = modeColors({
    dark: palette.secondary.main,
    light: palette.primary.main,
  });

  return (
    <ActionButton
      {...restProps}
      hasCount={!!count}
      label={count || "comment"}
      control={
        <IconButton color="primary" onClick={onComment}>
          {hasComment ? (
            <CommentIconFilled htmlColor={iconColor} />
          ) : (
            <CommentIcon />
          )}
        </IconButton>
      }
    />
  );
}
