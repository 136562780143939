import { PayloadAction } from "@reduxjs/toolkit";

export interface IReferralsVacancy {
  id: number;
  title: string;
  description: string;
  company: string;
  location: string;
  area: string;
  level: string;
  tech_stack: string;
  about_client: string;
  project_details: string;
  your_team: string;
  benefits: string;
  responsibilities: string;
  skills: string;
  recruiter: {
    photo: string;
    name: string;
    email: string;
  };
  legacy_url?: string;
}

export interface IFiltersCounter {
  area_id: number;
  count: number;
}

export interface IReferralsVacanciesState {
  pending: boolean;
  error: any;
  list: IReferralsVacancy[];
  filters_counters: IFiltersCounter[];
  poffset: number;
  plimit: number;
  ptotal: number;
  psearch: string;
  plevel: number | null;
  parea: number | null;
  plocation: number | null;
}

export interface IReferralsVacanciesStatePending {
  poffset?: number;
  plimit?: number;
  ptotal?: number;
  psearch?: string;
  plevel?: number | null;
  parea?: number | null;
  plocation?: number | null;
}

export interface IReferralsVacanciesStateSuccess {
  poffset?: number;
  plimit?: number;
  ptotal?: number;
  psearch?: string;
  plevel?: number | null;
  parea?: number | null;
  plocation?: number | null;
  list: IReferralsVacancy[];
  filters_counters: IFiltersCounter[];
}

export interface IReferralsVacanciesStateError {
  error: any;
}

export const initialState: IReferralsVacanciesState = {
  pending: false,
  error: "",
  poffset: 0,
  plimit: 10,
  ptotal: 0,
  psearch: "",
  plevel: null,
  parea: null,
  plocation: null,
  list: [],
  filters_counters: [],
};

export const reducers = {
  getReferralsVacancies: (
    state: IReferralsVacanciesState,
    action: PayloadAction<IReferralsVacanciesStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: "",
      ...(action.payload || {}),
    };
  },
  getReferralsVacanciesSuccess: (
    state: IReferralsVacanciesState,
    action: PayloadAction<IReferralsVacanciesStateSuccess>
  ) => {
    const { poffset, list, filters_counters, ...restOptions } = action?.payload;

    return {
      ...state,
      ...restOptions,
      pending: false,
      list: poffset ? [...(state.list || []), ...(list || [])] : list || [],
      filters_counters: filters_counters || [],
    };
  },
  getReferralsVacanciesError: (
    state: IReferralsVacanciesState,
    action: PayloadAction<IReferralsVacanciesStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
