import { TextField, Box } from "@mui/material";
import { IFieldProps } from "../interfaces";
import { GenerateField } from "./GenerateField";

export function TaskField(props: IFieldProps) {
  const { pending } = props;

  const name = "task";
  const maxValue = 2000;
  const getCounter = (length: number) => (
    <Box component="span" sx={{ display: "block", textAlign: "right" }}>
      {length} / {maxValue}
    </Box>
  );

  const handleOnChange = (e: any) => {
    const value = e.target.value;

    if (value?.length > maxValue) {
      return value.slice(0, maxValue);
    }

    return value;
  };

  return (
    <GenerateField
      name={name}
      rules={{
        validate: (value) => !!String(value)?.trim(),
      }}
      render={({ field: { onChange, value, ...restProps } }) => (
        <TextField
          {...restProps}
          fullWidth
          multiline
          disabled={pending}
          minRows={2}
          maxRows={6}
          value={value}
          onChange={(e) => onChange(handleOnChange(e))}
          helperText={getCounter(value?.length)}
          variant="filled"
          label="Task"
        />
      )}
    />
  );
}
