import { styled } from "@mui/material/styles";
import { GradientButton, ButtonBg } from "@/shared/components";

export const WhiteButton = styled(GradientButton)(({ theme }) => ({
  color: theme.palette.common.white,
  border: `4px solid ${theme.palette.common.white}`,
  background: "transparent",

  "&:hover": {
    background: "rgba(255,255,255,0.15)",
  },

  "&:before, &:after": {
    display: "none",
  },

  [ButtonBg as any]: {
    display: "none",
  },
}));
