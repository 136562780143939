import { Box } from "@mui/material";
import {
  Page,
  Container,
  MoodMonitoringHead,
  MoodMonitoringChart,
  MoodMonitoringContacts,
  MoodMonitoringLiterature,
} from "@/components";

export function MoodMonitoring() {
  return (
    <Page>
      <Container>
        <MoodMonitoringHead />

        <MoodMonitoringChart />

        <Box sx={{ fontSize: "1.25rem", mb: 4 }}>
          If you are in a bad mood and you don't know how to deal with it, we
          offer several options:
        </Box>

        <MoodMonitoringContacts />

        <MoodMonitoringLiterature />
      </Container>
    </Page>
  );
}
