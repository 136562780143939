import MuiPhoneNumber from "mui-phone-number";
import { useFormContext } from "react-hook-form";
import { GenerateField } from "./GenerateField";

export function PhoneNumberField() {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldName = "phone_number";
  const hasError = !!errors[fieldName]?.type;

  return (
    <GenerateField
      errorText="Not valid phone number"
      name="phone_number"
      rules={{
        validate: (value) => (value || "").replace(/\D/g, "").length > 8,
      }}
      render={({ field }) => (
        <MuiPhoneNumber
          {...field}
          fullWidth
          disableAreaCodes
          defaultCountry={"ua"}
          error={hasError}
          label="Mobile Phone Number"
          variant="filled"
          sx={{ "& svg": { height: "1em" } }}
        />
      )}
    />
  );
}
