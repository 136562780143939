import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Accordion, Title } from "@/shared/components";

interface IReferralsQAProps {
  title: string;
  content: any;
}
interface ISteps {
  title: string;
  description: string;
  description_2: string;
  list: [];
}

function getData({ t, translations }: { t: any; translations: any }) {
  const getSteps = (steps: any) => {
    return (
      <>
        {(steps || []).map(
          (
            { title, description, description_2, list }: ISteps,
            idx: number
          ) => (
            <div key={`${title}-${idx}`}>
              {title ? (
                <p>
                  <b>{title}</b>
                </p>
              ) : null}

              {description ? <p>{description}</p> : null}

              {list?.length ? (
                <ul>
                  {list.map((item: string) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              ) : null}

              {description_2 ? <Box sx={{ mb: 2 }}>{description_2}</Box> : null}
            </div>
          )
        )}
      </>
    );
  };

  return (translations || []).map(
    ({ title, description }: { title: string; description: any }) => ({
      title,
      desc: getSteps(description?.steps),
    })
  );
}

export function ReferralsQA(props: IReferralsQAProps) {
  const { title, content } = props;

  const { t } = useTranslation();

  const data = getData({ t, translations: content });

  return (
    <Box sx={{ mb: 8, pl: 1 }}>
      <Title fontWeight="500">{title}</Title>

      <Accordion data={data} sx={{ mt: 1 }} />
    </Box>
  );
}
