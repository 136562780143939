import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function ThanksGivingDayIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" htmlColor="transparent" {...props}>
      <g clipPath="url(#clip0_4740_36327)">
        <path
          d="M18.6663 12V20.3333H5.33301V12"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3337 7.83331H3.66699V12H20.3337V7.83331Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 20.3333V7.83331"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0003 7.83335H8.25033C7.69779 7.83335 7.16789 7.61386 6.77719 7.22316C6.38649 6.83246 6.16699 6.30255 6.16699 5.75002C6.16699 5.19749 6.38649 4.66758 6.77719 4.27688C7.16789 3.88618 7.69779 3.66669 8.25033 3.66669C11.167 3.66669 12.0003 7.83335 12.0003 7.83335Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 7.83335H15.75C16.3025 7.83335 16.8324 7.61386 17.2231 7.22316C17.6138 6.83246 17.8333 6.30255 17.8333 5.75002C17.8333 5.19749 17.6138 4.66758 17.2231 4.27688C16.8324 3.88618 16.3025 3.66669 15.75 3.66669C12.8333 3.66669 12 7.83335 12 7.83335Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4740_36327">
          <rect width="20" height="20" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
