import { call, put, takeLatest } from "redux-saga/effects";
import { INewsLocationsStateSuccess } from "./reducers";
import {
  getNewsLocations,
  getNewsLocationsError,
  getNewsLocationsSuccess,
} from "./slice";
import { getNewsLocationsRequest } from "./api";

function* getNewsLocationsSaga() {
  try {
    const filters: INewsLocationsStateSuccess = yield call(
      getNewsLocationsRequest
    );

    yield put(getNewsLocationsSuccess(filters));
  } catch (error) {
    yield put(getNewsLocationsError({ error }));
  }
}

function* NewsLocationsWatcher() {
  yield takeLatest(getNewsLocations.type, getNewsLocationsSaga);
}

export default NewsLocationsWatcher;
