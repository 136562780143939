import { INewsCommentsItem } from "@/store/redux";
import { CommentItemTemplate } from "./CommentItemTemplate";
import { CommentSubItemsWrapper } from "../Comments.styled";

interface ISubCommentsProps {
  selectedCommentRef: any;
  selectedReplyId: number | null;
  loading?: boolean;
  comments?: INewsCommentsItem[];
  onReply: (k: INewsCommentsItem) => void;
  onDelete: (k: INewsCommentsItem) => void;
  onParentSelect: (k: number | null | undefined) => void;
}

export function SubComments(props: ISubCommentsProps) {
  const {
    loading,
    comments,
    selectedCommentRef,
    selectedReplyId,
    onReply,
    onDelete,
    onParentSelect,
  } = props;

  return (
    <CommentSubItemsWrapper>
      {(comments || []).map((comment: INewsCommentsItem) => (
        <CommentItemTemplate
          key={comment?.id}
          loading={loading}
          comment={comment}
          onReply={onReply}
          onDelete={onDelete}
          onParentSelect={onParentSelect}
          selectedCommentRef={selectedCommentRef}
          selectedReplyId={selectedReplyId}
        />
      ))}
    </CommentSubItemsWrapper>
  );
}
