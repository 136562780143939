import { GradientButton } from "@/shared/components";
import {
  NoCandidatesSubtitle,
  NoCandidatesTitle,
  NoCandidatesWrapper,
} from "./Candidates.styled";

interface INoCandidatesProps {
  onDialogOpen: () => void;
}

export function NoCandidates(props: INoCandidatesProps) {
  const { onDialogOpen } = props;

  return (
    <>
      <NoCandidatesWrapper>
        <NoCandidatesTitle>There is no candidates for now :(</NoCandidatesTitle>
        <NoCandidatesSubtitle>
          Hit the button below to reffer a bright person.
        </NoCandidatesSubtitle>
      </NoCandidatesWrapper>

      <GradientButton sx={{ minWidth: 270 }} onClick={onDialogOpen}>
        REFER A CANDIDATE
      </GradientButton>
    </>
  );
}
