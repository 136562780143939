import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar, IconButton } from "@mui/material";
import { ActionButton } from "@/shared/components";
import { ShareIcon } from "@/shared/icons";
import { ShareMessageWrapper } from "./ShareButton.styled";

interface IShareProps {
  link: string;
}

export function ShareButton(props: IShareProps) {
  const { link } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnShare = () => {
    setOpen(true);
  };

  return (
    <>
      <CopyToClipboard text={link}>
        <ActionButton
          label="share"
          control={
            <IconButton onClick={handleOnShare}>
              <ShareIcon />
            </IconButton>
          }
        />
      </CopyToClipboard>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <ShareMessageWrapper>Link Copied</ShareMessageWrapper>
      </Snackbar>
    </>
  );
}
