import { Box } from "@mui/material";
import { Title, Tabs } from "@/shared/components";
import { TimeOffCalendar } from "./Calendar";
import { TimeOffList } from "./List";

export function TimeOffHistory() {
  return (
    <Box sx={{ mt: 4 }}>
      <Title fontStyle="normal">BOOKING HISTORY</Title>
      <Tabs
        titles={["List", "Calendar"]}
        contents={[<TimeOffList />, <TimeOffCalendar />]}
      />
    </Box>
  );
}
