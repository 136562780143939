import { PayloadAction } from "@reduxjs/toolkit";

export interface IMarketplaceBrand {
  logo: string;
  title: string;
  website: string;
  description: string;
  images: {
    desktop: string;
    mobile: string;
  };
  quote: string;
}

export interface IMarketplaceBrandState {
  pending: boolean;
  error: any;
  brand: IMarketplaceBrand;
}

export interface IMarketplaceBrandStateError {
  error: any;
}

export const initialState: IMarketplaceBrandState = {
  pending: false,
  error: "",
  brand: {} as IMarketplaceBrand,
};

export const reducers = {
  getMarketplaceBrand: (
    state: IMarketplaceBrandState,
    action: PayloadAction<number | string | undefined>
  ) => {
    return {
      ...state,
      brand: {} as IMarketplaceBrand,
      pending: true,
      error: "",
    };
  },
  getMarketplaceBrandSuccess: (
    state: IMarketplaceBrandState,
    action: PayloadAction<IMarketplaceBrand>
  ) => {
    return {
      ...state,
      pending: false,
      brand: action.payload,
    };
  },
  getMarketplaceBrandError: (
    state: IMarketplaceBrandState,
    action: PayloadAction<IMarketplaceBrandStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
