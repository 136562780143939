import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  PostThanksGivingActions,
  ProfileActions,
  selectProfile,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { Bubble } from "@/shared/components";
import { ThanksGivingDayIcon } from "@/shared/icons";
import { ThanksGivingDialog } from "./ThanksGivingDialog";
import { ThanksGivingDayErrorMessage } from "./ThanksGivingDayErrorMessage";
import { OpenButton } from "./ThanksGivingDay.styled";

export function ThanksGivingDay() {
  const { profile } = useSelector(selectProfile);
  const { resetPostThanksGiving } = useActions<typeof PostThanksGivingActions>(
    PostThanksGivingActions
  );
  const { updateProfile } = useActions<typeof ProfileActions>(ProfileActions);

  const [openDialog, setOpenDialog] = useState(false);

  const handleOnDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleOnDialogClose = () => {
    if (!profile?.has_thanksgiving) {
      updateProfile({ has_thanksgiving: true });
    }

    setOpenDialog(false);
    resetPostThanksGiving();
  };

  useEffect(() => {
    if (!profile) return;

    setOpenDialog(!profile?.has_thanksgiving);
  }, [profile]);

  return profile?.has_thanksgiving_marathon ? (
    <>
      <OpenButton onClick={handleOnDialogOpen}>
        <Bubble color="yellowPink" width="100%" height="100%">
          <ThanksGivingDayIcon />
        </Bubble>
      </OpenButton>

      <ThanksGivingDialog open={openDialog} onClose={handleOnDialogClose} />

      <ThanksGivingDayErrorMessage />
    </>
  ) : null;
}
