import { PayloadAction } from "@reduxjs/toolkit";

export interface ITimeOffCalendarDate {
  start_date: string;
  end_date: string;
  type: string;
}

export interface ITimeOffCalendarState {
  pending: boolean;
  error: any;
  ptype: string;
  pstatus: string;
  dates: ITimeOffCalendarDate[] | [];
}

export interface ITimeOffCalendarStatePending {
  ptype: string;
  pstatus: string;
  start_date: string;
  end_date: string;
}

export interface ITimeOffCalendarStateSuccess {
  ptype: string;
  pstatus: string;
  dates: ITimeOffCalendarDate[] | [];
}

export interface ITimeOffCalendarStateError {
  error: any;
}

export const initialState: ITimeOffCalendarState = {
  pending: false,
  error: null,
  ptype: "",
  pstatus: "",
  dates: [],
};

export const reducers = {
  getTimeOffCalendar: (
    state: ITimeOffCalendarState,
    action: PayloadAction<ITimeOffCalendarStatePending>
  ) => {
    return {
      ...state,
      pending: true,
      error: "",
    };
  },
  getTimeOffCalendarSuccess: (
    state: ITimeOffCalendarState,
    action: PayloadAction<ITimeOffCalendarStateSuccess>
  ) => {
    const { dates, ...rest } = action.payload;

    return {
      ...state,
      ...rest,
      pending: false,
      dates: dates || [],
    };
  },
  getTimeOffCalendarError: (
    state: ITimeOffCalendarState,
    action: PayloadAction<ITimeOffCalendarStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
