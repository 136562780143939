import { Container, Page, SurveysTabs } from "@/components";
import { Title } from "@/shared/components";

export function Surveys() {
  return (
    <Page title="My Surveys">
      <Container>
        <Title fontSize="60px" sx={{ mb: 2 }}>
          MY SURVEYS
        </Title>

        <SurveysTabs />
      </Container>
    </Page>
  );
}
