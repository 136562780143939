import { PayloadAction } from "@reduxjs/toolkit";

export interface IReferralsFilters {
  areas: {
    id: number;
    value: number;
    title: string;
  }[];
  locations: {
    id: number;
    value: number;
    title: string;
  }[];
  levels: {
    id: number;
    value: number;
    title: string;
  }[];
}

export interface IReferralsFiltersState extends IReferralsFilters {
  pending: boolean;
  error: any;
}

export interface IReferralsFiltersStateSuccess {
  areas: {
    id: number;
    value: number;
    title: string;
  }[];
  locations: {
    id: number;
    value: number;
    title: string;
  }[];
  levels: {
    id: number;
    value: number;
    title: string;
  }[];
}

export interface IReferralsFiltersStateError {
  error: any;
}

export const initialState: IReferralsFiltersState = {
  pending: false,
  error: null,
  areas: [{ id: -1, title: "All", value: -1 }],
  locations: [{ id: -1, title: "All", value: -1 }],
  levels: [{ id: -1, title: "All", value: -1 }],
};

function transfromFilters(filters: Array<any>) {
  return [
    { id: -1, title: "All", value: -1 },
    ...(filters || []).map((item) => ({ ...item, value: item.id })),
  ];
}

export const reducers = {
  getReferralsFilters: (state: IReferralsFiltersState) => {
    return {
      ...state,
      pending: true,
      error: "",
    };
  },
  getReferralsFiltersSuccess: (
    state: IReferralsFiltersState,
    action: PayloadAction<IReferralsFiltersStateSuccess>
  ) => {
    const { areas, locations, levels } = action?.payload || {};

    return {
      ...state,
      pending: false,
      areas: transfromFilters(areas || []),
      levels: transfromFilters(levels || []),
      locations: transfromFilters(locations || []),
    };
  },
  getReferralsFiltersError: (
    state: IReferralsFiltersState,
    action: PayloadAction<IReferralsFiltersStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
