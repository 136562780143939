import { ReactNode } from "react";
import {
  ErrorWrapper,
  ErrorLight,
  ErrorText,
  ErrorContent,
  ErrorContainer,
} from "./ErrorPage.styled";

interface IErrorPageProps {
  title?: ReactNode;
  subtitle?: ReactNode;
}

export function ErrorPage(props: IErrorPageProps) {
  const { title, subtitle } = props;

  return (
    <ErrorWrapper>
      <ErrorContent>
        <ErrorContainer>
          <ErrorLight />
          <ErrorText>
            <div>{title}</div>
            {subtitle}
          </ErrorText>
        </ErrorContainer>
      </ErrorContent>
    </ErrorWrapper>
  );
}
