import { call, put, takeLatest } from "redux-saga/effects";
import { IHotTopicsState } from "./reducers";
import { getHotTopics, getHotTopicsError, getHotTopicsSuccess } from "./slice";
import { getHotTopicsRequest } from "./api";

function* getHotTopicsSaga() {
  try {
    const topics: IHotTopicsState = yield call(getHotTopicsRequest);

    yield put(getHotTopicsSuccess(topics));
  } catch (error) {
    yield put(getHotTopicsError({ error }));
  }
}

function* NewsWatcher() {
  yield takeLatest(getHotTopics.type, getHotTopicsSaga);
}

export default NewsWatcher;
