import { styled } from "@mui/material/styles";
import { Container } from "@/components";
import { Title } from "@/shared/components";

export const ContainerStyled = styled(Container)(({ theme }) => ({
  zIndex: 1,
  position: "relative",
  marginTop: "220px",
  marginBottom: theme.spacing(8),

  [theme.breakpoints.down("md")]: {
    marginTop: "120px",
  },

  [theme.breakpoints.down("sm")]: {
    marginTop: "40px",
  },
}));

export const MarketPlaceHeadContainer = styled("div")(({ theme }) => ({
  position: "relative",
  margin: "-53px 0 12px",
  padding: theme.spacing(11, 0, 4.5),
  overflow: "hidden",
  background: "#000F1B",
  color: theme.palette.common.white,
}));

export const MarketPlaceHeadImage = styled("div")<{ $image?: string }>(
  ({ theme, $image }) => ({
    backgroundImage: `url(${$image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    minHeight: "500px",
    clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 80%)",

    [theme.breakpoints.down("md")]: {
      backgroundSize: "auto 70%",
    },

    [theme.breakpoints.down("sm")]: {
      backgroundSize: "auto 40%",
    },
  })
);

export const MarketPlaceHeadTitle = styled(Title)(({ theme }) => ({
  lineHeight: 1,
  fontSize: 75,

  [theme.breakpoints.down("sm")]: {
    fontSize: 60,
  },
}));

export const MarketPlaceHeadDesc = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: "1.5rem",

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    fontWeight: 500,
  },
}));

export const MarketPlaceDonateWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(8),
}));

export const MarketPlaceDonateTitle = styled(Title)(({ theme }) => ({
  fontSize: 36,

  [theme.breakpoints.down("sm")]: {
    fontSize: 30,
  },
}));

export const MarketPlaceDonateProgressWrapp = styled("div")(({ theme }) => ({
  background: "#E6E6E6",
  color: theme.palette.common.black,
  borderRadius: "40px",
  marginTop: theme.spacing(2),
  padding: theme.spacing(0.5, 4),
  overflow: "hidden",
  position: "relative",
  textAlign: "center",
}));

export const MarketPlaceDonateProgress = styled("div")<{ $progress: string }>(
  ({ theme, $progress }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    background: theme.palette.secondary.main,
    width: $progress,
    height: "100%",
    borderRadius: "40px",
  })
);

export const MarketPlaceDonateProgressValues = styled("span")(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  fontSize: "1.125rem",
  fontWeight: 700,

  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const RulesWrapper = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(9),
}));

export const RulesDesc = styled("div")(({ theme }) => ({
  margin: theme.spacing(4, 0),

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

export const PointsWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

export const PointWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "0 0 auto",
  width: 315,
  flexDirection: "column",
  padding: theme.spacing(8, 0, 0, 8),
  gap: theme.spacing(1),
  position: "relative",

  "&:before": {
    content: "''",
    display: "block",
    position: "absolute",
    height: "94px",
    width: "94px",
    borderRadius: "50%",
    background: "rgba(222, 255, 43, 0.6)",
    filter: "blur(15px)",
    zIndex: -1,
    top: 30,
    left: 30,
  },

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const PointDesc = styled("div")(({ theme }) => ({
  fontSize: "1.125rem",
  fontWeight: 700,
  maxWidth: 240,
  marginTop: theme.spacing(0.5),

  [theme.breakpoints.down("md")]: {
    maxWidth: "90%",
  },
}));
