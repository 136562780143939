import { PayloadAction } from "@reduxjs/toolkit";

interface IDeliveryType {
  value: number;
  title: string;
}
interface IPostalService {
  value: number;
  title: string;
}

export interface IDeliveryInfoState {
  pending: boolean;
  error: any;
  delivery_type: IDeliveryType[];
  postal_service: IPostalService[];
}

export interface IDeliveryInfoStateSuccess {
  delivery_type: IDeliveryType[];
  postal_service: IPostalService[];
}

export interface IDeliveryInfoStateError {
  error: any;
}

export const initialState: IDeliveryInfoState = {
  pending: false,
  error: "",
  delivery_type: [],
  postal_service: [],
};

export const reducers = {
  getCheckoutDeliveryInfo: (state: IDeliveryInfoState) => {
    return {
      ...state,
      delivery_type: [],
      postal_service: [],
      pending: true,
      error: "",
    };
  },
  getCheckoutDeliveryInfoSuccess: (
    state: IDeliveryInfoState,
    action: PayloadAction<IDeliveryInfoStateSuccess>
  ) => {
    const { delivery_type, postal_service } = action.payload;

    return {
      ...state,
      pending: false,
      postal_service: (postal_service || []).map((item: any) => ({
        value: item.id,
        title: item.title,
      })),
      delivery_type: (delivery_type || []).map((item: any) => ({
        value: item.id,
        title: item.title,
      })),
    };
  },
  getCheckoutDeliveryInfoError: (
    state: IDeliveryInfoState,
    action: PayloadAction<IDeliveryInfoStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
