import CheckIcon from "@mui/icons-material/Check";
import { GradientButton, Bubble } from "@/shared/components";
import {
  RequestButtonWrapp,
  RequestHead,
  RequestHeadTitle,
  RequestHeadSubTitle,
} from "./TimeOffRequest.styled";

interface ITimeOffRequestSuccessProps {
  onClose: () => void;
}

export function TimeOffRequestSuccess(props: ITimeOffRequestSuccessProps) {
  const { onClose } = props;

  return (
    <>
      <RequestHead>
        <Bubble width="100px" height="100px" color="yellowPink">
          <CheckIcon sx={{ fontSize: 60 }} />
        </Bubble>

        <RequestHeadTitle>We&apos;ve got your request!</RequestHeadTitle>
        <RequestHeadSubTitle>
          Your manager will check the inbox and get in touch soon.
        </RequestHeadSubTitle>
      </RequestHead>

      <RequestButtonWrapp>
        <GradientButton fullWidth onClick={onClose}>
          GOT IT
        </GradientButton>
      </RequestButtonWrapp>
    </>
  );
}
