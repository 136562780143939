import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { INewcomersState } from "./reducers";
import { getNewcomers, getNewcomersError, getNewcomersSuccess } from "./slice";
import { getNewcomersRequest } from "./api";

function* getNewcomersSaga(action: PayloadAction<INewcomersState>) {
  try {
    const newcomers: INewcomersState = yield call(
      getNewcomersRequest,
      action.payload
    );

    yield put(getNewcomersSuccess(newcomers));
  } catch (error) {
    yield put(getNewcomersError({ error }));
  }
}

function* NewCommersWatcher() {
  yield takeLatest(getNewcomers.type, getNewcomersSaga);
}

export default NewCommersWatcher;
