import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";

export const getNewcomersRequest = async (params = {}) => {
  const response: AxiosResponse = await apiInstance.get("/newcomers", {
    params,
  });

  return response.data;
};
