import { Skeleton } from "@mui/material";
import {
  LiteratureListItem,
  LiteratureListItemImage,
  LiteratureListItemContent,
  LiteratureListItemAuthors,
  LiteratureListItemName,
} from "./Literature.styled";

export function Loader() {
  return (
    <>
      {Array.from(Array(3).keys()).map((item) => (
        <LiteratureListItem key={item} sx={{ width: "33.3333%" }}>
          <LiteratureListItemImage>
            <Skeleton
              animation="wave"
              variant="circular"
              width={80}
              height={80}
            />
          </LiteratureListItemImage>
          <LiteratureListItemContent>
            <LiteratureListItemAuthors>
              <Skeleton
                animation="wave"
                height={25}
                width="40%"
                sx={{ mt: 1 }}
              />
            </LiteratureListItemAuthors>
            <LiteratureListItemName>
              <Skeleton animation="wave" height={15} width="80%" />
            </LiteratureListItemName>

            <Skeleton animation="wave" height={15} width="80%" />
            <Skeleton animation="wave" height={15} width="80%" />
          </LiteratureListItemContent>
        </LiteratureListItem>
      ))}
    </>
  );
}
