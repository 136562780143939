import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Avatar, useTheme, useMediaQuery } from "@mui/material";
import { Carousel } from "@/shared/components";
import { selectSwitchUser, IMoodContact } from "@/store/redux";
import { MoreInfo } from "./MoreInfo";
import { Loader } from "./Loader";
import {
  ContactsList,
  ContactsListItem,
  ContactsListItemContent,
  ContactsListItemAvatar,
  ContactsListItemName,
  ContactsListItemTitle,
} from "./Contacts.styled";

interface IContactsProps {
  pending: boolean;
  contacts: IMoodContact[];
  getContacts: any;
}

export function Contacts(props: IContactsProps) {
  const { getContacts, contacts, pending } = props;

  const theme = useTheme();
  const { user } = useSelector(selectSwitchUser);

  const hasTablet = useMediaQuery(theme.breakpoints.down("md"));
  const hasMobile = useMediaQuery(theme.breakpoints.down("smd"));

  const slidesToShow = hasMobile ? 1 : hasTablet ? 2 : 3;

  const slides = (contacts || []).map(
    ({
      position,
      name,
      photo_url,
      email,
      phone_number,
      has_viber,
      position_desc,
    }) => (
      <Box
        key={name}
        sx={{ display: "flex !important", justifyContent: "center" }}
      >
        <ContactsListItem>
          <ContactsListItemAvatar>
            <Avatar src={photo_url} sx={{ width: 80, height: 80 }} />
          </ContactsListItemAvatar>
          <ContactsListItemContent>
            <ContactsListItemTitle>{position}</ContactsListItemTitle>
            <ContactsListItemName>{name}</ContactsListItemName>

            <MoreInfo
              email={email}
              desc={position_desc}
              phone_number={phone_number}
              has_viber={has_viber}
            />
          </ContactsListItemContent>
        </ContactsListItem>
      </Box>
    )
  );

  useEffect(() => {
    getContacts();
  }, [user]); //eslint-disable-line

  if (pending) {
    return (
      <ContactsList>
        <Loader />
      </ContactsList>
    );
  }

  return (
    <>
      <Box sx={{ mb: 4, mt: 1 }}>
        <Carousel noArrowsMargin slides={slides} slidesToShow={slidesToShow} />
      </Box>
    </>
  );
}
