import { useState, FormEvent } from "react";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import sanitizeHtml from "sanitize-html";
import { Avatar, Box } from "@mui/material";
import { Dialog, GradientButton } from "@/shared/components";
import {
  selectProfile,
  INewsCommentsItem,
  INewsSubCommentsItem,
} from "@/store/redux";
import { htmlEncode } from "@/utils/helpers";
import { FieldWithCounter } from "./FieldWithCounter";
import {
  CommentBody,
  CommentAuthorName,
  CommentDate,
  ReplyFormWrapper,
  ReplyFormStyled,
  ReplyFormField,
  ReplyFormButton,
} from "./Comments.styled";

interface IReplyCommentProps {
  parentComment: INewsCommentsItem;
  open: boolean;
  onClose: () => void;
  onReply: (k: INewsCommentsItem) => void;
}

interface IReplyCommentFormProps {
  parentComment: INewsCommentsItem;
  onReply: (k: INewsCommentsItem) => void;
}

function ReplyCommentForm(props: IReplyCommentFormProps) {
  const { parentComment, onReply } = props;
  const { profile } = useSelector(selectProfile);

  const [replyText, setReplyText] = useState("");
  const [replyComment, setReplyComment] = useState<INewsCommentsItem>({
    text: "",
    author: {
      id: profile?.id,
      avatar: profile?.avatar || "",
      name: profile?.user_name || "",
    },
    has_like: false,
    likes: 0,
    created_at: "",
  });

  const { id, parent_id, author, created_at, text } = parentComment;
  const formatedDate = format(parseISO(created_at), "dd MMM hh:mm");
  const prettyText = text.replace(/\n/g, "<br />");

  const handleOnCommentChange = (value: string) => {
    setReplyText(value);
  };

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const cleanComment = sanitizeHtml(replyText);

    if (!cleanComment) return;

    const updatedReplyComment: INewsSubCommentsItem = {
      ...replyComment,
      id: +new Date(),
      parent_id: parent_id || id,
      parent_reply_id: id,
      parent_reply_name: author?.name,
      created_at: new Date().toISOString(),
      text: htmlEncode(cleanComment.trim()),
    };

    setReplyComment(updatedReplyComment);

    onReply(updatedReplyComment);

    setReplyText("");
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <ReplyFormWrapper>
        <Box sx={{ display: "flex", gap: 2.5 }}>
          <Avatar src={author?.avatar || ""} sx={{ width: 48, height: 48 }} />
          <div>
            <CommentAuthorName>{author?.name}</CommentAuthorName>
            <CommentDate>{formatedDate}</CommentDate>
            <CommentBody dangerouslySetInnerHTML={{ __html: prettyText }} />
          </div>
        </Box>

        <ReplyFormStyled>
          <Avatar src={profile?.avatar} sx={{ width: 48, height: 48 }} />

          <ReplyFormField>
            <FieldWithCounter
              maxValue={1000}
              value={replyText}
              onChange={handleOnCommentChange}
            />
          </ReplyFormField>
        </ReplyFormStyled>
      </ReplyFormWrapper>

      <ReplyFormButton>
        <GradientButton
          type="submit"
          disabled={!replyText.trim()}
          sx={{ minWidth: 270 }}
        >
          Reply
        </GradientButton>
      </ReplyFormButton>
    </form>
  );
}

export function ReplyCommentDialog(props: IReplyCommentProps) {
  const { parentComment, open, onClose, onReply } = props;

  return (
    <Dialog
      top
      fullWidth
      maxWidth="smd"
      title="ADD COMMENT"
      open={open}
      dialogContent={
        <ReplyCommentForm parentComment={parentComment} onReply={onReply} />
      }
      onClose={onClose}
    />
  );
}
