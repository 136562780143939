import { useSelector } from "react-redux";
import { Title, Contacts } from "@/shared/components";
import { MoodContactsActions, selectMoodContacts } from "@/store/redux";
import { useActions } from "@/utils/redux";

export function MoodMonitoringContacts() {
  const { pending, contacts } = useSelector(selectMoodContacts);
  const { getMoodContacts } =
    useActions<typeof MoodContactsActions>(MoodContactsActions);

  return (
    <>
      <Title>My Contacts</Title>

      <Contacts
        pending={pending}
        contacts={contacts}
        getContacts={getMoodContacts}
      />
    </>
  );
}
