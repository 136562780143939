import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { NewsContent, Comments, NewsArticleFilter } from "@/components";
import { Title, FeedItem } from "@/shared/components";
import { useActions } from "@/utils/redux";
import {
  NewsActions,
  NewsArticleActions,
  NewsArticleLikeActions,
  selectNewsArticle,
  selectProfileLang,
  selectSwitchUser,
} from "@/store/redux";

const langsMap: { en: string; ua: string; [k: string]: string } = {
  ua: "Ukrainian",
  en: "English",
};

export function NewsArticleContent() {
  const commentsRef = useRef<HTMLInputElement | null>(null);
  const { id } = useParams();
  const { state } = useLocation();

  const profileLang = useSelector(selectProfileLang);
  const { user } = useSelector(selectSwitchUser);
  const { article, pending } = useSelector(selectNewsArticle);
  const { getNewsArticle } =
    useActions<typeof NewsArticleActions>(NewsArticleActions);
  const { postNewsArticleLike } = useActions<typeof NewsArticleLikeActions>(
    NewsArticleLikeActions
  );
  const { updateNews } = useActions<typeof NewsActions>(NewsActions);

  const [lang, setLang] = useState<string>(profileLang || "");

  const langs = (article?.langs || []).map((lang) => ({
    value: lang,
    title: langsMap[lang],
  }));

  const scrollToComments = () => {
    if (commentsRef && commentsRef.current) {
      commentsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleOnFilterChange = (plang: string) => {
    setLang(plang);
    getNewsArticle({ id: article.id, plang });
  };

  const handleOnLike = (id: number, likes: number, hasLike: boolean) => {
    postNewsArticleLike(article.id);

    updateNews({ newsId: id, has_like: hasLike, likes });
  };

  useEffect(() => {
    if (id) getNewsArticle({ id, plang: lang });
  }, [id, user]); //eslint-disable-line

  useEffect(() => {
    if (profileLang) setLang(profileLang);
  }, [profileLang]);

  useEffect(() => {
    if (article && state?.toComments) {
      setTimeout(scrollToComments, 1000);
    }
  }, [article, state]);

  return (
    <NewsContent>
      <Title fontSize="60px" sx={{ lineHeight: 1 }}>
        BRIGHT NEWS
      </Title>

      <NewsArticleFilter
        loading={pending}
        langs={langs}
        value={lang}
        onChange={handleOnFilterChange}
        sx={{ mb: 2, mt: 1 }}
      />

      <FeedItem
        isNews
        isArticle
        {...article}
        loading={pending}
        onComment={scrollToComments}
        onLike={handleOnLike}
      />

      <Box ref={commentsRef} sx={{ scrollMarginTop: 125 }}>
        <Comments />
      </Box>
    </NewsContent>
  );
}
