import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { INewsArticleLikeState } from "./reducers";

export const newsArticleLikeSelector = (
  state: RootState
): INewsArticleLikeState => state.newsArticleLike;

export const selectNewsArticleLike = createSelector(
  newsArticleLikeSelector,
  (newsArticleLikeState: INewsArticleLikeState) => newsArticleLikeState.like
);
