import { useTheme, useMediaQuery } from "@mui/material";
import { Title, Carousel } from "@/shared/components";
import {
  PointsWrapper,
  PointWrapper,
  PointDesc,
  RulesWrapper,
  RulesDesc,
} from "./MarketPlace.styled";

interface IPoint {
  title: string;
  desc: string;
}

const points: IPoint[] = [
  {
    title: "CHOOSE THE ITEMS YOU LIKE",
    desc: "There is no limit on the number of items in a single cart, only a limited quantity of branded items",
  },
  {
    title: "DON'T WORRY ABOUT PAYMENTS",
    desc: "There is no need to pay when ordering items at the marketplace. We will deduct the sum for your order from your monthly compensation",
  },
  {
    title: "Confirm the order",
    desc: "Enter delivery details, add a donation (optional), and wait for items to arrive within a week or two. The recipient pays for delivery at the point of collection",
  },
];
const slides = points.map(({ title, desc }: IPoint, idx) => (
  <Point key={`point-${idx}`} title={title} desc={desc} />
));

export function MarketPlaceRules() {
  return (
    <RulesWrapper>
      <Title>HOW DOES IT WORK</Title>

      <RulesDesc>
        <p>
          Drops will happen monthly for 4 months, and all collected funds will
          go where they are needed most. Choose any item you like and purchase
          it. All proceeds from your purchases will be donated to the Bright
          Volunteers fund, which aims to purchase useful items for the Armed
          Forces of Ukraine.
        </p>
        <p>
          You can join the{" "}
          <a
            href="https://brightgrove1.sharepoint.com/sites/Brightgrove_org-wide/SitePages/How-to-join-Bright-Volunteers-and-Bright-Defense(2).aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bright Volunteers and Bright Defence
          </a>{" "}
          initiatives, and contribute by donating the pay of one workday for the
          needs of the defenders or compensating Brights serving in the Armed
          Forces.
        </p>
        <p>
          You can also donate to Bright Volunteers without setting up a "
          <a
            href="https://brightgrove1.sharepoint.com/sites/Brightgrove_org-wide/SitePages/How-to-join-Bright-Volunteers-and-Bright-Defense(2).aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            subscription
          </a>
          ." Simply transfer any amount you choose{" "}
          <a
            href="https://send.monobank.ua/jar/5u8ZTJtU4s"
            target="_blank"
            rel="noopener noreferrer"
          >
            into the jar
          </a>
          . Together we will win!
        </p>
      </RulesDesc>

      <Points />
    </RulesWrapper>
  );
}

function Points() {
  const theme = useTheme();
  const hasTablet = useMediaQuery(theme.breakpoints.down("md"));
  const hasMobile = useMediaQuery(theme.breakpoints.down("smd"));

  const slidesToShow = hasMobile ? 1 : hasTablet ? 2 : 3;

  return (
    <PointsWrapper>
      <Carousel noArrowsMargin slides={slides} slidesToShow={slidesToShow} />
    </PointsWrapper>
  );
}

function Point(props: IPoint) {
  const { title, desc } = props;

  return (
    <PointWrapper>
      <Title fontSize="26px" fontWeight="500">
        {title}
      </Title>
      <PointDesc>{desc}</PointDesc>
    </PointWrapper>
  );
}
