import { MouseEvent } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { InputAdornment, Box } from "@mui/material";
import { Title } from "@/shared/components";
import {
  DonateWrapper,
  DonateField,
  DonateButtonGroup,
  DonateButton,
} from "./Total.styled";

const donations = [0, 9, 19, 29];

export function Donate() {
  const { control, watch, setValue } = useFormContext();

  const donate = watch("donate");

  const handleOnDonateChange = (
    event: MouseEvent<HTMLElement>,
    value: number
  ) => {
    setValue("donate", value || 0);
  };

  const handleOnManualDonateChange = (e: any) => {
    const value = e.target.value ? parseInt(e.target.value) : 0;

    if (value > 999 || value < 0 || isNaN(value)) return;

    setValue("donate", value);
  };

  return (
    <Box sx={{ mt: 2, mb: 4 }}>
      <Title sx={{ mb: 1 }}>ADDITIONAL DONATION</Title>

      <DonateWrapper>
        <Controller
          name="donate"
          control={control}
          defaultValue={0}
          render={({ field }) => (
            <DonateButtonGroup
              {...field}
              exclusive
              onChange={handleOnDonateChange}
            >
              {donations.map((donate) => (
                <DonateButton key={donate} value={donate}>
                  ${donate}
                </DonateButton>
              ))}
            </DonateButtonGroup>
          )}
        />

        <DonateField
          value={donate || ""}
          placeholder="Custom"
          onChange={handleOnManualDonateChange}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          inputProps={{ pattern: "\\d*", inputMode: "numeric" }}
        />
      </DonateWrapper>
    </Box>
  );
}
