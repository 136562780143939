import { ReactNode, useState, SyntheticEvent } from "react";
import { Box, Tab, Tabs as MuiTabs, useTheme, SxProps } from "@mui/material";

interface ITabsProps {
  titles: any[];
  contents: ReactNode[];
  sticky?: boolean;
  sx?: SxProps;
}

interface ITabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      pt={2}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index ? children : null}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export function Tabs(props: ITabsProps) {
  const { sticky, titles, contents, sx } = props;
  const { palette } = useTheme();
  const [value, setValue] = useState(0);

  const isSticky = sticky
    ? {
        position: "sticky",
        top: "100px",
        background:
          palette.mode === "dark" ? palette.primary.main : palette.common.white,
        zIndex: 1,
      }
    : {};

  const handleOnChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={sx}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", ...isSticky }}>
        <MuiTabs
          indicatorColor="secondary"
          value={value}
          onChange={handleOnChange}
        >
          {titles.map((title: string, idx: number) => (
            <Tab
              key={`title-${idx}`}
              label={title}
              sx={{ flexDirection: "row" }}
              {...a11yProps(idx)}
            />
          ))}
        </MuiTabs>
      </Box>

      {contents.map((item: ReactNode, idx: number) => (
        <TabPanel key={`content-${idx}`} value={value} index={idx}>
          {item}
        </TabPanel>
      ))}
    </Box>
  );
}
