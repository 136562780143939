import { useSelector } from "react-redux";
import { format } from "date-fns";
import { selectTimesheetActivity } from "@/store/redux";
import { timeOffColors } from "@/theme";
import { getDayOff } from "../helpers";
import { IProjectsHoursState } from "../interfaces";
import {
  ProjectItem,
  ProjectName,
  ProjectTitle,
  Dot,
  Col,
  ColFullWidth,
} from "../Timesheet.styled";

interface IProjectsProps {
  date: string;
  reporting: IProjectsHoursState[];
  timeButton: (day: any, reporting: any) => JSX.Element;
}

export function Projects(props: IProjectsProps) {
  const { date, reporting, timeButton: TimeButton } = props;
  const { list } = useSelector(selectTimesheetActivity);

  const getTimeOff = (day: Date) => {
    const dayOff = getDayOff({
      list,
      day,
    });

    const bg = !!dayOff?.type
      ? timeOffColors[dayOff?.type]?.color
      : "transparent";

    return { bg, hasTimeOff: !!dayOff?.type };
  };

  return (
    <>
      {reporting.map(({ id, name, reporting }) => {
        const reportingObj = Object.assign(
          {},
          ...reporting.map((item) => ({
            [format(item.day, "dd/MM/yyyy")]: { ...item },
          }))
        );

        const projectReporting = {
          ...(reportingObj[date]?.reporting || {}),
          project: { id, name },
          type: "project",
        };

        const day = new Date(reportingObj[date]?.day || 0);

        return (
          <ProjectItem
            key={id}
            sx={{
              background: getTimeOff(day)?.bg,
            }}
          >
            <Col>
              <ProjectName
                sx={{
                  marginLeft: (theme) =>
                    theme.spacing(getTimeOff(day)?.hasTimeOff ? 1 : 0),
                }}
              >
                <Dot $hasOutage={false} />
                <ProjectTitle>{name}</ProjectTitle>
              </ProjectName>
            </Col>
            <Col>
              <TimeButton
                day={reportingObj[date]?.day}
                reporting={projectReporting}
                hasTimeOff={getTimeOff(day)?.hasTimeOff}
              />
            </Col>
            <ColFullWidth>{reportingObj[date]?.reporting?.task}</ColFullWidth>
          </ProjectItem>
        );
      })}
    </>
  );
}
