import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { ITimeOffListState } from "./reducers";

export const timeOffListSelector = (state: RootState): ITimeOffListState =>
  state.timeOffList;

export const selectTimeOffList = createSelector(
  timeOffListSelector,
  (timeOffListState: ITimeOffListState) => ({ ...timeOffListState })
);
