import { styled } from "@mui/material/styles";

export const LimitMessageWrapper = styled("div")(({ theme }) => ({
  background: theme.palette.pinkViolet.main,
  padding: theme.spacing(0.25),
  margin: theme.spacing(2, 0, 3),
}));

export const MessageBg = styled("div")(({ theme }) => ({
  background: theme.modeColors({
    dark: theme.palette.primary.main,
    light: theme.palette.common.white,
  }),
  padding: theme.spacing(2, 3),
}));

export const ErrorMessageWrapper = styled("div")(({ theme }) => ({
  background: theme.palette.yellowPink.main,
  padding: theme.spacing(0.25),
  margin: theme.spacing(2, 0, 3),
}));
