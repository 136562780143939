import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { CartActions } from "@/store/redux";
import { ICheckoutStateSuccess, ICheckoutStatePending } from "./reducers";
import {
  postCheckout,
  postCheckoutError,
  postCheckoutSuccess,
  resetCheckout,
  resetCheckoutError,
  resetCheckoutSuccess,
} from "./slice";
import { postCheckoutRequest } from "./api";

function* postCheckoutSaga(action: PayloadAction<ICheckoutStatePending>) {
  try {
    const message: ICheckoutStateSuccess = yield call(
      postCheckoutRequest,
      action.payload
    );

    yield put(postCheckoutSuccess(message));

    yield put(CartActions.getCart());
  } catch (error) {
    yield put(postCheckoutError({ error }));
  }
}

function* resetCheckoutSaga() {
  try {
    yield put(resetCheckoutSuccess());
  } catch (error) {
    yield put(resetCheckoutError());
  }
}

function* PostCheckoutWatcher() {
  yield takeLatest(postCheckout.type, postCheckoutSaga);
  yield takeLatest(resetCheckout.type, resetCheckoutSaga);
}

export default PostCheckoutWatcher;
