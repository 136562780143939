import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { selectCart, selectCheckout } from "@/store/redux";
import { Title, GradientButton } from "@/shared/components";
import { MARKETPLACE } from "@/routes/consts";
import {
  LimitMessageWrapper,
  ErrorMessageWrapper,
  MessageBg,
} from "./CheckoutHead.styled";

export function CheckoutHead() {
  const navigate = useNavigate();
  const { products, pending } = useSelector(selectCart);
  const { message } = useSelector(selectCheckout);

  const handleOnMarketplace = () => {
    navigate(MARKETPLACE);
  };

  if (pending && !products?.length) {
    return (
      <>
        <Skeleton height={35} sx={{ width: "75%", mb: 2 }} />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return (
    <>
      <Title fontSize="60px" sx={{ lineHeight: 1 }}>
        Checkout
      </Title>

      {products?.length ? (
        <>
          <LimitMessageWrapper>
            <MessageBg>
              As our stock is limited, items in your cart are reserved for{" "}
              <b>6 hours</b>. Be sure to finish checkout before they are gone.
            </MessageBg>
          </LimitMessageWrapper>

          {message ? (
            <ErrorMessageWrapper>
              <MessageBg>{message}</MessageBg>
            </ErrorMessageWrapper>
          ) : null}
        </>
      ) : (
        <>
          <LimitMessageWrapper>
            <MessageBg>
              We have a limited stock of items. Please add some bright merch
              here before we run out of it :)
            </MessageBg>
          </LimitMessageWrapper>

          <GradientButton
            sx={{ minWidth: 270, width: { xs: "100%", sm: "auto" } }}
            onClick={handleOnMarketplace}
          >
            VISIT BRIGHT MARKETPLACE
          </GradientButton>
        </>
      )}
    </>
  );
}
