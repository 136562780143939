import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function MoodIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 61 61" fill="none" {...props}>
      <path
        d="M30.5 60.7305C46.8477 60.7305 60.3828 47.166 60.3828 30.8477C60.3828 14.5 46.8184 0.964844 30.4707 0.964844C14.1523 0.964844 0.617188 14.5 0.617188 30.8477C0.617188 47.166 14.1816 60.7305 30.5 60.7305ZM23.2344 17.4883C26.4863 17.4883 28.9766 19.334 30.5 22.0879C31.9941 19.334 34.543 17.4883 37.7656 17.4883C42.8633 17.4883 46.584 21.3555 46.584 26.7168C46.584 34.627 38.1465 41.8633 31.9941 45.877C31.5254 46.2285 30.9395 46.6094 30.5586 46.6094C30.1777 46.6094 29.5039 46.1992 28.9766 45.877C22.7949 41.9512 14.416 34.627 14.416 26.7168C14.416 21.3555 18.1074 17.4883 23.2344 17.4883Z"
        fill="black"
      />
    </SvgIcon>
  );
}
