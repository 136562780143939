import { useSelector } from "react-redux";
import { ToolbarProps } from "react-big-calendar";
import { format } from "date-fns";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { selectTimeOffCalendar } from "@/store/redux";
import {
  CalendarToolbarMonth,
  CalendarToolbarDate,
  CalendarToolbarWrapp,
  CalendarToolbarButton,
  CalendarButtonsWrapp,
  CalendarToolbarButtonGroup,
} from "./TimeOffCalendar.styled";

export function CalendarToolbar(props: ToolbarProps) {
  const { date, onNavigate } = props;

  const { pending } = useSelector(selectTimeOffCalendar);

  const month = format(date, "MMMM");
  const year = format(date, "yyyy");

  const goToBack = () => {
    onNavigate("PREV");
  };
  const goToNext = () => {
    onNavigate("NEXT");
  };

  const goToToday = () => {
    onNavigate("TODAY");
  };

  return (
    <CalendarToolbarWrapp>
      <CalendarButtonsWrapp>
        <CalendarToolbarButton
          disabled={pending}
          size="large"
          variant="outlined"
          color="primary"
          onClick={goToToday}
        >
          Today
        </CalendarToolbarButton>

        <CalendarToolbarButtonGroup>
          <CalendarToolbarButton
            disabled={pending}
            size="large"
            variant="outlined"
            color="primary"
            onClick={goToBack}
          >
            <ArrowBackIosNewIcon />
          </CalendarToolbarButton>
          <CalendarToolbarButton
            disabled={pending}
            size="large"
            variant="outlined"
            color="primary"
            onClick={goToNext}
          >
            <ArrowForwardIosIcon />
          </CalendarToolbarButton>
        </CalendarToolbarButtonGroup>
      </CalendarButtonsWrapp>
      <CalendarToolbarDate>
        <CalendarToolbarMonth>{month}</CalendarToolbarMonth> {year}
      </CalendarToolbarDate>
    </CalendarToolbarWrapp>
  );
}
