import { PayloadAction } from "@reduxjs/toolkit";

export interface IEmployeeCalendarDate {
  type: "event"; // constant
  event_type:
    | "paid_time_off"
    | "health_time_off"
    | "birthday"
    | "anniversary"
    | "holiday";
  description: string;
  start_date: string;
  end_date: string;
  country_code: string;
}

export interface IEmployeeCalendarState {
  pending: boolean;
  error: any;
  types: string;
  country: number;
  events: IEmployeeCalendarDate[];
}

export interface IEmployeeCalendarStatePending {
  start_date: string;
  end_date: string;
  types?: string;
  country?: number;
  team?: number;
}

export interface IEmployeeCalendarStateSuccess {
  events: IEmployeeCalendarDate[];
}

export interface IEmployeeCalendarStateError {
  error: any;
}

export const initialState: IEmployeeCalendarState = {
  pending: false,
  error: null,
  types: "",
  country: -1,
  events: [],
};

export const reducers = {
  getEmployeeCalendar: (
    state: IEmployeeCalendarState,
    action: PayloadAction<IEmployeeCalendarStatePending>
  ) => {
    return {
      ...state,
      events: [],
      pending: true,
      error: "",
    };
  },
  getEmployeeCalendarSuccess: (
    state: IEmployeeCalendarState,
    action: PayloadAction<IEmployeeCalendarStateSuccess>
  ) => {
    return {
      ...state,
      pending: false,
      events: action?.payload?.events || [],
    };
  },
  getEmployeeCalendarError: (
    state: IEmployeeCalendarState,
    action: PayloadAction<IEmployeeCalendarStateError>
  ) => ({
    ...state,
    pending: false,
    error: action.payload.error,
  }),
};
