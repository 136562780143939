import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { calendarStyles, EventCalendar } from "@/shared/components";

export const EmployeeCalendarWrapper = styled(EventCalendar)(({ theme }) => ({
  ...calendarStyles(theme),

  "& .rbc-day-bg": {
    borderLeft: "1px solid #ddd",

    "&:last-of-type": {
      borderRight: "1px solid #ddd",
    },
  },
}));

export const EventWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.25),
}));

export const EmojiWrapper = styled("span")(({ theme }) => ({
  fontSize: "1.25rem",
  lineHeight: 1,
  margin: theme.spacing(0.5, 0.5, 0, 0),
}));

export const EventDescWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),

  "& + &": {
    marginTop: theme.spacing(1),
  },
}));

export const EventDesc = styled("div")(({ theme }) => ({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export const EventTitle = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const EventTitleDate = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: "0.75rem",
}));

export const FiltersWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

export const MobileListView = styled("div")(({ theme }) => ({
  margin: theme.spacing(6, 0),
}));

export const MobileListViewItem = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  borderTop: "1px solid #e6e6e6",
}));
