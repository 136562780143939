import type { orderField, orderDirection } from "./SurveysList";
import { PropsWithChildren } from "react";
import { Grid } from "@mui/material";
import { useDevice } from "@/utils/hooks";
import { SurveysListHeadItem } from "./SurveysListHeadItem";
import { ListHead } from "./SurveysList.styled";

interface ISurveysListHeadProps {
  sortType: orderDirection;
  orderByField: orderField;
  onSort: (name: orderField) => void;
  hasFinished?: boolean;
}

export function SurveysListHead(props: ISurveysListHeadProps) {
  const { hasFinished, sortType, orderByField, onSort } = props;

  return (
    <Head>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <SurveysListHeadItem
            name="name"
            title="Name"
            sortType={sortType}
            orderByField={orderByField}
            onSort={onSort}
          />
        </Grid>
        <Grid item xs={hasFinished ? 3 : 7}>
          <SurveysListHeadItem
            name="expire_date"
            title="Expiry Date"
            sortType={sortType}
            orderByField={orderByField}
            onSort={onSort}
          />
        </Grid>

        {hasFinished ? (
          <Grid item xs={4}>
            <SurveysListHeadItem
              name="status"
              title="Status"
              sortType={sortType}
              orderByField={orderByField}
              onSort={onSort}
            />
          </Grid>
        ) : null}
      </Grid>
    </Head>
  );
}

function Head(props: PropsWithChildren) {
  const isMobile = useDevice("down", "md");

  return isMobile ? null : <ListHead>{props.children}</ListHead>;
}
