import { styled } from "@mui/material/styles";

export const NewcomersWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),
}));

export const NewcomerWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  width: "100%",
  gap: theme.spacing(2),
}));

export const NewcomerAvatar = styled("div")(({ theme }) => ({}));
export const NewcomerContent = styled("div")(({ theme }) => ({
  flex: 1,
}));

export const NewcomerPosition = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 500,
}));

export const NewcomerName = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 900,
}));

export const NewcomerAction = styled("a")(({ theme }) => ({
  fontWeight: 700,
  display: "inline-flex",
  alignItems: "center",
  marginTop: theme.spacing(0.25),
  textDecoration: "none",
  color:
    theme.palette.mode === "dark"
      ? theme.palette.secondary.main
      : theme.palette.common.black,
  gap: theme.spacing(0.5),
}));
