import { PayloadAction } from "@reduxjs/toolkit";

export interface INotifierState {
  nextNotification: number;
  notifications: Array<any>;
}

type NotifierStateRemove = string | number;

type notificationProps = {
  variant: "default" | "info" | "warning" | "error" | "success";
  message: string;
  options?: any;
};

interface INotifierStateClose {
  key: string;
  dismissAll: boolean;
}

export const initialState: INotifierState = {
  nextNotification: -1, // used for notifications keys
  notifications: [], // contains the list of notifications
};

export const reducers = {
  addNotification: (
    state: INotifierState,
    action: PayloadAction<notificationProps>
  ) => {
    return {
      ...state,
      notifications: [
        ...state.notifications,
        {
          ...(action?.payload || {}),
          key: new Date().getTime() + Math.random(),
        },
      ],
    };
  },

  removeNotification: (
    state: INotifierState,
    action: PayloadAction<NotifierStateRemove>
  ) => {
    return {
      ...state,
      notifications: state.notifications.filter(
        (notification) => notification.key !== action?.payload
      ),
    };
  },

  closeNotification: (
    state: INotifierState,
    action: PayloadAction<INotifierStateClose>
  ) => {
    return {
      ...state,
      notifications: state.notifications.map((notification) =>
        action?.payload?.dismissAll || notification.key === action?.payload?.key
          ? { ...notification, dismissed: true }
          : { ...notification }
      ),
    };
  },
};
