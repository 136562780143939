import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { IUpdateProfileStatePending } from "./reducers";

export const getProfileRequest = async () => {
  const response: AxiosResponse = await apiInstance.get("/users/me");

  return response.data;
};

export const updateProfileRequest = async (
  params: IUpdateProfileStatePending = {}
) => {
  const response: AxiosResponse = await apiInstance.put("/users/me", params);

  return response.data;
};
