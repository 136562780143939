import { styled } from "@mui/material/styles";
import { Filters } from "@/shared/components";

export const LoadMoreWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  margin: theme.spacing(5, 0),

  [theme.breakpoints.down("sm")]: {
    "& button": {
      width: "100%",
    },
  },
}));

export const NewsFiltersWrapp = styled(Filters)(({ theme }) => ({
  background: theme.palette.common.white,
  margin: theme.spacing(2.5, 0, 5),
  padding: theme.spacing(1, 0, 1),
  gap: theme.spacing(1.5),
  position: "sticky",
  zIndex: 1,
  top: 101,

  [theme.breakpoints.down("md")]: {
    position: "static",
  },
}));

export const UpToDate = styled("div")(({ theme }) => ({
  position: "relative",
  textAlign: "center",
  margin: theme.spacing(6, 0),

  "&:before": {
    content: '""',
    position: "absolute",
    left: "17%",
    top: "50%",
    height: "1px",
    width: "65%",
    background: theme.palette.darkGrey.main,

    [theme.breakpoints.down("sm")]: {
      width: "80%",
      left: "10%",
    },
  },
}));

export const UpToDateTitle = styled("span")(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  padding: theme.spacing(0, 1.5),
  background: theme.modeColors({
    light: theme.palette.common.white,
    dark: theme.palette.primary.main,
  }),
  fontSize: "18px",
  fontWeight: 900,
  textTransform: "uppercase",
  color: theme.palette.darkGrey.main,
}));
