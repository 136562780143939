import { useEffect, useContext, PropsWithChildren } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { BreadcrumbsContext } from "@/context/Breadcrumbs";
import { loginRequest } from "@/config/msalConfig";
import { MsalError } from "@/components";

interface IPageProps extends PropsWithChildren {
  title?: string;
}

export function Page({ children, title }: IPageProps) {
  const { setTitle: setBreadcrumbsTitle } = useContext(BreadcrumbsContext);
  const authRequest = {
    ...loginRequest,
  };

  useEffect(() => {
    setBreadcrumbsTitle(title);

    return () => {
      setBreadcrumbsTitle("");
    };
  }, [title, setBreadcrumbsTitle]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={MsalError}
      loadingComponent={() => <>Loading ...</>}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
}
