import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { INewsCommentStateSuccess } from "./reducers";
import {
  deleteNewsComment,
  deleteNewsCommentError,
  deleteNewsCommentSuccess,
} from "./slice";
import { deleteNewsCommentsRequest } from "./api";

function* deleteNewsCommentSaga(action: PayloadAction<number>) {
  try {
    const id: INewsCommentStateSuccess = yield call(
      deleteNewsCommentsRequest,
      action.payload
    );

    yield put(deleteNewsCommentSuccess(id));
  } catch (error) {
    yield put(deleteNewsCommentError({ error }));
  }
}

function* deleteCommentWatcher() {
  yield takeLatest(deleteNewsComment.type, deleteNewsCommentSaga);
}

export default deleteCommentWatcher;
