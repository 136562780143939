import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Grid, Box } from "@mui/material";
import { selectProfile } from "@/store/redux";
import { Logo } from "@/shared/components";
import { UserMenu } from "./components/UserMenu";
import { UserInfo } from "./components/UserInfo";

export function Header() {
  const { profile } = useSelector(selectProfile);
  const navigate = useNavigate();
  const handleOnLogoClick = () => {
    navigate("/");
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            {profile?.id ? <UserMenu /> : null}
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ cursor: "pointer" }} onClick={handleOnLogoClick}>
              <Logo />
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <UserInfo />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
