import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "ReferralsCandidates",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getReferralsCandidates,
  getReferralsCandidatesError,
  getReferralsCandidatesSuccess,
} = slice.actions;

reducerRegistry.register("referralsCandidates", reducer);
