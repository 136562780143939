import { styled } from "@mui/material/styles";
import { IconButton, Avatar, Link } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Title } from "@/shared/components";

export const Arrow = styled(ArrowForwardIosIcon)(({ theme }) => ({
  fontSize: 16,
  opacity: 0,
  transition: "all 0.25s ease",
}));

export const VacanciesWrapper = styled("div")(({ theme }) => ({
  flex: 1,
  marginBottom: theme.spacing(3),
}));

export const VacancyItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.75),
  cursor: "pointer",

  "& + &": {
    marginTop: theme.spacing(1),
    borderTop: "1px solid #E6E6E6",
    paddingTop: theme.spacing(2),
  },

  "&:hover": {
    [Arrow as any]: {
      opacity: 1,
    },
  },
}));

export const VacancyLocation = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: "0.875rem",
  fontWeight: 500,
}));

export const VacancyTitle = styled("div")(({ theme }) => ({
  fontSize: "1.125rem",
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const VacancySubtitle = styled("div")(({ theme }) => ({
  color: theme.palette.darkGrey.main,
  fontSize: "0.875rem",
  fontWeight: 500,
}));

export const VacancyDesc = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
  padding: theme.spacing(4, 3, 4, 4),
  backgroundImage: theme.palette.yellowPink.main,
  color: theme.modeColors({ dark: "#000", light: "#000" }),

  [theme.breakpoints.down("smd")]: {
    padding: theme.spacing(3),
    alignItems: "start",
  },
}));

export const DrawerHeaderContent = styled("div")(({ theme }) => ({
  flex: 1,
}));

export const DrawerHeaderTitle = styled(Title)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
  fontWeight: 500,
  fontSize: "2.25rem",
}));

export const DrawerHeaderSubtitle = styled("div")(({ theme }) => ({
  fontWeight: 900,
}));

export const DrawerClose = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
}));

export const DrawerContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(4),

  "& ul": {
    margin: theme.spacing(0.25, 0),
    padding: theme.spacing(0, 0, 0, 2.5),
  },

  [theme.breakpoints.down("smd")]: {
    padding: theme.spacing(3),
  },
}));

export const VacancyContentLabel = styled("span")(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 900,
}));

export const VacancyContentSection = styled("div")(({ theme }) => ({
  "& + &": {
    marginTop: theme.spacing(3),
  },
}));

export const NoVacanciesWrapper = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(2),
  borderBottom: "1px solid #e6e6e6",
}));

export const NoVacanciesTitle = styled("div")(({ theme }) => ({
  fontSize: "1.125rem",
  fontWeight: 700,
  marginBottom: theme.spacing(0.5),
}));

export const NoVacanciesSubtitle = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
}));

export const RecruiterWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginTop: theme.spacing(0.5),
}));

export const RecruiterPhoto = styled(Avatar)(({ theme }) => ({
  width: 56,
  height: 56,

  [theme.breakpoints.down("sm")]: {
    width: 48,
    height: 48,
  },
}));

export const RecruiterContent = styled("div")(({ theme }) => ({
  flex: 1,
  marginTop: theme.spacing(0.5),
}));

export const RecruiterName = styled("div")(({ theme }) => ({
  fontWeight: 500,
}));

export const RecruitetChat = styled(Link)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  fontSize: "0.875rem",
  textDecoration: "none",
}));

export const ButtonsWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },

  "& button": {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
