import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/store/configureStore";
import { IPostTimeOffState } from "./reducers";

export const postTimeOffSelector = (state: RootState): IPostTimeOffState =>
  state.postTimeOff;

export const selectPostTimeOff = createSelector(
  postTimeOffSelector,
  (postTimeOffState: IPostTimeOffState) => ({ ...postTimeOffState })
);
