import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ITimesheetActivityStateSuccess,
  ITimesheetActivityStatePending,
  IHoliday,
} from "./reducers";
import {
  getTimesheetActivity,
  getTimesheetActivityError,
  getTimesheetActivitySuccess,
} from "./slice";
import {
  getTimesheetActivityRequest,
  getTimeOffsRequest,
  getHolidaysRequest,
} from "./api";
import { ITimeOffsSuccess } from "./interfaces";
import {
  getTimeOffsParams,
  getTimeoffs,
  getTimesheet,
  getHolidays,
} from "./helpers";

function* getTimesheetActivitySaga(
  action: PayloadAction<ITimesheetActivityStatePending>
) {
  try {
    const timesheetActivity: ITimesheetActivityStateSuccess = yield call(
      getTimesheetActivityRequest,
      action.payload
    );
    const timeoffs: ITimeOffsSuccess = yield call(
      getTimeOffsRequest,
      getTimeOffsParams(action.payload)
    );
    const holidaysEvents: { holidays: IHoliday[] } = yield call(
      getHolidaysRequest,
      action.payload
    );

    const computedData = {
      ...(timesheetActivity || {}),
      list: [
        ...getTimesheet(timesheetActivity?.list || []),
        ...getTimeoffs(timeoffs?.dates || []),
      ],
      holidays: [...getHolidays(holidaysEvents?.holidays || [])],
    };

    yield put(getTimesheetActivitySuccess(computedData as any));
  } catch (error) {
    yield put(getTimesheetActivityError({ error }));
  }
}

function* TimesheetActivityWatcher() {
  yield takeLatest(getTimesheetActivity.type, getTimesheetActivitySaga);
}

export default TimesheetActivityWatcher;
