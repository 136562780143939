import { createSlice } from "@reduxjs/toolkit";
import { reducerRegistry } from "@/utils/redux";
import { initialState, reducers } from "./reducers";

const slice = createSlice({
  name: "NewsLocations",
  initialState,
  reducers,
});

export const { actions, reducer } = slice;
export const {
  getNewsLocations,
  getNewsLocationsError,
  getNewsLocationsSuccess,
} = slice.actions;

reducerRegistry.register("newsLocations", reducer);
