import { styled } from "@mui/material/styles";
import { Logo } from "@/shared/components";

export const SplashScreenWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  background: theme.palette.primary.main,
  paddingBottom: "5%",
}));

export const SplashScreenLogo = styled(Logo)(({ theme }) => ({
  width: "35%",
  minWidth: "240px",
  maxWidth: "510px",
  height: "auto",
}));
