import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Avatar, Skeleton } from "@mui/material";
import { IconWithCount } from "@/shared/components";
import { LikeIcon, ArticleIcon } from "@/shared/icons";
import {
  IPopularAuthor,
  PopularAuthorsActions,
  selectPopularAuthors,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import {
  PopularAuthorAvatar,
  PopularAuthorContent,
  PopularAuthorName,
  PopularAuthorWrapper,
  PopularAuthorsWrapper,
  PopularAuthorActions,
} from "./PopularAuthors.styled";

export function PopularAuthors() {
  const { authors, pending } = useSelector(selectPopularAuthors);
  const { getPopularAuthors } = useActions<typeof PopularAuthorsActions>(
    PopularAuthorsActions
  );

  useEffect(() => {
    getPopularAuthors();
  }, []); //eslint-disable-line

  if (pending) {
    return <Loading />;
  }

  return (
    <PopularAuthorsWrapper>
      {(authors || []).map((item: IPopularAuthor) => (
        <Author key={`author-${item.name}`} {...item} />
      ))}
    </PopularAuthorsWrapper>
  );
}

export function Author(props: IPopularAuthor) {
  const { avatar, name, likes, posts } = props;

  return (
    <PopularAuthorWrapper>
      <PopularAuthorAvatar>
        <Avatar src={avatar} sx={{ width: 48, height: 48 }} />
      </PopularAuthorAvatar>
      <PopularAuthorContent>
        <PopularAuthorName>{name}</PopularAuthorName>
        <PopularAuthorActions>
          <IconWithCount small icon={<LikeIcon />} value={likes} />
          <IconWithCount small icon={<ArticleIcon />} value={posts} />
        </PopularAuthorActions>
      </PopularAuthorContent>
    </PopularAuthorWrapper>
  );
}

function Loading() {
  return (
    <PopularAuthorsWrapper>
      {Array.from(Array(3).keys()).map((item) => (
        <PopularAuthorWrapper key={item}>
          <PopularAuthorAvatar>
            <Skeleton variant="circular" sx={{ width: 48, height: 48 }} />
          </PopularAuthorAvatar>
          <PopularAuthorContent>
            <PopularAuthorName>
              <Skeleton height={12} width="100%" />
              <Skeleton height={12} width="65%" sx={{ mt: 0.5 }} />
            </PopularAuthorName>
            <PopularAuthorActions>
              <Skeleton height={14} width="45px" />
              <Skeleton height={14} width="45px" />
            </PopularAuthorActions>
          </PopularAuthorContent>
        </PopularAuthorWrapper>
      ))}
    </PopularAuthorsWrapper>
  );
}
