import { styled } from "@mui/material/styles";
import { Button, ButtonGroup } from "@mui/material";

export const CalendarToolbarWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(5),
  margin: theme.spacing(5, 0),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "start",
    gap: theme.spacing(2),
  },
}));

export const CalendarToolbarDate = styled("div")(({ theme }) => ({
  fontSize: "1.5rem",

  [theme.breakpoints.down("sm")]: {
    order: 1,
    width: "100%",
  },
}));

export const CalendarToolbarMonth = styled("span")(({ theme }) => ({
  fontWeight: 700,
  marginRight: theme.spacing(1),
}));

export const CalendarButtonsWrapp = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),

  [theme.breakpoints.down("sm")]: {
    order: 2,
    width: "100%",
  },
}));

export const CalendarToolbarButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  textTransform: "none",
  fontWeight: 500,
  fontSize: "1rem",
  lineHeight: 1,
  height: "42px",
  color:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.primary.main,
  borderColor:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.primary.main,

  "&:hover": {
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.common.white
        : theme.palette.primary.main,
    background:
      theme.palette.mode === "dark"
        ? theme.palette.primary.light
        : theme.palette.common.white,
  },

  "& svg": {
    fontSize: "0.875rem",
  },

  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
}));

export const CalendarToolbarButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
}));

export const DateTitle = styled("span")(({ theme }) => ({
  fontSize: "1rem",
}));
