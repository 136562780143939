import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ReferralsFiltersActions,
  selectReferralsFilters,
  selectReferralsVacancies,
} from "@/store/redux";
import { useActions } from "@/utils/redux";
import { useFilters } from "@/utils/hooks";
import { SearchField } from "@/shared/components";
import { Sidebar } from "./Sidebar";
import { Vacancies } from "../Vacancies";
import { Filters } from "../Filters";
import { Wrapper, SearchWrapp } from "./Positions.styled";

export interface IFilters {
  parea: number;
  plevel: number;
  plocation: number;
  psearch: string;
  [k: string]: number | string;
}

interface IPositionsProps {
  onDialogOpen: () => void;
}

export function Positions(props: IPositionsProps) {
  const { onDialogOpen } = props;

  const { areas, levels, locations, pending } = useSelector(
    selectReferralsFilters
  );
  const { filters_counters, pending: vacanciesPending } = useSelector(
    selectReferralsVacancies
  );
  const { getReferralsFilters } = useActions<typeof ReferralsFiltersActions>(
    ReferralsFiltersActions
  );

  const { filters, handleOnFilters } = useFilters<IFilters>({
    parea: -1,
    plevel: -1,
    plocation: -1,
    psearch: "",
  });

  const areasWithCounts = () => {
    const transformedCounters = filters_counters.reduce(
      (obj, item) => Object.assign(obj, { [item.area_id]: item.count }),
      {}
    ) as any;

    const transformedAreas = areas.map((area) => ({
      ...area,
      title: transformedCounters[area.id]
        ? `${area.title} (${transformedCounters[area.id]})`
        : area.title,
    }));

    return transformedAreas;
  };

  const handleOnFilterChange = (filter: { [k: string]: number }) => {
    const updatedFilters = {
      ...filters,
      ...filter,
    };

    handleOnFilters(updatedFilters);
  };

  const handleOnSearchSubmit = (e: any) => {
    const psearch = e?.target?.value;

    handleOnFilters({ psearch });
  };

  useEffect(() => {
    getReferralsFilters();
  }, []); //eslint-disable-line

  return (
    <>
      <SearchWrapp>
        <SearchField
          disabled={vacanciesPending}
          loading={vacanciesPending}
          onSubmit={handleOnSearchSubmit}
          placeholder="Search by title, location, area or project"
        />
      </SearchWrapp>

      <Filters
        values={filters}
        areas={areasWithCounts()}
        levels={levels}
        locations={locations}
        pending={pending || vacanciesPending}
        onChange={handleOnFilterChange}
      />

      <Wrapper>
        <Sidebar
          menu={areasWithCounts()}
          values={filters}
          onChange={handleOnFilterChange}
        />

        <Vacancies filters={filters} onDialogOpen={onDialogOpen} />
      </Wrapper>
    </>
  );
}
