import { Box, Grid, Skeleton } from "@mui/material";

export function Loader() {
  return (
    <>
      {Array.from(Array(3).keys()).map((item: number) => (
        <Grid key={item} item xs={6} sm={4} md={3} lg={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Skeleton animation="wave" height={25} width="75%" />

            <Skeleton
              animation="wave"
              variant="circular"
              width={135}
              height={135}
            />

            <Skeleton animation="wave" height={20} width="30%" />
            <Skeleton animation="wave" height={20} width="60%" />

            <Skeleton animation="wave" height={30} width="90%" />
          </Box>
        </Grid>
      ))}
    </>
  );
}
