import { call, put, takeLatest } from "redux-saga/effects";
import { ICountriesStateSuccess } from "./reducers";
import { getCountries, getCountriesError, getCountriesSuccess } from "./slice";
import { getCountriesRequest } from "./api";

function* getCountriesSaga() {
  try {
    const countries: ICountriesStateSuccess = yield call(getCountriesRequest);

    yield put(getCountriesSuccess(countries));
  } catch (error) {
    yield put(getCountriesError({ error }));
  }
}

function* CountriesWatcher() {
  yield takeLatest(getCountries.type, getCountriesSaga);
}

export default CountriesWatcher;
