import { AxiosResponse } from "axios";
import { apiInstance } from "@/store/configureStore";
import { INewsCommentStatePending } from "./reducers";

export const postNewsCommentsRequest = async (
  params: INewsCommentStatePending
) => {
  const { id, ...restParams } = params;

  const response: AxiosResponse = await apiInstance.post(
    `/news/${id}/comments`,
    {
      ...restParams,
    }
  );

  return response.data;
};
